import React, { useContext, useEffect, useState } from "react";
import {
  addCampaign,
  fetchAdvertiserByAgencyId,
  fetchCampaignsByAdvertiser,
} from "../../../api/ssp-api";

import { useNavigate } from "react-router-dom";
import SspTable from "../../ssp/campaign-stats/campaigns-table";
import AgencyWrapper from "../../../components/wrappers/agency-wrapper";
import withAuth from "../../../auth/auth";
import { createCampaignApi } from "../../../api/api";
import moment from "moment";
import { BreadcrumbContext } from "../../../layouts/dashboardLayout";
import Loader from "../../../components/loader";

function AgencyOverview() {
  const [advertiserId, setSelectedAdvertiser] = useState("");
  const [data, setData] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [campaignLoading, setCampaignLoading] = useState(true);
  const navigate = useNavigate();
  const [role, setRole] = useState("agency");
  const [visible, setVisible] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [fetching, setFetching] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const {setActions} = useContext(BreadcrumbContext)
  const onRefresh = () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
  setActions(  <div
              className="py-2 px-3 bg-primary rounded-md text-white font-semibold cursor-pointer w-40 justify-center"
              onClick={() => {
                const data = {
                  advertiserId: 2,
                  campaignName: "New Campaign",
                  startDate: new Date("2018-09-01"),
                  endDate: new Date("2018-12-31"),
                  impressions: -1,
                  revenueType: 1,
                  revenue: 12.5,
                  clicks: -1,
                  priority: 0,
                  weight: 1,
                };
                setFetching(true);
                addCampaign(data, base64Credentials).then((response) => {
                  setFetching(false);
                  console.log(response.data);
                  navigate(
                    `/agency/campaign/create/${response.data.campaignId}`
                  );
                });
              }}
            >
              {fetching ? (
                <div className="h-5 w-5 border-2 border-t-transparent rounded-full animate-spin  " />
              ) : (
                "Create Campaign"
              )}
            </div>)
    if (role === "agency") {
      const user = localStorage.getItem("kwanza");
      const { token, role, agencyId } = user ? JSON.parse(user) : {};
      fetchAdvertisers(agencyId, base64Credentials);
    }
  }, [refresh]);

  const handleChange = (event) => {
    setSelectedAdvertiser(event.target.value);
    fetchCampaignDataForAdvertiser(event.target.value, base64Credentials);
  };

  const fetchAdvertisers = (agencyId, credentials) => {
    fetchAdvertiserByAgencyId(agencyId, credentials)
      .then((response) => {
        if (response.data.length === 0) {
          setNotFound(true);
          setLoading(false);
          setCampaignLoading(false);
          setCampaigns([]);
        }
        fetchCampaignData(response.data, credentials);
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCampaignData = async (data, credentials) => {
    try {
      let newData = [];

      for (const item of data) {
        const response = await fetchCampaignsByAdvertiser(
          item.advertiserId,
          credentials
        );

        if (response.status === 500) {
          setNotFound(true);
        } else {
          // newData = newData.concat(response.data);
          setCampaigns((prev) => {
            const combinedData = [...prev, ...response.data];

            // Sort combined data by date
            combinedData.sort((a, b) => {
              return b.campaignId - a.campaignId;
            });

            return combinedData;
          });
          setCampaignLoading(false);
        }
      }

      // setCampaigns(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCampaignDataForAdvertiser = (advertiserId, credentials) => {
    setVisible(true);
    setCampaignLoading(true);
    fetchCampaignsByAdvertiser(advertiserId, credentials)
      .then((response) => {
        if (response.status === 500) {
          setCampaigns([]);
          setNotFound(true);
          setLoading(false);
        } else {
          setCampaigns(response.data);
          setCampaignLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAdminView = (campaignId, advertiserId) => {
    let startDate = "2023-01-01";
    navigate(`/agency/campaign/${campaignId}/${startDate}/${advertiserId}`);
  };
  const handlePublisherView = (campaignId, advertiserId) => {
    let startDate = "2023-01-01";
    navigate(`/ssp/campaign/${campaignId}/${startDate}/${advertiserId}`);
  };

  return (
    <div header={"SSP"}>
      <div>
        {role === "publisher" ? (
          ""
        ) : (
          <div className="flex justify-end">
          
          </div>
        )}
      </div>
      <div>
        {campaignLoading || campaigns === undefined || advertiserId === 0 ? (
          <div item xs={12}>
            <Loader/>
          </div>
        ) : (
          <div item xs={12}>
            {campaigns === null || campaigns.length === 0 || !campaigns ? (
              <Loader/>
            ) : (
              <SspTable
                data={campaigns}
                onRefresh={onRefresh}
                handleAdminView={handleAdminView}
                handlePublisherView={handlePublisherView}
                role={role}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default withAuth(AgencyOverview);
