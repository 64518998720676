import { Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";


class ErrorBoundaryClass extends Component{
    constructor(props){
        super(props)
        this.state = {hasError:false}
    }
    static getDerivedStateFromError (error){
        return {hasError:true}
    }
    render() {
         if(this.state.hasError){
            return  <div className="flex flex-col justify-center items-center h-screen w-5/12 mx-auto">
                <img className="h-48" src="/error2.png"/>
            <h1 className="font-bold text-2xl">Oops! Something went wrong</h1>
            <p className="text-base text-muted mt-2">There is an issue on our end, we are currently resolving it.</p>
            <button onClick={()=>{
              this.props.navigate(-1)
              window.location.href = window.location.href;
            }} className="bg-primary py-2 px-6 rounded-md mt-8 text-base hover:scale-105  text-white font-medium duration-200 transition-all">Go Back</button>
        </div> 
         } else{
            return this.props.children
         }
       
    };
}

const ErrorBoundary = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <div>
        <ErrorBoundaryClass {...props} location={location} navigate={navigate} />
    </div>
}

export default ErrorBoundary;