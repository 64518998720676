
import AgencyWrapper from "../../../../../components/wrappers/agency-wrapper";
import React, { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import { uploadSspHtmlBanner } from "../../../../../api/api";
import { AuthContext } from "../../../../../provider/AuthContext";

export default function AgencyCreateHtmlBanner() {
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    width: "",
    height: "",
    zipFile: null,
    agencyId: agencyId,
    destinationUrl: "",
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]); // Store the selected file
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".zip",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setDisabled(true);
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }

    if (
      !formData.name ||
      !formData.width ||
      !formData.height ||
      !formData.destinationUrl
    ) {
      toast.error("Please fill in all the fields!", {
        position: "top-center",
      });
      return;
    }

    formData.zipFile = selectedFile;
    console.log(`formData: ${JSON.stringify(formData)}`);
    uploadSspHtmlBanner(formData, token)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Banner uploaded successfully!");
          window.location.href = "/agency/banners";
        }
      })
      .catch((error) => {
        toast(error.message);
        setLoading(false);
        setDisabled(false);
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
      });
  };

  return (
    <div header={"Upload HTML Banner"}>
      <ToastContainer />
      <div
        container
        spacing={2}
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        
      >
        <div item style={{ width: "35%" }}>
          <div>
            <div
              {...getRootProps()}
              style={{
                border: "2px dashed gray",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography>Drop the ZIP file here...</Typography>
              ) : (
                <Typography>
                  Drag 'n' drop a ZIP file here, or click to select a file
                </Typography>
              )}
            </div>
            {selectedFile && (
              <Paper style={{ marginTop: "10px", padding: "10px" }}>
                <Typography variant="subtitle1">
                  File Name: {selectedFile.name}
                </Typography>
                <Typography variant="subtitle1">
                  File Size: {selectedFile.size} bytes
                </Typography>
              </Paper>
            )}
          </div>
        </div>
        <div item style={{ width: "35%" }}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            label="Destination Url"
            name="destinationUrl"
            value={formData.destinationUrl}
            onChange={handleChange}
            required
          />
        </div>
        <div item style={{ width: "35%" }}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div item style={{ width: "35%" }}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            label="Width"
            name="width"
            required
            number
            value={formData.width}
            onChange={handleChange}
          />
        </div>
        <div item style={{ width: "35%" }}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            label="Height"
            name="height"
            required
            number
            value={formData.height}
            onChange={handleChange}
          />
        </div>
        <div item style={{ width: "35%" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            sx={{
              backgroundColor: "#6EBF4A",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#6EBF4A",
                color: "#ffffff",
              },
            }}
            type="submit"
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress size="2.0rem" /> : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
}
