export const styles = {
  root: {
    marginTop: "3rem",
    paddingLeft: "2rem",
    "& .MuiInputBase-input": {
      height: "0.43rem",
      color: "rgba(151, 151, 151, 0.87)",
      fontSize: "0.8rem",
      borderRadius: "9px",
    },
    "& .MuiPickersDay-daySelected span": {
      color: "white",
    },
  },
  input: {
    width: "100%",
    height: "2.8rem",
    marginRight: "3.5rem",
  },

  datepicker: {
    width: "100%",
    color: "rgba(151, 151, 151, 0.87)",
  },
  row: {
    marginBottom: "1.52rem",
    "&:first-child": {
      marginRight: "2rem",
    },
  },
  first: {
    marginRight: "3.6rem",
  },
  helperText: {
    marginBottom: "0.6rem",
  },
  createCampaign: {
    backgroundColor: "#6EBF4A",
    color: "#FFF",
    "&:hover,&:focus": {
      backgroundColor: "#6EBF4A",
    },
    disabled: {
        backgroundColor: "#d8d5d5",
    },
    borderRadius: "9px",
    textTransform: "lowercase",
    width: "80%",
    height: "2.8rem",
    marginBottom: "4rem",
  },
  loader: {
    color: "white",
  },
};
