import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchBannerStatsApi,
  fetchCampaignStatsApi,
  fetchClicksApi,
  fetchIpDataApi,
  fetchOsData,
  fetchSitesApi,
} from "../../../../api/api";
import BannerStatsTable from "../report-summary/advert-summary";
import { AuthContext } from "../../../../provider/AuthContext";
import Loader from "../../../../components/loader";
import { BreadcrumbContext } from "../../../../layouts/dashboardLayout";
import CampaignStatsGraph from "../../../overview/campaign-stats-graph";
import moment from "moment";
import BarGraph from "../../../overview/bar-graph";
import PieChart from "../../../overview/pie-chart";
import MixedGraph from "../../../overview/mixed-graph";
import { BsAlexa, BsDatabase, BsFolder2Open, BsFolderFill } from "react-icons/bs";
import NoData from "../../../../components/noData";


const styles = {
  root: {
    flexGrow: 1,
  },
  skeleton: {
    width: "max-width",
    height: "500px",
  },
  paper: {
    // height: 329,
    background: "#F7F9FA",
    borderRadius: "25px",
    boxShadow: "none",
  },
  paperreport: {
    background: "#F7F9FA",
    borderRadius: "16px",
    height: "5rem",
    padding: "14% 0 0rem 16%",
    textAlign: "left",
  },
  button: {
    background: "#6EBF4A",
    fontFamily: "SF UI Display",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    color: "#FFFFFF",
  },
  buttonAds: {
    background: "#6EBF4A",
    fontFamily: "SF UI Display",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    color: "#FFFFFF",
    marginTop: "15px",
  },
  label: {
    marginTop: "24px",
  },
  title: {
    fontSize: 14,
    textAlign: "left",
    marginTop: "10px",
  },
  title2: {
    fontSize: 14,
    textAlign: "left",
    marginTop: "10px",
    marginLeft: "10px",
  },
  title3: {
    fontSize: 14,
    textAlign: "left",
    marginLeft: "10px",
  },
  div: {
    minWidth: "17%",
    marginRight: "2rem",
  },
  figure: {
    color: "#4B4B4B",
    fontWeight: 700,
    fontSize: "1.4rem",
  },
};

function ReportOverview(props) {
  const [loading, setLoading] = useState(true);
  const [bannerStats, setBannerStats] = useState(null);
  const [campaignStats, setCampaignStats] = useState(null);
  const [sitesData, setSitesData] = useState(null);
  const [clicksData, setClicksData] = useState(null);
  const [ipData, setIpData] = useState(null);
  const [osData, setOsData] = useState(null);
  const { userDetails } = useContext(AuthContext);
  const { setActions } = useContext(BreadcrumbContext);
  const { id } = useParams();

  useEffect(() => {
    setActions(
      <div>
        {/* <button className=" bg-primary rounded-md py-2 px-3 text-white w-40 flex justify-center font-medium items-center">
          Download Excel
        </button> */}
      </div>
    );
    fetchData();
  }, []);

  const fetchData = () => {
    const { token, role } = JSON.parse(userDetails);
    setLoading(true);
    const campaignReportAPIs = [
      fetchCampaignStatsApi(id, token),
      fetchBannerStatsApi(id, token),
      fetchSitesApi(id, token),
      fetchClicksApi(id, token),
      fetchIpDataApi(id, token),
      fetchOsData(id, token),
    ];
    Promise.all(campaignReportAPIs)
      .then((response) => {
        console.log("stats", response);
        setCampaignStats(response[0].data.campaignStats);
        setBannerStats(response[1].data);
        setSitesData(response[2].data);
        setClicksData(response[3].data);
        console.log("Ip data", response[4].data.data);
        setIpData(response[4].data.data);
        const dataMap = new Map(Object.entries(response[5].data.data[0]));
        const keys = Array.from(dataMap.keys());
        const values = Array.from(dataMap.values());
        setOsData({ keys, values });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(0);
        console.log(error);
      });
  };

  return loading ? (
    <Loader />
  ) : campaignStats.length < 2 ? (
    <NoData/>
  ) : (
    <div className="space-y-5">
      <div className="grid grid-cols-2  gap-5">
        {campaignStats && campaignStats.length > 1 && (
          <div className="bg-white p-8 rounded-md border  border-border">
            <p className="text-base mb-2 font-medium ">Impression</p>
            <div>
              <div>
                <MixedGraph
                  yValuesName="Impression"
                  yValuesName2="CTR"
                  values={campaignStats.map((item) => item.impressions)}
                  values2={campaignStats.map((item) => item.ctr * 100)}
                  xlabels={campaignStats.map((item) =>
                    moment(new Date(item.date)).format("DD MMM, yyy")
                  )}
                />
              </div>
            </div>
          </div>
        )}

        {campaignStats && campaignStats.length > 1 && (
          <div>
            <div className="bg-white p-8 rounded-md border  border-border">
              <p className="text-base mb-2 font-medium  ">Clicks</p>
              <div>
                <div>
                  <CampaignStatsGraph
                    valueName={"Clicks"}
                    categories={campaignStats.map((item) =>
                      moment(new Date(item.date)).format("DD MMM, yyy")
                    )}
                    values={campaignStats.map((item) => item.clicks)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {bannerStats && bannerStats.length > 0 && (
        <div>
          <div className="bg-white p-8 rounded-md border  border-border">
            <p className="text-base mb-2 font-medium ">Creatives Performance</p>
            <div>
              <p>
                <BannerStatsTable data={bannerStats} />
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 gap-5">
        {bannerStats && bannerStats.length > 0 && (
          <div className="bg-white p-8 rounded-md border  border-border">
            <p className="text-base mb-2 font-medium ">Creatives Impression</p>
            <div>
              <div>
                <BarGraph
                  yValuesName="Impressions"
                  values={bannerStats.map((item) => item.impressions)}
                  xlabels={bannerStats.map((item) => item.name)}
                />
              </div>
            </div>
          </div>
        )}

        {bannerStats && bannerStats.length > 0 && (
          <div>
            <div className="bg-white p-8 rounded-md border  border-border">
              <p className="text-base mb-2 font-medium  ">Creatives Clicks</p>
              <div>
                <div>
                  <BarGraph
                    yValuesName="Clicks"
                    values={bannerStats.map((item) => item.clicks)}
                    xlabels={bannerStats.map((item) => item.name)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 items-stretch gap-5">
        {sitesData && sitesData.length > 0 && (
          <div>
            <div className="bg-white p-8 rounded-md border h-full border-border">
              <p className="text-base mb-2 font-medium  ">
                Sites impressions data
              </p>

              <div>
                <PieChart
                  values={sitesData.map((item) => item.impressions)}
                  measurement=""
                  labels={sitesData.map((item) =>
                    item.domain
                      .replace(/%20/g, " ")
                      .replace(/%3A/g, " -")
                      .replace(/%26/g, "&")
                  )}
                />
              </div>
            </div>
          </div>
        )}

        {clicksData && clicksData.length > 0 && (
          <div>
            <div className="bg-white p-8 rounded-md border  h-full border-border">
              <p className="text-base mb-2 font-medium  ">Sites clicks data</p>
              <div>
                <PieChart
                  values={clicksData.map((item) => item.clicks)}
                  measurement=""
                  labels={clicksData.map((item) =>
                    item.domain
                      .replace(/%20/g, " ")
                      .replace(/%3A/g, " -")
                      .replace(/%26/g, "&")
                  )}
                />
              </div>
            </div>
          </div>
        )}

        {ipData && ipData.values.length > 0 && (
          <div>
            <div className="bg-white p-8 rounded-md border h-full border-border">
              <p className="text-base mb-2 font-medium  ">Operators</p>
              <div>
                <PieChart
                  values={ipData.map((item) => item.doc_count)}
                  measurement=""
                  labels={ipData.map((item) => item.company)}
                />
              </div>
            </div>
          </div>
        )}
        {osData && osData.values.length > 0 && (
          <div>
            <div className="bg-white p-8 rounded-md border h-full  border-border">
              <p className="text-base mb-2 font-medium  ">Operating System</p>

              <div>
                <PieChart
                  values={osData.values.map((item) =>
                    item == null ? 0 : item
                  )}
                  measurement=""
                  labels={osData.keys}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-5  content-stretch ">
        {/* <div>
          <p className="text-sm mb-2">Telco Segmentation</p>
          <div className="bg-white border border-border p-8 rounded">
            {ipData && (
              <div>
                <p >
                  <PieChartComponent data={ipData} />
                </p>
              </div>
            )}
          </div>
        </div> */}
        {/* <div className="h-full">
          <p className="text-sm mb-2">Operating System</p>

          <div className="bg-white border border-border rounded-md p-8">
            {osData && (
              <div>
                <p >{osData.length <1 && <OsComponent data={osData} />}
                  
                </p>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ReportOverview;
