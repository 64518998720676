import React, { useState } from "react";

import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

const ZoneDataTable = ({ data, role }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [filter, setFilter] = useState("");

  const publishersData = data;

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = publishersData.slice().sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    } else {
      return a[orderBy] < b[orderBy] ? 1 : -1;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div className="bg-white px-5 py-5 w-full rounded-md mt-0">
        <div className="flex justify-end space-x-2">
          <input
            label="Filter by name or campaign name"
            placeholder="Search here"
            className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <table className="text-sm table w-full">
          <thead className="text-muted">
            <tr className="">
              <th className="py-3 text-sm text-start">Publisher Name</th>
              <th className="text-sm text-start py-3">Impressions</th>
              <th className="text-sm text-start py-3">Clicks</th>
              <th className="text-sm text-start py-3">CTR</th>
              <th className="text-sm text-start py-3">Revenue</th>
            </tr>
          </thead>
          <tbody className="text-start">
            {sortedData
              .filter((item) =>
                item.publishername.toLowerCase().includes(filter.toLowerCase())
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                  <td className="text-start py-3 px-0 ">
                    {item.publishername}
                  </td>
                  <td className="text-start py-3 px-0 ">{item.impressions}</td>
                  <td className="text-start py-3 px-0 ">{item.clicks}</td>
                  <td className="text-start py-3 px-0">
                    {((item.clicks / item.impressions) * 100).toFixed(2)}
                  </td>
                  <td className="text-start py-3 px-0 ">
                    {role === "publisher"
                      ? Number(item.revenue).toFixed(3) * 1000
                      : Number(item.revenue).toFixed(3)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
          <h1 className=" translate-x-5">Rows per page</h1>
          <select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(e.target.value);
            }}
            className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
          >
            <option className="" value={5}>
              5
            </option>
            <option className="" value={8}>
              8
            </option>
          </select>
          <div
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
          >
            <MdOutlineChevronLeft />
          </div>
          <h1>
            {page + 1} out of {Math.ceil(sortedData.length / rowsPerPage)}
          </h1>
          <div
            onClick={() => {
              if (page < Math.round(sortedData.length / rowsPerPage)) {
                setPage(page + 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
          >
            <MdOutlineChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZoneDataTable;
