import React, { useContext, useEffect, useState } from "react";

import { styles } from "./style";
import { budgets as budgetItems } from "./constant";
import BudgetItem from "./budget-item";
import clsx from "clsx";
import SubmitButtons from "../submit_buttons";
import { toDays } from "../../../util";

import { useBudgetData } from "../../../provider/BudgetContext";

import { useCampaignData } from "../../../provider/DataContext";
import { getCampaignById, submitBudgetApi } from "../../../api/api";
import { IoMdAdd } from "react-icons/io";
import Spinner from "../../../components/spinner";
import { IoCloseSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../provider/AuthContext";
import { toast } from "react-toastify";
import { CampaignContext } from "../campaign-create/create-campaign-tabs";



const Budget = ({ classes, history }) => {
  const {uuid} = useParams()

  const [showBudget, setShowBudget] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [budgets, setBudgets] = useState(budgetItems);
  const [isLoading, setIsLoading] = useState(false);
  const { addData } = useBudgetData();
  const { userDetails } = useContext(AuthContext);
  const { token } = JSON.parse(userDetails);
  const [campaign, setCampaign] = useState(null);
  const {activeStep,setActiveStep} = useContext(CampaignContext);
  

  useEffect(() => {
    getCampaignById(token, uuid).then((response) => {
      console.log(response.data)
      setCampaign(response.data);
    });
  }, []);

  const onShowBudget = (_, amount) => {
    console.log(_, amount);
    setAmount(amount);
  };

  const submitCampaignBudget = (campaign,budget) => {
    if (selectedIndex !== null) {
      setIsLoading(true);
     // get daily budget from total budget and days between start and end date
      let days = toDays(new Date(campaign.expire_time), new Date(campaign.activate_time)) ;
      let daily = (budget)/days;
      const budgetData = {
        totalBudget: budget,
        dailyBudget: parseInt(daily),
      };
      addData(budgetData);
      submitBudgetApi(budgetData, uuid)
        .then((res) => {
          setIsLoading(false);
          setActiveStep(activeStep+1)
          toast.success("Saved Successully")
        })
        .catch((err) => {
          console.log("error", err);
          setIsLoading(false);
        });
    } else {
      setActiveStep(activeStep+1)
      console.log("not selected");
    }
  };

  const submitBudget = () => {
    setBudgets((prevBudgets) => [
      ...prevBudgets,
      {
        daily: amount,
        monthly: amount * 30,
        recommended: false,
      },
    ]);
    setShowBudget(false);
    setSelectedIndex(budgets.length);
    addData(budgets, selectedIndex);
    console.log("budgets", budgets, "selectedIndex", selectedIndex);
  };

  const onBudgetChange = (amount) => {
    setAmount(amount);
  };

  

  const toggleShow = () => {
    setShowBudget(!showBudget);
  };

  return campaign&&(
    <div header={"Budget"}>
      <div className="bg-white p-10 border border-border rounded">
       
        <div >
          <h1 className="text-2xl font-bold">Let's set your budget</h1>
          <h1 className="text-sm">
            Select the option that works best for your business and the results you want. You can make updates any time.
          </h1>
          
          <div className="grid grid-cols-4 mt-6 gap-4">
            {budgets.map(({ daily, monthly, recommended }, index) => (
            <div onClick={()=>{
              setSelectedIndex(index)
            }} className={`w-full border border-border flex hover:scale-105 transition-all flex-col rounded-md justify-center items-center py-8 cursor-pointer 
            ${selectedIndex == null && ((campaign.total_budget)/(toDays(new Date(campaign.expire_time), new Date(campaign.activate_time))))==daily && "bg-primary bg-opacity-5"} ${index==selectedIndex&&"bg-primary bg-opacity-5"}`}>
               <h1 className="text-2xl font-bold text-primary">{daily}$</h1>
               <p className="text-sm text-slate-500">Daily estimate</p>
               <p className="text-sm mt-2"><span className="font-bold">{monthly}$</span>  Monthly maximum</p>

            </div>

            ))}
              {/* <BudgetItem
                daily={daily}
                monthly={monthly}
                recommended={recommended}
                selectedIndex={selectedIndex}
                key={index}
                classes={classes}
                index={index}
                onHandleClick={onHandleClick}
              /> */}
            <button className="w-full flex flex-col justify-center items-center text-sm border border-border rounded" onClick={toggleShow}>
            <IoMdAdd className="text-primary size-12" />
              Enter your own Budget
            </button>
            <Modal open={showBudget}>
              <div className="w-full h-full flex flex-col justify-center items-center">
              <div className="w-6/12 2xl:w-4/12 mx-auto bg-white p-10 rounded">
              <div className="flex justify-end">
        <IoCloseSharp onClick={()=>{
          setShowBudget(false)
        }} className="size-5 text-textColor cursor-pointer" />
        </div>
              <h1 className="text-xl font-bold">Enter your own budget</h1>
                <p className="text-sm mt-3">
                  Set the amount you'd like to spend
                </p>
                <div xs={12} container justify="flex-end">
                  <div xs={6} container justify="flex-end">
                    <div xs={6} container alignItems="flex-end">
                      <input
                        className="form-style"
                        type="number"
                        value={amount}
                        min={5}
                        onChange={(event) => {
                          alert(event.target.value.split('$')[0])
                          onBudgetChange(event.target.value.split('$')[0])
                        }}
                       
                      />
                    </div>
                    <div >
                     
                      <label className="text-xs text-primary font-bold">
                        daily average*
                      </label>
                    </div>

                    <div className="mt-3">
                      <label
                        sx={{
                          ...styles.descText,
                          ...styles.monthText,
                        }}
                      >
                        ${amount * 30} monthly maximum
                      </label>
                    </div>
                  </div>
                </div>
                <div xs={12} sx={styles.slider}>
                  <CustomSlider
                    onChange={onShowBudget}
                    defaultValue={amount}
                    className=""
                    value={amount}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                  />
                </div>
                <div >
                 
                  <button
              className=" bg-primary text-white rounded-md py-2 px-4 w-40 flex justify-center"
              onClick={submitBudget}
              style={{ marginTop: "20px" }}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                "Add Budget"
              )}
            </button>
                  
                </div>
             </div>
              </div>
              
              
            </Modal>
          </div>
          <div className="flex justify-between mt-6">
            <div className="w-full flex justify-start space-x-2">
            <button onClick={()=>{
                setActiveStep(activeStep-1)
            }}
                className="text-textColor py-2  justify-center flex hover:text-primary transition-all duration-200"
                type="submit"
                style={{ marginTop: "20px" }}
              >
                Back
              </button>
              <button
              className=" bg-primary text-white rounded-md py-2 px-4 w-40  flex justify-center"
              onClick={submitCampaignBudget}
              style={{ marginTop: "20px" }}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                "Save & Continue"
              )}
            </button>
           </div>
           <button
           className="py-2 rounded-md  text-textColor   justify-center flex hover:text-primary transition-all duration-200"
            onClick={()=>{
              setActiveStep(activeStep+1)
            }}
            type="submit"
          >
            Skip
          </button>
            </div>
        
          
          {/* <SubmitButtons
            isLoading={isLoading}
            next={submitCampaignBudget}
            redirectUrl={`/create-campaign/banner`}
          /> */}
        </div>
        {/*<div xs={4}>*/}
        {/*  <PotentialAudience />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Budget;
