
import { ReactComponent as EditCampaign } from "../../assets/editCampaign.svg";

import clsx from "clsx";

const styles = {
  item: {
    marginTop: "-2rem",
  },
  button: {
    backgroundColor: "#FFF",
    color: "#6EBF4A",
    "&:hover,&:focus": {
      backgroundColor: "#FFF",
    },
    borderRadius: "8px",
    textTransform: "none",
    fontSize: "0.9rem",
    width: "24%",
    height: "2.8rem",
    marginTop: "2rem",
    "& .icon": {
      marginRight: "1.7rem",
    },
  },
  isFull: {
    width: "100%",
  },
};

const EditCampaignButton = ({ full, currentTab, history, redirectUrl }) => {
  function handleEdit() {
    navigate(redirectUrl);
  }

  return (
    <div
      xs={12}
      container
      alignItems="center"
      justify="flex-end"
      sx={styles.item}
    >
      <Button
        sx={{
          ...styles.button,
        ...{ [styles.isFull]: full }}}
        onClick={() => handleEdit()}
      >
        <EditCampaign className="icon" />
        Edit information
      </Button>
    </div>
  );
};

export default EditCampaignButton;
