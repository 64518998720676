import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Campaigns from "../containers/campaigns/campaigns-dsp-overview";
import Home from "../containers/home/login";
import NotFound from "../components/not-found";
import CampaignOverview from "../containers/overview/campaign-overview";
import Reports from "../containers/reports";
import CampaignCreate from "../containers/campaigns/campaign-create/create-campaign";
import NewAudience from "../containers/audience/new-audience";
import Audience from "../containers/audience/audience";
import ViewCampaign from "../containers/campaigns/campaign-edit/view-campaign";
import Settings from "../containers/settings";
import Support from "../containers/support";
import ReportView from "../containers/reports/views/reports-view";
import CampaignData from "../containers/reports/views/report-campaign-data/report-campaign-data";
import AdvertData from "../containers/reports/views/report-advert-data/report-advert-data";
import SitesData from "../containers/reports/views/report-sites-data/report-sites-data";
import Billing from "../containers/billing";
import InvoiceGenerator from "../containers/invoice-generator";
import InvoiceTemplate from "../containers/invoice-generator/invoice-template";
import Banners from "../containers/banners/banners";
import CampaignsOverview from "../containers/ssp/campaigns-overview";
import SspCampaignData from "../containers/ssp/campaign-stats/campaign-data";
import Users from "../containers/users/users";
import EditAudience from "../containers/audience/edit-audience";
import CampaignAudience from "../containers/campaigns/campaign-audience/campaign-audience-info";
import CampaignAdvert from "../containers/campaigns/campaign-advert/create-campaign-advert";
import Budget from "../containers/campaigns/campaign-budget/create-campaign-budget";
import PreviewCampaign from "../containers/campaigns/campaign-preview/campaign-preview";
import PublisherWebsites from "../containers/websites/publisher-websites";
import PublisherForm from "../containers/websites/new-website";
import Zones from "../containers/zones/zones";
import ZoneForm from "../containers/zones/new-zone";
import ZoneIc from "../containers/zones/ic-zone";
import CreateSspCampaign from "../containers/ssp/create-campaign/create-campaign";
import CreateBanner from "../containers/banners/create-banner";
import ZoneInfo from "../containers/zones/zone-info";
import LinkBanners from "../containers/banners/link-banner-zone";
import SspInvoice from "../containers/ssp/invoice/SspInvoice";
import CreateSSPBanner from "../containers/ssp/create-campaign/create-banner";
import LinkCampaignToZones from "../containers/ssp/create-campaign/link-campaign";
import EditSspCampaignTabs from "../containers/ssp/edit-campaign/edit-tabs";
import PublisherCampaignData from "../containers/publisher-module/publisher-campaign-data";
import Invoices from "../containers/ssp/invoice/Invoices";
import PublisherOverview from "../containers/publisher-module/publisher-overview";
import PublisherInvoices from "../containers/publisher-module/publisher-invoices";
import AgencyOverview from "../containers/agency-module/ssp/ssp-overview";
import AgencyPublisherWebsites from "../containers/agency-module/ssp/websites/publisher-websites";
import AgencyAdvertiser from "../containers/agency-module/ssp/advertiser/advertiser";
import AgencyNewAdvertiser from "../containers/agency-module/ssp/advertiser/new-advertiser";
import AgencyViewAdvertiser from "../containers/agency-module/ssp/advertiser/view-advertiser";
import AgencyZones from "../containers/agency-module/ssp/zones/zones";
import AgencyZoneForm from "../containers/agency-module/ssp/zones/new-zone";
import AgencyZoneInfo from "../containers/agency-module/ssp/zones/zone-info";
import AgencyZoneIc from "../containers/agency-module/ssp/zones/ic-zone";
import AgencyInvoices from "../containers/agency-module/ssp/invoices/ssp-invoices";
import AgencyBanners from "../containers/agency-module/ssp/banners/banners";
import AgencyCreateBanner from "../containers/agency-module/ssp/banners/create/create-image-banner";
import AgencyUsers from "../containers/agency-module/ssp/users/users";
import AgencyBilling from "../containers/agency-module/ssp/billing";
import AgencyCreateCampaign from "../containers/agency-module/ssp/campaigns/create-campaign/create-campaign";
import AgencyLinkCampaign from "../containers/agency-module/ssp/campaigns/create-campaign/link-campaign";
import AgencyPublisherForm from "../containers/agency-module/ssp/websites/new-website";
import AgencyEditSspCampaignTabs from "../containers/agency-module/ssp/campaigns/edit-campaign/edit-tabs";
import AgencySspCampaignData from "../containers/agency-module/ssp/campaigns/campaign-stats/campaign-data";
import AgencyData from "../containers/agency/agency-overview";
import AgencyView from "../containers/agency/view-agency";
import AgencyCampaignBanner from "../containers/agency-module/ssp/campaigns/create-campaign/create-banner";
import AgencyCreateHtmlBanner from "../containers/agency-module/ssp/banners/create/create-html-banner";
import AgencyEditBanner from "../containers/agency-module/ssp/banners/edit/banner-info";
import AdvertiserOverview from "../containers/advertiser-module/campaign-overview";
import AdvertiserSspCampaignData from "../containers/advertiser-module/campaigns/campaign-data";
import AdvertiserBanners from "../containers/advertiser-module/banners/banners";
import AdvertiserEditBanner from "../containers/advertiser-module/banners/banner-info";
import Advertiser from "../containers/advertiser/advertiser";
import NewAdvertiser from "../containers/advertiser/new-advertiser";
import ViewAdvertiser from "../containers/advertiser/view-advertiser";
import DashboardLayout from "../layouts/dashboardLayout";
import ErrorBoundary from "../layouts/errorBoundry";
import EditZone from "../containers/zones/edit-zone";
import CreateCampaignTabs from "../containers/campaigns/campaign-create/create-campaign-tabs";
import EditPublisher from "../containers/websites/edit-website";
import ResetEmail from "../containers/home/reset-email";
import ResetPassword from "../containers/home/password-reset";
import GeneralReports from "../containers/reports/general-reports";
import CompaniesList from "../containers/reports/companies-list";
import ChannelsList from "../containers/reports/channels-list";
import CampaignReport from "../containers/reports/campaign-report";
import RegistrationPage from "../containers/website-pages/registrationPage";
import WebsiteLayout from "../layouts/websiteLayout";
import HomePage from "../containers/website-pages/homePage";
import PrivacyPolicy from "../containers/website-pages/privacyPolicy";
import TermsOfUse from "../containers/website-pages/termsOfUse";
import InfluencersList from "../containers/influencers-module/influencers-list";
import InfluencerGigs from "../containers/influencers-module/influencer_gigs";
import GIgPosts from "../containers/influencers-module/gigPosts";
import ScrollToTop from "../containers/website-pages/scrollToTop";

const AllRoutes = () => {
  const pathname = useLocation();

  return (
    <ErrorBoundary>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Home />} />
        <Route path="/" element={<WebsiteLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<RegistrationPage />} />
        </Route>
        <Route path="/reset-email" element={<ResetEmail />} />
        <Route
          path="/password-reset/:email/:token"
          element={<ResetPassword />}
        />
        <Route path="/" element={<DashboardLayout />}>
          <Route path="/" element={<CampaignOverview />} />
          <Route path="/advertiser" element={<Advertiser />} />
          <Route path="/advertiser/new" element={<NewAdvertiser />} />
          <Route path="/advertiser/view/:id" element={<ViewAdvertiser />} />
          <Route path="/audience" element={<Audience />} />
          <Route path="/audience/create-audience" element={<NewAudience />} />
          <Route path="/audience/new/:campaignId" element={<NewAudience />} />
          <Route
            path="/influencers/gigs/:userId"
            element={<InfluencerGigs />}
          />
          <Route path="/gig/posts/:gigId" element={<GIgPosts />} />
          <Route path="/audience/edit/:audienceId" element={<EditAudience />} />
          <Route path="/agencies" element={<AgencyData />} />
          <Route path="/agencies/:id/:name" element={<AgencyView />} />
          <Route path="/campaigns" element={<Campaigns />} />
          <Route
            path="/create-campaign/dsp/:uuid"
            element={<CreateCampaignTabs />}
          />
          <Route
            path="/create-campaign/information"
            element={<CampaignCreate />}
          />
          <Route
            path="/create-campaign/audience"
            element={<CampaignAudience />}
          />
          <Route
            path="/create-campaign/banner/:campaignId"
            element={<CampaignAdvert />}
          />
          <Route
            path="/create-campaign/budget/:campaignId"
            element={<Budget />}
          />
          <Route
            path="/create-campaign/preview"
            element={<PreviewCampaign />}
          />
          <Route
            path="/create-campaign/:campaignId"
            element={<CampaignCreate />}
          />

          <Route
            path="/generate-invoice/invoice-type"
            element={<InvoiceGenerator />}
          />
          <Route
            path="/generate-invoice/invoice-type/:invoiceId"
            element={<InvoiceGenerator />}
          />
          <Route
            path="/generate-invoice/company-details/:invoiceId"
            element={<InvoiceGenerator />}
          />
          <Route
            path="/generate-invoice/invoice-details/:invoiceId"
            element={<InvoiceGenerator />}
          />
          <Route
            path="/generate-invoice/invoice-preview/:invoiceId"
            element={<InvoiceGenerator />}
          />

          <Route path="/overview" element={<CampaignOverview />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/audience/new" element={<NewAudience />} />
          <Route path="/campaigns/:campaignId" element={<ViewCampaign />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/support" element={<Support />} />
          <Route path="/banners" element={<Banners />} />
          <Route path="/influencers" element={<InfluencersList />} />
          <Route path="/banners/create" element={<CreateBanner />} />
          <Route path="/websites" element={<PublisherWebsites />} />
          <Route path="/websites/:uuid" element={<EditPublisher />} />
          <Route path="/websites/new" element={<PublisherForm />} />
          <Route path="/zones" element={<Zones />} />
          <Route path="/zones/new" element={<ZoneForm />} />
          <Route path="/zones/edit/:zoneId" element={<EditZone />} />
          <Route path="/zones/ic/:zoneId" element={<ZoneIc />} />
          <Route path="/ssp/invoice" element={<SspInvoice />} />
          <Route path="/report/:id" element={<ReportView />} />
          <Route path="/data/:id" element={<CampaignData />} />
          <Route path="/ad/:Id" element={<AdvertData />} />
          <Route path="/sites/:Id" element={<SitesData />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/invoice/template" element={<InvoiceTemplate />} />
          <Route path="/users" element={<Users />} />

          {/*ssp/admin*/}
          <Route path="/ssp" element={<CampaignsOverview />} />
          <Route
            path="/ssp/campaign/:id/:startDate/:advertiserId"
            element={<SspCampaignData />}
          />
          <Route path="/ssp/campaign/create" element={<CreateSspCampaign />} />
          <Route path="/ssp/banner/create" element={<CreateSSPBanner />} />
          <Route
            path="/ssp/campaign/link/:id"
            element={<LinkCampaignToZones />}
          />
          <Route
            path="/ssp/campaign/edit/:id"
            element={<EditSspCampaignTabs />}
          />
          <Route path="/ssp/banner/:id" element={<LinkBanners />} />

          {/*ssp/agency*/}
          <Route path="/agency/ssp" element={<AgencyOverview />} />
          <Route
            path="/agency/campaign/create/:uuid"
            element={<AgencyCreateCampaign />}
          />
          <Route
            path={"/agency/campaign/banner/create"}
            element={<AgencyCampaignBanner />}
          />
          <Route
            path={"/agency/campaign/edit/:id"}
            element={<AgencyEditSspCampaignTabs />}
          />
          <Route
            path={"/agency/campaign/:id/:startDate/:advertiserId"}
            element={<AgencySspCampaignData />}
          />
          <Route path="/agency/banners" element={<AgencyBanners />} />
          <Route
            path="/agency/banners/create"
            element={<AgencyCreateBanner />}
          />
          <Route
            path="/agency/banners/edit/:id"
            element={<AgencyEditBanner />}
          />
          <Route
            path="/agency/banners/html/create"
            element={<AgencyCreateHtmlBanner />}
          />
          <Route
            path={"/agency/campaign/link/:id"}
            element={<AgencyLinkCampaign />}
          />
          <Route path="/agency/advertiser" element={<AgencyAdvertiser />} />
          <Route
            path="/agency/advertiser/new"
            element={<AgencyNewAdvertiser />}
          />
          <Route path="/general/reports/:id" element={<GeneralReports />} />
          <Route
            path="/traditional-campaign/reports/:id"
            element={<CampaignReport />}
          />
          <Route path="/reports/companies" element={<CompaniesList />} />
          <Route path="/reports/channels" element={<ChannelsList />} />
          <Route
            path="/agency/advertiser/view/:id"
            element={<AgencyViewAdvertiser />}
          />
          <Route
            path="/agency/websites"
            element={<AgencyPublisherWebsites />}
          />
          <Route
            path="/agency/websites/new"
            element={<AgencyPublisherForm />}
          />

          <Route path="/agency/zones" element={<AgencyZones />} />
          <Route path="/agency/zones/new" element={<AgencyZoneForm />} />
          <Route
            path="/agency/zones/edit/:zoneId"
            element={<AgencyZoneInfo />}
          />
          <Route path="/agency/zones/ic/:zoneId" element={<AgencyZoneIc />} />

          <Route path="/agency/invoices" element={<AgencyInvoices />} />
          <Route path="/agency/billing" element={<AgencyBilling />} />
          <Route path="/agency/users" element={<AgencyUsers />} />

          {/*ssp/publisher*/}
          <Route path={"/publisher/ssp"} element={<PublisherOverview />} />
          <Route
            path="/publisher/ssp/campaign/:id/:startDate/:advertiserId"
            element={<PublisherCampaignData />}
          />
          <Route path={"/publisher/invoices"} element={<PublisherInvoices />} />

          <Route path={"/invoices"} element={<Invoices />} />

          {/*ssp/advertiser*/}
          <Route path="/advertiser/ssp" element={<AdvertiserOverview />} />
          <Route
            path="/advertiser/campaign/:id/:startDate/:advertiserId"
            element={<AdvertiserSspCampaignData />}
          />
          <Route path={"/advertiser/banners"} element={<AdvertiserBanners />} />
          <Route
            path="/advertiser/banners/edit/:bannerId"
            element={<AdvertiserEditBanner />}
          />
        </Route>

        {/*<Route  path="/advertiser/new" element={<NewAdvertiser />} />*/}
        {/*<Route  path="/advertiser/view/:id" element={<ViewAdvertiser />} />*/}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default AllRoutes;
