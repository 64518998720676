import { AnimatePresence, useInView, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const ValuesSection2 = () => {
  const navigate = useNavigate();

  // Refs for both sections
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  // Check if sections are in view
  const section1InView = useInView(section1Ref, { threshold: 0.5 });
  const section2InView = useInView(section2Ref, { threshold: 0.5 });

  // Images for marketers and creators
  const images = ["/images/formarketers.svg", "/images/forcreators.svg"];

  // State to track current image
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    if (section1InView) {
      setCurrentImage(0);
    } else if (section2InView) {
      setCurrentImage(1);
    }
  }, [section1InView, section2InView]);

  return (
    <div className="w-10/12 mx-auto my-12 bg-white">
      <div className="grid grid-cols-1">
        {/* Left Content */}
        <div className="   w-full mb-8">
          <div ref={section1Ref} className="">
            <button className="bg-primary text-primary font-semibold bg-opacity-20 border py-1 text-sm px-4 rounded-full border-primary">
              For marketers
            </button>
            <h1 className="text-2xl md:text-3xl font-bold pt-4">
              An Ecosystem of Advertisers
            </h1>
            <p className="text-muted">Kwanza Media Trading Desk</p>
            <button
              onClick={() => navigate("/register/?role=advertiser")}
              className="bg-primary mt-8 py-3 px-8 font-medium hover:scale-105 transition-all  rounded-full text-white"
            >
              BOOK A DEMO
            </button>
            <div className="grid grid-cols-2 gap-8 mt-8">
              {[
                {
                  title: "Hyper-Targeting",
                  description:
                    "We have many filters in place to ensure no bot traffic will see your ads.",
                },
                {
                  title: "Machine learning based media planning",
                  description:
                    "Develop accurate consumer insights quickly and effectively through our integrated DMP.",
                },
                {
                  title: "Data driven campaign",
                  description:
                    "Identifies desired audiences and delivers relevant messages where they are likely to see it",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-start space-y-1"
                >
                  <p className="text-textColor font-bold">{item.title}</p>
                  <p className="text-muted">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Sticky Image */}
        <div className=" w-full sticky top-48 flex justify-end">
          <img
            alt="for marketers or creators"
            src={images[0]}
            className="sticky"
          />
        </div>
      </div>
      <div className="grid grid-cols-1">
        {/* Left Content */}

        <div ref={section2Ref} className="pt-12">
          <button className="bg-primary text-primary font-semibold bg-opacity-20 border py-1 text-sm px-4 rounded-full border-primary">
            For Creators
          </button>
          <h1 className="text-2xl md:text-3xl font-bold pt-4">
            An Ecosystem of Creators
          </h1>
          <p className="text-muted">Kwanza Media Trading Desk</p>
          <button
            onClick={() => navigate("/register/?role=publisher")}
            className="bg-primary mt-8 py-3 px-8 hover:scale-105 transition-all font-medium rounded-full text-white"
          >
            BOOK A DEMO
          </button>
          <div className="grid grid-cols-2 gap-8 mt-8">
            {[
              {
                title: "Traffic you can trust",
                description:
                  "Develop accurate consumer insights quickly and effectively through our integrated DMP.",
              },
              {
                title: "Machine learning based media planning",
                description:
                  "Develop accurate consumer insights quickly and effectively through our integrated DMP.",
              },
              {
                title: "Data driven campaign",
                description:
                  "Identifies desired audiences and delivers relevant messages where they are likely to see it",
              },
            ].map((item, index) => (
              <div key={index} className="flex flex-col items-start space-y-1">
                <p className="text-textColor font-bold">{item.title}</p>
                <p className="text-muted">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="  sticky top-48 flex justify-end">
          <img
            alt="for marketers or creators"
            src={images[1]}
            className="sticky"
          />
        </div>
        {/* Right Sticky Image */}
      </div>
    </div>
  );
};

export default ValuesSection2;
