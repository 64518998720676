import { motion } from "framer-motion";
import { figureSeparator } from "../../../util/figureSeparator";
import MixedGraph from "../../overview/mixed-graph";
import PieChart from "../../overview/pie-chart";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { AiOutlineMinus } from "react-icons/ai";

const GeneralStats = ({ data }) => {
  const campaignsSummary = [
    {
      label: "Total Spent",
      value: `$${figureSeparator(Math.round(parseFloat(data.totalSpent)))}`,
      icon: "/bitcoin.svg",
      hint: "-30%",
      color: "bg-green-500",
    },
    {
      label: "Impressions/Reach",
      value: `${figureSeparator(Math.round(parseFloat(data.totalImpressions ?? 0)))}`,
      icon: "/analytics.svg",
      hint: `+8%`,
      color: "bg-red-500",
    },
    {
      label: "Total Clicks",
      value: 0,
      hint: "0%",
      icon: "/cursor.svg",
      color: "bg-blue-500",
    },
    {
      label: "AVE",
      value: `$${figureSeparator(Math.round(parseFloat(data.totalAVE)))}`,
      icon: "/ctr.svg",
      hint: "+2%",
      color: "bg-orange-500",
    },
  ];
  const parentVariants = {
    hidden: {
      opacity: 0,
      x: 500,
    },
    visible: {
      opacity: 1,
      ease: "linear",
      x: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const childVariants = {
    hidden: {
      opacity: 0,
      y: 70,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  return (
    <div className="">
      <motion.div
        variants={parentVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-4 gap-4"
      >
        {campaignsSummary.map((item, index) => {
          return (
            <motion.div
              variants={childVariants}
              className=" bg-white border border-border group rounded-md py-3 px-4 flex items-center space-x-2 hover:scale-[1.05] cursor-pointer transition-all duration-200"
            >
              <div
                className={`size-14 rounded-full  bg-opacity-10  flex items-center justify-center`}
              >
                <img src={item.icon} />
              </div>
              <div>
                <p className="text-2xl font-bold text-textColor">
                  {item.value}
                </p>
                <div className="flex">
                  <h1 className="text-sm text-muted">{item.label}</h1>
                </div>
                <div
                  className={`${item.hint.includes("+") ? "text-primary" : item.hint.includes("-") ? "text-red-500" : "text-secondary"} flex items-center text-sm`}
                >
                  {/* {item.hint.includes("+") ? (
                    <FaArrowUp />
                  ) : item.hint.includes("-")  ? (
                    <FaArrowDown />
                  ) : (
                    <AiOutlineMinus />
                  
                  )} */}
                  {/* {item.hint} */}
                </div>
              </div>
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default GeneralStats;
