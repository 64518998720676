import { toDays } from "../../../util";
import { getCampaignById } from "../../../api/api";
import { AuthContext } from "../../../provider/AuthContext";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CampaignContext } from "../campaign-create/create-campaign-tabs";

const styles = {
  root: {
    minHeight: "34vh",
    marginBottom: "4rem",
    background: "#ccc",
    marginTop: "1rem",
    width: "97%",
  },
  price: {
    color: "#6EBF4A",
    fontWeight: 500,
    fontSize: "1.6rem",
    transform: "translateY(-13%)",

    marginRight: "0.7rem",
  },
  desc: {
    color: "#979797",
    fontSize: "0.8rem",
    marginRight: "1rem",
  },
  edit: {
    transform: "translateX(178%)",
  },
};
const getDailyTotal = (totalBudget, endDate, startDate) => {
  const days = toDays(new Date(endDate), new Date(startDate));
  console.log(days);
  const cost = (days > 0 ? totalBudget / days : 0).toFixed(2);
  return cost;
};
const BudgetInformation = () => {
  const { userDetails } = useContext(AuthContext);
  const { token } = JSON.parse(userDetails);
  const [campaign, setCampaign] = useState(null);
  const { uuid } = useParams();
  const { activeStep, setActiveStep } = useContext(CampaignContext);

  useEffect(() => {
    getCampaignById(token, uuid).then((response) => {
      console.log(response.data);
      setCampaign(response.data);
    });
  }, []);
  return (
    campaign && (
      <div className="bg-white border border-border  rounded-md p-10">
        <div alignItems="center">
          <p className="text-3xl font-bold">
            $
            {getDailyTotal(
              campaign.total_budget,
              new Date(campaign.expire_time),
              new Date(campaign.activate_time)
            )}
          </p>
          <div>
            <p className="text-sm text-gray-500">daily average </p>
            <p className="mt-2">
              ${parseFloat(campaign.total_budget) || 0} Total budget
            </p>
          </div>
          <button
            className=" border border-primary text-primary  rounded-md py-1 px-4 w-40 flex justify-center"
            onClick={() => {
              setActiveStep(0);
            }}
            style={{ marginTop: "20px" }}
          >
            Edit Details
          </button>

          {/* <EditCampaignButton redirectUrl={`/create-campaign/budget`} /> */}
        </div>
      </div>
    )
  );
};

export default BudgetInformation;
