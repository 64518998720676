
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import { uploadSspHtmlBanner } from "../../../../../../api/api";
import { Formik } from "formik";
import * as Yup from "yup";
import { BsCloudArrowUp, BsFileZip } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { fetchCreativesByCampaignId } from "../../../../../../api/ssp-api";
import { IoIosCloseCircle } from "react-icons/io";
import { IoAddOutline } from "react-icons/io5";
import { filter } from "lodash";
import { StepsContext } from "../create-campaign";
import ZippedUploadedItem from "../../../../../../components/zipped-uploaded-item";

export default function HtmlCreatives({
  savedCampaign,
  token,
  agencyId,
  handleComplete,
}) {
  const [loading, setLoading] = useState(false);
  const campaignId = localStorage.getItem("campaignId");
  const { uuid } = useParams();
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const { activeStep, setActiveStep } = useContext(StepsContext);
  const base64Credentials = btoa(credentials);
  const [data, setData] = useState(null);
  useEffect(() => {
    fetchCreativesByCampaignId(uuid, base64Credentials).then((response) => {
      console.log(response.data.filter((item) => item.storageType == "html"));
      setData(response.data.filter((item) => item.storageType == "html"));
    });
  }, []);
  const formData = {
    name: "",
    width: "",
    height: "",
    zipFile: null,
    agencyId: agencyId,
    destinationUrl: "",
    campaignId: uuid,
  };

  const uploadData = async (values) => {
    if (!values.files.length) {
      if(data.length == 0){
        alert("Please select a file first!");
      }else{
        setActiveStep(activeStep+1)

      }
      return;
    }

    setLoading(true);

    for (const file of values.files) {
      const parts = file.name.split("-"); // Split the filename by the "-" character
      const name = parts[0]; // The name is the first part
      const dimensions = parts[parts.length-1].split("x"); // Split the second part by the "x" character
      const width = dimensions[0]; // The width is the first part of the dimensions
      const height = dimensions[1].split(".")[0]; // The height is the second part of the dimensions, remove file extension

      // Add the extracted values to the formData
      formData.name = name;
      formData.width = width;
      formData.height = height;
      formData.zipFile = file;
      formData.destinationUrl = values.destinationUrl;
       console.log(formData)
      try {
        const response = await uploadSspHtmlBanner(formData, token);
        if (response.status === 200) {
          toast.success("Banner uploaded successfully!");
        }
        handleComplete();
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const onDrop = useCallback((acceptedFiles, setFieldValue) => {
    setFieldValue("files", Array.from(acceptedFiles));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, event) =>
      onDrop(acceptedFiles, event.setFieldValue),
    accept: ".zip",
    multiple: true,
  });

  const validationSchema = Yup.object({
    destinationUrl: Yup.string().required("Please enter destination URL"),
    files: Yup.array()
      .of(
        Yup.mixed().test(
          "file-name",
          "Files must follow the format fileName-widthxheight",
          (file) => {
            if (file.name) {
              const parts = file.name.split("-");
              const dimensions = parts[parts.length-1].split("x");
              if (dimensions.length !== 2) return false;
              return true;
            }

            return false;
          }
        )
      )
      .required("Please upload at least one file"),
  });

  return (
    data && (
      <div className="w-full">
        <div item>
          <ToastContainer />
        </div>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            destinationUrl: data.length ? data[0].url : "",
            files: [],
          }}
          onSubmit={(values) => {
            uploadData(values);
          }}
        >
          {({
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            values,
            isSubmitting,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                item
                style={{
                  marginTop: "8px",
                  overflowY: "auto",
                }}
              >
                <div className="w-full">
                  <div className={ `grid  grid-cols-1 gap-4`}>
                    
                    {/* {
                  selectedImages.map((item)=>{
                    return  <div className="border border-border p-4 text-center rounded">
                    <div className="flex justify-end">
                      <IoIosCloseCircle onClick={()=>{
                        setSelectedImages(selectedImages.filter((e)=>e != item))
                      }} className="text-gray-500 size-5 cursor-pointer" />
                    </div>
                    <div className="flex justify-center ">
                    <img className=" h-48 object-cover py-4" src={URL.createObjectURL(item.file)}/>
                    </div>
                    <p className="text-sm">Image dimensions {item.dimensions.width}x{item.dimensions.height}</p>
                  </div>
                  })
                } */}
                    

                    <div
                      {...getRootProps()}
                      className="w-full flex flex-col justify-center items-center border space-y-2 text-center border-slate-400 rounded-md border-dashed p-4"
                    >
                      <IoAddOutline className="size-12 text-primary" />
                      <input
                        {...getInputProps()}
                        onChange={(e) => {
                          const files = Array.from(e.currentTarget.files);
                          setFieldValue("files", files);
                        }}
                      />
                      {isDragActive ? (
                        <p className="text-sm">Drop the ZIP file here...</p>
                      ) : (
                        <p className="text-sm">
                          Drag 'n' drop a ZIP file here, or click to select a
                          file
                        </p>
                      )}
                      <div className="py-1 px-3 rounded-md border border-primary text-primary cursor-pointer">
                        Browse
                      </div>
                    </div>
                    {values.files.map(
                      (selectedFile) =>
                        selectedFile && (
                          <ZippedUploadedItem selectedFile={selectedFile}/>
                        )
                    )}
                    {data.map((item) => {
                      return (
                        <div className="border border-border  items-center   p-4  flex flex-col justify-center rounded">
                    <iframe height={255} src={item.iurl}></iframe>
                  </div>
                      );
                    })}
                  </div>

                  {touched.files && errors.files && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.files}
                    </div>
                  )}
                </div>

                <div className="mt-2 space-y-1">
                  <h1 className="text-sm">Destination URL</h1>
                  <input
                    type="text"
                    onChange={handleChange}
                    value={values.destinationUrl}
                    name="destinationUrl"
                    className="form-style"
                  />
                  {touched.destinationUrl && errors.destinationUrl && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.destinationUrl}
                    </div>
                  )}
                </div>
          <div className="flex justify-between mt-5">

                <div className="flex space-x-2 mt-6">
                      <button
                className="py-2  rounded-md  text-textColor  justify-center flex hover:text-primary transition-all duration-200"
                  onClick={()=>{
                    setActiveStep(activeStep-1)
                  }}
                  type="submit"
                >
                  Back
                </button>
                  <button
                    className=" bg-primary rounded-md py-2 px-3 text-white w-40 flex justify-center cursor-pointer items-center"
                    type="submit"
                    disabled={isSubmitting || loading}
                  >
                    {isSubmitting || loading ? (
                      <CircularProgress
                        style={{ color: "white" }}
                        size="1.4rem"
                      />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
                {/* <button
           className="py-2 px-3 rounded-md  text-textColor  w-24 justify-center flex hover:text-primary transition-all duration-200"
            onClick={()=>{
              setActiveStep(activeStep+1)
            }}
            type="submit"
          >
            Skip
          </button> */}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    )
  );
}
