import React, { useContext, useEffect, useState } from "react";
import { countries } from "./country";
import { interests as goals, iabCategories } from "./constant";
import {
  editTarget,
  fetchCountryForecast,
  getAudienceById,
  getIabApi,
  listPretargetsApi,
} from "../../api/api";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import moment from "moment";
import { IoCloseSharp } from "react-icons/io5";
import { AuthContext } from "../../provider/AuthContext";
import PieChart from "../overview/pie-chart";
import { FaChartPie } from "react-icons/fa";
import Spinner from "../../components/spinner";
import { toast } from "react-toastify";
import BarGraph from "../overview/bar-graph";

const EditAudience = ({ classes }) => {
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [goal, setGoal] = useState([]);
  const [pretargets, setPretargets] = useState([]);
  const [interests, setInterests] = useState([]);
  const audienceId = window.location.pathname.split("/")[3];
  const navigate = useNavigate();
  const { setCurrentPage } = useContext(BreadcrumbContext);
  const [interest, setInterest] = useState([]);
  const [country, setCountry] = useState([]);
  const { userDetails } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(0);
  const { token, role } = JSON.parse(userDetails);
  const { setActions } = useContext(BreadcrumbContext);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [showForecastData, setShowForecastData] = useState(false);
  const [selectedForecastTab, setSelectedForecastTab] = useState(0);

  useEffect(() => {
    try {
      fetchCountryForecast(selectedCountry, token)
        .then((data) => {
          console.log(data);
          const receivedData = data.data;
          const convertAge = (ageObject) => new Map(Object.entries(ageObject));
          const convertDevices = (deviceObject) =>
            new Map(Object.entries(deviceObject));
          const convertGender = (genderObject) =>
            new Map(Object.entries(genderObject));
          const ageResult = convertAge(receivedData.age);
          const deviceResult = convertDevices(receivedData.device);
          const genderResult = convertGender(receivedData.gender);
          const convertSite = (siteObject) =>
            new Map(Object.entries(siteObject));
          const sitesResult = convertSite(receivedData.sites);
          const keys = Array.from(sitesResult.values());
          setForecastData({
            ...receivedData,
            age: ageResult,
            device: deviceResult,
            gender: genderResult,
            sites: sitesResult,
          });
          console.log(forecastData);
        })
        .catch((error) => {
          setForecastData(null);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [selectedCountry]);
  useEffect(() => {
    setActions(<div></div>);
    setCurrentPage("Edit Audience");
    // Initialize a variable to track the number of completed requests
    let completedRequests = 0;

    // Define a function to handle request completion
    const handleRequestComplete = () => {
      completedRequests++;

      // Check if all requests are completed
      if (completedRequests === 2) {
        // All requests are completed, set loading to false
        setLoading(false);
      }
    };
    setLoading(true);
    getAudienceById(audienceId)
      .then((res) => {
        console.log("audience", res);
        setFormData(res.data);
        console.log(res.data);
        setLoading(false);
        setSelectedCountry(res.data.country);

        setGoal(res.data.goals);
        // setInterest(res.data.iabWhitelist)
        handleRequestComplete();
      })
      .catch((err) => {
        console.log("audience error", err);
        handleRequestComplete();
      });
    listPretargetsApi().then((response) => {
      setPretargets(response.data ? response.data : []);
    });
    getIabApi()
      .then((response) => {
        setInterests(response.data);
        handleRequestComplete();
      })
      .catch((error) => {
        console.log(error);
        handleRequestComplete();
      });

    // listPretargetsApi()
    //     .then((response) => {
    //         setPretargets(response.data);
    //         handleRequestComplete();
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         handleRequestComplete();
    //     });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onHandleChange = () => {};
  const onStartDateChange = (activateTime) => {
    setFormData((prevData) => ({
      ...prevData,
      activateTime: activateTime._d,
    }));
  };

  const onEndDateChange = (expireTime) => {
    setFormData((prevData) => ({
      ...prevData,
      expireTime: expireTime._d,
    }));
  };

  const onSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let data = {
      audienceName: e.target.audienceName.value,
      activateTime: new Date(e.target.activateTime.value),
      expireTime: new Date(e.target.expireTime.value),
      // audienceGoals: goal.join(","),
      country: e.target.country.value,
      interest: interest.join(","),
      billingId: parseFloat(e.target.billingId.value),
      // carrier: selectedCarriers.join(","),
      // os: selectedOs.join(","),
    };
    console.log("data", data);
    console.log("Audience Id", audienceId);

    editTarget(data, audienceId)
      .then((res) => {
        console.log("edit target response", res);
        setIsLoading(false);
        toast.success("Saved Successfully");
        navigate(-1);
      })
      .catch((err) => {
        console.log("edit target error", err);
      });
  };

  return loading ? (
    <Loader />
  ) : (
    formData && (
      <div>
        {forecastData && showForecastData == true && (
          <div className=" bg-black fixed h-screen w-screen inset-0 z-50 bg-opacity-20 flex justify-center items-center">
            <div className="bg-white w-6/12 2xl:w-4/12 border border-border rounded-lg p-6">
              <div className="flex justify-between items-center">
                <h1 className="text-xl font-semibold">
                  {forecastData.countryName} Forecast
                </h1>
                <IoCloseSharp
                  size={20}
                  className="text-muted cursor-pointer"
                  onClick={() => {
                    setShowForecastData(false);
                  }}
                />
              </div>
              <div className="mt-4 space-y-2">
                {[
                  {
                    title: "Country Name",
                    value: forecastData.countryName,
                  },
                  {
                    title: "Country Code",
                    value: forecastData.currencyCode,
                  },
                  { title: "Impressions", value: forecastData.impressions },
                  { title: "Uniques", value: forecastData.uniques },
                ].map((item) => {
                  return (
                    <div className="flex justify-between">
                      <p className="text-muted">{item.title}</p>
                      <h1 className="">{item.value}</h1>
                    </div>
                  );
                })}
              </div>
              <div className="  mt-4 border-t border-border py-2">
                <div>
                  <div className="flex flex-wrap space-x-2">
                    {["Devices", "Age", "Gender", "Sites"].map(
                      (item, index) => {
                        return (
                          <div
                            onClick={() => {
                              setSelectedForecastTab(index);
                            }}
                            className={`${selectedForecastTab == index ? "bg-primary bg-opacity-10" : "text-muted hover:scale-105 transition-all duration-200"} py-1 px-3 rounded-full cursor-pointer`}
                          >
                            {item}
                          </div>
                        );
                      }
                    )}
                  </div>
                  {selectedForecastTab == 0 && (
                    <PieChart
                      labels={["Mobile", "Tablet", "Desktop", "Connected TV"]}
                      colors={["#6DBF48", "#FBC23D", "#082A7B", "#EB345F"]}
                      values={[
                        forecastData.device.get("Mobile").replace("%", ""),
                        forecastData.device.get("Tablet").replace("%", ""),
                        forecastData.device.get("Desktop").replace("%", ""),
                        forecastData.device
                          .get("Connected TV")
                          .replace("%", "")
                          .replace("<", ""),
                      ]}
                    />
                  )}
                  {selectedForecastTab == 1 && (
                    <PieChart
                      labels={[
                        "18-24",
                        "25-34",
                        "35-44",
                        "45-54",
                        "55-64",
                        "65+",
                        "Unknown",
                      ]}
                      colors={[
                        "#6DBF48",
                        "#FBC23D",
                        "#082A7B",
                        "#EB345F",
                        "#15E6FF",
                        "#8A15FF",
                        "#1556FF",
                        "#FF8515",
                      ]}
                      values={[
                        forecastData.age.get("18-24").replace("%", ""),
                        forecastData.age.get("25-34").replace("%", ""),
                        forecastData.age.get("35-44").replace("%", ""),
                        forecastData.age.get("45-54").replace("%", ""),
                        forecastData.age.get("55-64").replace("%", ""),
                        forecastData.age.get("65+").replace("%", ""),
                        forecastData.age.get("Unknown").replace("%", ""),
                      ]}
                    />
                  )}
                  {selectedForecastTab == 2 && (
                    <PieChart
                      labels={["Male", "Female", "Unknown"]}
                      colors={["#6DBF48", "#FBC23D", "#082A7B"]}
                      values={[
                        forecastData.gender.get("Male").replace("%", ""),
                        forecastData.gender.get("Female").replace("%", ""),
                        forecastData.gender.get("Unknown").replace("%", ""),
                      ]}
                    />
                  )}
                  {selectedForecastTab == 3 && (
                    <BarGraph
                      values={Array.from(forecastData.sites.values()).map(
                        (item) => item.replace("M", "")
                      )}
                      yValuesName="Visits (M)"
                      ylabels={Array.from(forecastData.sites.values())}
                      xlabels={Array.from(forecastData.sites.keys())}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <form
          onSubmit={onSubmit}
          className="bg-white p-10 border border-border rounded-md "
        >
          <div className="grid grid-cols-3 gap-4">
            <div xs={9} container>
              <div xs={9}>
                <label className="text-sm">Audience name*</label>
                <input
                  className="form-style"
                  defaultValue={formData.name}
                  name="audienceName"
                />
              </div>
            </div>
            <div className=" ">
              <div>
                <label className="text-sm">Country*</label>
                <select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  className="form-style"
                  defaultValue={formData.country}
                  onChange={(e) => {
                    console.log("Data", e.target.value);
                    setSelectedCountry(e.target.value);
                    setShowForecastData(true);
                  }}
                  name="country"
                >
                  <option>Select Country</option>
                  {countries.map(({ iso3, label }, index) => (
                    <option value={iso3} key={index}>
                      {label}
                    </option>
                  ))}
                </select>
                {selectedCountry && forecastData && (
                  <div className="flex space-x-1 items-center transition-all duration-200 hover:scale-105 ">
                    <FaChartPie className="text-primary" />
                    <h1
                      onClick={() => {
                        setShowForecastData(true);
                      }}
                      className="text-primary font-bold px-1 py-2 cursor-pointer"
                    >
                      View Forcast
                    </h1>
                  </div>
                )}
              </div>
            </div>
            <div xs={9} container>
              <div xs={9}>
                <label className="text-sm">Start Date*</label>
                <input
                  type="date"
                  defaultValue={moment(new Date(formData.activate_time)).format(
                    "yyyy-MM-DD"
                  )}
                  name="activateTime"
                  onChange={onStartDateChange}
                  className="form-style"
                />
              </div>
            </div>

            <div xs={9} container>
              <div xs={9}>
                <label className="text-sm">End Date*</label>
                <input
                  type="date"
                  name="expireTime"
                  defaultValue={moment(new Date(formData.expire_time)).format(
                    "yyyy-MM-DD"
                  )}
                  onChange={onEndDateChange}
                  className="form-style"
                />
              </div>
            </div>
            <div>
              <div>
                <div className="flex space-x-2">
                  <h4 className="font-bold">Audience</h4>
                  <p className="text-sm">(Who should see your ad?)</p>
                </div>
                <select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  className="form-style"
                  name="billingId"
                  defaultValue={formData.audience_id}
                >
                  {pretargets.map(({ billingId, configName }, index) => (
                    <option value={billingId} key={index}>
                      {configName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className=" ">
              <div>
                <label className="text-sm">Interests Category</label>
                <select
                  className="form-style"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedCategory(e.target.value);
                  }}
                >
                  {iabCategories.map((item, index) => (
                    <option value={index} key={index}>
                      {item.category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {selectedCategory && (
              <div className=" ">
                <div>
                  <label className="text-sm">Interests*</label>
                  <select
                    className="form-style"
                    name="interest"
                    onChange={(e) => {
                      setInterest([...interest, e.target.value]);
                    }}
                  >
                    {iabCategories[selectedCategory].categories
                      .filter((item) => !interest.includes(item.value))
                      .map(({ value, name }, index) => (
                        <option value={value} key={index}>
                          {name}
                        </option>
                      ))}
                  </select>
                  <div className="flex flex-wrap">
                    {interest.map((item) => {
                      return (
                        <div className="text-sm bg-border py-1 px-3 rounded-full me-2 mb-2 mt-2 flex items-center space-x-1">
                          <div>
                            {
                              iabCategories[selectedCategory].categories.filter(
                                (e) => e.value == item
                              )[0].name
                            }
                          </div>
                          <IoCloseSharp
                            onClick={() => {
                              setInterest([
                                ...interest.filter((e) => e != item),
                              ]);
                            }}
                            className="size-4 cursor-pointer"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {/* <div className="col-span-6">
                  <div>
                    <label className="text-sm">Select carriers</label>
                    <select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      className="form-style"
                      onChange={(e) => {
                        setSelectedCarriers([
                          ...selectedCarriers,
                          e.target.value,
                        ]);
                      }}
                      name="telco"
                    >
                      <option>Select carriers</option>
                      {carriers
                        .filter((e) => !selectedCarriers.includes(e.carrierId))
                        .map((car, index) => (
                          <option value={car.carrierId} key={index}>
                            {car.carrierName}
                          </option>
                        ))}
                    </select>
                    <div className="flex flex-wrap">
                      {selectedCarriers.map((item) => {
                        return (
                          <div className="text-sm bg-border py-1 px-3 rounded-full me-2 mb-2 mt-2 flex items-center space-x-1">
                            <div>
                              {
                                carriers.filter((e) => e.carrierId == item)[0]
                                  .carrierName
                              }
                            </div>
                            <IoCloseSharp
                              onClick={() => {
                                setSelectedCarriers([
                                  ...selectedCarriers.filter((e) => e != item),
                                ]);
                              }}
                              className="size-4 cursor-pointer"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-span-6">
                  <div>
                    <label className="text-sm">Operating System</label>
                    <select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      onChange={(e) => {
                        setSelectedOs([...selectedOs, e.target.value]);
                      }}
                      name="telco"
                      className="form-style"
                    >
                      <option>Select operation system</option>
                      {operatingSystem
                        .filter((e) => !selectedOs.includes(e.osName))
                        .map((os, index) => (
                          <option
                            className={classes.menuItem}
                            value={os.osName}
                            key={index}
                          >
                            {os.osName}
                          </option>
                        ))}
                    </select>
                    <div className="flex flex-wrap">
                      {selectedOs.map((item) => {
                        return (
                          <div className="text-sm bg-border py-1 px-3 rounded-full me-2 mb-2 mt-2 flex items-center space-x-1">
                            <div>{item}</div>
                            <IoCloseSharp
                              onClick={() => {
                                setSelectedOs([
                                  ...selectedOs.filter((e) => e != item),
                                ]);
                              }}
                              className="size-4 cursor-pointer"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div> */}
          </div>
          <button
            type="submit"
            className="py-2 px-3 bg-primary w-48 font-semibold flex justify-center items-center text-white rounded-md mt-8"
          >
            {isLoading ? <Spinner /> : "Save Changes"}
          </button>
          {/*<div xs={4}>*/}
          {/*  <PotentialAudience />*/}
          {/*</div>*/}
        </form>
      </div>
    )
  );
};

export default EditAudience;
