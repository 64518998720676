import { useContext, useEffect, useState } from "react";
import {
  fetchLinkedZonesByCampaignId,
  linkZoneToCampaign,
  unlinkZoneFromCampaign,
} from "../../../api/ssp-api";

import { fetchSspZones, linkSspZone, unlinkSspZone } from "../../../api/api";
import { AuthContext } from "../../../provider/AuthContext";
import Loader from "../../../components/loader";


export default function LinkCampaign({ linkZones, edit }) {
  const editLink = edit;
  const campaignId = window.location.pathname.split("/")[4];
  const [publishers, setPublishers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const [linkedZones, setLinkedZones] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { token, agencyId } = JSON.parse(userDetails);


  useEffect(() => {
    setLoading(true);

    if (linkZones) {
      setLinkedZones(linkZones);
      setLoading(false);
    } else {
      fetchLinkedZonesByCampaignId(campaignId, base64Credentials)
        .then((res) => {
          setLinkedZones(res.data);
          console.log("linkedZones", JSON.stringify(linkZones));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    fetchSspZones(token, agencyId)
      .then((response) => {
        console.log(response.data);
        setZones(response.data.sspZones);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setZoneLoading(false);
      });
  }, []); // Removed zones from dependencies

  const handleUnlinkCampaign = (zoneId) => {
    setLoading(true);

    const { username, password } = JSON.parse(localStorage.getItem("ssp"));

    let removedLinkedZone = null;
    let updatedLinkedZones = linkedZones.filter((linkedZone) => {
      if (linkedZone.zoneId === zoneId) {
        removedLinkedZone = linkedZone;
        return false; // Remove from linkedZones
      }
      return true; // Keep in linkedZones
    });

    if (removedLinkedZone) {
      const newZone = {
        zone_id: removedLinkedZone.zoneId,
        zone_name: removedLinkedZone.zoneName,
        type: removedLinkedZone.type,
        width: removedLinkedZone.width,
        height: removedLinkedZone.height,
      };

      // Add the transformed zone back to zones
      setZones([...zones, newZone]);
    } else {
      console.log(`Linked zone with ID ${zoneId} not found in linkedZones.`);
    }

    setLinkedZones(updatedLinkedZones);

    unlinkZoneFromCampaign(zoneId, campaignId, username, password)
      .then((response) => {
        console.log(response.data);
        const data = {
          zoneId: zoneId,
        };
        const { token } = JSON.parse(userDetails);

        unlinkSspZone(data, token)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLinkCampaign = (zoneId) => {
    setZoneLoading(true);

    const { username, password } = JSON.parse(localStorage.getItem("ssp"));

    let removedZone = null;
    let updatedZones = zones.filter((zone) => {
      if (zone.zone_id === zoneId) {
        removedZone = zone;
        return false; // Remove from zones
      }
      return true; // Keep in zones
    });

    if (removedZone) {
      const newLinkedZone = {
        zoneId: removedZone.zone_id,
        zoneName: removedZone.zone_name,
        type: removedZone.type,
        width: removedZone.width,
        height: removedZone.height,
      };

      setLinkedZones([...linkedZones, newLinkedZone]);
    } else {
      console.log(`Zone with ID ${zoneId} not found in zones.`);
    }

    // Update the zones without the removed zone
    setZones(updatedZones);

    linkZoneToCampaign(zoneId, campaignId, username, password)
      .then((response) => {
        console.log(response.data);

        const data = {
          zoneId: zoneId,
        };
        const { token } = JSON.parse(userDetails);

        linkSspZone(data, token)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setZoneLoading(false);
      });
  };

  if (editLink !== "edit") {
    return (
      <div header={"Link Campaign"}>
        <div
          container
          spacing={2}
          style={{ height: "500px", overflowY: "auto" }}
        >
          <div item spacing={2} xs={12}>
            {loading ? (
              <Loader/>
            ) : (
              <div item xs={12}>
                <p>Linked zones</p>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Zone ID</TableCell>
                        <TableCell>Zone Name</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {linkedZones.map((row) => (
                        <TableRow key={row.zoneId}>
                          <TableCell>{row.zoneId}</TableCell>
                          <TableCell>{row.zoneName}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="secondary"
                              // startIcon={<DeleteIcon />}
                              onClick={() => handleUnlinkCampaign(row.zoneId)}
                            >
                              Unlink
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
          {zoneLoading ? (
            <div item spacing={2} xs={12}>
              <Loader/>
            </div>
          ) : (
            <div item xs={12} spacing={2}>
              <p>Available zones</p>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Zone ID</TableCell>
                      <TableCell>Zone Name</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {zones.map((row) => (
                      <TableRow key={row.zone_id}>
                        <TableCell>{row.zone_id}</TableCell>
                        <TableCell>{row.zone_name}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            // startIcon={<LinkIcon />}
                            onClick={() => handleLinkCampaign(row.zone_id)}
                          >
                            Link
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        container
        spacing={2}
        style={{ height: "500px", overflowY: "auto" }}
      >
        <div item spacing={2} xs={12}>
          {loading ? (
            <div item>
              <Loader/>
            </div>
          ) : (
            <div item xs={12}>
              <p>Linked zones</p>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Zone ID</TableCell>
                      <TableCell>Zone Name</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {linkedZones.map((row) => (
                      <TableRow key={row.zoneId}>
                        <TableCell>{row.zoneId}</TableCell>
                        <TableCell>{row.zoneName}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            // startIcon={<DeleteIcon />}
                            onClick={() => handleUnlinkCampaign(row.zoneId)}
                          >
                            Unlink
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
        {zoneLoading ? (
          <div item spacing={2} xs={12}>
            <Loader/>
          </div>
        ) : (
          <div item xs={12} spacing={2}>
            <p>Available zones</p>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Zone ID</TableCell>
                    <TableCell>Zone Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {zones.map((row) => (
                    <TableRow key={row.zone_id}>
                      <TableCell>{row.zone_id}</TableCell>
                      <TableCell>{row.zone_name}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          // startIcon={<LinkIcon />}
                          onClick={() => handleLinkCampaign(row.zone_id)}
                        >
                          Link
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    );
  }
}
