import React, { useContext, useEffect, useState } from "react";

import {
  addCampaign,
  fetchAdvertiserByAgencyId,
  fetchCampaignsByAdvertiser,
  getRoles,
} from "../../api/ssp-api";
import Loader from "../../components/loader";
import SspTable from "./campaign-stats/campaigns-table";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/spinner";
import moment from "moment";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";

export default function CampaignsOverview() {
  const [advertiserId, setSelectedAdvertiser] = useState("");
  const [data, setData] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [campaignLoading, setCampaignLoading] = useState(true);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const user = localStorage.getItem("kwanza");
  const { token, role, agencyId } = user ? JSON.parse(user) : {};
  const [fetching, setFetching] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const { setActions } = useContext(BreadcrumbContext);

  const onRefresh = () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    setActions(
      <div
        className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer w-40 flex justify-center items-center"
        onClick={() => {
          setFetching(true);
          const data = {
            advertiserId: 2,
            campaignName: "New Campaign",
            startDate: moment(Date.now()).format("yyy-MM-DD"),
            endDate: moment(Date.now()).format("yyy-MM-DD"),
            impressions: -1,
            revenueType: 1,
            revenue: 12.5,
            clicks: -1,
            priority: 0,
            weight: 1,
          };
          localStorage.removeItem("isPremium");
          addCampaign(data, base64Credentials).then((response) => {
            console.log("response", response);
            setFetching(false);
            navigate(`/agency/campaign/create/${response.data.campaignId}`);
          });
        }}
      >
        {fetching ? <Spinner /> : "Create Campaign"}
      </div>
    );
    let role = getRoles();
    setCampaigns([]);
    if (role === "admin") {
      fetchAdvertisers(role, base64Credentials, agencyId);
    }
    if (role === "publisher") {
      navigate("/ssp/publisher");
    }
    if (role === "advertiser") {
      const user = localStorage.getItem("kwanza");
      const { token, role, advertiserId } = user ? JSON.parse(user) : {};
      setSelectedAdvertiser(advertiserId);
      setLoading(false);
      fetchCampaignDataForAdvertiser(advertiserId, base64Credentials);
    }
    // fetchCampaignDataForAdvertiser(advertiserId);
  }, [advertiserId, refresh]);

  const handleChange = (event) => {
    setSelectedAdvertiser(event.target.value);
    fetchCampaignDataForAdvertiser(event.target.value, base64Credentials);
  };

  const fetchAdvertisers = (role, credentials, agencyId) => {
    fetchAdvertiserByAgencyId(agencyId, credentials)
      .then((response) => {
        fetchCampaignData(response.data, credentials);
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCampaignData = async (data, credentials) => {
    try {
      let newData = [];

      for (const item of data) {
        const response = await fetchCampaignsByAdvertiser(
          item.advertiserId,
          credentials
        );

        if (response.status === 500) {
          setNotFound(true);
        } else {
          console.log(response.data);
          // newData = newData.concat(response.data);
          setCampaigns((prev) => {
            const combinedData = [...prev, ...response.data];

            // Sort combined data by date
            combinedData.sort((a, b) => {
              return b.campaignId - a.campaignId;
            });

            return combinedData;
          });
          setCampaignLoading(false);
        }
      }

      // setCampaigns(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCampaignDataForAdvertiser = (advertiserId, credentials) => {
    setVisible(true);
    setCampaignLoading(true);
    fetchCampaignsByAdvertiser(advertiserId, credentials)
      .then((response) => {
        if (response.status === 500) {
          setCampaigns([]);
          setNotFound(true);
          setLoading(false);
        } else {
          setCampaigns(response.data);
          setCampaignLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAdminView = (campaignId, advertiserId) => {
    let startDate = "2023-01-01";
    navigate(`/ssp/campaign/${campaignId}/${startDate}/${advertiserId}`);
  };
  const handlePublisherView = (campaignId, advertiserId) => {
    let startDate = "2023-01-01";
    navigate(`/ssp/campaign/${campaignId}/${startDate}/${advertiserId}`);
  };

  return (
    <div header={"SSP"}>
      {role === "publisher" ? "" : <div className="flex justify-end"></div>}
      <div className="">
        {campaignLoading || campaigns === undefined || advertiserId === 0 ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div>
            {
              <SspTable
                onRefresh={onRefresh}
                data={campaigns}
                handleAdminView={handleAdminView}
                handlePublisherView={handlePublisherView}
                role={role}
              />
            }
          </div>
        )}
      </div>
    </div>
  );
}
