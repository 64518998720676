
import { ReactComponent as PageDownload } from "../../assets/pageDownload.svg";




const CampaignDownload = ({ size = 224, onDownloadCsv, isLoading = true }) => {
  const classes = useStyles();
  return (
    <>
      <Button
        className={classes.createCampaign}
        onClick={() => onDownloadCsv()}
      >
        {isLoading && (
          <CircularProgress className={classes.loader} size="1.6rem" />
        )}

        {!isLoading && (
          <>
            CSV({size}) <PageDownload className="icon" />
          </>
        )}
      </Button>

      <Button className={classes.createCampaign}>
        PDF({size}) <PageDownload className="icon" />
      </Button>
    </>
  );
};

export default CampaignDownload;
