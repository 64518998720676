import { delay, motion, stagger } from "framer-motion";

const AdFormatSection = () => {
  const staggerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      ease: "linear",
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const variants = {
    hidden: { scale: 0.4, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  return (
    <div>
      <motion.div
        variants={staggerVariants}
        initial="hidden"
        transition="transition"
        whileInView="visible"
        className="grid grid-cols-12 gap-8 mt-12"
      >
        {[
          {
            icon: "/icons/tv-01.png",
            title: "Television",
            description:
              "TV advertising has a high reach with broad demographic coverage, moderate engagement with strong visual impact but lower interaction, a high CPM, and 35% effectiveness in influencing consumer purchase decisions.",
          },
          {
            icon: "/icons/radio-02.png",
            title: "Radio",
            description:
              "Radio advertising has a high reach with broad demographic coverage, moderate engagement through audio content, a relatively low CPM, and effectiveness in influencing consumer behavior through consistent exposure.",
          },
          {
            icon: "/icons/camera-video.png",
            title: "Influencers",
            description:
              "Influencer marketing offers moderate reach with targeted demographics, high engagement through personalized content, a variable CPM, and strong effectiveness in driving purchase decisions through trust and relatability.",
          },
          {
            icon: "/icons/billboard 1.png",
            title: "Billboards",
            description:
              "Billboard advertising provides high reach with broad visibility, low engagement due to brief exposure, a moderate CPM, and effectiveness in reinforcing brand awareness through repeated impressions.",
          },
          {
            icon: "/icons/news-01.png",
            title: "Prints",
            description:
              "Print advertising offers targeted reach, moderate engagement through in-depth content, a higher CPM, and effectiveness in influencing niche audiences with detailed messaging.",
          },
          {
            icon: "/icons/seo.png",
            title: "Direct Placement",
            description:
              "Direct placement advertising provides targeted reach, high engagement through personalized content, a variable CPM, and strong effectiveness in driving conversions with direct, focused messaging.",
          },
          {
            icon: "/icons/change-screen-mode.png",
            title: "Displays",
            description:
              "Display advertising offers broad reach, moderate engagement with visual appeal, a low CPM, and effectiveness in building brand awareness through repeated online exposure.",
          },
          {
            icon: "/icons/thumbs-up.png",
            title: "Social Media",
            description:
              "Social media advertising provides broad reach with targeted demographics, high engagement through interactive content, a variable CPM, and strong effectiveness in driving conversions through user interaction and social proof.",
          },
          {
            icon: "/icons/seo.png",
            title: "SEO",
            description:
              "SEO offers broad reach with targeted visibility in search results, moderate engagement through organic search traffic, a low CPM, and high effectiveness in driving long-term, relevant traffic to websites.",
          },
        ].map((item, index) => {
          return (
            <motion.div
              variants={variants}
              key={item.title}
              className="col-span-6 md:col-span-3 cursor-pointer"
            >
              <div className="flex flex-col items-start space-y-1">
                <div className="size-20 bg-[#2A2A2A] p-6 rounded-2xl flex justify-center items-center">
                  <img className="" src={item.icon} alt={item.title} />
                </div>
                <p className="text-white font-bold text-base md:text-lg pt-4">
                  {item.title}
                </p>
                <p className="text-muted text-sm md:text-base ">
                  {item.description}
                </p>
              </div>
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default AdFormatSection;
