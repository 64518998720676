
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addZone, fetchPublisherWebsites } from "../../api/ssp-api";
import Loader from "../../components/loader";
import { addSspZone } from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import Spinner from "../../components/spinner";

export default function ZoneForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    publisherId: "",
    zoneName: "",
    type: "0",
    width: 0,
    height: 0,
  });
  const [loading, setLoading] = useState(true);
  const [publisherData, setPublisherData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const { agencyId, token } = JSON.parse(userDetails);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { setCurrentPage,setActions } = useContext(BreadcrumbContext);
  const [isChecked, setIsChecked] = useState(false);


  useEffect(() => {
    setActions(<div> </div>)
    setCurrentPage("Add Zone");
    fetchPublisherWebsites(base64Credentials, agencyId)
      .then((response) => {
        setPublisherData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

 

  const handleSubmit = (e) => {
    e.preventDefault();
   
    setLoading(true);
    const payload = {
      publisherId:parseInt(e.target.publisherId.value),
      zoneName:e.target.zoneName.value,
      width:parseInt(e.target.width.value),
      height:parseInt(e.target.height.value),
      agencyId:parseInt(agencyId),
      tier: isChecked ? "premium" : "mass",
    };
    console.log(payload)
    addSspZone(payload, token)
      .then((res) => {
        console.log(res);
        setLoading(false);
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err);
      });
  };

  return (
    <div header={"Zone"}>
      <div xs={12}  container>
        {loading || publisherData === undefined ? (
          <Loader/>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="bg-white p-10 border border-border"
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="space-y-1">
                  <label className="text-sm">Publisher</label>
                  <select
                    name="publisherId"
                    className="form-style"
                    required
                  >
                    {publisherData.map((publisher) => (
                      <option
                        key={publisher.publisherId}
                        value={publisher.publisherId}
                      >
                        {publisher.website}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="space-y-1">
                <label className="text-sm">Zone Name</label>
                <input
                  label="Zone Name"
                  name="zoneName"
                  className="form-style"
                  required
                />
              </div>
              <div className="space-y-1">
                <label className="text-sm">Width</label>
                <input
                  label="Width"
                  name="width"
                  className="form-style"
                  required
                  type="number"
                />
              </div>
              <div className="space-y-1">
                <label className="text-sm">Height</label>
                <input
                  label="Height"
                  name="height"
                  className="form-style"
                  required
                  type="number"
                />
              </div>
              <div className="space-y-1 flex flex-col">
                    <div>
                      <label className="text-sm">
                        Is this zone Premium ?
                      </label>
                    </div>
                    <div className="flex space-x-2 items-center group">
                      <input
                        placeholder="Enter an Email Address"
                        type="checkbox"
                        name="email"
                        className="size-8 rounded-md border-border checked:bg-primary focus:ring-primary hover:bg-transparent checked:hover:bg-primary checked:focus:bg-primary   focus:border-primary mt-1"
                        onChange={(e) => {
                          setIsChecked(e.target.checked);
                        }}
                        margin="normal"
                      />
                      <p className=" group-aria-checked:hidden font-normal  text-sm">
                        (
                        {!isChecked
                          ? "No, it is not premium"
                          : "Yes, it is premium"}{" "}
                        )
                      </p>
                    </div>
                  </div>
             
            </div>
            <div >
                <button className="py-2 px-3 bg-primary text-white rounded-md mt-4 w-40 font-medium flex justify-center">
                  {loading?<Spinner/>:"Submit"}
                </button>
              </div>
          </form>
        )}
      </div>
    </div>
  );
}
