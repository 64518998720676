
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../provider/AuthContext";
import { deleteSspBanner, fetchSspBanners } from "../../../../api/api";

import BannerTable from "./banner-table";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BreadcrumbContext } from "../../../../layouts/dashboardLayout";
import Loader from "../../../../components/loader";

export default function AgencyBanners() {
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { setActions } = useContext(BreadcrumbContext);

  useEffect(() => {
    setActions(
      <div>
        <button
          className="bg-primary text-white py-2 px-3 flex rounded-md items-cente"
          onClick={() => navigate("/agency/banners/create")}
        >
          Create Banner
        </button>
      </div>
    );
    fetchSspBanners(token, agencyId)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setBannerData(response.data.data);
          // console.log(`bannerData: ${JSON.stringify(response.data.data)}`);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDeleteBanner = (id) => {
    console.log(`Delete banner with id: ${id}`);

    deleteSspBanner(id, token).then((response) => {
      if (response.status === 200) {
        console.log("Banner deleted successfully");
        const updatedBanners = bannerData.filter((banner) => banner.id !== id);
        setBannerData(updatedBanners);
      } else {
        console.error("Error deleting banner");
        toast.error("Error deleting banner", {
          position: "top-center",
        });
      }
    });
  };


  return loading?<Loader/>: (
    <div header="Banners">
      <ToastContainer />
      <div>
        <div>
          <BannerTable
            filteredData={bannerData}
            handleDeleteBanner={handleDeleteBanner}
          />
        </div>
      </div>
    </div>
  );
}
