import React, { useContext, useEffect, useState } from "react";

import { styles } from "./new-advertiser-style";

import { newAdvertiserApi } from "../../api/api";
import { addAdvertiserApi } from "../../api/ssp-api";
import { AuthContext } from "../../provider/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ImageDropzone from "../banners/image-dropzone";
import Spinner from "../../components/spinner";

const NewAdvertiser = ({ classes, history }) => {
  const [name, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [advertiserType, setAdvertiserType] = useState(1);
  const [files, setFiles] = useState([]);
  const [ecpm, setEcpm] = useState("");
  const [bidCpm, setBidCpm] = useState("");
  const [hourlyCpm, setHourlyCpm] = useState("0");
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sspAdvertiserId, setSspAdvertiserId] = useState({});

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const { userDetails } = useContext(AuthContext);
  const { currentPage, setCurrentPage, setActions } =
    useContext(BreadcrumbContext);
  const navigate = useNavigate();
  useEffect(() => {
    setActions(<div></div>);
    setCurrentPage("Create advertiser");
  }, []);

  const onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === "userName") setUserName(value);
    else if (name === "email") setEmail(value);
    else if (name === "company") setCompany(value);
    else if (name === "advertiserType") setAdvertiserType(value);
    else if (name === "ecpm") setEcpm(value);
    else if (name === "bidCpm") setBidCpm(value);
    else if (name === "hourlyCpm") setHourlyCpm(value);
  };

  let advertiser = {
    contactName: name,
    emailAddress: email,
    advertiserName: company,
  };

  const onSubmit = async () => {
    if (email === "" || name === "" || company === "") {
      toast.error("Please fill all the required fields", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("contactName", name);
    formData.append("emailAddress", email);
    formData.append("company", company);
    formData.append("ecpm", ecpm);
    formData.append("bidCpm", bidCpm);
    formData.append("hourlyCpm", hourlyCpm);
    formData.append("logo", files[0]);

    advertiser.defaultAccountId = 3;
    // advertiser.password = `${userName}@kwanza`
    advertiser.active = 1;
    advertiser.advertiserName = company;
    console.log(formData);
    try {
      // Adding an Advertiser
      const advertiserResponse = await addAdvertiserApi(
        advertiser,
        base64Credentials
      );
      if (advertiserResponse.status === 200) {
        formData.append("sspId", advertiserResponse.data.advertiserId);
        const { token, role, agencyId } = JSON.parse(userDetails);
        formData.append("agencyId", agencyId);
        // Adding a new Advertiser
        const newAdvertiserResponse = await newAdvertiserApi(formData, token);
        console.log("New advertiser added", newAdvertiserResponse.data);
        navigate(-1);
        toast.success("Advertiser added successfully");
      } else {
        console.log("failed to add advertiser");
        toast.error("Failed to add advertiser");
      }

      setIsLoading(false);
      navigate("/advertiser");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div header={"New Advertiser"}>
      <ToastContainer />
      <div className="bg-white border border-border rounded-md p-10 space-y-4">
        <div className="space-y-4" sx={styles.root}>
          <div className="space-y-1">
            <label className="text-sm">Logo Creative*</label>
            <div className="w-full border border-border  bg-background rounded-md py-4">
              {!isUploaded ? (
                <ImageDropzone
                  onImageUpload={(file) => {
                    console.log("Adding files");
                    setFiles((prev) => [...prev, file]);
                    console.log(files);
                    setIsUploaded(true);
                  }}
                />
              ) : (
                <div className="flex justify-center">
                  <img
                    className="rounded h-24 w-24 object-cover"
                    onClick={() => {
                      setIsUploaded(false);
                      setFiles([]);
                    }}
                    src={URL.createObjectURL(files[0])}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div item xs={5} className="space-y-1" sx={styles.first}>
              <label className="text-sm">Contact name*</label>
              <input
                className="form-style"
                value={name}
                autoComplete="off"
                name="userName"
                required
                onChange={onChange}
              />
            </div>
            <div item xs={5} className="space-y-1" sx={styles.first}>
              <label className="text-sm">Email*</label>
              <input
                className="form-style"
                value={email}
                name="email"
                autoComplete="off"
                onChange={onChange}
              />
            </div>
            <div item xs={9} container sx={styles.row}>
              <div item xs={5} className="space-y-1">
                <label className="text-sm">Company*</label>
                <input
                  className="form-style"
                  value={company}
                  name="company"
                  autoComplete="off"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 ">
            <div xs={5} className="space-y-1">
              <label className="text-sm">eCPM*</label>
              <input
                className="form-style"
                value={ecpm}
                name="ecpm"
                type="number"
                autoComplete="off"
                min="0"
                onChange={onChange}
              />
            </div>
            <div xs={5} className="space-y-1">
              <label className="text-sm">Bid CPM*</label>
              <input
                className="form-style"
                value={bidCpm}
                name="bidCpm"
                type="number"
                autoComplete="off"
                min="0"
                onChange={onChange}
              />
            </div>
            <div xs={9} container sx={styles.row}>
              <div xs={5} className="space-y-1">
                <label className="text-sm">Hourly CPM*</label>
                <input
                  className="form-style"
                  value={hourlyCpm}
                  name="hourlyCpm"
                  type="number"
                  autoComplete="off"
                  onChange={onChange}
                  min="0"
                />
              </div>
            </div>
          </div>

          <button
            // disabled
            className="py-2 px-3 rounded-md mt-4 flex bg-primary text-white w-40 justify-center items-center "
            onClick={onSubmit}
          >
            {isLoading ? <Spinner /> : "Create Advertiser"}
          </button>
        </div>
      </div>
    </div>
  );
};


export default NewAdvertiser;
