import { useContext } from "react";
import { DeletionContext } from "../layouts/dashboardLayout";
import { GrTrash } from "react-icons/gr";
import CustomModal from "./custom-modal";
import { toast } from "react-toastify";

const DeleteModal = () => {
  const { onDelete, showConfirmDeletion, setShowConfirmDeletion } =
    useContext(DeletionContext);
  return (
    <CustomModal
      show={showConfirmDeletion}
      heading={"Confirm Deletion"}
      content={
        <div className="flex flex-col justify-center items-center">
          <GrTrash className="text-4xl text-red-400" />
          <h1 className="text-xl mt-4">Are you sure you want to delete ?</h1>
          <div className="flex space-x-2 mt-4">
            <button
              className="font-bold py-2 px-4  bg-muted bg-opacity-40 hover:scale-105 transition-all duration-200  text-white rounded"
              onClick={() => {
                setShowConfirmDeletion(false);
              }}
            >
              No
            </button>
            <button
              className="font-bold py-2 px-4  bg-red-400 hover:scale-105 transition-all duration-200  text-white rounded"
              onClick={() => {
                onDelete();
                setShowConfirmDeletion(false);
                toast.success("Deleted Successfully");
              }}
            >
              Yes
            </button>
          </div>
        </div>
      }
      setShow={setShowConfirmDeletion}
    />
  );
};

export default DeleteModal;
