
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addZone, fetchPublisherWebsites } from "../../../../api/ssp-api";

import { addSspZone } from "../../../../api/api";
import { AuthContext } from "../../../../provider/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import AgencyWrapper from "../../../../components/wrappers/agency-wrapper";
import { Formik } from "formik";
import * as Yup from "yup"
import { BreadcrumbContext } from "../../../../layouts/dashboardLayout";
import Loader from "../../../../components/loader";

export default function AgencyZoneForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    publisherId: "",
    zoneName: "",
    type: "0",
    width: 0,
    height: 0,
  });
  const [loading, setLoading] = useState(true);
  const [publisherData, setPublisherData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const { userDetails } = useContext(AuthContext);

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const { token, role, agencyId } = JSON.parse(userDetails);
  const {setCurrentPage} = useContext(BreadcrumbContext)
  useEffect(() => {
    setCurrentPage("New Zone")
    fetchPublisherWebsites(base64Credentials, agencyId)
      .then((response) => {
        setPublisherData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue =
      name === "width" || name === "height" || name === "publisherId"
        ? parseInt(value, 10)
        : value;

    setFormData((prevData) => ({ ...prevData, [name]: newValue }));
  };

  const onSubmit = (values) => {
    formData.publisherId = values.publisherId;
    formData.height = values.height;
    formData.width = values.width;
    formData.zoneName = values.zoneName;
    formData.agencyId = agencyId;

    addSspZone(formData, token).then((res) => {
      console.log(res);
      setLoading(false);
      navigate("/agency/zones");
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      toast.error(err, {
        position: toast.POSITION.TOP_CENTER,
      })
    })
  };


const validationSchema = Yup.object({
  publisherId:Yup.string().required("Select publisher"),
  zoneName:Yup.string().required("Enter zone name"),
  width:Yup.string().required("Enter width"),
  height:Yup.string().required("Enter height")
})
  return (
    <div header={"Zone"}>
      <ToastContainer />
      <div xs={12}  container>
        {loading || publisherData === undefined ? (
          <Loader/>
        ) : (
          <Formik  onSubmit={onSubmit} validationSchema={validationSchema} initialValues={{
            publisherId:"",
            zoneName:"",
            width:"",
            height:""
          }}>
            {({values,errors,handleChange,handleSubmit,touched,isSubmitting})=>(
              <form className="bg-white p-10 rounded-md w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                <div className="mt-2 w-full">
          <h1 className="text-xs text-gray-500 mb-1">Publisher</h1>
          <select onChange={handleChange} value={values.publisherId} name="publisherId"  className=" form-style">
            <option>Select</option>
            {publisherData.map((item) => (
                 <option key={item.publisherId} value={item.publisherId}>
                   {item.website}
                 </option>
               ))}
          </select>
          <div>{touched.publisherId && errors.publisherId && <div className="text-red-500 text-sm mt-1 form-style">{errors.publisherId}</div>}</div>
         </div>
         <div className="mt-2">
          <h1 className="text-xs text-gray-500 mb-1">Zone Name</h1>
          <input type="text" onChange={handleChange} value={values.zoneName} name="zoneName"  className="form-style"/>
          <div>{touched.zoneName && errors.zoneName && <div className="text-red-500 text-sm mt-1">{errors.zoneName}</div>}</div>
         </div>
         <div className="mt-2">
          <h1 className="text-xs text-gray-500 mb-1">Width</h1>
          <input type="number" onChange={handleChange} value={values.width} name="width"  className="form-style"/>
          <div>{touched.width && errors.width && <div className="text-red-500 text-sm mt-1">{errors.width}</div>}</div>
         </div>
         <div className="mt-2">
          <h1 className="text-xs text-gray-500 mb-1">Height</h1>
          <input type="number" onChange={handleChange} value={values.height} name="height"  className="form-style"/>
          <div>{touched.height && errors.height && <div className="text-red-500 text-sm mt-1">{errors.height}</div>}</div>
         </div>
         <div className="space-y-1 flex flex-col">
                    <div>
                      <label className="text-sm">
                        Is this website Premium ?
                      </label>
                    </div>
                    <div className="flex space-x-2 items-center group">
                      <input
                        placeholder="Enter an Email Address"
                        type="checkbox"
                        name="email"
                        className="size-8 rounded-md border-border checked:bg-primary focus:ring-primary hover:bg-transparent checked:hover:bg-primary checked:focus:bg-primary   focus:border-primary mt-1"
                        onChange={(e) => {
                          setIsChecked(e.target.checked);
                        }}
                        margin="normal"
                      />
                      <p className=" group-aria-checked:hidden font-normal  text-sm">
                        (
                        {!isChecked
                          ? "No, it is not premium"
                          : "Yes, it is premium"}{" "}
                        )
                      </p>
                    </div>
                  </div>
                </div>
        
         <button
                  className="mt-6 bg-primary font-medium rounded-md py-2 px-3 text-white"
                  type="submit">
                  {isSubmitting ? <CircularProgress style={{color:"white"}} size="1.4rem" /> : "Add Zone"}
                </button>
            
          </form>
            )}
          
          </Formik>
          
        )}
      </div>
    </div>
  );
}
