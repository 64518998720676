import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { MdOutlineChevronRight, MdOutlineChevronLeft } from "react-icons/md";
import ModifyButton from "../../../components/modify-button";

const DspAdvertiserTable = ({ data, deleteDspAdvertiser }) => {
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onEdit = (id) => {};
  const onView = (id) => {
    navigate(`/advertiser/view/${id}`);
  };
  const onDelete = (dspId, sspId) => {
    deleteDspAdvertiser(dspId, sspId);
  };
  const [filter, setFilter] = useState("");
  const filteredData = data.filter(
    (item) => item.advertiser.toLowerCase().includes(filter.toLowerCase()) // Filter by campaign name
  );
  return (
    <div className="bg-white px-5 py-5 w-full rounded-md border border-border">
      <div className="flex justify-end space-x-2">
        <input
          label="Filter by name or campaign name"
          placeholder="Search here"
          className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <table className="text-sm table w-full mt-4">
        <thead className="text-muted">
          <tr className="">
            <th className="py-3 text-sm text-start">Id</th>
            <th className="text-sm text-start py-3">Advertiser</th>
            <th className="text-sm text-start py-3">Company</th>
            <th className="text-sm text-start py-3">Email</th>
            <th className="text-sm text-start py-3"></th>
          </tr>
        </thead>
        <tbody className="text-start">
          {filteredData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => (
              <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                <td className="text-start py-3 px-0 ">{item.id}</td>
                <td className="text-start py-3 px-0 ">{item.advertiser}</td>
                <td className="text-start py-3 px-0 ">{item.company}</td>
                <td className="text-start py-3 px-0 ">{item.email}</td>
                <td className="text-start px-0 py-3 flex space-x-4">
                  <ModifyButton
                    id={item.id}
                    onEdit={() => {
                      onView(item.id);
                    }}
                    onDelete={() => {
                      onDelete(item.id, item.sspId);
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
        <h1 className=" translate-x-5">Rows per page</h1>
        <select
          value={rowsPerPage}
          onChange={(e) => {
            setRowsPerPage(e.target.value);
          }}
          className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
        >
          <option className="" value={5}>
            5
          </option>
          <option className="" value={8}>
            8
          </option>
        </select>
        <div
          onClick={() => {
            if (page > 0) {
              setPage(page - 1);
            }
          }}
          className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
        >
          <MdOutlineChevronLeft />
        </div>
        <h1>
          {page + 1} out of {Math.ceil(data.length / rowsPerPage)}
        </h1>
        <div
          onClick={() => {
            if (page < Math.round(data.length / rowsPerPage)) {
              setPage(page + 1);
            }
          }}
          className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
        >
          <MdOutlineChevronRight />
        </div>
      </div>
    </div>
  );
};

export default DspAdvertiserTable;
