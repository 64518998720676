



const CampaignDetailWrapper = ({ header, children }) => {
  const classes = useStyles();
  return (
    <div xs={12} className={classes.root}>
      <Typography className={classes.header}>
        {header || "Component"}
      </Typography>

      <Paper className={classes.paper}>{children}</Paper>
    </div>
  );
};

export default CampaignDetailWrapper;
