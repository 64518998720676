export const interests = [
  "Building Sales and Profits",
  "Building Brand Awareness",
  "Getting People's Attention",
];
export const headers = [
  {
    label: "Audience",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
    sortKey: "campaign",
  },
  {
    label: "Start date",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
  {
    label: "End date",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
  {
    label: "Actions",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
];

export const iabCategories = [
  {
    category: "Arts & Entertainment",
    categories: [
      { name: "Books & Literature", value: "IAB1-1" },
      { name: "Celebrity Fan/Gossip", value: "IAB1-2" },
      { name: "Fine Art", value: "IAB1-3" },
      { name: "Humor", value: "IAB1-4" },
      { name: "Movies", value: "IAB1-5" },
      { name: "Music", value: "IAB1-6" },
      { name: "Television", value: "IAB1-7" },
    ],
  },
  {
    category: "Automotive",
    categories: [
      { name: "Auto Parts", value: "IAB2-1" },
      { name: "Auto Repair", value: "IAB2-2" },
      { name: "Buying/Selling Cars", value: "IAB2-3" },
      { name: "Car Culture", value: "IAB2-4" },
      { name: "Certified Pre-Owned", value: "IAB2-5" },
      { name: "Convertible", value: "IAB2-6" },
      { name: "Coupe", value: "IAB2-7" },
      { name: "Crossover", value: "IAB2-8" },
      { name: "Diesel", value: "IAB2-9" },
      { name: "Electric Vehicle", value: "IAB2-10" },
      { name: "Hatchback", value: "IAB2-11" },
      { name: "Hybrid", value: "IAB2-12" },
      { name: "Luxury", value: "IAB2-13" },
      { name: "MiniVan", value: "IAB2-14" },
      { name: "Mororcycles", value: "IAB2-15" },
      { name: "Off-Road Vehicles", value: "IAB2-16" },
      { name: "Performance Vehicles", value: "IAB2-17" },
      { name: "Pickup", value: "IAB2-18" },
      { name: "Road-Side Assistance", value: "IAB2-19" },
      { name: "Sedan", value: "IAB2-20" },
      { name: "Trucks & Accessories", value: "IAB2-21" },
      { name: "Vintage Cars", value: "IAB2-22" },
      { name: "Wagon", value: "IAB2-23" },
    ],
  },
  {
    category: "Business",
    categories: [
      { name: "Advertising", value: "IAB3-1" },
      { name: "Agriculture", value: "IAB3-2" },
      { name: "Biotech/Biomedical", value: "IAB3-3" },
      { name: "Business Software", value: "IAB3-4" },
      { name: "Construction", value: "IAB3-5" },
      { name: "Forestry", value: "IAB3-6" },
      { name: "Government", value: "IAB3-7" },
      { name: "Green Solutions", value: "IAB3-8" },
      { name: "Human Resources", value: "IAB3-9" },
      { name: "Logistics", value: "IAB3-10" },
      { name: "Marketing", value: "IAB3-11" },
      { name: "Metals", value: "IAB3-12" },
    ],
  },
  {
    category: "Careers",
    categories: [
      { name: "Career Planning", value: "IAB4-1" },
      { name: "College", value: "IAB4-2" },
      { name: "Financial Aid", value: "IAB4-3" },
      { name: "Job Fairs", value: "IAB4-4" },
      { name: "Job Search", value: "IAB4-5" },
      { name: "Resume Writing/Advice", value: "IAB4-6" },
      { name: "Nursing", value: "IAB4-7" },
      { name: "Scholarships", value: "IAB4-8" },
      { name: "Telecommuting", value: "IAB4-9" },
      { name: "U.S. Military", value: "IAB4-10" },
      { name: "Career Advice", value: "IAB4-11" },
    ],
  },
  {
    category: "Education",
    categories: [
      { name: "7-12 Education", value: "IAB5-1" },
      { name: "Adult Education", value: "IAB5-2" },
      { name: "Art History", value: "IAB5-3" },
      { name: "College Administration", value: "IAB5-4" },
      { name: "College Life", value: "IAB5-5" },
      { name: "Distance Learning", value: "IAB5-6" },
      { name: "English as a 2nd Language", value: "IAB5-7" },
      { name: "Language Learning", value: "IAB5-8" },
      { name: "Graduate School", value: "IAB5-9" },
      { name: "Homeschooling", value: "IAB5-10" },
      { name: "Homework/Study Tips", value: "IAB5-11" },
      { name: "K-6 Educators", value: "IAB5-12" },
      { name: "Private School", value: "IAB5-13" },
      { name: "Special Education", value: "IAB5-14" },
      { name: "Studying Business", value: "IAB5-15" },
    ],
  },
  {
    category: "Family & Parenting",
    categories: [
      { name: "Adoption", value: "IAB6-1" },
      { name: "Babies & Toddlers", value: "IAB6-2" },
      { name: "Daycare/Pre School", value: "IAB6-3" },
      { name: "Family Internet", value: "IAB6-4" },
      { name: "Parenting - K-6 Kids", value: "IAB6-5" },
      { name: "Parenting teens", value: "IAB6-6" },
      { name: "Pregnancy", value: "IAB6-7" },
      { name: "Special Needs Kids", value: "IAB6-8" },
      { name: "Eldercare", value: "IAB6-9" },
    ],
  },
  {
    category: "Health & Fitness",
    categories: [
      { name: "Exercise", value: "IAB7-1" },
      { name: "A.D.D.", value: "IAB7-2" },
      { name: "AIDS/HIV", value: "IAB7-3" },
      { name: "Allergies", value: "IAB7-4" },
      { name: "Alternative Medicine", value: "IAB7-5" },
      { name: "Arthritis", value: "IAB7-6" },
      { name: "Asthma", value: "IAB7-7" },
      { name: "Autism/PDD", value: "IAB7-8" },
      { name: "Bipolar Disorder", value: "IAB7-9" },
      { name: "Brain Tumor", value: "IAB7-10" },
      { name: "Cancer", value: "IAB7-11" },
      { name: "Cholesterol", value: "IAB7-12" },
      { name: "Chronic Fatigue Syndrome", value: "IAB7-13" },
      { name: "Chronic Pain", value: "IAB7-14" },
      { name: "Cold & Flu", value: "IAB7-15" },
      { name: "Deafness", value: "IAB7-16" },
      { name: "Dental Care", value: "IAB7-17" },
      { name: "Depression", value: "IAB7-18" },
      { name: "Dermatology", value: "IAB7-19" },
      { name: "Diabetes", value: "IAB7-20" },
      { name: "Epilepsy", value: "IAB7-21" },
      { name: "GERD/Acid Reflux", value: "IAB7-22" },
      { name: "Headaches/Migraines", value: "IAB7-23" },
      { name: "Heart Disease", value: "IAB7-24" },
      { name: "Herbs for Health", value: "IAB7-25" },
      { name: "Holistic Healing", value: "IAB7-26" },
      { name: "IBS/Crohn's Disease", value: "IAB7-27" },
      { name: "Incest/Abuse Support", value: "IAB7-28" },
      { name: "Incontinence", value: "IAB7-29" },
      { name: "Infertility", value: "IAB7-30" },
      { name: "Men's Health", value: "IAB7-31" },
      { name: "Nutrition", value: "IAB7-32" },
      { name: "Orthopedics", value: "IAB7-33" },
      { name: "Panic/Anxiety Disorders", value: "IAB7-34" },
      { name: "Pediatrics", value: "IAB7-35" },
      { name: "Physical Therapy", value: "IAB7-36" },
      { name: "Psychology/Psychiatry", value: "IAB7-37" },
      { name: "Senior Health", value: "IAB7-38" },
      { name: "Sexuality", value: "IAB7-39" },
      { name: "Sleep Disorders", value: "IAB7-40" },
      { name: "Smoking Cessation", value: "IAB7-41" },
      { name: "Substance Abuse", value: "IAB7-42" },
      { name: "Thyroid Disease", value: "IAB7-43" },
      { name: "Weight Loss", value: "IAB7-44" },
      { name: "Women's Health", value: "IAB7-45" },
    ],
  },
  {
    category: "Food & Drink",
    categories: [
      { name: "American Cuisine", value: "IAB8-1" },
      { name: "Barbecues & Grilling", value: "IAB8-2" },
      { name: "Cajun/Creole", value: "IAB8-3" },
      { name: "Chinese Cuisine", value: "IAB8-4" },
      { name: "Cocktails/Beer", value: "IAB8-5" },
      { name: "Coffee/Tea", value: "IAB8-6" },
      { name: "Cuisine-Specific", value: "IAB8-7" },
      { name: "Desserts & Baking", value: "IAB8-8" },
      { name: "Dining Out", value: "IAB8-9" },
      { name: "Food Allergies", value: "IAB8-10" },
      { name: "French Cuisine", value: "IAB8-11" },
      { name: "Health/Lowfat Cooking", value: "IAB8-12" },
      { name: "Italian Cuisine", value: "IAB8-13" },
      { name: "Japanese Cuisine", value: "IAB8-14" },
      { name: "Mexican Cuisine", value: "IAB8-15" },
      { name: "Vegan", value: "IAB8-16" },
      { name: "Vegetarian", value: "IAB8-17" },
      { name: "Wine", value: "IAB8-18" },
    ],
  },
  {
    category: "Hobbies & Interests",
    categories: [
      { name: "Art/Technology", value: "IAB9-1" },
      { name: "Arts & Crafts", value: "IAB9-2" },
      { name: "Beadwork", value: "IAB9-3" },
      { name: "Bird-Watching", value: "IAB9-4" },
      { name: "Board Games/Puzzles", value: "IAB9-5" },
      { name: "Candle & Soap Making", value: "IAB9-6" },
      { name: "Card Games", value: "IAB9-7" },
      { name: "Chess", value: "IAB9-8" },
      { name: "Cigars", value: "IAB9-9" },
      { name: "Collecting", value: "IAB9-10" },
      { name: "Comic Books", value: "IAB9-11" },
      { name: "Drawing/Sketching", value: "IAB9-12" },
      { name: "Freelance Writing", value: "IAB9-13" },
      { name: "Genealogy", value: "IAB9-14" },
      { name: "Getting Published", value: "IAB9-15" },
      { name: "Guitar", value: "IAB9-16" },
      { name: "Home Recording", value: "IAB9-17" },
      { name: "Investors & Patents", value: "IAB9-18" },
      { name: "Jewelry-Making", value: "IAB9-19" },
      { name: "Magic & Illusion", value: "IAB9-20" },
      { name: "Needlework", value: "IAB9-21" },
      { name: "Painting", value: "IAB9-22" },
      { name: "Photography", value: "IAB9-23" },
      { name: "Radio", value: "IAB9-24" },
      { name: "Roleplaying Games", value: "IAB9-25" },
      { name: "Sci-Fi & Fantasy", value: "IAB9-26" },
      { name: "Scrapbooking", value: "IAB9-27" },
      { name: "Screenwriting", value: "IAB9-28" },
      { name: "Stamps & Coins", value: "IAB9-29" },
      { name: "Video & Computer Games", value: "IAB9-30" },
      { name: "Woodworking", value: "IAB9-31" },
    ],
  },
  {
    category: "Home & Garden",
    categories: [
      { name: "Appliances", value: "IAB10-1" },
      { name: "Entertaining", value: "IAB10-2" },
      { name: "Environmental Safety", value: "IAB10-3" },
      { name: "Gardening", value: "IAB10-4" },
      { name: "Home Repair", value: "IAB10-5" },
      { name: "Home Theater", value: "IAB10-6" },
      { name: "Interior Decorating", value: "IAB10-7" },
      { name: "Landscaping", value: "IAB10-8" },
      { name: "Remodeling & Construction", value: "IAB10-9" },
    ],
  },
  {
    category: "Law, Gov't & Politics",
    categories: [
      { name: "Immigration", value: "IAB11-1" },
      { name: "Legal Issues", value: "IAB11-2" },
      { name: "U.S. Government Resources", value: "IAB11-3" },
      { name: "Politics", value: "IAB11-4" },
      { name: "Commentary", value: "IAB11-5" },
    ],
  },
  {
    category: "News",
    categories: [
      { name: "International News", value: "IAB12-1" },
      { name: "National News", value: "IAB12-2" },
      { name: "Local News", value: "IAB12-3" },
    ],
  },
  {
    category: "Personal Finance",
    categories: [
      { name: "Beginning Investing", value: "IAB13-1" },
      { name: "Credit/Debt & Loans", value: "IAB13-2" },
      { name: "Financial News", value: "IAB13-3" },
      { name: "Financial Planning", value: "IAB13-4" },
      { name: "Hedge Fund", value: "IAB13-5" },
      { name: "Insurance", value: "IAB13-6" },
      { name: "Investing", value: "IAB13-7" },
      { name: "Mutual Funds", value: "IAB13-8" },
      { name: "Options", value: "IAB13-9" },
      { name: "Retirement Planning", value: "IAB13-10" },
      { name: "Stocks", value: "IAB13-11" },
      { name: "Tax Planning", value: "IAB13-12" },
    ],
  },
  {
    category: "Society",
    categories: [
      { name: "Dating", value: "IAB14-1" },
      { name: "Divorce Support", value: "IAB14-2" },
      { name: "Gay Life", value: "IAB14-3" },
      { name: "Marriage", value: "IAB14-4" },
      { name: "Senior Living", value: "IAB14-5" },
      { name: "Teens", value: "IAB14-6" },
      { name: "Weddings", value: "IAB14-7" },
      { name: "Ethnic Specific", value: "IAB14-8" },
    ],
  },
  {
    category: "Science",
    categories: [
      { name: "Astrology", value: "IAB15-1" },
      { name: "Biology", value: "IAB15-2" },
      { name: "Chemistry", value: "IAB15-3" },
      { name: "Geology", value: "IAB15-4" },
      { name: "Paranormal Phenomena", value: "IAB15-5" },
      { name: "Physics", value: "IAB15-6" },
      { name: "Space/Astronomy", value: "IAB15-7" },
      { name: "Geography", value: "IAB15-8" },
      { name: "Botany", value: "IAB15-9" },
      { name: "Weather", value: "IAB15-10" },
    ],
  },
  {
    category: "Pets",
    categories: [
      { name: "Aquariums", value: "IAB16-1" },
      { name: "Birds", value: "IAB16-2" },
      { name: "Cats", value: "IAB16-3" },
      { name: "Dogs", value: "IAB16-4" },
      { name: "Large Animals", value: "IAB16-5" },
      { name: "Reptiles", value: "IAB16-6" },
      { name: "Veterinary Medicine", value: "IAB16-7" },
    ],
  },
  {
    category: "Sports",
    categories: [
      { name: "Auto Racing", value: "IAB17-1" },
      { name: "Baseball", value: "IAB17-2" },
      { name: "Bicycling", value: "IAB17-3" },
      { name: "Bodybuilding", value: "IAB17-4" },
      { name: "Boxing", value: "IAB17-5" },
      { name: "Canoeing/Kayaking", value: "IAB17-6" },
      { name: "Cheerleading", value: "IAB17-7" },
      { name: "Climbing", value: "IAB17-8" },
      { name: "Cricket", value: "IAB17-9" },
      { name: "Figure Skating", value: "IAB17-10" },
      { name: "Fly Fishing", value: "IAB17-11" },
      { name: "Football", value: "IAB17-12" },
      { name: "Freshwater Fishing", value: "IAB17-13" },
      { name: "Game & Fish", value: "IAB17-14" },
      { name: "Golf", value: "IAB17-15" },
      { name: "Horse Racing", value: "IAB17-16" },
      { name: "Horses", value: "IAB17-17" },
      { name: "Hunting/Shooting", value: "IAB17-18" },
      { name: "Inline Skating", value: "IAB17-19" },
      { name: "Martial Arts", value: "IAB17-20" },
      { name: "Mountain Biking", value: "IAB17-21" },
      { name: "NASCAR Racing", value: "IAB17-22" },
      { name: "Olympics", value: "IAB17-23" },
      { name: "Paintball", value: "IAB17-24" },
      { name: "Power & Motoryachts", value: "IAB17-25" },
      { name: "Pro Basketball", value: "IAB17-26" },
      { name: "Pro Ice Hockey", value: "IAB17-27" },
      { name: "Rodeo", value: "IAB17-28" },
      { name: "Rugby", value: "IAB17-29" },
      { name: "Running/Jogging", value: "IAB17-30" },
      { name: "Sailing", value: "IAB17-31" },
      { name: "Saltwater Fishing", value: "IAB17-32" },
      { name: "Scuba Diving", value: "IAB17-33" },
      { name: "Skateboarding", value: "IAB17-34" },
      { name: "Skiing", value: "IAB17-35" },
      { name: "Snowboarding", value: "IAB17-36" },
      { name: "Surfing/Body-Boarding", value: "IAB17-37" },
      { name: "Swimming", value: "IAB17-38" },
      { name: "Table Tennis/Ping-Pong", value: "IAB17-39" },
      { name: "Tennis", value: "IAB17-40" },
      { name: "Volleyball", value: "IAB17-41" },
      { name: "Walking", value: "IAB17-42" },
      { name: "Waterski/Wakeboard", value: "IAB17-43" },
      { name: "World Soccer", value: "IAB17-44" },
    ],
  },
  {
    category: "Style & Fashion",
    categories: [
      { name: "Accessories", value: "IAB18-1" },
      { name: "Beauty", value: "IAB18-2" },
      { name: "Body Art", value: "IAB18-3" },
      { name: "Fashion", value: "IAB18-4" },
      { name: "Jewelry", value: "IAB18-5" },
      { name: "Clothing", value: "IAB18-6" },
    ],
  },
  {
    category: "Technology & Computing",
    categories: [
      { name: "3-D Graphics", value: "IAB19-1" },
      { name: "Animation", value: "IAB19-2" },
      { name: "Antivirus Software", value: "IAB19-3" },
      { name: "C/C++", value: "IAB19-4" },
      { name: "Cameras & Camcorders", value: "IAB19-5" },
      { name: "Cell Phones", value: "IAB19-6" },
      { name: "Computer Certification", value: "IAB19-7" },
      { name: "Computer Networking", value: "IAB19-8" },
      { name: "Computer Peripherals", value: "IAB19-9" },
      { name: "Computer Reviews", value: "IAB19-10" },
      { name: "Data Centers", value: "IAB19-11" },
      { name: "Databases", value: "IAB19-12" },
      { name: "Desktop Publishing", value: "IAB19-13" },
      { name: "Desktop Video", value: "IAB19-14" },
      { name: "Email", value: "IAB19-15" },
      { name: "Graphics Software", value: "IAB19-16" },
      { name: "Home Video/DVD", value: "IAB19-17" },
      { name: "Internet Technology", value: "IAB19-18" },
      { name: "Java", value: "IAB19-19" },
      { name: "JavaScript", value: "IAB19-20" },
      { name: "Mac Support", value: "IAB19-21" },
      { name: "MP3/MIDI", value: "IAB19-22" },
      { name: "Net Conferencing", value: "IAB19-23" },
      { name: "Net for Beginners", value: "IAB19-24" },
      { name: "Network Security", value: "IAB19-25" },
      { name: "Palmtops/PDAs", value: "IAB19-26" },
      { name: "PC Support", value: "IAB19-27" },
      { name: "Portable", value: "IAB19-28" },
      { name: "Shareware/Freeware", value: "IAB19-29" },
      { name: "Unix", value: "IAB19-30" },
      { name: "Visual Basic", value: "IAB19-31" },
      { name: "Web Clip Art", value: "IAB19-32" },
      { name: "Web Design/HTML", value: "IAB19-33" },
      { name: "Web Search", value: "IAB19-34" },
      { name: "Windows", value: "IAB19-35" },
    ],
  },
  {
    category: "Travel",
    categories: [
      { name: "Adventure Travel", value: "IAB20-1" },
      { name: "Africa", value: "IAB20-2" },
      { name: "Air Travel", value: "IAB20-3" },
      { name: "Australia & New Zealand", value: "IAB20-4" },
      { name: "Bed & Breakfasts", value: "IAB20-5" },
      { name: "Budget Travel", value: "IAB20-6" },
      { name: "Business Travel", value: "IAB20-7" },
      { name: "By US Locale", value: "IAB20-8" },
      { name: "Camping", value: "IAB20-9" },
      { name: "Canada", value: "IAB20-10" },
      { name: "Caribbean", value: "IAB20-11" },
      { name: "Cruises", value: "IAB20-12" },
      { name: "Eastern Europe", value: "IAB20-13" },
      { name: "Europe", value: "IAB20-14" },
      { name: "France", value: "IAB20-15" },
      { name: "Greece", value: "IAB20-16" },
      { name: "Honeymoons/Getaways", value: "IAB20-17" },
      { name: "Hotels", value: "IAB20-18" },
      { name: "Italy", value: "IAB20-19" },
      { name: "Japan", value: "IAB20-20" },
      { name: "Mexico & Central America", value: "IAB20-21" },
      { name: "National Parks", value: "IAB20-22" },
      { name: "South America", value: "IAB20-23" },
      { name: "Spas", value: "IAB20-24" },
      { name: "Theme Parks", value: "IAB20-25" },
      { name: "Traveling with Kids", value: "IAB20-26" },
      { name: "United Kingdom", value: "IAB20-27" },
    ],
  },
  {
    category: "Real Estate",
    categories: [
      { name: "Apartments", value: "IAB21-1" },
      { name: "Architects", value: "IAB21-2" },
      { name: "Buying/Selling Homes", value: "IAB21-3" },
    ],
  },
  {
    category: "Shopping",
    categories: [
      { name: "Contests & Freebies", value: "IAB22-1" },
      { name: "Couponing", value: "IAB22-2" },
      { name: "Comparison", value: "IAB22-3" },
    ],
  },
  {
    category: "Religion & Spirituality",
    categories: [
      { name: "Alternative Religions", value: "IAB23-1" },
      { name: "Atheism/Agnosticism", value: "IAB23-2" },
      { name: "Buddhism", value: "IAB23-3" },
      { name: "Catholicism", value: "IAB23-4" },
      { name: "Christianity", value: "IAB23-5" },
      { name: "Hinduism", value: "IAB23-6" },
      { name: "Islam", value: "IAB23-7" },
      { name: "Judaism", value: "IAB23-8" },
      { name: "Latter-Day Saints", value: "IAB23-9" },
      { name: "Pagan/Wiccan", value: "IAB23-10" },
    ],
  },
  {
    category: "Uncategorized",
    categories: [{ name: "Uncategorized", value: "IAB24" }],
  },
  {
    category: "Non-Standard Content",
    categories: [
      { name: "Illegal Content", value: "IAB25-1" },
      { name: "Warez", value: "IAB25-2" },
      { name: "Spyware/Malware", value: "IAB25-3" },
      { name: "Copyright Infringement", value: "IAB25-4" },
    ],
  },
];

export const chartColors = ["#6DBF48", "#FBC23D", "#082A7B", "#EB345F","#58C3FF","#8358FF","#DE58FF"]