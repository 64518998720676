import { set } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartColors } from "../audience/constant";

const CustomBarGraph = ({ xlabels, values, ylabels, yValuesName }) => {
  const chartData = {
    series: [
      {
        name: yValuesName,
        data: values,
      },
    ],
    options: {
      animations: {
        enabled: true,
      },
      chart: {
        height: 1000,
        type: "area",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "30%",
        },
      },
      yaxis: {
        title: {
          text: yValuesName,
        },
        labels: {
          style: {
            colors: "#FFFFFF", // Set x-axis label color to white
          },
        },
        axisBorder: {
          show: false, // Hide x-axis line
        },
        categories: ylabels,
      },
      xaxis: {
        categories: xlabels.map((item) => item.split(" ")),
        labels: {
          style: {
            colors: "#FFFFFF", // Set x-axis label color to white
          },
        },
        axisBorder: {
          show: false, // Hide x-axis line
        },
      },
      tooltip: {
        theme: "dark",
      },
      grid: {
        show: false,
      },
      colors: chartColors, // Adding the color green
    },
  };

  return (
    <div className=" rounded-md">
      <Chart options={chartData.options} series={chartData.series} type="bar" />
    </div>
  );
};

export default CustomBarGraph;
