import Loader from "../../components/loader";




const TableLoader = ({ rows, columns }) => {
  const classes = useStyles();
  let rowArray = "* ".repeat(rows - 1).split(" ");
  let columnArray = "* ".repeat(columns - 1).split(" ");
  console.log(columnArray);
  return (
    <>
      {rowArray.map((_, index) => (
        <tr key={index}>
          {columnArray.map((_, index) => (
            <td key={index}>
              <Loader/>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableLoader;
