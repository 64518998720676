import { BsGraphUpArrow } from "react-icons/bs";
import { LuMousePointerClick } from "react-icons/lu";
import { MdOutlineAdsClick } from "react-icons/md";
import { TbBrandCashapp } from "react-icons/tb";
import { motion } from "framer-motion";

export default function CampaignSummary({ data, role }) {
  const { impressions, clicks, revenue } = data[0];

  const campaignsSummary = [
    {
      label: "Impressions",
      value: impressions,
      icon: <BsGraphUpArrow />,
      color: "bg-red-500",
    },
    {
      label: "Clicks",
      value: clicks,
      icon: <LuMousePointerClick />,
      color: "bg-blue-500",
    },
    {
      label: "CTR-Clicks",
      value: `${((clicks / impressions) * 100).toFixed(2)}%`,
      icon: <MdOutlineAdsClick />,
      color: "bg-orange-500",
    },
    {
      label: "Revenue",
      value: `${
        role === "publisher"
          ? "TSh " + impressions
          : "USD " + Number(revenue).toFixed(2)
      }`,
      icon: <TbBrandCashapp />,
      color: "bg-green-500",
    },
  ];
  if (data.length === 0) {
    return <p>No Data</p>;
  }

  const parentVariants = {
    hidden: {
      opacity: 0,
      x: 500,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const childVariants = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  return (
    <div>
      <motion.div
        variants={parentVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-4 gap-4 mt-2"
      >
        {campaignsSummary.map((item, index) => {
          return (
            <motion.div
              variants={childVariants}
              key={index}
              className=" bg-white group rounded-md py-3 px-4 flex items-center space-x-2 hover:scale-[1.05] cursor-pointer transition-all duration-200"
            >
              <div
                className={`size-12 rounded-full ${item.color} bg-opacity-10  flex items-center justify-center`}
              >
                <div>{item.icon}</div>
              </div>
              <div>
                <p className="text-2xl font-bold text-textColor">
                  {item.value}
                </p>
                <h1 className="text-sm">{item.label}</h1>
              </div>
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
}
