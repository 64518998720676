import axios from "axios";

let baseURL = process.env.REACT_APP_DSP_URL;
let role, id;
const user = localStorage.getItem("kwanza");
if (user) {
  try {
    const userObj = JSON.parse(user);
    role = userObj.role;
    id = userObj.id;
  } catch (error) {
    // Handle the error if the data is not in JSON format
    console.error("Error parsing user data:", error);
  }
}

function getHeaders() {
  const user = localStorage.getItem("kwanza");
  const { token } = user ? JSON.parse(user) : {};
  return {
    Authorization: `Bearer ${token}`,
  };
}

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const loginAPI = (email, password) => {
  return api({
    method: "POST",
    data: {
      email,
      password,
    },
    url: "/users/login",
  });
};

export const logoutAPI = (token) => {
  return api({
    method: "POST",
    data: {
      token,
    },
    url: "/users/logout",
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const verifyTokenAPI = (token) => {
  return api({
    method: "POST",
    data: {
      token,
    },
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    url: "/users/verify",
  });
};
export const verifyEmail = (data) => {
  return api({
    method: "POST",
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    url: "/verify",
  });
};
export const resetPassword = (data) => {
  return api({
    method: "POST",
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    url: "/password/reset",
  });
};
export const addDspUserApi = (data, token) => {
  return api({
    method: "POST",
    url: "/users/new",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
export const getCampaignsAPI = () => {
  return api({
    method: "GET",
    url: "/campaigns",
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createCampaignApi = (data) => {
  return api({
    method: "POST",
    url: "/campaign",
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const saveCampaigns = (data) => {
  return api({
    method: "POST",
    url: "/campaigns/info",
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const editCampaigns = (token, data, id) => {
  return api({
    method: "PUT",
    url: `/campaigns/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const getCampaignById = (token, id) => {
  return api({
    method: "GET",
    url: `/dsp/campaign/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const deleteCampaignById = (token, id) => {
  return api({
    method: "DELETE",
    url: `/campaigns/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getAdvertisers = (token, agencyId) => {
  return api({
    method: "GET",
    url: `/advertisers/${agencyId}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getAudiencesApi = (token) => {
  return api({
    method: "GET",
    url: "/targets",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getTargets = (token) => {
  return api({
    method: "GET",
    url: "/targets",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getAudienceById = (id) => {
  return api({
    method: "GET",
    url: `/targets/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const editTarget = (data, id) => {
  return api({
    method: "PUT",
    url: `/target/${id}`,
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const getIabApi = () => {
  return api({
    method: "GET",
    url: "/iab",
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const newAudienceApi = (data) => {
  return api({
    method: "POST",
    url: "/targets",
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getAdverSize = (data) => {
  return api({
    method: "POST",
    url: "advertisers/size",
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const addCampaignAudienceApi = (id, targetId) => {
  return api({
    method: "PUT",
    url: `campaigns/audience/${id}`,
    data: { targetId },
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchBannersByCampaignId = (campaignId, token) => {
  return api({
    method: "GET",
    url: `/dsp/banner/${campaignId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const submitBanner = (data) => {
  return api({
    method: "POST",
    url: "/dsp/banner",
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const deleteDspBanner = (id) => {
  return api({
    method: "DELETE",
    url: `/dsp/banner/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const uploadDSPImageCreative = (data) => {
  return api({
    method: "POST",
    url: "/dsp/image",
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const submitBudgetApi = (data, id) => {
  return api({
    method: "PUT",
    url: `/campaign/budget/${id}`,
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchCampaignStatsApi = (id, token) => {
  return api({
    method: "GET",
    url: `/stats/campaign/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchCampaignSummaryApi = (id, token) => {
  return api({
    method: "GET",
    url: `/campaigns/summary/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchBannerStatsApi = (id, token) => {
  console.log(id, token);
  return api({
    method: "GET",
    url: `/creatives/stats/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchBestBannerApi = (id) => {
  return api({
    method: "GET",
    url: `/creatives/stats/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const downloadReportCsvApi = (campaigns) => {
  console.log(campaigns);
  return api({
    method: "POST",
    url: `/campaigns/exportCsv`,
    data: {
      campaigns,
    },
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "text/csv",
    },
  });
};

export const getAdvertisersDetailsApi = (token, agencyId) => {
  return api({
    method: "GET",
    url: `/advertisers/${agencyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const newAdvertiserApi = (data, token) => {
  return api({
    method: "POST",
    url: "advertisers/new",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const newAdvertiserFromCSV = (data) => {
  return api({
    method: "POST",
    url: "advertisers/new/csv",
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const addChannel = (data) => {
  return api({
    method: "POST",
    url: "channels",
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const getChannels = () => {
  return api({
    method: "GET",
    url: "channels",
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const deleteChannel = (id) => {
  return api({
    method: "DELETE",
    url: `channels/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const addTraditionalCampaign = (data) => {
  return api({
    method: "POST",
    url: "/traditionalCampaigns",
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const getTraditionalCampaigns = (id) => {
  return api({
    method: "GET",
    url: `/traditionalCampaigns/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const deleteTraditionalCampaign = (id) => {
  return api({
    method: "DELETE",
    url: `/traditionalCampaigns/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const addRecord = (data) => {
  return api({
    method: "POST",
    url: "/records",
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const registerUser = (data) => {
  return api({
    method: "POST",
    url: "/register",
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const addInfluencer = (data) => {
  return api({
    method: "POST",
    url: "/influencer",
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const getInfluencerId = (id) => {
  return api({
    method: "GET",
    url: `/influencer/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const getInfluencers = () => {
  return api({
    method: "GET",
    url: "/influencers",
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const addGig = (data) => {
  return api({
    method: "POST",
    url: "/gig",
    data,
    headers: {
      ...getHeaders(),
      Accept: "application/json",
    },
  });
};
export const updateGig = (id, data) => {
  return api({
    method: "PUT",
    url: `/gig/${id}`,
    data,
    headers: {
      ...getHeaders(),
      Accept: "application/json",
    },
  });
};
export const deleteGig = (id) => {
  return api({
    method: "DELETE",
    url: `/gig/${id}`,
    headers: {
      ...getHeaders(),
      Accept: "application/json",
    },
  });
};
export const getGigs = () => {
  return api({
    method: "GET",
    url: `/gigs`,
    headers: {
      ...getHeaders(),
      Accept: "application/json",
    },
  });
};
export const addGigPost = (data, gigId) => {
  console.log(getHeaders());
  return api({
    method: "POST",
    url: `/gig-post/${gigId}`,
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const updateGigPost = (id, data) => {
  return api({
    method: "PUT",
    url: `/gig-post/${id}`,
    data,
    headers: {
      ...getHeaders(),
      Accept: "application/json",
    },
  });
};
export const deleteGigPost = (id) => {
  return api({
    method: "DELETE",
    url: `/gig-post/${id}`,
    headers: {
      ...getHeaders(),
      Accept: "application/json",
    },
  });
};
export const getGigPosts = (id) => {
  return api({
    method: "GET",
    url: `/gig/posts/${id}`,
    headers: {
      ...getHeaders(),
      Accept: "application/json",
    },
  });
};
export const getRecords = (id) => {
  return api({
    method: "GET",
    url: `/records/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const getAdvertiserReport = (id, startDate, endDate) => {
  return api({
    method: "GET",
    url: `/advertiserReport/${id}?start_date=${startDate}&end_date=${endDate}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const getCampaignReport = (id, startDate, endDate, media) => {
  return api({
    method: "GET",
    url: `/campaignReport/${id}?start_date=${startDate}&end_date=${endDate}&media=${media}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const getAdvertiserByIdApi = (id, token) => {
  return api({
    method: "GET",
    url: `/advertiser/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const editAdvertiserApi = (data, id) => {
  return api({
    method: "PUT",
    url: `/advertisers/${id}`,
    data,
    headers: {
      ...getHeaders(),
      "Content-Type": "multpart/form-data",
      Accept: "application/json",
    },
  });
};

export const deleteAdvertiserApi = (id) => {
  return api({
    method: "DELETE",
    url: `/advertisers/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const listPretargetsApi = () => {
  return api({
    method: "GET",
    url: "/targets/pretargets",
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const publishCampaignApi = ($campaignId) => {
  return api({
    method: "POST",
    url: `/campaigns/publish/${$campaignId}`,
    data: {},
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const resumeCampaignApi = (id) => {
  return api({
    method: "PUT",
    url: `/campaigns/resume/${id}`,
    data: {},
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const pauseCampaignApi = (id) => {
  return api({
    method: "PUT",
    url: `/campaigns/pause/${id}`,
    data: {},
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const addSubscriber = (data) => {
  return api({
    method: "POST",
    url: `/subscriber`,
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getKwanzaStatsAPI = (token, role) => {
  if (role === "admin") {
    console.log(baseURL);
    return api({
      method: "GET",
      url: `/stats/campaigns/all`,
      data: {},
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  } else
    return api({
      method: "GET",
      url: `/stats/campaigns/${id}`,
      data: {},
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
};

export const getStatsForOverview = (token, startDate, endDate) => {
  return api({
    method: "GET",
    url: `/campaigns/${startDate}/${endDate}`,
    data: {},
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getPlatformAPI = (id, platform) => {
  return api({
    method: "GET",
    url: `/campaigns/${id}/${platform}`,
    data: {},
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchSitesApi = (id, token) => {
  return api({
    method: "GET",
    url: `/stats/impressions/${id}`,
    data: {},
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const fetchClicksApi = (id, token) => {
  return api({
    method: "GET",
    url: `/stats/clicks/${id}`,
    data: {},
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchIpDataApi = (id, token) => {
  return api({
    method: "GET",
    url: `/stats/ip/${id}`,
    data: {},
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchCountryForecast = (countryCode, token) => {
  return api({
    method: "GET",
    url: `/forecast/${countryCode}`,
    data: {},
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchAllOsData = (token) => {
  console.log(getHeaders());
  return api({
    method: "GET",
    url: `/stats/os`,
    data: {},
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const fetchOsData = (id, token) => {
  return api({
    method: "GET",
    url: `/stats/os/${id}`,
    data: {},
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const getCampaignsWithAdvertisers = () => {
  return api({
    method: "GET",
    url: `/campaigns/with-advertisers`,
    data: {},
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createInvoiceTypeApi = (data) => {
  return api({
    method: "POST",
    url: `/invoices/type`,
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const createCompanyInvoiceDetailsApi = (data, invoiceId) => {
  return api({
    method: "PUT",
    url: `/invoices/${invoiceId}/company-details`,
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createInvoiceDetailsApi = (data, invoiceId) => {
  return api({
    method: "PUT",
    url: `/invoices/${invoiceId}/details`,
    data: data,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getInvoiceSummaryApi = (invoiceId) => {
  return api({
    method: "GET",
    url: `/invoices/${invoiceId}/summary`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const viewInvoiceApi = (invoiceId) => {
  return api({
    method: "GET",
    url: `/invoices/${invoiceId}/view`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getInvoicesApi = () => {
  return api({
    method: "GET",
    url: `/invoices`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const publishInvoiceApi = (invoiceId) => {
  return api({
    method: "PUT",
    url: `/invoices/${invoiceId}/publish`,
    data: {},
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchDspUsersApi = (token, agencyId) => {
  return api({
    method: "GET",
    url: `/users/list/${agencyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const deleteDspUserApi = (id) => {
  return api({
    method: "DELETE",
    url: `/users/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const deleteTargetApi = (id) => {
  return api({
    method: "DELETE",
    url: `/target/${id}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getAdvertiserCreativesApi = (token, id) => {
  return api({
    method: "GET",
    url: `/creatives/advertiser/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getCreatives = (token) => {
  return api({
    method: "GET",
    url: `/creatives`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const addSspZone = (data, token) => {
  return api({
    method: "POST",
    url: `/ssp/zone/new`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchSspZones = (token, tier, agencyId) => {
  console.log(token, tier, agencyId);
  return api({
    method: "GET",
    url: `/ssp/zones/available/${tier}/${agencyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const fetchSspZoneById = (agencyId, token) => {
  return api({
    method: "GET",
    url: `/ssp/zone/${agencyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const editSspZone = (data, token) => {
  return api({
    method: "PUT",
    url: `/ssp/zone/${data.zoneId}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const linkSspZone = (data, token) => {
  return api({
    method: "POST",
    url: `/ssp/zone/link`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const unlinkSspZone = (data, token) => {
  return api({
    method: "POST",
    url: `/ssp/zone/unlink`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const addSspInvoice = (data, token) => {
  return api({
    method: "POST",
    url: `/ssp/publisher/invoices`,
    data: data,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    },
  });
};

export const fetchAllSspInvoices = (token, agencyId) => {
  return api({
    method: "GET",
    url: `/ssp/publisher/invoices/${agencyId}`,
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchSspInvoice = (id, token, agencyId) => {
  return api({
    method: "GET",
    url: `/ssp/publisher/invoices/${id}/${agencyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getAgencies = (token) => {
  return api({
    method: "GET",
    url: `/agencies`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getAgencyById = (id, token) => {
  return api({
    method: "GET",
    url: `/agency/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const uploadSspHtmlBanner = (data, token) => {
  return api({
    method: "POST",
    url: `/banner/upload`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const uploadSspImageBanner = (data, token) => {
  return api({
    method: "POST",
    url: `/banner/upload/image`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

export const fetchSspBanners = (token, agencyId) => {
  return api({
    method: "GET",
    url: `/banners/list/${agencyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchUnlinkedSspBanners = (token, agencyId) => {
  return api({
    method: "GET",
    url: `/banners/unlinked/${agencyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const sendSspBanner = (data, token) => {
  return api({
    method: "POST",
    url: `/banner/send`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const deleteSspBanner = (id, token) => {
  return api({
    method: "DELETE",
    url: `/banner/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchSspBanner = (id, token) => {
  return api({
    method: "GET",
    url: `/banner/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const unlinkBannerFromZone = (token, zoneId, bannerId) => {
  return api({
    method: "DELETE",
    url: `/banner/unlink/${bannerId}/${zoneId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const fetchPublisherCategories = (token) => {
  return api({
    method: "GET",
    url: `/categories`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const addPublisherWebsite = (data, token) => {
  console.log(token);
  return api({
    method: "POST",
    url: `/publisher`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const deletePublisherWebsite = (id, token) => {
  return api({
    method: "DELETE",
    url: `/publisher/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const fetchPublisherWebsite = (id, token) => {
  return api({
    method: "GET",
    url: `/publisher/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const editPublisherWebsite = (id, data, token) => {
  console.log(token);
  return api({
    method: "PUT",
    url: `/publisher/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const fetchPublisherWebsites = (token, agencyId) => {
  return api({
    method: "GET",
    url: `/publishers/${agencyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
