import { useContext, useEffect, useState } from "react";
import { fetchAllSspInvoices, fetchSspInvoice } from "../../../api/api";
import { fetchPublisherWebsites, getRoles } from "../../../api/ssp-api";


import { formatDate } from "../../../util";
import { AuthContext } from "../../../provider/AuthContext";
import { BreadcrumbContext } from "../../../layouts/dashboardLayout";
import Loader from "../../../components/loader";

export default function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [publisherId, setPublisherId] = useState([]);
  const { userDetails } = useContext(AuthContext);
  const {setActions} = useContext(BreadcrumbContext)

  useEffect(() => {
    setActions(<div></div>)
    let role = getRoles();
    const { token, agencyId } = JSON.parse(userDetails);

    if (role === "admin") {
      fetchAllSspInvoices(base64Credentials, agencyId)
        .then((response) => {
          setInvoices(response.data.invoices);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    } else {
      fetchPublisherWebsites(base64Credentials).then((response) => {
        const matchingPublishers = response.data.filter((publisher) =>
          publisher.emailAddress.includes(username),
        );
        const matchingIds = matchingPublishers.map(
          (publisher) => publisher.publisherId,
        );

        fetchSspInvoice(matchingIds[0], token)
          .then((data) => {
            console.log(`invoices`, data);
            setInvoices(data.data.invoices);
            setLoading(false);
          })
          .catch((error) => {
            setError(error.message);
            setLoading(false);
          });
      });
    }
  }, []);

  if (loading === false && invoices.length === 0) {
    return (
      <div header="Invoices">
        <div
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <h3>No invoices available</h3>
          <p>To create an invoice follow the steps below</p>
          <div item xs={6}>
            <ul>
              <li>Click on the "Ssp Campaigns" tab</li>
              <li>Click on the "View Stats" button</li>
              <li>Click on the "Generate Invoice" button</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div header="Invoices">
      <div style={{ marginTop: "24px", height: "600px", overflowY: "auto" }}>
        {loading || invoices === undefined ? (
          <Loader/>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Invoice ID</TableCell>
                  <TableCell>Campaign Name</TableCell>
                  <TableCell align="right">Impressions</TableCell>
                  <TableCell align="right">Clicks</TableCell>
                  <TableCell align="right">Revenue</TableCell>
                  <TableCell>Publisher Email</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow
                    key={invoice.invoice_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {invoice.invoice_id}
                    </TableCell>
                    <TableCell>{invoice.campaign_name}</TableCell>
                    <TableCell align="right">{invoice.impressions}</TableCell>
                    <TableCell align="right">{invoice.clicks}</TableCell>
                    <TableCell align="right">{invoice.impressions}</TableCell>
                    <TableCell>
                      {invoice.publisher_details.publisherEmail}
                    </TableCell>

                    <TableCell>{formatDate(invoice.created_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}
