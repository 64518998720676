import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DeletionContext } from "../layouts/dashboardLayout";
import { useState, useContext } from "react";

const ModifyButton = ({ onDelete, onEdit, id }) => {
  const { setOnDelete, setShowConfirmDeletion } = useContext(DeletionContext);
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div>
      <div className="relative">
        <BsThreeDotsVertical
          onClick={() => {
            setShowMenu(!showMenu);
          }}
          className=" cursor-pointer z-0"
        />
        {showMenu && (
          <div
            key={id}
            className="absolute right-5 bg-white shadow-lg rounded-lg p-5 z-40 space-y-2"
          >
            {onEdit && (
              <button
                onClick={() => {
                  setShowMenu(false);
                  onEdit();
                }}
                className="flex items-center font-medium space-x-2 hover:text-green-500 "
              >
                <AiOutlineEdit />
                <div>Edit</div>
              </button>
            )}
            {onDelete && (
              <button
                onClick={() => {
                  setShowMenu(false);
                  setShowConfirmDeletion(true);
                  setOnDelete(() => onDelete);
                }}
                className="flex items-center font-medium space-x-2 hover:text-red-500 "
              >
                <AiOutlineDelete />
                <div>Delete</div>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifyButton;
