import * as React from "react";
import { useEffect, useState,setContext } from "react";

import EditSspCampaign from "./edit-campaign";
import LinkCampaignToZones from "../create-campaign/link-campaign";
import { fetchLinkedZonesByCampaignId } from "../../../../../api/ssp-api";
import AgencyWrapper from "../../../../../components/wrappers/agency-wrapper";
import LinkedBanners from "./linked-banners";
import Loader from "../../../../../components/loader";
import { BreadcrumbContext } from "../../../../../layouts/dashboardLayout";
import { set } from "lodash";

export default function AgencyEditSspCampaignTabs() {
  const [value, setValue] = React.useState("1");
  const campaignId = window.location.pathname.split("/")[4];
  const [linkZones, setLinkZones] = useState([]);
  const [loading, setLoading] = useState(true);

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
 const [selectedTab,setSelectedTab] = useState(0)

 const {setActions} = React.useContext(BreadcrumbContext)
  useEffect(() => {
    setActions(<div></div>)
    setLoading(true);
    fetchLinkedZonesByCampaignId(campaignId, base64Credentials)
      .then((res) => {
        setLinkZones(res.data);
        console.log("linkedZones", JSON.stringify(linkZones));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div header={"Edit Campaign"}>
      <div  spacing={2}>
        {loading ? (
          <Loader/>
        ) : (
          <div item xs={12}>
            <div className="flex space-x-4">
              {["Edit Campaign","Linked Zones","Linked Banners"].map((item,index)=>{
                return <div key={item} onClick={()=>{
                  setSelectedTab(index)
                }} className={`${selectedTab == index && "border-b-4 text-primary pb-2"} border-b-primary cursor-pointer `}>{item}</div>
              })}
            </div>
            <div className="pt-5">
            {selectedTab ==0 && <EditSspCampaign />}
            {selectedTab ==1 && <LinkCampaignToZones linkZones={linkZones} edit={"edit"} />}
            {selectedTab ==2 && <LinkedBanners campaignId={campaignId} /> }
            </div>
           
           
          </div>
        )}
      </div>
    </div>
  );
}
