import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { fetchAllOsData } from "../../api/api";
import { figureSeparator } from "../../util/figureSeparator";

const OsGraph = ({campaigns}) => {
const [labels,setLabels] = useState([]);
const [values,setValues] = useState([]);
const colors = ["#6DBF48", "#FBC23D", "#082A7B", "#EB345F"]
  const chartData = {
    series: values,

    options: {
      chart: {
        type: "donut",
      },
      colors: colors,
      labels: labels,
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            labels: {
              show: true,
              value:{
                show:true,
                fontSize:"25px",
                fontWeight:600
              },
              total:{
                show:true,
                color:"#828682",
                showAlways:true,
                formatter:function(val){
                  console.log("Val",)
                  const values = val.config.series
                  const sum = values.reduce((a,b)=>a+b,0)
                  return figureSeparator(sum) 
                }
                
                
              }
            },
            size: "65%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  };
 useEffect(()=>{
  fetchAllOsData().then((response)=>{
    const dataMap = new Map(Object.entries(response.data.data[0]));
    const keys = Array.from(dataMap.keys()) ;
    const values = Array.from(dataMap.values())
    setLabels(keys)
    setValues(values.map((value)=>parseInt(value)))
  })
 },[])
  return (
    <div className="p-8 bg-white border border-border rounded-md h-full" >
      <h1 className="font-bold text-lg mb-4">Operating Systems</h1>
      <Chart
        series={chartData.series}
        options={chartData.options}
        type="donut"
      />
      <div className="space-y-2 mt-5">
      {values.map((item,index) => {
        return (
          <div className="flex justify-between">
            <div className="flex space-x-2 items-center">
              <div style={{backgroundColor:colors[index]}} className=" size-3 rounded-full" />
              <p className="capitalize">{labels[index]}</p>
            </div>
            <p>{item}</p>
          </div>
        );
      })}
      </div>
      
    </div>
  );
};

export default OsGraph;
