import { ReactComponent as CampaignIcon } from "../../../assets/campaigns.svg";

export const navigation = [
  {
    path: "/publisher/ssp",
    name: "SSP Campaigns",
    icon: <CampaignIcon className="hoveredIcon" />,
  },

  {
    path: "/publisher/invoices",
    name: "Invoices",
    icon: <CampaignIcon className="hoveredIcon" />,
  },
];
