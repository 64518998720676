import { useState } from "react";

export default function EditZone({ data, publisherData, handleSubmit }) {
  const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue =
      name === "width" || name === "height" || name === "publisherId"
        ? parseInt(value, 10)
        : value;
    setFormData((prevData) => ({ ...prevData, [name]: newValue }));
  };

  const onSubmit = () => {
    handleSubmit(formData);
  };

  return (
    <form>
      <div xs={6} container spacing={2}>
        {/*<div item xs={12}>*/}
        {/*  <FormControl fullWidth>*/}
        {/*    <InputLabel>Publisher</InputLabel>*/}
        {/*    <Select*/}
        {/*      name="publisherId"*/}
        {/*      value={formData.publisherId}*/}
        {/*      onChange={handleChange}*/}
        {/*      required*/}
        {/*    >*/}
        {/*      {publisherData.map((publisher) => (*/}
        {/*        <MenuItem*/}
        {/*          key={publisher.publisherId}*/}
        {/*          value={publisher.publisherId}*/}
        {/*        >*/}
        {/*          {publisher.website}*/}
        {/*        </MenuItem>*/}
        {/*      ))}*/}
        {/*    </Select>*/}
        {/*  </FormControl>*/}
        {/*</div>*/}
        <div item xs={12}>
          <TextField
            label="Zone Name"
            name="zoneName"
            value={formData.zoneName}
            onChange={handleChange}
            fullWidth
            required
          />
        </div>
        <div item xs={6}>
          <TextField
            label="Width"
            name="width"
            value={formData.width}
            onChange={handleChange}
            fullWidth
            required
            type="number"
          />
        </div>
        <div item xs={6}>
          <TextField
            label="Height"
            name="height"
            value={formData.height}
            onChange={handleChange}
            fullWidth
            required
            type="number"
          />
        </div>
        <div item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            style={{ color: "white" }}
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
}
