import React, {createContext, useContext, useEffect, useState} from "react";

// Create the AuthContext
export const AuthContext = createContext();

// Custom hook to access the AuthContext
export function useAuth() {
    return useContext(AuthContext);
}

// AuthProvider component to wrap your application with
export function AuthProvider({ children }) {
    const [userDetails, setUser] = useState(localStorage.getItem('kwanza') || '');
    const [sspCred, setSspCred] = useState([]);

    // Whenever the token changes, update localStorage
    useEffect(() => {
        localStorage.setItem('kwanza', userDetails);
    }, [userDetails]);


    const login = (newUser) => {
        setUser(newUser);
    };

    const setSspUser = (newSspCred) => {
        setSspCred(newSspCred);
    };

    const logout = () => {
        setUser(null);
    };
    return (
        <AuthContext.Provider value={{  userDetails, sspCred, login, setSspUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
}
