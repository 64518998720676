
import clsx from "clsx";
import PropTypes from "prop-types";
import { ReactComponent as DownloadPdf } from "../../assets/downloadPdf.svg";


const NavigationButtons = ({
  onClickNextButton,
  onClickBackButton,
  showNextButton,
  showBackButton,
  content,
  hasContent,
  hasDownload,
  savePdf,
  isLoading,
}) => {
  const classes = useStyles();
  return (
    <div container xs={12} className={classes.root}>
      <Button
        className={clsx(classes.buttons, {
          [classes.hideButton]: !showBackButton,
        })}
        onClick={onClickBackButton}
      >
        Back
      </Button>
      <Button
        className={clsx(classes.buttons, classes.nextButton, {
          [classes.hideButton]: !showNextButton,
        })}
        onClick={onClickNextButton}
      >
        {isLoading ? (
          <CircularProgress className={classes.loader} />
        ) : hasContent ? (
          content
        ) : (
          "next"
        )}
      </Button>
      {hasDownload && (
        <IconButton onClick={() => savePdf()}>
          <DownloadPdf />
        </IconButton>
      )}
    </div>
  );
};

NavigationButtons.propTypes = {
  onClickBackButton: PropTypes.func.isRequired,
  onClickNextButton: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  showNextButton: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default NavigationButtons;
