
import CampaignDetailWrapper from "../campaign-detail-wrapper";
import EditCampaignButton from "../edit-campaign-button";
import { countries } from "../../audience/country";
import CampaignSkeleton from "../campaign-skeleton";
import { useContext, useEffect, useState } from "react";
import { getAudienceById, getCampaignById } from "../../../api/api";
import { AuthContext } from "../../../provider/AuthContext";
import { useParams } from "react-router-dom";
import { CampaignContext } from "../campaign-create/create-campaign-tabs";

const styles = {
  root: {
    minHeight: "34vh",
    marginBottom: "2rem",
    background: "#ccc",
    marginTop: "1rem",
    width: "97%",
  },
  itemName: {
    color: "#4B4B4B",
    fontWeight: 700,
    fontSize: "0.8rem",
  },
  itemDesc: {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "#979797",
    marginTop: "0.3rem",
  },
  item: {
    marginBottom: "1.7rem",
  },
};

// function formatCountry(countryString) {
//   if (!countryString) {
//     return "";
//   }
//   let countryIso = countryString.split(",");

//   let countryNames = countryIso.map((iso) => {
//     let country = countries.find(
//       ({ iso3, code }) => iso === iso3 || code === iso,
//     );
//     return country ? country.label : "";
//   });
//   return countryNames.join(",");
// }
const AudienceInformation = () => {
    const [audienceData, setAudienceData] = useState({});
  // console.log("audience info",audience);
  const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   getAudienceById(audience[0].audienceId).then((response) => {
  //       console.log("response audience", response);
  //       setAudienceData(response.data);
  //       setIsLoading(false);
  //   }).catch((error) => {
  //       console.log(error);
  //   })

  // }, []);

  let { goals, name, country, iabWhitelist, iabBlacklist } = audienceData;
  // const { id } = campaign;
  const { userDetails } = useContext(AuthContext);
  const { token } = JSON.parse(userDetails);
  const [campaign, setCampaign] = useState(null);
  const {uuid} = useParams();
  const {activeStep,setActiveStep} = useContext(CampaignContext);

  useEffect(() => {
    getCampaignById(token, uuid).then((response) => {
      console.log("Audience data")
      console.log(response.data)
      setCampaign(response.data);
    });
  }, []);
  return campaign && campaign.target &&  (
        <div className="bg-white p-10 border border-border rounded">
          <div>
            <div className="grid grid-cols-12">
              <p className=" col-span-3">
                Audience Name
              </p>
              <p className="col-span-9" >{campaign.target.name}</p>
            </div>
            <div className="grid grid-cols-12">
              <p className="col-span-3">Audience</p>
              <p className="col-span-9">
                {campaign.target.IAB_category}
              </p>
            </div>
          </div>
          <div   >
            <div className="grid grid-cols-12">
              <p className="col-span-3">
                What's your main advertising goal?
              </p>
              <p className="col-span-9">{campaign.target.target_goals}</p>
            </div>
            <div className="grid grid-cols-12" >
              <p className="col-span-3" >Gender</p>
              <p className="col-span-9">All</p>
            </div>
          </div>
          <div   >
            <div >
            </div>
            <div >
            </div>
          </div>
          <div   >
          </div>
          <button
              className=" border border-primary  text-primary rounded-md py-1 px-4 w-40 flex justify-center"
              onClick={()=>{
                setActiveStep(1)
              }}
              style={{ marginTop: "20px" }}
            >
              Edit Details
            </button>
          {/* <EditCampaignButton redirectUrl={`/create-campaign/audience`} /> */}
        </div>
  );
};

export default AudienceInformation;
