import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  deleteDspBanner,
  deleteSspBanner,
  fetchBannersByCampaignId,
  getAdverSize,
  submitBanner,
  uploadDSPImageCreative,
} from "../../../api/api";
import { styles } from "./style";

import { useBannerData } from "../../../provider/BannerContext";
import { AuthContext } from "../../../provider/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { TbFileZip } from "react-icons/tb";
import Spinner from "../../../components/spinner";
import { useParams } from "react-router-dom";
import { CampaignContext } from "../campaign-create/create-campaign-tabs";
import { useDropzone } from "react-dropzone";
import { set } from "lodash";
import ImageDropzone from "../../banners/image-dropzone";
import { IoIosCloseCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import ZippedUploadedItem from "../../../components/zipped-uploaded-item";
import CustomModal from "../../../components/custom-modal";
import {
  BsEmojiDizzy,
  BsFillEnvelopeOpenFill,
  BsFillFolderFill,
} from "react-icons/bs";
import NoData from "../../../components/noData";

const CampaignAdvert = ({ classes, history }) => {
  const campaignId = window.location.pathname.split("/")[3];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [type, setType] = useState("");
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { addData } = useBannerData();
  const [selectedTab, setSelectedTab] = useState(0);

  const [advertiserId, setAdvertiserId] = useState(1);
  const [campDest, setCampDest] = useState("");
  const [newData, setNewData] = useState("");
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const [banners, setBanners] = useState([]);
  const { uuid } = useParams();
  const { activeStep, setActiveStep } = useContext(CampaignContext);
  const [showCreativeUploadModel, setShowCreativeUploadModal] = useState(false);

  //upload images
  const uploadImageCreatives = async () => {
    console.log("Uploading banners");
    if (!selectedImages || selectedImages.length === 0) {
      toast.error("At least one banner is required");
      return;
    }
    const formData = {};
    const uploadPromises = selectedImages.map((selectedFile) => {
      console.log(selectedFile.name);
      setIsLoading(true);
      formData.image = selectedFile;
      formData.name = selectedFile.name;
      formData.campaignId = parseInt(uuid);
      formData.agencyId = agencyId;
      console.log("Form data", formData);
      return uploadDSPImageCreative(formData);
    });
    try {
      const responses = await Promise.all(uploadPromises);
      toast.success("All banners uploaded successfully");
      setActiveStep(activeStep + 1);
    } catch (error) {
      console.error("Error in uploading banners:", error);
      toast.error("Error in uploading banners");
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBannersByCampaignId(uuid, token).then((response) => {
      console.log("Banners", response.data.banner);
      setBanners(response.data.banner);
    });
  }, []);

  const handleFileSelect = (file) => {
    setSelectedImages((prev) => [...prev, file]);
    setShowCreativeUploadModal(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    width: "",
    height: "",
    zipFile: null,
    agencyId: agencyId,
    campaignId: parseInt(campaignId),
  });

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".zip",
    multiple: true,
  });

  async function handleSubmit() {
    if (selectedFiles.length > 0) {
      setIsLoading(true);
      for (let file of selectedFiles) {
        const parts = file.name.split("-"); // Split the filename by the "-" character
        console.log(parts);
        const name = parts[0]; // The name is the first part
        const dimensions = parts[parts.length - 1].split("x"); // Split the second part by the "x" character
        const width = dimensions[0]; // The width is the first part of the dimensions
        const height = dimensions[dimensions.length - 1].split(".")[0]; // The height is the second part of the dimensions, remove file extension

        // Add the extracted values to the formData
        formData.name = name;
        formData.width = parseInt(width);
        formData.height = parseInt(height);
        formData.zipFile = file;
        console.log(formData);

        try {
          const response = await submitBanner(formData);
          console.log(response.banner);
          setIsLoading(false);
          toast.success("Uploaded successfully");
          setActiveStep(activeStep + 1);
        } catch (error) {
          console.error("Error in uploading banners:", error);
          toast.error("Invalid file");
          setIsLoading(false);
        }
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  }
  return (
    <div header={"Advert"}>
      <div className="bg-white p-10 border rounded-md border-border ">
        <div item>
          <ToastContainer />
        </div>

        <div>
          <div>
            <div className="flex space-x-4 mb-4">
              {["Image Creatives", "HTML Creatives"].map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      setSelectedTab(index);
                    }}
                    className={`${selectedTab == index ? "text-primary border-b-4 pb-1   border-primary " : "text-muted"} font-bold cursor-pointer`}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
            <CustomModal
              setShow={setShowCreativeUploadModal}
              show={showCreativeUploadModel}
              heading={"Upload Images"}
              content={
                <div>
                  <ImageDropzone onImageUpload={handleFileSelect} />
                </div>
              }
            />
            {selectedTab == 0 && (
              <div className="flex justify-between mb-4 items-center ">
                <h1 className="text-xl font-bold">Image Creatives</h1>
                <button
                  onClick={() => {
                    setShowCreativeUploadModal(true);
                  }}
                  className=" bg-primary text-white rounded-md py-2 px-4 font-medium w-40 flex justify-center"
                >
                  {"Upload Creatives"}
                </button>
              </div>
            )}

            {selectedTab == 0 && (
              <div className="border border-border rounded-md p-8 ">
                {banners.filter((item) => item.contenttype != "html").length +
                  selectedImages.length <
                  1 && <NoData withBorder={false} />}
                <div className="grid grid-cols-3 2xl:grid-cols-4 gap-4 ">
                  {banners
                    .filter((item) => item.contenttype != "html")
                    .map((item) => {
                      return (
                        <div className="text-center rounded">
                          <div className="flex justify-end">
                            <IoClose
                              onClick={() => {
                                deleteDspBanner(item.id).then((e) => {
                                  toast.success("Deleted successfully");
                                  // alert("hello");
                                  setBanners(
                                    banners.filter((e) => e.id != item.id)
                                  );
                                });
                              }}
                              className=" hover:text-gray-800 transition-all duration-200 text-muted size-5 cursor-pointer"
                            />
                          </div>
                          <div className="flex justify-center">
                            <img
                              className=" h-48 object-cover "
                              src={item.iurl}
                            />
                          </div>
                          <p className="text-sm">
                            {/* Image dimensions {item.width}x{item.height} */}
                          </p>
                        </div>
                      );
                    })}
                  {selectedImages.map((item) => {
                    return (
                      <div className=" transition-all duration-200 p-0 text-center rounded">
                        <div className="flex justify-end">
                          <IoClose
                            onClick={() => {
                              setSelectedImages(
                                selectedImages.filter((e) => e != item)
                              );
                            }}
                            className="text-muted size-5 cursor-pointer"
                          />
                        </div>
                        <div className="flex justify-center ">
                          <img
                            className="h-48 object-cover rounded"
                            src={URL.createObjectURL(item)}
                          />
                        </div>
                      </div>
                    );
                  })}

                  {/* <ImageDropzone onImageUpload={handleFileSelect} /> */}
                </div>
              </div>
            )}
            {selectedTab == 1 && (
              <div className="space-y-2">
                <div
                  {...getRootProps()}
                  className="border border-slate-300 border-dashed flex flex-col justify-center items-center py-12 rounded"
                >
                  <TbFileZip className="size-16 text-primary" />
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <h1>Drop the ZIP file here...</h1>
                  ) : (
                    <h1 className="text-sm">
                      Drag 'n' drop a ZIP file here, or click to select a file
                    </h1>
                  )}
                </div>
                {selectedFiles.length > 0 && (
                  <h1 className="pt-4">Uploaded creatives</h1>
                )}
                {selectedFiles.map(
                  (selectedFile) =>
                    selectedFile && (
                      <ZippedUploadedItem selectedFile={selectedFile} />
                    )
                )}
                {banners
                  .filter((item) => item.contenttype == "html")
                  .map((selectedFile) => (
                    <div className="border border-border  items-center   p-4  flex flex-col justify-center rounded">
                      <iframe src={selectedFile.iurl}></iframe>
                      {selectedFile.iurl}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div item container direction="row">
          <div className="flex justify-between mt-6">
            <div className="w-full flex justify-start space-x-2">
              <button
                onClick={() => {
                  setActiveStep(activeStep - 1);
                }}
                className="text-textColor py-2  justify-center flex hover:text-primary transition-all duration-200"
                type="submit"
                style={{ marginTop: "20px" }}
              >
                Back
              </button>
              <button
                className=" bg-primary text-white rounded-md py-2 px-4 font-medium w-40 flex justify-center"
                onClick={() => {
                  if (selectedImages.length > 0) {
                    if (selectedTab == 0) {
                      uploadImageCreatives();
                    } else {
                      handleSubmit();
                    }
                  } else {
                    setActiveStep(activeStep + 1);
                  }
                }}
                style={{ marginTop: "20px" }}
              >
                {isLoading ? <Spinner /> : "Save & Continue"}
              </button>
            </div>
            <button
              className="py-2 rounded-md  text-textColor   justify-center  flex hover:text-primary transition-all duration-200"
              onClick={() => {
                setActiveStep(activeStep + 1);
              }}
              type="submit"
            >
              Skip
            </button>
          </div>

          {/* <SubmitButtons
            next={handleSubmit}
            isLoading={isLoading}
            redirectUrl={`/create-campaign/information`}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default CampaignAdvert;
