
import { ToastContainer } from "react-toastify";
import React, { useContext, useEffect, useState } from "react";
import { fetchUnlinkedSspBanners, sendSspBanner } from "../../../../../api/api";
import { AuthContext } from "../../../../../provider/AuthContext";
import { useNavigate } from "react-router-dom";
import { deleteSspCampaignApi } from "../../../../../api/ssp-api";

export default function AgencyCampaignBanner() {
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const navigate = useNavigate();

  const [hasFetchedBanners, setHasFetchedBanners] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [advertiserId, setAdvertiserId] = useState(null);
  const [campaignId, setCampaignId] = useState(null);

  useEffect(() => {
    if (!hasFetchedBanners) {
      const savedData = JSON.parse(localStorage.getItem("campaign"));
      setAdvertiserId(savedData.advertiserId);
      setCampaignId(savedData.campaignId);
      console.log("saved data:", savedData);
      fetchUnlinkedSspBanners(token, agencyId)
        .then((response) => {
          console.log(response);
          setBannerData(response.data.data);
          setLoading(false);
          setHasFetchedBanners(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedIds]);
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "url", headerName: "URL", width: 250 },
    { field: "width", headerName: "Width", type: "number", width: 110 },
    { field: "height", headerName: "Height", type: "number", width: 110 },
    {
      field: "is_linked",
      headerName: "Is Linked",
      type: "boolean",
      width: 130,
    },
    { field: "type", headerName: "Type", width: 120 },
    { field: "agency_id", headerName: "Agency ID", type: "number", width: 120 },
    { field: "created_at", headerName: "Created At", width: 200 },
  ];

  const rows = bannerData.map((banner) => {
    return {
      id: banner.id,
      name: banner.name,
      url: banner.url,
      width: banner.width,
      height: banner.height,
      type: banner.type,
      is_linked: banner.is_linked,
      agency_id: banner.agency_id,
      created_at: new Date(banner.created_at).toLocaleString("en-GB"),
    };
  });

  const handleRowSelected = (params) => {
    setSelectedIds(params);
  };

  const handleSubmit = () => {
    setSaveLoading(true);
    setDisabled(true);
    if (selectedIds.length === 0) {
      return;
    }

    const data = {
      bannerId: selectedIds,
      campaignId: campaignId,
      agencyId: agencyId,
    };

    sendSspBanner(data, token, agencyId)
      .then((response) => {
        console.log(response);
        setSaveLoading(false);
        setDisabled(false);
        navigate(`/agency/campaign/link/${campaignId}`);
      })
      .catch((error) => {
        console.error(error);
        setSaveLoading(false);
        setDisabled(false);
      });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = rows.filter(
    (row) =>
      row.name.toLowerCase().includes(searchText.toLowerCase()) ||
      row.url.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleBannerClick = () => {
    deleteSspCampaignApi(campaignId)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
    navigate("/agency/banners");
  };

  if (bannerData.length === 0) {
    return (
      <div header="Banners">
        <div container spacing={2} >
          <div item xs={12}>
            <p>No banners </p>
            <p>To go to the banners tab click the button below</p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleBannerClick()}
              style={{ marginTop: "8px" }}
              sx={{
                backgroundColor: "#6EBF4A",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#6EBF4A",
                  color: "#ffffff",
                },
              }}
            >
              Banners
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div header="Add Banner to Campaign">
      <ToastContainer />
      <div
        container
        spacing={2}
        style={{ marginTop: "24px", height: "600px", overflowY: "auto" }}
      >
        <div item xs={12}>
          {loading ? (
            <Loader/>
          ) : (
            <div
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              item
              xs={12}
            >
              <div
                item
                xs={12}
                container
                justifyContent="space-between" // Change this line
                alignItems="center"
              >
                <TextField
                  id="search-field"
                  label="Search"
                  variant="outlined"
                  value={searchText}
                  onChange={handleSearchChange}
                  style={{ marginBottom: "8px" }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  style={{ marginBottom: "8px" }}
                  sx={{
                    backgroundColor: "#6EBF4A",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#6EBF4A",
                      color: "#ffffff",
                    },
                  }}
                  disabled={disabled}
                >
                  {saveLoading ? <CircularProgress size="2.0rem" /> : "Save"}
                </Button>
              </div>
              <DataGrid
                columns={columns}
                rows={filteredRows}
                pageSize={5}
                checkboxSelection
                onRowSelectionModelChange={handleRowSelected}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
