
import React from "react";
import {
  fetchCampaignsByAdvertiser,
  fetchCreativesByCampaignId,
} from "../../api/ssp-api";
import Loader from "../../components/loader";

export default function LinkZones({ data, zoneData, handleLink }) {
  const [selectedAdvertiser, setSelectedAdvertiser] = React.useState("");
  const [campaignData, setCampaignData] = React.useState([]);
  const [campaignLoading, setCampaignLoading] = React.useState(false);
  const [hidden, setHidden] = React.useState(true);
  const [selectedCampaign, setSelectedCampaign] = React.useState("");
  const [bannerData, setBannerData] = React.useState([]);
  const [bannerLoading, setBannerLoading] = React.useState(false);
  const [bannerHidden, setBannerHidden] = React.useState(true);
  const [selectedBanner, setSelectedBanner] = React.useState("");
  const [buttonHidden, setButtonHidden] = React.useState(true);
  const [error, setError] = React.useState(false);
  const handleChange = (event) => {
    setSelectedAdvertiser(event.target.value);
    setError(false);
    getCampaigns(event.target.value);
  };
  const getCampaigns = (selectedAdvertiser) => {
    fetchCampaignsByAdvertiser(selectedAdvertiser)
      .then((r) => {
        console.log("campaign data", r.data);
        setCampaignData(r.data);
        setCampaignLoading(false);
        setHidden(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCampaign = (event) => {
    setSelectedCampaign(event.target.value);
    setError(false);
    getBanners(event.target.value);
  };

  const getBanners = (selectedCampaign) => {
    setBannerLoading(true);
    fetchCreativesByCampaignId(selectedCampaign).then((r) => {
      console.log("banner data", r.data);
      if (r.data.length === 0) {
        setError(true);
        setBannerLoading(false);
        setBannerHidden(true);
        return;
      }
      let filteredBannerData = filterBannersByZone(zoneData, r.data);
      if (filteredBannerData.length > 0) {
        console.log("banner data", r.data);
        setBannerData(filteredBannerData);
        setBannerHidden(false);
        setBannerLoading(false);
      } else {
        setError(true);
        setBannerLoading(false);
      }
    });
  };

  function filterBannersByZone(zoneData, bannerData) {
    const { width: zoneWidth, height: zoneHeight } = zoneData;

    // Use the filter method to find banners with matching width and height
    return bannerData.filter((banner) => {
      const { width: bannerWidth, height: bannerHeight } = banner;
      return zoneWidth === bannerWidth && zoneHeight === bannerHeight;
    });
  }

  const handleBanner = (event) => {
    console.log(event.target.value);
    setSelectedBanner(event.target.value);
    setButtonHidden(false);
  };

  const onLink = () => {
    handleLink(zoneData.zoneId, selectedBanner);
  };
  return (
    <div xs={12} container spacing={2} direction="row">
      <div item xs={3}>
        <FormHelperText>Select Advertiser*</FormHelperText>
        <FormControl fullWidth>
          <Select
            variant="outlined"
            value={selectedAdvertiser}
            onChange={handleChange}
            label="Select advertiser"
          >
            {data.map((item) => (
              <MenuItem key={item.advertiserId} value={item.advertiserId}>
                {item.advertiserName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {hidden ? null : (
        <div item xs={3}>
          <FormHelperText>Select Campaign*</FormHelperText>

          <FormControl fullWidth>
            <Select
              variant="outlined"
              value={selectedCampaign}
              onChange={handleCampaign}
              label="Select campaign"
            >
              {campaignData.map((item) => (
                <MenuItem key={item.campaignId} value={item.campaignId}>
                  {item.campaignName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {bannerHidden ? null : (
        <div item xs={3} spacing={2}>
          {bannerLoading ? (
            <Loader/>
          ) : (
            <div>
              <FormControl fullWidth>
                <FormHelperText>Select Banner*</FormHelperText>
                <Select
                  variant="outlined"
                  value={selectedBanner}
                  onChange={handleBanner}
                  label="Select campaign"
                >
                  {bannerData.map((item) => (
                    <MenuItem key={item.bannerId} value={item.bannerId}>
                      {item.bannerName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      )}
      {buttonHidden ? null : (
        <div container item xs={3}>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={onLink}
          >
            Link Zone
          </Button>
        </div>
      )}
      <div item xs={12}>
        {error ? <p>No banners found for this zone</p> : null}
      </div>
    </div>
  );
}
