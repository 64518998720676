import { createContext, useState } from "react";

import CampaignCreate from "./create-campaign";
import CampaignAudience from "../campaign-audience/campaign-audience-info";
import CampaignAdvert from "../campaign-advert/create-campaign-advert";
import Budget from "../campaign-budget/create-campaign-budget"
import PreviewCampaign from "../campaign-preview/campaign-preview.js"

export const CampaignContext = createContext()
const CreateCampaignTabs = () => {
    const [activeStep, setActiveStep] = useState(0);
   
    
    return ( <div>
          <div className="grid grid-cols-4  ">
          {["Campaign Informations","Audience","Banners","Campaign Preview"].map((item,index)=>{
            return <div onClick={()=>{
              setActiveStep(index)
            }} className="flex items-center cursor-pointer ">
              <div className={`${activeStep >= index?"bg-primary text-white font-bold":"bg-border"} text-sm size-6 flex justify-center items-center rounded-full`}>{index+1}</div>
              <div className={` ${activeStep >= index?"bg-primary":"bg-border"} h-[3px]  flex-grow`}/>
              <h1 className={`${activeStep >= index?"text-primary font-semibold":""}`}>{item}</h1>
              <div className={` ${activeStep >= index?"bg-primary":"bg-border"} h-[3px] flex-grow `}/>
              </div>
          })}
        </div>
        <CampaignContext.Provider value={{activeStep, setActiveStep}}>
        <div className="mt-5">
        {activeStep == 0 && <CampaignCreate/>}
        {activeStep == 1 && <CampaignAudience/>}
        {activeStep == 2 && <CampaignAdvert/>}
        {activeStep == 3 && <PreviewCampaign/>}
        </div>
        </CampaignContext.Provider>
        
        {/* {activeStep == 1 && <CampaignAudienceInfo/>}
        {activeStep == 2 && <CampaignAdvertInfo/>}
        {activeStep == 3 && <CreateCampaignAdvert/>}
        {activeStep == 4 && <CreateCampaignBudget/>}
        {activeStep == 5 && <CampaignPreview/>} */}




    </div> );
}
 
export default CreateCampaignTabs;