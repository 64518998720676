export const budgets = [
  {
    daily: 9,
    monthly: 270,
    recommended: false,
  },
  {
    daily: 5,
    monthly: 150,
    recommended: false,
  },
];
