
import PublisherTable from "./websites-table";
import { useContext, useEffect, useState } from "react";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthContext";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import { fetchPublisherWebsites } from "../../api/api";

export default function PublisherWebsites() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);
  const [refresh,setRefresh] = useState(0)
 const {setActions} = useContext(BreadcrumbContext)
  useEffect(() => {
    setActions(<div>
      <div
           className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer"
            onClick={() => navigate("/websites/new")}
          >
            Add New Website
          </div>
    </div>)
    fetchPublisherWebsites(token, agencyId)
      .then((response) => {
        console.log(response.data.publishers)
        setData(response.data.publishers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);


  return (
      <div header={"Publisher Websites"}>
        <div className="flex justify-end">
        
        </div>
        <div
          xs={12}
          style={{   overflowY: "auto" }}
          container
        >
          {loading || data === undefined ? (
            <Loader/>
          ) : (
            <PublisherTable refresh={refresh} setRefresh={setRefresh} data={data} />
          )}
        </div>
      </div>
  );
}
