import "./App.css";
import { DataProvider } from "./provider/DataContext";
import { AudienceProvider } from "./provider/AudienceContext";
import { BannerProvider } from "./provider/BannerContext";
import { BudgetProvider } from "./provider/BudgetContext";
import { InvoiceProvider } from "./provider/InvoiceContext";
import { AuthProvider } from "./provider/AuthContext";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import AllRoutes from "./routes/routes";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

function App() {
  const pathname = useLocation();
  return (
    <AuthProvider>
      <DataProvider>
        <AudienceProvider>
          <BannerProvider>
            <BudgetProvider>
              <InvoiceProvider>
                <div className="App">
                  <ToastContainer position="top-right" />
                  
                      <AllRoutes />
                </div>
              </InvoiceProvider>
            </BudgetProvider>
          </BannerProvider>
        </AudienceProvider>
      </DataProvider>
    </AuthProvider>
  );
}

export default App;
