import React from "react";








export default function AdvertsDataTable(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Day</StyledTableCell>
            <StyledTableCell align="right">Impressions</StyledTableCell>
            <StyledTableCell align="right">Clicks</StyledTableCell>
            <StyledTableCell align="right">CTR</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <StyledTableRow key={row.iurl}>
              <StyledTableCell component="th" scope="row">
                <div>
                  <img
                    src={row.iurl}
                    width={row.width}
                    height={row.height / 2}
                  />
                </div>
              </StyledTableCell>
              <StyledTableCell align="right">{row.impressions}</StyledTableCell>
              <StyledTableCell align="right">{row.clicks}</StyledTableCell>
              <StyledTableCell align="right">{row.ctr}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
