const RegistrationDescription = () => {
  return (
    <div className="space-y-6 ">
      <div className="space-y-2">
        <h1 className="text-lg ">Quick Launch of your account and campaigns</h1>
        <p className="text-white text-opacity-60 text-sm">
          From 15 minutes from the start of registration until the approval of
          your campaigns by moderation
        </p>
      </div>
      <div className="space-y-2">
        <h1 className="text-lg ">
          Traffic volumes from 1 Million unique impressions per day
        </h1>
        <p className="text-white text-opacity-60 text-sm">
          This will allow you to work fully without feeling high competition for
          bid in your chosen geo.
        </p>
      </div>
      <div className="space-y-2">
        <h1 className="text-lg">
          Trading under CPM pricing models for all Ad Formats:
        </h1>
        <ul className=" list-inside list-disc text-sm">
          {[
            "Native ads",
            "Push notifications",
            "Inpage traffic",
            "Popunder",
          ].map((item) => {
            return <li className="text-white text-opacity-60">{item}</li>;
          })}
        </ul>
      </div>
      <div className="space-y-2">
        <h1 className="text-lg ">Own anti-fraud solutions</h1>
        <p className="text-white text-opacity-60 text-sm">
          Get handpicked traffic and real users
        </p>
      </div>
    </div>
  );
};

export default RegistrationDescription;
