import { ReactComponent as FacebookIcon } from "../../assets/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/linkedin.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/youtube.svg";

const channelIcons = [
  {
    id: "facebook",
    label: "Facebook",
    icon: <FacebookIcon />,
  },
  {
    id: "twitter",
    label: "Twitter",
    icon: <TwitterIcon />,
  },
  {
    id: "instagram",
    label: "Instagram",
    icon: <InstagramIcon />,
  },
  {
    id: "linkedin",
    label: "Linkedin",
    icon: <LinkedinIcon />,
  },
  {
    id: "youtube",
    label: "Youtube",
    icon: <YoutubeIcon />,
  },
];

export const getIcon = (iconId) => {
  const channel = channelIcons.find(({ id }) => id === iconId);
  return channel ? channel.icon : <></>;
};
