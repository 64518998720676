import React, { useEffect, useState } from "react";
import CampaignInformation from "./campaign-information";
import AudienceInformation from "./audience-info";
import BannerInformation from "./banner-info";
import BudgetInformation from "./budget-information";
import { saveCampaigns } from "../../../api/api";
import SubmitButtons from "../submit_buttons";

import { styles } from "./style";
import { useCampaignData } from "../../../provider/DataContext";
import { useAudienceData } from "../../../provider/AudienceContext";
import { useBannerData } from "../../../provider/BannerContext";
import { useBudgetData } from "../../../provider/BudgetContext";

import { toast, ToastContainer } from "react-toastify";
import { useAnimate } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader";

function PreviewCampaign(props) {
  const { data } = useCampaignData();
  const [campaign, setCampaign] = useState([]);
  const [loading, setLoading] = useState(true);
  const { audienceData } = useAudienceData();
  const { bannerData } = useBannerData();
  const { budgetData } = useBudgetData();
  const [audience, setAudience] = useState([]);
  const [banners, setBanners] = useState([]);
  const [budget, setBudget] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    if (data.length > 0 || audienceData.length > 0 || bannerData.length > 0) {
      setCampaign(data);
      setAudience(audienceData);
      setBanners(bannerData);
      setBudget(budgetData);
      setLoading(false);
    } else {
      setCampaign({
        campaignName: "",
        campaignDestination: "",
        advertiser: "",
        startDate: "",
        endDate: "",
      });

      setAudience({
        audienceId: "",
      });
      setBanners({
        bannerId: "",
      });
      setBudget({
        totalBudget: "",
        daily: "",
      });
    }
    setLoading(false);
  }, []);

  const submitCampaign = () => {
    props.navigate("/campaigns");
  };
  function convertToISOFormat(date) {
    let dateString = date.toLocaleDateString();
    // Split the date string into [day, month, year]
    const parts = dateString.split("/");

    // Rearrange to 'YYYY-MM-DD' format
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }

  const { classes } = props;

  return (
    <div header={"Preview Campaign"} backUrl={"/create-campaign"}>
      <div>
        <ToastContainer />
        {loading === true || campaign === undefined ? (
          <Loader/>
        ) : (
          <div className="space-y-3">
            
            <div className="" >
              <CampaignInformation  />
            </div>
            <div item xs={6}>
              <AudienceInformation />
            </div>
            <div item xs={6}>
              <BannerInformation  />
            </div>
            <div item xs={6}>
              <BudgetInformation />
            </div>
            <button
            onClick={()=>{
              navigate('/campaigns')
            }}
              className=" bg-primary text-white mt-3 rounded-md py-2 px-4 w-40 flex justify-center mb-3"
              >
                Finish Campaign
            </button>
           
          </div>
        )}

        {/* <div open={isLoading} sx={styles.div}>
          <div >
            <div className={classes.container}>
              <CircularProgress size="3.8rem" />
            </div>
            <Typography sx={styles.message}>Please wait your</Typography>
            <Typography sx={styles.message}>
              Campaign is publishing...
            </Typography>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PreviewCampaign;
