import Loader from "../../components/loader";



const CampaignSkeleton = () => {
  const classes = useStyles();
  return <Loader/>;
};

export default CampaignSkeleton;
