
import PropTypes from "prop-types";


const TablePagination = ({ page, pageCount, setPageNumber }) => {
  const classes = useStyles();

  return (
    <div
      xs={12}
      container
      justify="flex-end"
      alignItems="center"
      className={classes.root}
    >
      <Pagination
        count={pageCount}
        page={page}
        showFirstButton
        showLastButton
        onChange={(_, page) => setPageNumber(page)}
      />
    </div>
  );
};

TablePagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
};

export default TablePagination;
