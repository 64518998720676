import { useState } from "react";
import { useNavigate } from "react-router-dom";


export default function AdvertiserBannerTable({ filteredData }) {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8); // Set the number of rows per page
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = filteredData.filter((item) =>
    item.bannerName.toLowerCase().includes(filter.toLowerCase()),
  );

  return (
    <div>
      <div
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <TextField
          label="Filter by name or campaign name"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{ margin: "20px" }}
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Width</TableCell>
              <TableCell>Height</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Apply pagination
              .map((item) => (
                <TableRow key={item.bannerId}>
                  <TableCell>{item.bannerId}</TableCell>
                  <TableCell>{item.bannerName}</TableCell>
                  <TableCell>{item.width}</TableCell>
                  <TableCell>{item.height}</TableCell>
                  <TableCell>
                    {item.storageType === "url" ? "image" : "html"}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white", backgroundColor: "#6EBF4A" }}
                        onClick={() =>
                          navigate(
                            `/advertiser/banners/edit/${item.bannerId}`,
                          )
                        }
                      >
                        View
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]} // Define options for rows per page
          component="div"
          count={filteredData.length} // Total number of rows
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}
