import React, { useContext, useEffect, useState } from "react";
import {
  fetchPublisherCampaignStats,
  fetchPublisherWebsites,
  getUserName,
} from "../../api/ssp-api";
import SspTable from "../ssp/campaign-stats/campaigns-table";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import PublisherWrapper from "../../components/wrappers/publisher-wrapper";
import { AuthContext } from "../../provider/AuthContext";

export default function PublisherOverview() {
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);
  const [refresh,setRefresh] = useState(0);
  const onRefresh = ()=>{
    setRefresh(refresh+1)
  }
  useEffect(() => {
    fetchPublishers(base64Credentials, agencyId);
  }, [refresh]);

  const fetchPublishers = async (creds) => {
    try {
      const response = await fetchPublisherWebsites(creds, agencyId);
      const username = getUserName();
      const matchingPublisher = response.data.filter((publisher) =>
        publisher.emailAddress.includes(username),
      );

      if (matchingPublisher.length === 0) {
        console.log("No matching publishers found.");
        setLoading(false);
        return;
      }

      const promises = matchingPublisher.map((publisher) =>
        fetchPublisherCampaignStats(publisher.publisherId, creds),
      );

      const results = await Promise.all(promises);
      const publisherData = results.map((result) => ({
        ...result.data,
        campaigns: {
          data: result.data.campaigns.data.map((campaign) => ({
            ...campaign,
            impressions: parseInt(campaign.impressions),
            clicks: parseInt(campaign.clicks),
            revenue: parseFloat(campaign.revenue),
          })),
        },
      }));

      const combinedData = aggregateCampaignData(publisherData);

      setCampaigns(combinedData);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  function aggregateCampaignData(publisherData) {
    if (publisherData.length === 0) {
      console.log("No publisher campaign data to aggregate.");
      return [];
    }
    const aggregatedData = {};
    publisherData.forEach((publisher) => {
      publisher.campaigns.data.forEach((campaign) => {
        const { campaignid } = campaign;

        if (!aggregatedData[campaignid]) {
          aggregatedData[campaignid] = { ...campaign };
        } else {
          aggregatedData[campaignid].impressions += campaign.impressions;
          aggregatedData[campaignid].clicks += campaign.clicks;
          aggregatedData[campaignid].revenue += campaign.revenue;
        }
      });
    });

    return Object.values(aggregatedData);
  }

  const handleAdminView = (campaignId, advertiserId) => {
    let startDate = "2023-01-01";
    navigate(`/ssp/campaign/${campaignId}/${startDate}/${advertiserId}`);
  };
  const handlePublisherView = (campaignId, advertiserId) => {
    let startDate = "2023-01-01";
    navigate(
      `/publisher/ssp/campaign/${campaignId}/${startDate}/${advertiserId}`,
    );
  };

  return (
   
      <div container direction="row" spacing={2}>
        {loading || campaigns === undefined ? (
          <div item xs={12}>
            <Loader/>
          </div>
        ) : (
          <div item xs={12}>
            {campaigns === null || campaigns.length === 0 || !campaigns ? (
              <Typography variant="h6" gutterBottom component="div">
                No Campaign Found
              </Typography>
            ) : (
              <SspTable
                data={campaigns}
                onRefresh={onRefresh}
                handleAdminView={handleAdminView}
                handlePublisherView={handlePublisherView}
                role={"publisher"}
              />
            )}
          </div>
        )}
      </div>
  );
}
