import { Component } from "react";
import { styles } from "./styles";



class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div container justify="flex-start">
        <div header={"Support"}></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default Support;
