import { AnimatePresence,motion } from "framer-motion";
import { IoClose } from "react-icons/io5";

const Preview = ({url,width,height,setShow,show,selectedItem,type}) => {
    return ( <AnimatePresence>
        {show && selectedItem != null && (
          <motion.div   className=" fixed inset-0 bg-black z-50 bg-opacity-30 ">
            <div className="flex flex-col justify-center items-center h-full w-full">
              <motion.div initial={{scale:0,opacity:0}} animate={{scale:1,opacity:1}} exit={{scale:0,opacity:0}}  className="p-10 w-6/12 2xl:w-4/12 bg-white rounded-md shadow-2xl ">
                <div className="flex justify-between mb-5">
                  <h1 className=" text-xl font-semibold">Preview</h1>
                  <IoClose
                    onClick={() => {
                      setShow(false);
                    }}
                    className="text-xl cursor-pointer text-muted"
                  />
                </div>
                {type=="html"? <iframe
                  src={url}
                  alt={"Create Preview"}
                  width={width}
                  height={height}
                />:<img
                  src={url}
                  alt={"Create Preview"}/>}
               
              </motion.div>
            </div>
          </motion.div>
        )}
        </AnimatePresence>  );
}
 
export default Preview;