import { set } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const CampaignStatsGraph = ({ categories,values,valueName}) => {

  
  const chartData = {
    series: [
      {
        name: valueName,
        data: values,
      }
    
    ],
    options: {
      animations: {
        enabled: true,
      },
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories
      },

      colors: ["#6DBF48","#FAB005"], // Adding the color green
    },
  };

  return (
    <div className=" bg-white rounded-md">
      <div className="flex justify-between">
        {/* <h1 className="font-bold text-xl">Impressions and Clicks</h1> */}
        {/* <select
          defaultValue={year}
          onChange={(e) => {
            setYear(e.target.value);
          }}
          className="text-sm border border-border font-medium focus:border-primary focus:ring-primary rounded-md bg-white"
        >
          {[
            moment(Date.now()).format("yyy"),
            moment(Date.now()).format("yyy") - 1,
            moment(Date.now()).format("yyy") - 2,
            moment(Date.now()).format("yyy") - 3,
            moment(Date.now()).format("yyy") - 4,
          ].map((item) => {
            return <option>{item}</option>;
          })}
        </select> */}
      </div>

      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
      />
    </div>
  );
};

export default CampaignStatsGraph;
