// src/components/CampaignTable.js
import React from 'react';

const CampaignTable = ({ data, handlePublisherView }) => {
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Campaign Name</TableCell>
                        <TableCell>Impressions</TableCell>
                        <TableCell>Clicks</TableCell>
                        <TableCell>CTR</TableCell>
                        <TableCell>Revenue</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((campaign) => (
                        <TableRow key={campaign.campaignid}>
                            <TableCell>{campaign.campaignid}</TableCell>
                            <TableCell>{campaign.campaignname}</TableCell>
                            <TableCell>{campaign.impressions}</TableCell>
                            <TableCell>{campaign.clicks}</TableCell>
                            <TableCell>{((campaign.clicks / campaign.impressions) * 100).toFixed(2)}</TableCell>
                            <TableCell>{campaign.impressions}</TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ color: "white" }}
                                    onClick={() => handlePublisherView(campaign.campaignid, campaign.advertiserid)}
                                >
                                    View Stats
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {/* Add pagination */}
        </div>
    );
};

export default CampaignTable;
