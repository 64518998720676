import moment from "moment";
import { useState } from "react";
import Chart from "react-apexcharts";
import { chartColors } from "../audience/constant";

const GroupedGraph = ({  series,xLabels}) => {
  // const [year, setYear] = useState(moment(Date.now()).format("yyyy") - 1);

  const chartData = {
    series,
    legend: {
      show: false,
    },
    options: {
      legend: {
        show: false,
      },
      chart: {
        type: 'bar',
        toolbar: {
            show: false,
          },

      
      },
      stroke: {
        width: [0, 4],
        radiues:30,
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: xLabels??[],
      },
      legend: {
        show: false,
      },
      colors:chartColors ,
    },
  };

  return (
    <div className="rounded-md">
      <Chart
        options={chartData.options}
        series={chartData.series}
        height={500}
        
        type="bar"
      />
    </div>
  );
};

export default GroupedGraph;
