import React, { useState } from "react";

import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import Iframe from "../../components/iframe";
import { IoClose } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import Preview from "../../components/preview";
import ModifyButton from "../../components/modify-button";
import { deleteDspBanner } from "../../api/api";

const DspBanners = ({ bannerData, refresh, setRefresh }) => {
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(8); // Set the number of rows per page
  const [keyword, setKeyword] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    bannerData && (
      <div>
        <div className="bg-white px-5 py-5 w-full rounded-md mt-4 border border-border">
          <div className="flex justify-end space-x-2">
            <input
              label="Filter by name or campaign name"
              placeholder="Search here"
              className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <Preview
            setShow={setShow}
            show={show}
            selectedItem={selectedItem}
            height={selectedItem && selectedItem.height}
            width={selectedItem && selectedItem.width}
            type={
              selectedItem && selectedItem.contenttype == "html"
                ? "html"
                : "image"
            }
            url={selectedItem && selectedItem.iurl}
          />

          <table className="text-sm table w-full">
            <thead className="text-muted">
              <tr className="">
                <th className="py-3 text-sm text-start">Id</th>
                <th className="py-3 text-sm text-start">Name</th>
                <th className="py-3 text-sm text-start">Campaign name</th>
                <th className="text-sm text-start py-3">Width</th>
                <th className="text-sm text-start py-3">Height</th>
                <th className="text-sm text-start py-3">Created At</th>
                <th className="text-sm text-start py-3">Advertiser Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="text-start">
              {bannerData
                .filter(
                  (item) =>
                    item.name.toLowerCase().includes(keyword.toLowerCase()) // Filter by campaign name
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                    <td className="text-start py-3 px-0 ">{item.id}</td>
                    <td className="text-start py-3 px-0 ">{item.name}</td>
                    <td className="text-start py-3 px-0 ">
                      {item.campaignName}
                    </td>
                    <td className="text-start py-3 px-0 ">{item.width}</td>
                    <td className="text-start py-3 px-0 ">{item.height}</td>
                    <td className="text-start py-3 px-0 ">{item.created_at}</td>

                    <td className="text-start py-3 px-0 ">
                      {item.advertiserName}
                    </td>
                    <td className="text-start px-0 py-3">
                      <button
                        onClick={() => {
                          setShow(true);
                          setSelectedItem(item);
                        }}
                        className="font-bold bg-primary text-white py-1 rounded-md px-2"
                      >
                        Preview
                      </button>
                    </td>
                    <td>
                      <ModifyButton
                        id={item.id}
                        onDelete={() => {
                          deleteDspBanner(item.id).then((response) => {
                            setRefresh(refresh + 1);
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
            <h1 className=" translate-x-5">Rows per page</h1>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(e.target.value);
              }}
              className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
            >
              <option className="" value={5}>
                5
              </option>
              <option className="" value={8}>
                8
              </option>
            </select>
            <div
              onClick={() => {
                if (page > 0) {
                  setPage(page - 1);
                }
              }}
              className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
            >
              <MdOutlineChevronLeft />
            </div>
            <h1>
              {page + 1} out of {Math.ceil(bannerData.length / rowsPerPage)}
            </h1>
            <div
              onClick={() => {
                if (page < Math.round(bannerData.length / rowsPerPage)) {
                  setPage(page + 1);
                }
              }}
              className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
            >
              <MdOutlineChevronRight />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DspBanners;
