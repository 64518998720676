import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { IoClose } from "react-icons/io5";

export default function Bannertable({ filteredData, handleDeleteBanner }) {
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set the number of rows per page
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    console.log("");
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filter, setFilter] = useState("");
  const filteredRows = filteredData.filter((item) =>
    item.name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="bg-white px-5 py-5 w-full rounded-md  border border-border">
      <div className="flex justify-end">
        <input
          className="py-2 rounded-md border-border text-sm  focus:border-primary focus:ring-primary"
          placeholder="Search here"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <AnimatePresence>
        {show && selectedItem != null && (
          <motion.div className=" fixed inset-0 bg-black z-50 bg-opacity-30 ">
            <div className="flex flex-col justify-center items-center h-full w-full">
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                className="p-10 w-6/12 2xl:w-4/12 bg-white rounded-md shadow-2xl "
              >
                <div className="flex justify-between mb-5">
                  <h1 className=" text-xl font-semibold">Preview</h1>
                  <IoClose
                    onClick={() => {
                      setShow(false);
                    }}
                    className="text-xl cursor-pointer text-muted"
                  />
                </div>
                {selectedItem.type == "image" ? (
                  <img src={selectedItem.url} />
                ) : (
                  <iframe
                    src={selectedItem.url}
                    alt={"Create Preview"}
                    width={selectedItem.width}
                    height={selectedItem.height}
                  />
                )}
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="bg-white px-5 py-5 w-full rounded-md ">
        <table className="text-sm table w-full">
          <thead className="text-muted">
            <tr>
              <th className="text-sm text-start py-3">ID</th>
              <th className="text-sm text-start py-3">Name</th>
              <th className="text-sm text-start py-3">Width</th>
              <th className="text-sm text-start py-3">Height</th>
              <th className="text-sm text-start py-3">Linked</th>
              <th className="text-sm text-start py-3">Type</th>
              <th className="text-sm text-start py-3">Created At</th>
              <th className="text-sm text-start py-3"></th>
            </tr>
          </thead>
          <tbody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Apply pagination
              .map((item) => (
                <tr
                  onClick={() => {
                    setShow(true);
                    setSelectedItem(item);
                  }}
                  className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer"
                  key={item.id}
                >
                  <td className="text-start py-3 px-0">{item.id}</td>
                  <td>
                    {item.type == "image" ? (
                      <img height={100} width={100} src={item.url} />
                    ) : (
                      <iframe width={100} height={100} src={item.url} />
                    )}
                  </td>
                  <td className="text-start py-3 px-0">{item.name}</td>
                  <td className="text-start py-3 px-0">{item.width}</td>
                  <td className="text-start py-3 px-0">{item.height}</td>
                  <td className="text-start py-3 px-0">
                    {item.is_linked ? "True" : "False"}
                  </td>
                  <td className="text-start py-3 px-0">{item.type}</td>
                  <td className="text-start py-3 px-0">
                    {new Date(item.created_at).toLocaleString("en-GB")}
                  </td>
                  <td className="text-start px-0 py-3">
                    <button
                      className="font-bold text-red-400 bg-red-100 py-1 rounded-md px-2"
                      onClick={() => handleDeleteBanner(item.id)}
                    >
                      Delete
                    </button>
                  </td>
                  <td className="text-start px-0 py-3">
                    <button
                      className="font-bold py-1 px-2 bg-primary text-white rounded"
                      onClick={() =>
                        navigate(`/agency/banners/edit/${item.id}`)
                      }
                    >
                      View
                    </button>
                  </td>

                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
          <h1 className=" translate-x-5">Rows per page</h1>
          <select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(e.target.value);
            }}
            className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
          >
            <option className="" value={5}>
              5
            </option>
            <option className="" value={8}>
              8
            </option>
          </select>
          <div
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
          >
            <MdOutlineChevronLeft />
          </div>
          <h1>
            {page + 1} out of {Math.ceil(filteredRows.length / rowsPerPage)}
          </h1>
          <div
            onClick={() => {
              if (page < Math.round(filteredRows.length / rowsPerPage)) {
                setPage(page + 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
          >
            <MdOutlineChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
}
