import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../provider/AuthContext";
import { fetchBannersByCampaignId } from "../../../api/api";
import { useParams } from "react-router-dom";
import { CampaignContext } from "../campaign-create/create-campaign-tabs";




const BannerInformation = () => {
  const { userDetails } = useContext(AuthContext);
const { token, agencyId } = JSON.parse(userDetails);
const [banners, setBanners] = useState([])
const {uuid} = useParams()

useEffect(() => {
  fetchBannersByCampaignId(uuid,token).then((response)=>{
    console.log(response.data.banner)
    setBanners(response.data.banner)
  })
}, [])

  const [dimensions, setDimensions] = useState({
    dimensions: "",
    type: "",
  });
  const {activeStep,setActiveStep} = useContext(CampaignContext);
  
 
 
  return banners&&(
    <div className="border border-border rounded-md bg-white p-10">
      
        <>
          <div  >
            <div >
              <div className="font-bold">Banner Previews</div>
              <div>
                <div className="grid grid-cols-4 gap-3 mt-4 ">
                  {banners.map(({ width, height, iurl, contenttype, name}) => (
                    <div className="  border-border  rounded">
                        {contenttype == "html"?<iframe className=" h-64" src={iurl} alt={name} />:<img className=" h-64" src={iurl} alt/>}
                      <div className="text-sm " >Banner Name: {name}</div>
                    
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button
                onClick={()=>{
                  setActiveStep(2)
                }}
                className=" border border-primary text-primary  rounded-md py-1 px-4 w-40 flex justify-center"
                type="submit"
                style={{ marginTop: "20px" }}
              >
                Edit Details
              </button>
      
        </>
      
    </div>
  );
};

export default BannerInformation;
