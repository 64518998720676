import {
  AiOutlineAreaChart,
  AiOutlineCalendar,
  AiOutlineDownload,
} from "react-icons/ai";
import { FaCalendar, FaChartArea, FaDownload } from "react-icons/fa";
import BarGraph from "../overview/bar-graph";
import CustomBarGraph from "../overview/custom-bar-graph";
import { MdOutlineAddChart } from "react-icons/md";
import CustomLineGraph from "../overview/custom-line-graph";
import { FaArrowTrendUp } from "react-icons/fa6";
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from "framer-motion";
import { useEffect, useRef, useState } from "react";
import CustomButton from "../../components/customButton";
import RightHeroSection from "../../components/website-components/right-hero-section";
import { useLocation, useNavigate } from "react-router-dom";
import ValuesSection from "../../components/website-components/valuesSection";
import ValuesSection2 from "../../components/website-components/valuesSection2";
import AdFormatSection from "../../components/website-components/adFormatSection";
const HomePage = () => {
  const partners = [
    // "/logos/Logo 1.png",
    "/logos/Logo 2.png",
    "/logos/logo 3.png",
    "/logos/logo 4.png",
    "/logos/Logo 5.png",
    // "/logos/Logo 6.png",
    "/logos/Logo 7.png",
    "/logos/Logo 8.png",
    "/logos/Logo 9.png",
    // "/logos/Logo 10.png",
    // "/logos/Logo 11.png",
    // "/logos/Logo 12.png",
    // "/logos/Logo 13.png",
    // "/logos/Logo 14.png",
    // "/logos/Logo 15.png",
    "/logos/Logo 16.png",
  ];
  const outlets = [
    "Billboards",
    "Influencers",
    "Social Media",
    "Displays",
    "Direct Placements",
    "Audio Ads",
    "Search Ads",
    "Radio",
    "Television",
    "Print",
  ];
  const [width, setWidth] = useState();
  const textAnimationContainer = useRef();
  const plaformParagraph = `Kwanza leverages the force of high-quality media, data accuracy,
              and imaginative creativity to ensure sustainable business
              achievements Commodo proident sint mollit qui nostrud ipsum id.`;
  const { scrollY } = useScroll();
  const translateX = useTransform(
    scrollY,
    [3200, 3800],
    [-1, plaformParagraph.length]
  );
  const [currentChar, setCurrentChar] = useState();
  const [scrollValue, setScrollValue] = useState(0);
  useMotionValueEvent(scrollY, "change", (value) => {
    console.log(value);
    setScrollValue(value);
    console.log(value);
    setCurrentChar(parseInt(translateX.get()));
  });
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.scrollWidth / 2);
    }
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      <div className="">
        <div className=" pt-16 flex flex-col justify-center h-[83vh] md:h-[91vh] bg-cubic-gradient  ">
          <div className="w-11/12  md:w-7/12 mx-auto space-y-4">
            <h1 className=" text-5xl md:text-6xl 2xl:text-6xl leading-none md:leading-tight 2xl:leading-snug font-bold text-center">
              Reach your target Audience{" "}
              <span className=" bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent ">
                Anywhere
              </span>
              ,{" "}
              <span className=" bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                Anytime
              </span>
            </h1>
            <p className=" text-lg md:text-xl text-center text-muted pb-3 md:pb-12 px-8 md:px-36 2xl:px-72">
              Break down traditional and digital silos with all in one AdTech
              platform
            </p>
            <div className="flex justify-center mt-0 md:mt-8">
              <button
                onClick={() => {
                  navigate("/register?role=advertiser");
                }}
                className="bg-primary py-4 hover:scale-105 transition-all px-8 font-medium text-sm md:text-base rounded-full "
              >
                BOOK A DEMO
              </button>
            </div>
            {/* <div className="flex justify-center space-x-10 pt-12">
              <div className=" flex flex-col items-center space-y-1">
                <h1 className="text-2xl text-white font-bold">2863+</h1>
                <p className="text-muted">Publishers</p>
              </div>
              <div className=" flex flex-col items-center space-y-1">
                <h1 className="text-2xl text-white font-bold">428+</h1>
                <p className="text-muted">Advertisers</p>
              </div>
              <div className=" flex flex-col items-center space-y-1">
                <h1 className="text-2xl text-white font-bold">562+</h1>
                <p className="text-muted">Campaigns</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="bg-primary py-6 overflow-hidden ">
        <motion.div
          ref={containerRef}
          animate={{
            x: [0, -width],
            transition: {
              repeat: Infinity,
              duration: 16,
              repeatType: "loop",
              ease: "linear",
            },
          }}
          className="flex  "
        >
          {[...partners, ...partners].map((item, index) => {
            return (
              <img
                src={item}
                className=" object-fit h-8 md:h-12 px-2 md:px-20"
              />
            );
          })}
        </motion.div>
      </div>
      <div className="bg-white py-12 text-textColor">
        <div className=" hidden md:block">
          <ValuesSection />
        </div>
        <div className=" block md:hidden">
          <ValuesSection2 />
        </div>

        <div className=" bg-dark py-12 md:py-24 ">
          <div className="w-10/12 mx-auto text-white">
            <div className=" w-full md:w-5/12 2xl:w-4/12 ">
              <h1 className=" text-2xl md:text-3xl font-bold">
                Engage users with
                <br />
                high-converting ad formats
              </h1>
            </div>
            <AdFormatSection />
          </div>
        </div>
        <motion.div className="text-black text-2xl">
          {translateX > 40}
        </motion.div>
        <div className=" w-10/12 md:w-9/12 2xl:w-7/12 mx-auto py-24 md:py-48 ">
          <div ref={textAnimationContainer} className=" space-y-2">
            <h1 className="text-primary  font-bold text-base md:text-lg uppercase">
              OUR PLATFORM
            </h1>
            <motion.div>
              <p className="  text-2xl md:text-5xl font-bold ">
                {plaformParagraph.split(/(?!^)/).map((item, index) => (
                  <motion.span
                    key={index} // Ensure each character has a unique key
                    style={{
                      color: currentChar > index ? "black" : "#D1D5DA",
                    }}
                  >
                    {item}
                  </motion.span>
                ))}
              </p>
            </motion.div>
          </div>
        </div>
        <div className=" w-10/12 mx-auto">
          <div
            style={{
              background: "url('/background.svg')",
              backgroundSize: "cover",
            }}
            className="bg-dark p-6 md:p-24  rounded-2xl md:rounded-2xl  content-none grid grid-cols-12 gap-4 md:gap-x-24 items-center"
          >
            <div className=" col-span-12 md:col-span-6 ">
              <h1 className="text-white font-bold text-center md:text-start text-xl 2xl:text-3xl ">
                Join Us Today
              </h1>
              <p className="text-muted mt-2 text-center md:text-start md:mt-4">
                You’re just momet away from unlocking high-performing ad
                campaigns and attracting a surge of quality traffic. Step into
                your newfound growth zone!
              </p>
            </div>
            <div className=" col-span-12 md:col-span-6">
              <div className="flex flex-col md:flex-row  space-y-3 md:space-y-0 md:space-x-3 justify-end">
                <button
                  onClick={() => {
                    navigate("/register/?role=advertiser");
                  }}
                  className="bg-primary text-sm hover:scale-105 transition-all cursor-pointer  py-3 px-8 font-medium rounded-md text-white "
                >
                  I'm an Advertiser
                </button>
                <button
                  onClick={() => {
                    navigate("/register/?role=publisher");
                  }}
                  className="bg-primary text-sm hover:scale-105 transition-all cursor-pointer  py-3 px-8 font-medium rounded-md text-white "
                >
                  i'm a Publisher
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
