import React, { Component } from "react";
import { styles } from "./style";

import NavigationButtons from "../navigation-buttons";
import InvoiceTemplate from "../invoice-template";
import { publishInvoiceApi } from "../../../api/api";

class InvoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPdf: false,
      isLoading: false,
    };
    this.pdfComponent = React.createRef();
  }

  componentDidMount() {}
  onSubmitForm = () => {
    const { invoiceId } = (this.props.match && this.props.match.params) || {};
    this.setState({ isLoading: true });
    publishInvoiceApi(invoiceId)
      .then((res) => {
        this.setState({ isLoading: false });
        this.props.navigate("/billing");
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  onBackButtonClick = () => {
    const { invoiceId } = (this.props.match && this.props.match.params) || {};
    this.props.navigate(`/generate-invoice/invoice-details/${invoiceId}`);
  };
  onClickDownloadButton = () => {};
  savePdf = () => {
    this.pdfComponent.current.save();
  };
  render() {
    const { invoiceDetails, classes } = this.props;
    const { isLoading } = this.state;
    console.log(invoiceDetails);
    const { invoiceId } = (this.props.match && this.props.match.params) || {};

    return (
      <>
        <PDFExport
          paperSize="A4"
          scale={0.75}
          ref={this.pdfComponent}
          margin={{ top: 10, left: 10, bottom: 10, right: 10 }}
        >
          <InvoiceTemplate invoiceId={invoiceId} />
        </PDFExport>
        <NavigationButtons
          showNextButton={true}
          showBackButton={true}
          onClickNextButton={this.onSubmitForm}
          onClickBackButton={this.onBackButtonClick}
          savePdf={this.savePdf}
          hasDownload={true}
          hasContent={true}
          content={"save invoice"}
        />
        <Modal open={isLoading} className={classes.modal}>
          <div className={classes.root}>
            <div className={classes.container}>
              <CircularProgress size="3.8rem" />
            </div>
            <Typography className={classes.message}>Please wait</Typography>
            <Typography className={classes.message}>
              Kwanza is generating your invoice...
            </Typography>
          </div>
        </Modal>
      </>
    );
  }
}

export default InvoicePreview;
