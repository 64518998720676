import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import Logo from "../../../assets/logo.svg";

import { useInvoiceData } from "../../../provider/InvoiceContext";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const SspInvoice = ({ invoiceId }) => {
  const [invoice, setInvoice] = useState({});
  const [advertiser, setAdvertiser] = useState({});
  const [subtotal, setSubtotal] = useState(80);
  const [tax, setTax] = useState(20);
  const [total, setTotal] = useState(100);
  const [invoiceDate, setInvoiceDate] = useState(Date.now());
  const [title, setTitle] = useState("");
  const { data } = useInvoiceData();
  const { addData } = useInvoiceData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data.length > 0) {
      // setTitle(data[0].campaignName);
      setSubtotal(data.campaignTotal);
      setTax(20);
      setTotal(Number(data.campaignTotal) + tax);
      setLoading(false);
    } else {
      console.log("no data");
      navigate("/ssp");
      setLoading(false);
    }
  }, []);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleString(undefined, options);
  }
  function addDays(originalDate, days) {
    const newDate = new Date(originalDate);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  const handleBack = () => {
    addData([]);
    navigate("/ssp");
  };

  const captureComponentAsImage = () => {
    const componentRef = document.getElementById('print'); // Replace with the ID of your component

    html2canvas(componentRef).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      printImageToPdf(imgData);
    });
  };
  const printImageToPdf = (imgData) => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // 'A4' size: 210 x 297 mm
    pdf.save(`${title}.pdf`);
  };


  return (
    <div header={"Generate Invoice"}>
      <div
        container
        spacing={2}
        style={{ marginTop: "24px", height: "600px", overflowY: "auto" }}
      >
          <div container xs={12}>
            <div container direction="row" justifyContent="space-between">
              <div item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleBack()}
                  style={{ color: "white" }}
                  startIcon={<ArrowBackIosNewOutlined />}
                >
                  Back to Campaigns
                </Button>
              </div>
              <div item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => captureComponentAsImage()}
                  style={{ color: "white" }}
                >
                  Download
                </Button>
              </div>
            </div>
            <div className="root" id="print">
              <div className="head">
                <img src={Logo} alt="kwanza logo" className="image-logo" />
                <div className="kwanza-info">
                  <div className="info-contents">
                    <p className="network">Kwanza Advertising Network</p>
                    <p className="contacts">clients@kwanza.co.tz</p>
                    <p className="contacts">+255 786 520 788</p>
                    <p className="contacts">www.kwanza.co.tz</p>
                  </div>
                </div>
              </div>
              <div className="contents">
                <div className="invoice-header">
                  <p>{title}</p>
                  <p>INVOICE</p>
                </div>
                <div className="company-info">
                  <div>
                    <img
                      src={
                        advertiser.logo === true
                          ? advertiser.logo
                          : "https://kwanza-creatives.s3.eu-west-1.amazonaws.com/RifalyLogo.png"
                      }
                      width={100}
                      height={100}
                      alt="company logo"
                    />
                  </div>
                  <div className="invoice-to">
                    <p>Bill to</p>
                    <p>Kwanza Network</p>
                    <p>clients@kwanza.co.tz</p>
                  </div>
                  <div className="invoice-date">
                    <p>
                      <span>Invoice</span>: {invoice.invoice_number}
                    </p>
                    <p>
                      <span>Date</span>: {formatTimestamp(invoiceDate)}
                    </p>
                    <p>
                      <span>Due date</span> :{" "}
                      {addDays(invoiceDate, 7).toLocaleDateString("en-US", {})}
                    </p>
                  </div>
                </div>
                <table className="channels-table">
                  <thead className="text-muted">
                    <tr className="table-header">
                      <td className="first table-col">Channel</td>
                      <td className="second table-col">Description</td>
                      <td className="third table-col">
                        Amount(<span className="color-gr">TSh</span>)
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {" "}
                      <td className="first table-col">Kwanza SSP</td>
                      <td className="second table-col">{title}</td>
                      <td className="third table-col">{subtotal}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="invoice-summary">
                  <tbody>
                    <tr className="invoice-table-header">
                      <td className="tx-5">Payment Method</td>
                      <td className="tx-3">Subtotal</td>
                      <td className="tx-2 bold-700">${subtotal}</td>
                    </tr>
                    <tr>
                      <td className="tx-5">Bank</td>
                      <td className="tx-3">Tax</td>
                      <td className="tx-2 bold-700">${tax}</td>
                    </tr>
                    <tr>
                      <td className="tx-5"></td>
                      <td className="tx-3 final">Total Due</td>
                      <td className="tx-2 final bold-700">${total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default SspInvoice;
