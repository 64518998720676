import React, { useContext, useEffect, useState } from "react";

import { styles } from "./view-campaign-styles";



import EditCampaignForm from "./campaign-edit-form";
import Loader from "../../../components/loader";
import { BreadcrumbContext } from "../../../layouts/dashboardLayout";

const ViewCampaign = ({ classes, filterCampaign, filterByRange }) => {
  const [campaign, setCampaign] = useState({});
  const {setActions} = useContext(BreadcrumbContext);
  
  useEffect(()=>{
    setActions(<div></div>)
  },[])

  const { name, advertiser } = campaign;

  return (
    <div header="Campaign">
      <div>
          <EditCampaignForm />
      </div>
    </div>
  );
};



export default ViewCampaign;
