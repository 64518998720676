import { BsCursor, BsEye } from "react-icons/bs";
import { FaCalendar, FaEye, FaICursor } from "react-icons/fa";
import { StepsContext } from "../create-campaign";
import { useContext, useEffect, useState } from "react";
import { fetchCampaignById } from "../../../../../../api/ssp-api";
import { useParams } from "react-router-dom";
import moment from "moment";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { figureSeparator } from "../../../../../../util/figureSeparator";

const CampaignBudget = ({ savedCampaign, credentials, handleComplete }) => {
  const { activeStep, setActiveStep } = useContext(StepsContext);
  const { uuid } = useParams();
  const [data, setData] = useState(null);
  const isPremium = JSON.parse(localStorage.getItem("isPremium"));

  useEffect(() => {
    fetchCampaignById(uuid, credentials).then((response) => {
      console.log(response.data);
      setData(response.data);
    });
  }, []);

  var estimatedImpressions = () => {
    return isPremium
      ? Math.ceil((parseFloat(data.revenue) * 1000) / 2)
      : Math.ceil((parseFloat(data.revenue) * 1000) / 1.5);
  };
  var estimatedClicks = () => {
    return isPremium
      ? Math.ceil(parseFloat(data.revenue)  / 0.75)
      : Math.ceil(parseFloat(data.revenue) / 0.5);
  };
  var campaignDurationInDays = () => {
    var startDate = new Date(moment(data.startDate).format("YYYY-MM-DD"));
    console.log(startDate);
    var endDate = new Date(moment(data.endDate).format("YYYY-MM-DD"));
    console.log(endDate);
    var diffTime = Math.abs(endDate - startDate);
    console.log(diffTime);
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  return (
    data && (
      <div>
        <h1 className="font-bold text-4xl text-center">
          ${figureSeparator(parseFloat(data.revenue))}
        </h1>
        <h1 className=" text-center text-muted">Campaign Budget</h1>

        <div className="grid grid-cols-2 gap-5 mt-4 text-center">
          {parseInt(data.revenueType) === 1 && (
            <div className=" border border-slate-300 hover:bg-primary cursor-pointer hover:bg-opacity-5 transition-all duration-200 p-8 rounded-md flex flex-col  items-center">
              <BsEye className="size-12 text-primary mb-3" />
              <h1 className="text-xl font-bold ">{figureSeparator(estimatedImpressions())} </h1>
              <h1 className="text-muted">Expected Impressions</h1>
            </div>
          )}

          {parseInt(data.revenueType) === 2 && (
            <div className=" border border-slate-300 p-8 rounded-md cursor-pointer hover:bg-primary hover:bg-opacity-5 transition-all duration-200 flex flex-col  items-center">
              <BsCursor className="size-12 text-primary mb-3" />
              <h1 className="text-xl font-semiBold ">{figureSeparator(estimatedClicks())} </h1>
              <h1 className="text-muted">Expected Clicks</h1>
            </div>
          )}

          <div className=" border border-slate-300 p-8 rounded-md cursor-pointer hover:bg-primary hover:bg-opacity-5 transition-all duration-200 flex flex-col  items-center">
            <IoCalendarNumberOutline className="size-12 text-primary mb-3" />
            <h1 className="text-xl font-semiBold ">
              {campaignDurationInDays()} days{" "}
            </h1>
            <h1 className="text-muted">Campaign duration</h1>
          </div>
        </div>
        <div className="flex justify-between mt-5">
          <div className="flex space-x-3">
            <button
              className="py-2  rounded-md  text-textColor  justify-center flex hover:text-primary transition-all duration-200"
              onClick={() => {
                setActiveStep(activeStep - 1);
              }}
              type="submit"
            >
              Back
            </button>
            <button
              onClick={() => {
                setActiveStep(activeStep + 1);
              }}
              className="py-2 px-3 rounded-md bg-primary font-medium  text-white w-40 justify-center flex focus:scale-105 transition-all duration-150"
              type="submit"
            >
              Continue
            </button>
          </div>

          <button
            className="py-2 px-3 rounded-md  text-textColor  w-24 justify-center flex hover:text-primary transition-all duration-200"
            onClick={() => {
              setActiveStep(activeStep + 1);
            }}
            type="submit"
          >
            Skip
          </button>
        </div>
      </div>
    )
  );
};

export default CampaignBudget;
