import { ReactComponent as AdvertIcon } from "../../../assets/advert.svg";
import { ReactComponent as CampaignIcon } from "../../../assets/campaigns.svg";
import { ReactComponent as UserIcon } from "../../../assets/users.svg";
import { ReactComponent as BannerIcon } from "../../../assets/creatives.svg";
import { ReactComponent as BrowserIcon } from "../../../assets/browser.svg";
import { ReactComponent as ZoneIcon } from "../../../assets/zone.svg";

export const navigation = [
  // {
  //   path: "/overview",
  //   name: "Overview",
  //   icon: <OverviewIcon className="hoveredIcon" />,
  // },
  // {
  //   path: "/campaigns",
  //   name: "DSP Campaigns",
  //   icon: <CampaignIcon className="hoveredIcon" />,
  // },
  {
    path: "/agency/ssp",
    name: "SSP Campaigns",
    icon: <CampaignIcon className="hoveredIcon" />,
  },

  {
    path: "/agency/banners",
    name: "Banners",
    icon: <BannerIcon className="hoveredIcon" />,
  },
  {
    path: "/agency/advertiser",
    name: "Advertiser",
    icon: <AdvertIcon className="hoveredIcon" />,
  },
  // {
  //   path: "/audience",
  //   name: "Audience",
  //   icon: <ChartIcon className="hoveredIcon" />,
  // },
  {
    path: "/agency/websites",
    name: "Websites",
    icon: <BrowserIcon className="hoveredIcon" />,
  },

  {
    path: "/agency/zones",
    name: "Zones",
    icon: <ZoneIcon className="hoveredIcon" />,
  },

  // {
  //   path: "/reports",
  //   name: "Reports",
  //   icon: <PrintIcon className="hoveredIcon" />,
  // },
  {
    path: "/agency/invoices",
    name: "Invoices",
    icon: <CampaignIcon className="hoveredIcon" />,
  },
  // {
  //   path: "/agency/billing",
  //   name: "Billing",
  //   icon: <PaymentIcon className="hoveredIcon" />,
  // },
  {
    path: "/agency/users",
    name: "Users",
    icon: <UserIcon className="hoveredIcon" />,
  },
];
