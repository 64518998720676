
import { Component } from "react";
import { styles } from "./style";

import NavigationButtons from "../navigation-buttons";
import clsx from "clsx";
import { toDateFormat, toDateString } from "../../../util";
import { ReactComponent as FacebookIcon } from "../../../assets/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/linkedin.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/youtube.svg";
import { DateTime } from "luxon";
import { createInvoiceDetailsApi } from "../../../api/api";

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceTitle: null,
      invoiceNumber: "5624-26-64",
      invoiceSummary: null,
      filter: "Past month",
      showDateDialog: false,
      dateRange: { startDate: new Date(), endDate: new Date() },
      channels: [
        {
          channelIndex: 0,
          channel: "",
          description: "",
          amount: 0,
          currency: "USD",
        },
      ],
      lastChannelIndex: 0,
      isLoading: false,
    };
  }
  componentDidUpdate(prevProps) {
    const {
      invoiceTitle,
      invoiceNumber,

      invoiceSummary,
      filter,
      dateRange,
      channels,
    } = this.props.invoiceDetails;
    const { startDate, endDate } = dateRange || {};
    if (prevProps.invoiceDetails !== this.props.invoiceDetails) {
      const newState = {
        invoiceTitle,
        invoiceNumber,
        invoiceSummary,
        dateRange: {
          startDate: startDate || new Date(),
          endDate: endDate || new Date(),
        },
        filter,
      };
      if ((channels || []).length >= 1) {
        newState.lastChannelIndex = (channels || []).length - 1;
        newState.channels = channels;
      }
      this.setState(newState);
    }
  }
  onHandleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onSubmitForm = () => {
    const { invoiceTitle, invoiceSummary, dateRange, filter, channels } =
      this.state;
    const formattedChannel = channels.map(
      ({ channel, description, amount }) => ({
        channel,
        summary: description,
        amount,
      }),
    );
    const { startDate, endDate } = this.getDateRange(filter, dateRange);
    this.setState({ isLoading: true });
    const { invoiceId } = (this.props.match && this.props.match.params) || {};
    createInvoiceDetailsApi(
      {
        title: invoiceTitle,
        endDate,
        startDate,
        summary: invoiceSummary,
        channels: formattedChannel,
      },
      invoiceId,
    )
      .then((res) => {
        const id = res && res.data && res.data.id;
        this.props.navigate(`/generate-invoice/invoice-preview/${id}`);
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  getDateRange = (filter, dateRange) => {
    let startDate;
    let endDate;
    if (filter === "Past month") {
      startDate = DateTime.local().toFormat("yyyy-LL-dd");
      endDate = DateTime.local().minus({ days: 30 }).toFormat("yyyy-LL-dd");
    } else if (filter === "Lifetime") {
      startDate = "00-00-0000";
      endDate = "00-00-0000";
    } else {
      startDate = toDateString(dateRange.startDate);
      endDate = toDateString(dateRange.endDate);
    }
    return { startDate: startDate, endDate: endDate };
  };
  onBackButtonClick = () => {
    const { invoiceId } = (this.props.match && this.props.match.params) || {};
    this.props.navigate(`/generate-invoice/company-details/${invoiceId}`);
  };
  onCloseDateDialog = () => {
    this.setState({
      showDateDialog: false,
    });
  };
  onSubmitDate = (date) => {
    const { startDate, endDate } = date[0];
    const dateRange = { startDate, endDate };
    this.setState({
      dateRange,
      showDateDialog: false,
    });
  };
  onEditCustomDate = () => {
    this.setState({
      showDateDialog: true,
    });
  };
  onFilterChange = (event) => {
    const { value } = event.target;
    this.setState({
      filter: value,
      showDateDialog: value === "Custom",
    });
  };
  onDeleteChannel = (index) => {
    const { channels } = this.state;
    const updatedChannels = channels.filter(
      ({ channelIndex }) => channelIndex !== index,
    );
    this.setState({
      channels: updatedChannels,
    });
  };
  renderDateRange = () => {
    const { dateRange } = this.state;
    const { startDate, endDate } = dateRange;
    return `From ${toDateFormat(startDate)} to ${toDateFormat(endDate)}`;
  };
  renderSocialMediaChannels = () => {
    return [
      {
        id: "facebook",
        label: "Facebook",
        icon: <FacebookIcon />,
      },
      {
        id: "twitter",
        label: "Twitter",
        icon: <TwitterIcon />,
      },
      {
        id: "instagram",
        label: "Instagram",
        icon: <InstagramIcon />,
      },
      {
        id: "linkedin",
        label: "Linkedin",
        icon: <LinkedinIcon />,
      },
      {
        id: "youtube",
        label: "Youtube",
        icon: <YoutubeIcon />,
      },
    ];
  };

  onChannelDetailsChange = (event, index) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const updatedChannels = this.state.channels.map((channel) => {
      if (channel.channelIndex !== index) {
        return channel;
      }
      return {
        ...channel,
        [fieldName]: fieldValue,
      };
    });
    this.setState({
      channels: updatedChannels,
    });
  };
  onAddChannel = () => {
    const { channels, lastChannelIndex } = this.state;
    this.setState({
      channels: [
        ...channels,
        {
          channelIndex: lastChannelIndex + 1,
          channel: "",
          currency: "USD",
          description: "",
          amount: 0,
        },
      ],
      lastChannelIndex: lastChannelIndex + 1,
    });
  };

  renderChannelDetails = ({
    channelIndex,
    channel,
    currency,
    description,
    amount,
  }) => {
    const { classes } = this.props;
    const { channels } = this.state;
    const currencies = ["USD", "TZS"];
    return (
      <div
        xs={12}
        container
        justifyContent="space-between"
        className={classes.marginTop}
        key={channelIndex}
      >
        <div xs={3}>
          <FormHelperText className={classes.helperText}>
            Channel
          </FormHelperText>
          <Select
            input={<OutlinedInput className={clsx(classes.input)} />}
            name="channel"
            value={channel}
            onChange={(event) =>
              this.onChannelDetailsChange(event, channelIndex)
            }
          >
            {this.renderSocialMediaChannels().map(
              ({ id, label, icon }, index) => (
                <MenuItem className={classes.menuItem} value={id} key={index}>
                  <span className={classes.socialIcon}>{icon}</span>
                  <span>{label}</span>
                </MenuItem>
              ),
            )}
          </Select>
        </div>
        <div xs={4}>
          <FormHelperText className={classes.helperText}>
            Description
          </FormHelperText>
          <OutlinedInput
            className={classes.input}
            name="description"
            value={description}
            onChange={(event) =>
              this.onChannelDetailsChange(event, channelIndex)
            }
          />
        </div>
        <div xs={3}>
          <FormHelperText className={classes.helperText}>
            Amount{" "}
            <span>
              <Select
                input={
                  <OutlinedInput
                    className={clsx(classes.input, classes.currencyInput)}
                    classes={{
                      notchedOutline: classes.noBorder,
                    }}
                  />
                }
                name="currency"
                value={currency}
                onChange={(event) =>
                  this.onChannelDetailsChange(event, channelIndex)
                }
              >
                {currencies.map((item, index) => (
                  <MenuItem
                    className={classes.menuItem}
                    value={item}
                    key={index}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </span>
          </FormHelperText>
          <OutlinedInput
            className={classes.input}
            name="amount"
            value={amount}
            onChange={(event) =>
              this.onChannelDetailsChange(event, channelIndex)
            }
            endAdornment={
              <InputAdornment position="end">
                <span className={classes.currencyName}>{currency}</span>
              </InputAdornment>
            }
            type="number"
          />
        </div>
        {channels.length > 1 && (
          <div xs={1}>
            <FormHelperText className={classes.helperText}></FormHelperText>
            <IconButton
              className={classes.closeIcon}
              onClick={() => this.onDeleteChannel(channelIndex)}
            >
              <DeleteForever className={classes.fillRed} />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      invoiceNumber,
      invoiceTitle,
      invoiceSummary,
      filter,
      showDateDialog,
      dateRange,
      channels,
      isLoading,
    } = this.state;
    const filters = [
      {
        id: "Past month",
        name: "Past month",
      },
      {
        id: "Custom",
        name: "Custom",
      },
      {
        id: "Lifetime",
        name: "Lifetime",
      },
    ];
    const { startDate, endDate } = dateRange;
    console.log(startDate, endDate);
    return (
      <div container justifyContent="flex-start">
        <div xs={12} container>
          <div xs={12} container>
            <div xs={5} className={classes.marginRight}>
              <FormHelperText className={classes.helperText}>
                Invoice Title
              </FormHelperText>
              <OutlinedInput
                className={classes.input}
                name="invoiceTitle"
                value={invoiceTitle}
                placeholder="Enter the title for this invoice"
                onChange={this.onHandleChange}
              />
            </div>
            <div xs={5}>
              <FormHelperText className={classes.helperText}>
                Invoice Number
              </FormHelperText>
              <OutlinedInput
                className={classes.input}
                name="invoiceNumber"
                value={invoiceNumber}
                onChange={this.onHandleChange}
              />
            </div>
          </div>
          <div xs={12} container className={classes.marginTop}>
            <div xs={5} className={classes.marginRight}>
              <FormHelperText className={classes.helperText}>
                Invoice Summary (Campaign Description/Details)
              </FormHelperText>
              <TextareaAutosize
                maxRows={6}
                className={clsx(classes.input, classes.textarea)}
                minRows={6}
                value={invoiceSummary}
                name="invoiceSummary"
                onChange={this.onHandleChange}
              />
            </div>
            <div xs={5} className={classes.dateContainer}>
              <FormHelperText className={classes.helperText}>
                Date
              </FormHelperText>
              <div xs={12} container justifyContent="flex-start">
                <div xs={9} container justifyContent="flex-start">
                  {" "}
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    input={
                      <OutlinedInput
                        className={clsx(classes.input, classes.dateFilter)}
                      />
                    }
                    name="campaignId"
                    value={filter}
                    onChange={this.onFilterChange}
                  >
                    {filters.map(({ id, name }, index) => (
                      <MenuItem
                        className={classes.menuItem}
                        value={id}
                        key={index}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {filter === "Custom" && (
                  <div xs={1}>
                    <IconButton onClick={this.onEditCustomDate}>
                      <Edit className={classes.editIcon} />
                    </IconButton>
                  </div>
                )}
              </div>

              <DateRangeDialog
                open={showDateDialog}
                onSubmit={this.onSubmitDate}
                handleClose={this.onCloseDateDialog}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
          <Typography className={classes.channelPerformance}>
            Enter Channel Perfromance Details Below
          </Typography>
          {channels.map(this.renderChannelDetails)}
          <Button className={classes.addChannel} onClick={this.onAddChannel}>
            &#43; add more channels
          </Button>
        </div>

        <NavigationButtons
          showNextButton={true}
          showBackButton={true}
          onClickNextButton={this.onSubmitForm}
          onClickBackButton={this.onBackButtonClick}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default InvoiceDetails;
