import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  deleteSspAdvertiserApi,
  fetchAdvertiserByAgencyId,
} from "../../api/ssp-api";
import Loader from "../../components/loader";
import DspAdvertiserTable from "./tables/dsp-table";
import { deleteAdvertiserApi, getAdvertisersDetailsApi } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthContext";
import { toast } from "react-toastify";

export default function LabTabs() {
  const [value, setValue] = React.useState("1");
  const [loading, setLoading] = useState(true);
  const [advertisers, setAdvertisers] = useState([]);
  const [dspAdvertisers, setDspAdvertisers] = useState([]);
  const navigate = useNavigate();
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { userDetails } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);

    const fetchAdvertiserPromise = fetchAdvertiserByAgencyId(
      2,
      base64Credentials
    )
      .then((response) => {
        setAdvertisers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    const { token, agencyId } = JSON.parse(userDetails);

    const getAdvertisersDetailsPromise = getAdvertisersDetailsApi(
      token,
      agencyId
    )
      .then((response) => {
        setDspAdvertisers(response.data.advertisers);
      })
      .catch((error) => {
        console.log(error);
      });

    Promise.all([fetchAdvertiserPromise, getAdvertisersDetailsPromise]).then(
      () => {
        setLoading(false);
      }
    );
  }, [refresh]);

  const deleteDspAdvertiser = (dspId, sspId) => {
    setLoading(true);
    deleteAdvertiserApi(dspId)
      .then((response) => {
        setRefresh(refresh + 1);
        toast.success("Deleted Successfully");
        deleteSspAdvertiserApi(sspId)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
        // setDspAdvertisers(dspAdvertisers.filter((advertiser) => advertiser.id !== dspId));
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
    navigate("/advertiser");
  };

  return (
    <>
      {loading || dspAdvertisers === undefined ? (
        <Loader />
      ) : (
        <DspAdvertiserTable
          data={dspAdvertisers}
          deleteDspAdvertiser={deleteDspAdvertiser}
        />
      )}
    </>
  );
}
