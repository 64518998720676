export const headers = [
  {
    label: "Campaign",
    isSorted: true,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
    sortKey: "campaign",
  },
  {
    label: "Impressions",
    isSorted: true,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
    sortKey: "impressions",
  },
  {
    label: "Expected Impressions",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
  {
    label: "Clicks",
    isSorted: true,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
    sortKey: "clicks",
  },
  {
    label: "CTR",
    isSorted: false,
    sort: "asc",
    isTooltip: true,
    tooltip: "Add",
  },
  {
    label: "Start date",
    isSorted: true,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
    sortKey: "startDate",
  },
  {
    label: "End date",
    isSorted: true,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
    sortKey: "endDate",
  },
  {
    label: "Action",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
];
