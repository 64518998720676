exports.getCompletedSteps = (
  invoiceId,
  companyDetails,
  invoiceDetails,
  invoiceType,
) => {
  if (!invoiceId) {
    return 0;
  }
  if (invoiceType.invoiceType && invoiceType.invoiceId) {
    if (
      (companyDetails.advertiserId && companyDetails.isExclusive) ||
      (companyDetails.advertiserId &&
        !companyDetails.isExclusive &&
        companyDetails.campaignId)
    ) {
      if (
        invoiceDetails.invoiceTitle &&
        invoiceDetails.invoiceNumber &&
        invoiceDetails.invoiceSummary &&
        (invoiceDetails.dateRange || {}).startDate &&
        (invoiceDetails.dateRange || {}).endDate &&
        (invoiceDetails.channels || []).length > 0
      ) {
        return 6;
      } else {
        return 4;
      }
    } else {
      return 2;
    }
  } else {
    return 0;
  }
};

exports.formatInvoice = (invoice) => {
  const companyDetails = {
    advertiserId:
      (invoice && invoice.invoice && invoice.invoice.advertiser_id) || null,
    campaignId:
      (invoice &&
        invoice.invoiceCampaign &&
        invoice.invoiceCampaign.campaign_id) ||
      null,
    isExclusive:
      ((invoice && invoice.invoice && invoice.invoice.invoice_type) || null) ===
      "kwanza exclusive",
  };
  const invoiceDetails = {
    invoiceTitle: (invoice && invoice.invoice && invoice.invoice.title) || null,
    invoiceNumber:
      (invoice && invoice.invoice && invoice.invoice.invoice_number) || null,
    invoiceSummary:
      (invoice && invoice.invoice && invoice.invoice.invoice_summary) || null,
    filter: "Custom",
    dateRange: {
      startDate: new Date(
        (invoice && invoice.invoice && invoice.invoice.start_date) ||
          new Date(),
      ),
      endDate: new Date(
        (invoice && invoice.invoice && invoice.invoice.end_date) || new Date(),
      ),
    },
    channels: ((invoice && invoice.invoiceChannels) || []).map(
      ({ channel, description, amount }, index) => ({
        channel,
        description,
        amount,
        channelIndex: index,
        currency: "USD",
      }),
    ),
  };
  const invoiceType = {
    invoiceType:
      (invoice && invoice.invoice && invoice.invoice.invoice_type) || null,
    invoiceId: (invoice && invoice.invoice && invoice.invoice.id) || null,
  };
  return { invoiceType, invoiceDetails, companyDetails };
};
