import React, { useContext, useEffect, useState } from "react";


import {
  addCreative,
  fetchAdvertiserByAgencyId,
  fetchCampaignsByAdvertiser,
} from "../../api/ssp-api";
import ImageDropzone from "./image-dropzone";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import { toast } from "react-toastify";

export default function CreateBanner() {
  const [selectedFile, setselectedFile] = useState(null);
  const [isImageselected, setIsImageselected] = useState(false);
  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [binaryData, setBinaryData] = useState("");
  const [formData, setFormData] = useState({
    campaignId: 0,
    bannerName: "banner",
    storageType: "web",
    imageURL: "",
    url: "",
    width: 0,
    height: 0,
    aImage: {
      filename: "",
      content: "",
    },
  });
  const [advertiserData, setAdvertiserData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [advertiserLoading, setAdvertiserLoading] = useState(true);
  const [selectedAdvertiser, setselectedAdvertiser] = useState("");
  const [campaignLoading, setCampaignLoading] = useState(true);
  const [hidden, setHidden] = useState(true);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const {setCurrentPage} = useContext(BreadcrumbContext);
  const [loading,setLoading] =  useState(false)

  useEffect(() => {
    setCurrentPage("Create Banner")
    fetchAdvertiserByAgencyId(2, base64Credentials)
      .then((res) => {
        console.log(res.data);
        setAdvertiserData(res.data);
        setAdvertiserLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleAdvertiserChange = (event) => {
    setselectedAdvertiser(event.target.value);
    getCampaigns(event.target.value);
  };
  const getCampaigns = (selectedAdvertiser) => {
    fetchCampaignsByAdvertiser(selectedAdvertiser, base64Credentials)
      .then((r) => {
       if (r.data.length === 0) {
         setHidden(true)
         alert("No campaigns found for the selected advertiser");
         return;
       }
        setCampaignData(r.data);
        setCampaignLoading(false);
        setHidden(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleImageUpload = (imageInfo) => {
    setselectedFile(imageInfo);
    setIsImageselected(!!imageInfo)

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "storageType") {
      setIsVisible(value === "html");
    }
    const newValue =
      name === "width" || name === "height" || name === "campaignId"
        ? parseInt(value)
        : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    console.log(selectedFile)
    const extension = selectedFile.file.name.split(".").pop();
    let newFileName = Date.now() + "." + extension;
    formData.imageURL = `${process.env.REACT_APP_SSP_URL}/www/images`;
    formData.bannerName = selectedFile.file.name;
    formData.fileName = `${newFileName}`;
    formData.aImage.filename = `${newFileName}`;
    formData.aImage.content = binaryData;
    // formData.bannerName = e.target.bannerName.value,
    formData.url = e.target.url.value,
    formData.storageType = "web",
    formData.width = parseInt(selectedFile.dimensions.width),
    formData.height = parseInt(selectedFile.dimensions.height),
    formData.campaignId = parseInt(e.target.campaignId.value),
    console.log("banner data:", formData);
    console.log(formData)
    const bannerResponse = await addCreative(formData, base64Credentials);
    console.log("Banner response:", bannerResponse);
    if (bannerResponse.status === 200) {
    setLoading(true)
      toast.success("Created successfully")
      navigate(-1);
    }
  };

  return (
    <div className="bg-white border border-border rounded-md p-10 space-y-4">
      <div container xs={12} spacing={2}>
        {advertiserLoading ? (
          <Loader/>
        ) : (
          <div item className="space-y-1" xs={4}>
            <label className="text-sm">select Advertiser*</label>
            <FormControl className="form-style">
              <select
                variant="outlined"
                className="form-style"
                value={selectedAdvertiser}
                onChange={handleAdvertiserChange}
              >
                <option>Select advertiser</option>
                {advertiserData.map((item) => (
                  <option key={item.advertiserId} value={item.advertiserId}>
                    {item.advertiserName}
                  </option>
                ))}
              </select>
            </FormControl>
          </div>
        )}
      </div>

      {hidden ? null : (
        <form
        onSubmit={handleSubmit}
          className="space-y-4"
          spacing={2}
        >
          <div xs={4} container spacing={2} className="space-y-4" direction="column">
           
            {/*<div item xs={6}>*/}
            {/*  <FormControl className="form-style">*/}
            {/*    <label className="text-sm">Creative Type*</label>*/}
            {/*    <select*/}
            {/*      name="storageType"*/}
            {/*      id="storageType"*/}
            {/*      value={formData.storageType}*/}
            {/*      onChange={handleChange}*/}
            {/*    >*/}
            {/*      <MenuItem value="web">Image</MenuItem>*/}
            {/*      <MenuItem value="html">Html</MenuItem>*/}
            {/*    </select>*/}
            {/*  </FormControl>*/}
            {/*</div>*/}
            <div className="grid grid-cols-2 gap-4">
            <div item xs={6}>
                <label className="text-sm">Campaign*</label>
                <select
                  name="campaignId"
                  id="campaignId"
                  className="form-style"
                >
                  {campaignData.map((item) => (
                    <option key={item.campaignId} value={item.campaignId}>
                      {item.campaignName}
                    </option>
                  ))}
                </select>
            </div>
            <div className="flex flex-col space-y-1" item>
            <label className="text-sm">Destination URL</label>
              <input
                variant="outlined"
                className="form-style"
                type="text"
                label="Destination URL"
                name="url"
              />
            </div>
            {isVisible ? (
              <div item>
                <TextField
                  variant="outlined"
                  className="form-style"
                  type="text"
                  label="Folder name"
                  name="folderName"
                />
              </div>
            ) : null}
            </div>
           
            <div className="flex flex-col justify-center items-center">
              <ImageDropzone onImageUpload={handleImageUpload} />
            </div>
            {error ? (
              <div item xs={12}>
                <p>Creative is required</p>
              </div>
            ) : null}
            {/* <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-col space-y-1" item>
            <label className="text-sm">Width</label>
              <input
                className="form-style"
                type="number"
                label="Width"
                name="width"
              />
            </div>
            <div className="flex flex-col space-y-1" item>
            <label className="text-sm">Height</label>
              <input
                className="form-style"
                type="number"
                label="Height"
                name="height"
              />
            </div>
            </div> */}
            

            <div item xs={4}>
              <button
                className="bg-primary py-2 px-3 text-white rounded-md mt-4 w-28 flex justify-center"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
