export const styles = {
  root: {
    marginTop: "3rem",
    paddingLeft: "2rem",
    maxHeight: "69vh",
    overflowY: "auto",
    "& .MuiInputBase-input": {
      height: "0.43rem",
      color: "rgba(151, 151, 151, 0.87)",
      fontSize: "0.8rem",
      borderRadius: "9px",
      textAlign: "left",
    },
    "& .MuiPickersDay-daySelected span": {
      color: "white",
    },
  },

  input: {
    width: "100%",
    height: "2.8rem",
    marginRight: "3.5rem",
  },

  datepicker: {
    width: "100%",
    color: "rgba(151, 151, 151, 0.87)",
  },
  row: {
    marginBottom: "1.52rem",
    "&:first-child": {
      marginRight: "2rem",
    },
  },
  first: {
    marginRight: "3.6rem",
  },
  helperText: {
    marginBottom: "0.6rem",
    color: "#4B4B4B",
  },
  createCampaign: {
    backgroundColor: "#6EBF4A",
    color: "#FFF",
    "&:hover,&:focus": {
      backgroundColor: "#6EBF4A",
    },
    borderRadius: "9px",
    textTransform: "lowercase",
    width: "80%",
    height: "2.8rem",
    marginBottom: "4rem",
  },
  loader: {
    color: "white",
  },
  dropzone: {
    outline: "none",
  },
  dropContent: {
    width: "90.6%",
    outline: "none",
    color: "#4B4B4B",
    border: "0.819465px dashed #C4C4C4",
    borderRadius: "9px",
    textAlign: "center",
    paddingTop: "1rem",
  },
  drag: {
    color: "#808080",
    fontSize: "0.7rem",
    marginBottom: "0.4rem",
  },
  browse: {
    background: "#E3E3E3",
    borderRadius: "4px",
    color: "#808080",
    textTransform: "none",
    fontSize: "0.7rem",
    width: "52%",
    marginBottom: "2rem",
  },
  creativeText: {
    textAlign: "left",
    color: "#4B4B4B",
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  menuItem: {
    fontSize: "0.7rem",
  },
  preview: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "rgba(110, 191, 74, 0.1)",
    border: "0.819465px dashed #6EBF4A",
    width: "90.6%",
    maxWidth: "90.6%",
    minHeight: "13vh",
    fontSize: "0.6rem",
    color: "#000",
    fontWeight: 200,
  },
  actionButtons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8rem",
  },
  action: {
    fontSize: "0.6rem",
    marginBottom: "0.3rem",
  },
  innerContent: {
    paddingLeft: "3rem",
    paddingRight: "2rem",
  },
  delete: {
    transform: "translateY(-7%)",
    "& .icon": {
      width: "4rem",
      transform: "translateY(12%)",
      height: "2rem",
    },
  },
};
