import React, { useContext, useEffect, useState } from "react";
import {
  editCampaign,
  fetchAdvertiserByAgencyId,
  fetchCampaignById,
} from "../../../../../api/ssp-api";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BreadcrumbContext } from "../../../../../layouts/dashboardLayout";
import Loader from "../../../../../components/loader";
import moment from "moment";

export default function EditSspCampaign() {
  const mode = window.location.pathname.split("/")[3];
  const { id } = useParams();
  const [advertisers, setAdvertisers] = useState([]);
  const [advertiserId, setSelectedAdvertiser] = useState(1);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [revenue, setRevenue] = useState(1);
  const [impressions, setImpressions] = useState(-1);
  const [clicks, setClicks] = useState(-1);
  const [weight, setWeight] = useState(0);
  const [priority, setPriority] = useState(0);
  const [isContract, setIsContract] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const user = localStorage.getItem("kwanza");
  const { token, role, agencyId } = user ? JSON.parse(user) : {};
  const { currentPage, setCurrentPage } = useContext(BreadcrumbContext);

  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    setCurrentPage("Edit Campaign");

    fetchAdvertiserByAgencyId(agencyId, base64Credentials)
      .then((response) => {
        console.log(response.data);
        setAdvertisers(response.data);
      })
      .then((data) => {
        if (mode === "edit") {
          getCampaignById();
        }
      });
  }, []);

  async function getCampaignById() {
    try {
      setLoading(true);
      const response = await fetchCampaignById(id, base64Credentials);
      console.log("campaign", response.data);
      setCampaign(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let startDate = new Date(e.target.startDate.value);
    console.log("Date for payload", startDate);
    const payload = {
      campaignName: e.target.campaignName.value,
      advertiserId: parseInt(e.target.advertiserId.value),
      priority: e.target.priority.value,
      startDate: startDate,
      revenueType: e.target.revenueType.value,
      revenue: e.target.revenue.value,
      impressions: parseInt(e.target.impressions.value),
      clicks: parseInt(e.target.clicks.value),
      weight: parseInt(e.target.weight.value),
      isContract: e.target.priority.value == 1,
      level: 1,
    };
    if (campaign.endDate) {
      payload.endDate = new Date(e.target.startDate.value);
    }
    console.log(payload);
    if (mode === "edit") {
      editCampaign(id, payload, base64Credentials)
        .then((response) => {
          setLoading(false);
          toast.success("Changes Saved");
          navigate(-1);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        });
    }
  };

  return loading ? (
    <Loader />
  ) : (
    campaign && (
      <div>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <div className="bg-white p-10 border border-border">
            <div className="grid grid-cols-3 gap-5">
              <div>
                <div className="space-y-1">
                  <label className="text-sm">Select an advertiser</label>
                  <select
                    id="dropdown"
                    defaultValue={campaign.advertiserId}
                    className="form-style"
                    name="advertiserId"
                    placeholder="select an option"
                  >
                    {advertisers.map((item) => (
                      <option key={item.advertiserId} value={item.advertiserId}>
                        {item.advertiserName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="space-y-1">
                <label className="text-sm">Campaign name*</label>
                <input
                  defaultValue={campaign.campaignName}
                  autoComplete="off"
                  name="campaignName"
                  className="form-style"
                  required
                />
              </div>
              <div>
                <div className="space-y-1">
                  <label className="text-sm">Campaign Type*</label>
                  <select
                    name="priority"
                    className="form-style"
                    defaultValue={
                      campaign.priority >= 1 ? 1 : campaign.priority
                    }
                  >
                    <option value={0}>Remnant</option>
                    <option value={1}>Contract</option>
                    <option value={-1}>Override</option>
                  </select>
                </div>
              </div>
              {isContract && (
                <div>
                  <label className="text-sm">
                    Priority over other campaigns*
                  </label>
                  <div>
                    <label>Select priority</label>
                    <select
                      defaultValue={campaign.level}
                      name="level"
                      placeholder="select a Number"
                    >
                      {[...Array(10)].map((_, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div>
                <div className="space-y-1">
                  <label className="text-sm">Start Date</label>
                  <input
                    type="date"
                    className="form-style"
                    name="startDate"
                    defaultValue={moment(
                      `${campaign.startDate.year}-${campaign.startDate.month}-${campaign.startDate.day}`
                    ).format("yyy-MM-DD")}
                  />
                </div>
              </div>
              {campaign.endDate && (
                <div>
                  <div className="space-y-1">
                    <label className="text-sm">End Date</label>
                    <input type="date" className="form-style" name="endDate" />
                  </div>
                </div>
              )}

              <div className="space-y-1">
                <label className="text-sm">Pricing Model*</label>
                <select
                  name="revenueType"
                  defaultValue={campaign.revenueType}
                  className="form-style"
                >
                  <option value={1}>CPM</option>
                  <option value={2}>CPC</option>
                </select>
              </div>
              <div className="space-y-1">
                <label className="text-sm">Price/Rate*</label>
                <input
                  type="number"
                  name="revenue"
                  className="form-style"
                  defaultValue={campaign.revenue}
                  step="0.01"
                />
              </div>
              <div className="space-y-1">
                <label className="text-sm">Impressions*</label>
                <input
                  type="number"
                  name="impressions"
                  className="form-style"
                  defaultValue={campaign.impressions}
                />
              </div>
              <div className="space-y-1">
                <label className="text-sm">Clicks*</label>
                <input
                  type="number"
                  name="clicks"
                  className="form-style"
                  defaultValue={campaign.clicks}
                />
              </div>

              <div className="space-y-1">
                <label className="text-sm">
                  Priority in relation to other campaigns (Active/Paused)
                </label>
                <input
                  type="number"
                  name="weight"
                  className="form-style"
                  defaultValue={campaign.weight}
                />
              </div>
              <div></div>
            </div>
            <button
              type="submit"
              className="bg-primary text-white py-2 px-4 rounded-md mt-8"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    )
  );
}
