
import { toDate } from "../../util";
import React from "react";
import clsx from "clsx";


const FilterItem = ({ filter, handleChange }) => {
  const classes = useStyles();
  return (
    <ListItemButton
      className={classes.listItem}
      onClick={() => handleChange(filter)}
      button
    >
      {filter}
    </ListItemButton>
  );
};
const filter = ["Lifetime", "Past month", "Custom"];
export default function FilterMenu({
  onFilterChange,
  onFilterDateChange,
  reportFilter,
  customActive,
  reportFilterInactive,
  hideCustom,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMenu, setMenu] = React.useState(false);
  const [modalOpen, setModalState] = React.useState(false);
  const [customDate, setCustomDate] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleClose = () => {
    setModalState(false);
  };
  const handleMenuClose = () => {
    setMenu(false);
  };

  const handleClick = (option) => {
    setAnchorEl(option);
    if (option === "Custom") {
      setModalState(true);
    } else {
      onFilterChange(option);
    }
    setMenu(false);
  };
  const onSubmit = () => {
    setModalState(false);
    onFilterDateChange(customDate);
  };
  const toggleMenu = () => {
    setMenu(!showMenu);
  };
  const classes = useStyles();
  console.log(anchorEl);
  let { startDate, endDate } = customDate[0];
  return (
    <div>
      <div xs={12} container>
        <div>
          <Button
            className={clsx(classes.button, { [classes.noMargin]: hideCustom })}
            onClick={toggleMenu}
          >
            {anchorEl || "Lifetime"}{" "}
            {showMenu ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </div>
        {anchorEl === "Custom" && !hideCustom && (
          <>
            <div>
              <Button className={classes.endButton}>{toDate(startDate)}</Button>
            </div>
            <div>
              <Button className={classes.startButton}>{toDate(endDate)}</Button>
            </div>
          </>
        )}
      </div>
      <Dialog
        open={showMenu}
        aria-describedby="alert-dropdownn"
        onClose={handleMenuClose}
        classes={{
          paper: clsx(
            classes.paper,
            { [classes.hideMenu]: !showMenu },
            { [classes.reportFilter]: reportFilter },
            { [classes.customActive]: customActive },
            { [classes.reportFilterInactive]: reportFilterInactive },
            classes.dropDownMenu,
          ),
        }}
      >
        <DialogContent id="alert-dropdownn">
          <List>
            {filter.map((item, index) => (
              <FilterItem
                filter={item}
                key={index}
                handleChange={handleClick}
              />
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogContent
          id="alert-dialog-description"
          className={classes.dialogContent}
        >
          <DateRange
            className={classes.dateRange}
            editableDateInputs={true}
            onChange={(item) => setCustomDate([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={customDate}
            rangeColors={["#6EBF4A"]}
          />
          <Button
            className={clsx(classes.endButton, classes.submitButton)}
            onClick={onSubmit}
          >
            set custom date
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
