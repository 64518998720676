import React, { useContext, useState } from "react";
import { styles } from "./styles";
import { verifyEmail } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import Spinner from "../../components/spinner";

const ResetEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = { email: e.target.email.value };
    verifyEmail(payload)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        navigate(`/password-reset/${payload.email}/${response.data.token}`);
      })
      .catch((error) => {
        console.log(error);
        const message = error.response ? error.response.data.message : "";
        toast.error(message);
        setIsLoading(false);
      });
  };
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="h-screen overflow-hidden flex flex-col justify-center items-center">
      <div className="grid grid-cols-12 text-textColor items-center w-10/12 2xl:w-8/12 mx-auto ">
        <form
          onSubmit={onSubmit}
          className=" col-span-5 2xl:col-span-4 flex flex-col justify-between h-full"
        >
          <div className="mt-8">
            {" "}
            <img className="h-10" src="/Kwanza 1.svg" />
          </div>
          <div className="mt-3">
            <h1 className=" font-bold text-2xl ">Let's verify your email</h1>
            <h1 className="text-muted text-sm">Enter your email below</h1>

            <div className="w-10/12 ">
              <div className="mt-8">
                <label className="text-sm">Email address</label>
                <input className="form-style" type="email" name="email" />
              </div>

              <button
                className="w-full bg-primary mt-8 text-white font-medium py-3 cursor-pointer rounded-md flex justify-center items-center"
                type="submit"
              >
                {isLoading ? <Spinner /> : "Verify Email"}
              </button>
            </div>

            <h1 className="space-x-2 text-sm text-muted pt-2">
              Return to{" "}
              <span
                onClick={() => {
                  navigate("/login");
                }}
                className="text-primary underline cursor-pointer"
              >
                Login
              </span>
            </h1>
          </div>
          <div></div>
        </form>
        <div className="  col-span-7 2xl:col-span-8">
          <div className=" bg-[#F2F2F2]  p-8 flex flex-col h-[95vh] justify-center items-center  rounded-2xl px-24 text-start ">
            <div className="flex w-96">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, x: 200 }}
                  animate={{ opacity: 1, x: 0, transition: { duration: 1 } }}
                  exit={{ opacity: 0, x: -200, transition: { duration: 1 } }}
                >
                  <img
                    className="min-h-96 min-w-96 object-cover"
                    src={["/login.png", "login.png"][selectedIndex]}
                  />
                </motion.div>
              </AnimatePresence>
            </div>

            <div>
              <h1 className="font-bold text-2xl text-start">
                A Few clicks away from publishing your ads
              </h1>
              <p className="text-start text-sm text-muted">
                Consectetur irure officia magna sit occaecat occaecat fugiat do
                ipsum. Sunt duis laborum laboris dolor. Irure do nulla amet eu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetEmail;
