import React, { useContext, useEffect, useState } from "react";

import UserTab from "./users-tab";

import { fetchAdvertiserByAgencyId } from "../../api/ssp-api";
import Loader from "../../components/loader";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import AddUserModal from "../agency-module/ssp/users/add-user";
import CustomModal from "../../components/custom-modal";

export default function Users() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [advertisers, setAdvertisers] = useState([]);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { setActions } = useContext(BreadcrumbContext);

  useEffect(() => {
    setActions(
      <div>
        <div
          className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer"
          onClick={handleOpenModal}
        >
          Add User
        </div>
      </div>
    );
    fetchAdvertiserByAgencyId(2, base64Credentials)
      .then((response) => {
        console.log("advertisers", response.data);
        setAdvertisers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {loading || advertisers === undefined ? (
        <Loader />
      ) : (
        <div style={{ overflow: "auto" }} spacing={2}>
          <div>
            <CustomModal
              heading="Add User"
              setShow={setIsModalOpen}
              content={
                <div>
                  <AddUserModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    data={advertisers}
                  />
                </div>
              }
              show={isModalOpen}
            />
            <div className="flex justify-end"></div>
          </div>
          <div >
            <UserTab />
          </div>
        </div>
      )}
    </div>
  );
}
