import React, { useContext, useEffect, useState } from "react";

import {
  editCampaigns,
  fetchCampaignSummaryApi,
  getAdvertisers,
  getAudiencesApi,
  getCampaignById,
} from "../../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../provider/AuthContext";
import { BreadcrumbContext } from "../../../layouts/dashboardLayout";
import { toast } from "react-toastify";
import Spinner from "../../../components/spinner";
import Loader from "../../../components/loader";
import moment from "moment";

const styles = (theme) => ({
  form: {
    width: "50%",
    marginTop: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    color: "#FFF",
  },
  input: {
    width: "100%",
    height: "2.8rem",
    marginRight: "3.5rem",
  },
});

const EditCampaignForm = ({}) => {
  // const [campaign, setCampaign] = useState({});
  const [campaignDetails, setCampaignDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [advertisers, setAdvertisers] = useState(null);
  const [audience, setAudience] = useState(null);
  const [audiences, setAudiences] = useState([]);
  const [banners, setBanners] = useState({});
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  const { setCurrentPage } = useContext(BreadcrumbContext);
  const { token, role, agencyId } = userDetails ? JSON.parse(userDetails) : {};
  const { campaignId } = useParams();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setCurrentPage("Edit Campaign");
    fetchCampaignData();
  }, []);

  const fetchCampaignData = () => {
    // Initialize a variable to track the number of completed requests
    let completedRequests = 0;
    // Define a function to handle request completion
    const handleRequestComplete = () => {
      completedRequests++;
      // Check if all requests are completed
      if (completedRequests === 3) {
        // All requests are completed, update state and set isLoading to false
        setIsLoading(false);
      }
    };

    // Fetch campaign by id
    getCampaignById(token, campaignId).then((response) => {
      console.log("Campaign by ID", response.data);
      setCampaignDetails(response.data);
      setIsLoading(false)

    });

    // Fetch campaign summary
    // fetchCampaignSummaryApi(campaignId, token)
    //   .then((response) => {
    //     console.log("campaign response", response.data);
    //     const { campaign, target, banners } = response.data;
    //     setCampaignDetails(campaign);
    //     console.log(campaign);
    //     setBanners(banners);
    //     setAudience(target);
    //     handleRequestComplete();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     handleRequestComplete();
    //   });

    // Fetch audiences
    getAudiencesApi(token)
      .then((response) => {
        console.log(response.data);
        setAudiences(response.data);
        handleRequestComplete();
      })
      .catch((error) => {
        console.log(error);
        handleRequestComplete();
      });

    // Fetch advertisers
    getAdvertisers(token, agencyId)
      .then((response) => {
        console.log(response.data);
        setAdvertisers(response.data.advertisers);

        handleRequestComplete();
      })
      .catch((error) => {
        console.log(error);
        handleRequestComplete();
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // const data =  {  advertiserId: parseInt(e.target.advertiser.value),
    //     campaignDestination: e.target.campaignDestination.value,
    //     campaignName: e.target.campaignName.value,
    //     startDate: new Date(e.target.startDate.value),
    //     endDate: new Date(e.target.endDate.value),
    //     totalBudget: 100,
    //     dailyBudget: 10,
    //     audienceId:1,
    //     status: "offline",
    //     id: parseInt(uuid),}
    const data = {
      campaignName: e.target.campaignName.value,
      id: parseInt(campaignId),
      campaignDestination: e.target.campaignDestination.value,
      audienceId: parseInt(e.target.audience.value),
      advertiserId: parseInt(e.target.advertiser.value),
      totalBudget: parseFloat(e.target.budget.value),
      dailyBudget: 10.0,
      status: e.target.status.value,
      expectedImpressions: parseInt(campaignDetails.expectedImpressions),
      startDate: new Date(e.target.startDate.value),
      endDate: new Date(e.target.endDate.value),
    };
    console.log(data);
    editCampaigns(token, data, campaignId)
      .then((response) => {
        setLoading(false);
        console.log(response);
        toast.success("Saved Successfully");
        navigate("/campaigns");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
        console.log(error);
      });
  };

  return  isLoading?<Loader/>: advertisers&& (
    <div>
      <div></div>
      <div className="bg-white p-10 rounded-md border-border border">
       
        
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-3 w-full gap-4">
                <div className="space-y-1">
                  <label className=" text-sm">Campaign Name</label>
                  <input
                    className="form-style"
                    placeholder="Campaign Name"
                    name="campaignName"
                    defaultValue={campaignDetails.name}
                    variant="outlined"
                  />
                </div>
                <div className="space-y-1">
                  <label className="pb-2 text-sm">Select advertiser</label>
                  <select name="advertiser" className="form-style">
                    {advertisers.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.company}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="space-y-1">
                  <label className="pb-2 text-sm">Compaign Destination</label>
                  <input
                    placeholder="Campaign Destination"
                    name="campaignDestination"
                    className="form-style"
                    defaultValue={campaignDetails.campaignDestination}
                    variant="outlined"
                  />
                </div>
                <div className="space-y-1">
                  <label className="pb-2 text-sm">Start Date</label>
                  <input
                    placeholder="Start Date"
                    name="startDate"
                    type="date"
                    className="form-style"
                    defaultValue={moment(campaignDetails.activate_time).format("yyy-MM-DD")}
                    labelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </div>
                <div className="space-y-1">
                  <label className="pb-2 text-sm">End Date</label>
                  <input
                    placeholder="End Date"
                    name="endDate"
                    type="date"
                    className="form-style"
                    defaultValue={moment(campaignDetails.expire_time).format("yyy-MM-DD")}
                    labelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </div>
                <div className="space-y-1">
                  <label className="pb-2 text-sm">Audience</label>
                  <select
                    name="audience"
                    className="form-style"
                    defaultValue={campaignDetails.target}
                  >
                    {audiences.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label className="pb-2 text-sm">Budget</label>
                  <input
                    placeholder="Budget"
                    name="budget"
                    type="number"
                    className="form-style"
                    defaultValue={parseFloat(campaignDetails.total_budget).toFixed(1)}
                    variant="outlined"
                  />
                </div>

                <div className="space-y-1">
                  <label className="pb-2 text-sm">Status</label>
                  <select
                    name="status"
                    className="form-style"
                    defaultValue={campaignDetails.status}
                  >
                    <option value="offline">offline</option>
                    <option value="running">running</option>
                  </select>
                </div>
              </div>
              <button
                variant="contained"
                color="primary"
                className="py-3 font-medium px-4 rounded-md bg-primary text-white text-sm mt-8 w-40  flex justify-center items-center"
                type="submit"
              >
                {loading ? <Spinner /> : "Save Changes"}
              </button>
            </form>
          
        
      </div>
    </div>
  );
};

export default EditCampaignForm;
