import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  deleteSspAdvertiserApi,
  fetchAdvertiserByAgencyId,
} from "../../../../api/ssp-api";
import DspAdvertiserTable from "./tables/dsp-table";
import {
  deleteAdvertiserApi,
  getAdvertisersDetailsApi,
} from "../../../../api/api";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../../../provider/AuthContext";
import Loader from "../../../../components/loader";

export default function AgencyTabs() {
  const [value, setValue] = React.useState("1");
  const [loading, setLoading] = useState(true);
  const [advertisers, setAdvertisers] = useState([]);
  const [dspAdvertisers, setDspAdvertisers] = useState([]);
  const navigate = useNavigate();
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId, accountId } = JSON.parse(userDetails);

  useEffect(() => {
    setLoading(true);
    const fetchAdvertiserPromise = fetchAdvertiserByAgencyId(
      agencyId,
      base64Credentials,
    )
      .then((response) => {
        setAdvertisers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    const getAdvertisersDetailsPromise = getAdvertisersDetailsApi(
      token,
      agencyId,
    )
      .then((response) => {
        setDspAdvertisers(response.data.advertisers);
      })
      .catch((error) => {
        console.log(error);
      });

    Promise.all([fetchAdvertiserPromise, getAdvertisersDetailsPromise]).then(
      () => {
        setLoading(false);
      },
    );
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteDspAdvertiser = (dspId, sspId) => {
    setLoading(true);
    deleteAdvertiserApi(dspId)
      .then((response) => {
        deleteSspAdvertiserApi(sspId)
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });
        // setDspAdvertisers(dspAdvertisers.filter((advertiser) => advertiser.id !== dspId));
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
    navigate("/agency/advertiser");
  };
  const deleteSspAdvertiser = (id) => {
    deleteSspAdvertiserApi(id)
      .then((response) => {
        console.log(response);
        navigate("/agency/advertiser");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (dspAdvertisers === undefined || dspAdvertisers.length === 0) {
    return <p>No advertisers found</p>;
  }

  return (
    <div container spacing={3} style={{ marginTop: "0px" }}>
      <div item xs={12}>
        {loading ? (
          <Loader/>
        ) : (
          <DspAdvertiserTable
            data={dspAdvertisers}
            deleteDspAdvertiser={deleteDspAdvertiser}
          />
        )}
      </div>
    </div>
  );
}
