import { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import CustomButton from "../../components/customButton";
import {
  addGig,
  addGigPost,
  addInfluencer,
  getGigPosts,
  getGigs,
} from "../../api/api";
import CustomModal from "../../components/custom-modal";
import { useParams } from "react-router-dom";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import NoData from "../../components/noData";
import ModifyButton from "../../components/modify-button";

const GIgPosts = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);
  const { setActions, setCurrentPage } = useContext(BreadcrumbContext);
  const [uploading, setUploading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { gigId } = useParams();

  useEffect(() => {
    getGigPosts(gigId).then((response) => {
      console.log(response);
      setData(response.data.data);
    });
  }, []);

  useEffect(() => {
    setCurrentPage("Gig Posts");
    setActions(
      <CustomButton
        onClick={() => {
          setShowCreateModal(true);
        }}
        text={"New Post"}
      />
    );
  }, []);
  return (
    <div>
      <CustomModal
        heading={"Gig Post"}
        content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setUploading(true);
              let formData = new FormData();
              formData.append("title", e.target.name.value);
              formData.append("description", "");
              formData.append("image", e.target.file.files[0]);
              addGigPost(formData, gigId).then((res) => {
                setUploading(false);
              });
            }}
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label>Select an image</label>
                <input name="file" className="form-style" type="file" />
              </div>
              <div>
                <label>Select platform</label>
                <select name="platform" className="form-style">
                  {["instagram", "Facebook", "Twitter", "instagram"].map(
                    (item) => {
                      return <option value={item}>{item}</option>;
                    }
                  )}
                </select>
              </div>
            </div>
            <div className="mt-5">
              <CustomButton loading={uploading} text={"Add Post"} />
            </div>
          </form>
        }
        setShow={setShowCreateModal}
        show={showCreateModal}
      />
      {data.length < 1 ? (
        <NoData />
      ) : (
        <div>
          <div className="bg-white px-5 py-5 w-full rounded-md border border-border ">
            <div className="grid grid-cols-4 gap-6 justify-center">
              {data.map((item) => {
                return (
                  <div className=" border border-border p-5 rounded-md">
                    <div className="flex justify-between items-center">
                      <h1 className="font-bold mb-2">{item.title}</h1>
                      <CustomButton
                        background={
                          "bg-primary bg-opacity-20 hover:bg-opacity-30"
                        }
                        textColor={"text-primary"}
                        text={"Report"}
                      />
                    </div>

                    <div className="flex justify-center mt-2">
                      <img className="" src={item.image_url} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GIgPosts;
