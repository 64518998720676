import React, { useState } from "react";

import PropTypes from "prop-types";

import ClearInvoices from "./clear-invoice";


const BillingActions = ({ invoiceId, history }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clearInvoiceOpen, setClearInvoice] = useState(false);
  const open = Boolean(anchorEl);
  const handlePopOverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopOverClose = () => {
    setAnchorEl(null);
  };
  const onClickClearInvoiceButton = () => {
    setAnchorEl(null);
    setClearInvoice(!clearInvoiceOpen);
  };
  const redirectToEdit = () => {
    navigate(`/generate-invoice/invoice-type/${invoiceId}`);
  };
  const classes = useStyles();
  const popoverProps = {
    className: classes.root,
    classes: {
      root: classes.root,
      paper: classes.paper,
    },
    open,
    anchorEl,
    anchorOrigin: {
      vertical: "center",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: -20,
      horizontal: 100,
    },
    onClose: handlePopOverClose,
  };
  return (
    <>
      <IconButton onClick={handlePopOverOpen}>
        <MoreHoriz />
      </IconButton>
      <Popover {...popoverProps}>
        <div className={classes.content}>
          <Button className={classes.contentButton} onClick={redirectToEdit}>
            Edit
          </Button>
          <Button className={classes.contentButton}>Download</Button>
          <Button
            className={classes.contentButton}
            onClick={onClickClearInvoiceButton}
          >
            Clear Invoice
          </Button>
        </div>
      </Popover>
      <ClearInvoices
        open={clearInvoiceOpen}
        onClose={onClickClearInvoiceButton}
      />
    </>
  );
};

BillingActions.propTypes = {
  invoiceId: PropTypes.number.isRequired,
};

export default BillingActions;
