import { Component } from "react";

import { headers } from "./constant";
import FilterMenu from "../../components/menu";
import TableLoader from "../../components/table-loader";
import TableHeader from "../../components/table-header";
import DownloadReport from "../../components/download-report";
import BillingEmptyState from "./empty-states";
import BillingActions from "./billing-actions";
import TablePagination from "../../components/table-pagination";
import { useNavigate } from "react-router-dom";

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customActive: false,
    };
  }

  componentDidMount() {
    this.props.getInvoices();
  }
 
  render() {
    const { classes, isLoading, activeInvoices, page, pageCount,navigate } = this.props;

    return (
      <div className="w-full">
<div header={"Billing and Invoices"}>
          {!activeInvoices.length > 0 && !isLoading ? (
            <BillingEmptyState navigate={navigate} />
          ) : (
            <>
             <div className="flex justify-end">
        <div
           className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer"
           onClick={()=>{
            navigate("/generate-invoice/invoice-type");
          }}
          >
            Add Invoice
          </div>
        </div>
        
             <div className="bg-white px-5 py-5 w-full rounded-md mt-4">
     <div className="flex justify-between">
     <select className="border border-border rounded-md py-2 text-sm focus:border-primary focus:ring-primary">
          <option value="Lifetime">Lifetime</option>
          <option value="Lifetime">Past Month</option>
          <option value="Lifetime">Custom</option>
        </select>
     <div className="flex justify-end items-center space-x-2 text-white text-sm">
        <div>
        <button className="bg-primary py-1 px-3 rounded">CVS (0)</button>
        </div>
        <input className="border border-border rounded-md py-2 text-sm focus:border-primary focus:ring-primary" placeholder="Search here"/>
      </div>
     </div>
<div className="mt-4">
<table className="text-sm table w-full">
            <thead className="text-muted">
              <tr className="">
                <th className="py-3 text-sm text-start">Client</th>
                <th className="py-3 text-sm text-start">Invoice Title</th>
                <th className="text-sm text-start py-3">Invoice Type</th>
                <th className="text-sm text-start py-3">Kwanza Campaign</th>
                <th className="text-sm text-start py-3">Spent</th>
                <th className="text-sm text-start py-3">Status</th>
                <th className="text-sm text-start py-3"></th>


              </tr>
            </thead>
            <tbody className="text-start">
            {activeInvoices
                .map((item) => (
                  <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                  <td className="text-start py-3 px-0 ">{item.company}</td>
                  <td className="text-start py-3 px-0 ">{item.title}</td>
                  <td className="text-start py-3 px-0 ">{item.invoiceType}</td>
                  <td className="text-start py-3 px-0">{item.campaignName}</td>
                  <td className="text-start py-3 px-0">$ {item.spent}</td>
                  <td className="text-start py-3 px-0">{item.paymentStatus}</td>
                  <td className="text-start px-0 py-3"><button className="font-bold text-white bg-primary py-1 rounded-md px-2" onClick={() => viewCampaign(id)}>View</button></td>
                </tr>
                ))}
            </tbody>
          </table>
          {/* <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
            <h1 className=" translate-x-5">Rows per page</h1>
            <select value={rowsPerPage} onChange={(e)=>{
              setRowsPerPage(e.target.value)
            }} className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm">
              <option className="" value={5}>5</option>
             <option className="" value={8}>8</option>
            </select>
            <div onClick={()=>{
              if(page>0){
                setPage(page-1)
              }
            }} className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer">
            <MdOutlineChevronLeft />
            </div>
            <h1>{page+1} out of {Math.ceil(activeCampaigns.length/rowsPerPage)}</h1>
            <div onClick={()=>{
              if( page< Math.round(activeCampaigns.length/rowsPerPage)){
                setPage(page+1)
              }
               
            }} className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center">
            <MdOutlineChevronRight />
            </div>
          </div> */}
</div>
     </div>
            
            </>
          )}
        </div>
      </div>
        
    
    );
  }
}

function mapStateToProps(state) {
  const { activeInvoices, isLoading, page, pageCount } = state.invoices;
  return {
    activeInvoices,
    isLoading,
    page,
    pageCount,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getInvoices: getInvoices,
      setPageNumber: setPageNumber,
    },
    dispatch,
  );
}
const BillingWithStyles = Billing;

const BillingWrapper = (props) => {
  const navigate = useNavigate();
  return <BillingWithStyles {...props} navigate={navigate} />;
};
export default BillingWrapper;
