import React, { useState } from "react";

import { MdOutlineChevronRight, MdOutlineChevronLeft } from "react-icons/md";

const CampaignTable = ({ campaigns, handleView }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [searchText, setSearchText] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredCampaigns = campaigns.filter((campaign) =>
    campaign.campaign.toLowerCase().includes(searchText.toLowerCase())
  );

  const onView = (campaignId) => {
    handleView(campaignId);
  };

  return (
    <div>
      <div className="bg-white px-5 py-5 w-full rounded-md mt-6 border border-border">
        <div className="flex justify-end">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            className="text-sm border-border rounded-md focus:border-primary focus:ring-primary"
            placeholder="Search here..."
          />
        </div>
        <table className="text-sm table w-full">
          <thead className="text-muted">
            <tr className="">
              <th className="py-3 text-sm text-start">Campaign Name</th>
              <th className="text-sm text-start py-3">Total impressions</th>
              <th className="text-sm text-start py-3">Total clicks</th>
              <th className="text-sm text-start py-3">Total CTR</th>
              <th className="text-sm text-start py-3">Total Spend</th>
              <th className="text-sm text-start py-3">Budget</th>
              <th className="text-sm text-start py-3">Status</th>
              <th className="text-sm text-start py-3">Start Date</th>
              <th className="text-sm text-start py-3">End Date</th>
              <th className="text-sm text-start py-3"></th>
            </tr>
          </thead>
          <tbody className="text-start">
            {filteredCampaigns
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                  <td className="text-start py-3 px-0 ">{item.campaign}</td>
                  <td className="text-start py-3 px-0 ">
                    {item.totalImpressions}
                  </td>
                  <td className="text-start py-3 px-0 ">{item.totalClicks}</td>
                  <td className="text-start py-3 px-0 ">{item.totalCTR}</td>
                  <td className="text-start py-3 px-0 ">
                    {item.totalSpend.toFixed(2)}
                  </td>
                  <td className="text-start py-3 px-0 ">
                    {Number(item.budget)}
                  </td>
                  <td className="text-start py-3 px-0 ">
                    {item.status === "runnable" ? (
                      <div>Active</div>
                    ) : (
                      <div>Paused</div>
                    )}
                  </td>
                  <td className="text-start py-3 px-0 ">{item.startDate}</td>
                  <td className="text-start py-3 px-0 ">{item.endDate}</td>
                  <td className="text-start px-0 py-3">
                    <button
                      className="font-bold text-white bg-primary py-1 rounded-md px-2"
                      onClick={() => onView(item.id)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
          <h1 className=" translate-x-5">Rows per page</h1>
          <select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(e.target.value);
            }}
            className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
          >
            <option className="" value={5}>
              5
            </option>
            <option className="" value={8}>
              8
            </option>
          </select>
          <div
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
          >
            <MdOutlineChevronLeft />
          </div>
          <h1>
            {page + 1} out of{" "}
            {Math.ceil(filteredCampaigns.length / rowsPerPage)}
          </h1>
          <div
            onClick={() => {
              if (page < Math.round(filteredCampaigns.length / rowsPerPage)) {
                setPage(page + 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
          >
            <MdOutlineChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignTable;
