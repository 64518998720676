const Loader = () => {
  return (
    <div className="fixed w-full h-[50vh]">
      <div className=" md:w-[75%] 2xl:w-[85%] h-full flex justify-center items-center">
        <div className="border-4 border-t-transparent rounded-full size-12 border-primary animate-spin"></div>
      </div>
    </div>
  );
};

export default Loader;
