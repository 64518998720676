import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { BsCloudArrowUp } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoAddOutline } from "react-icons/io5";

function ImageDropzone({ onImageUpload,setSelectedImages,selectedImages }) {
 
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        console.log(file)
        reader.onload = (e) => {
          const base64String = e.target.result;
          const image = new Image();
          image.src = base64String;

          image.onload = () => {
            const dimensions = { width: image.width, height: image.height };
            console.log(dimensions)
            const imageInfo = {
              file,
              dimensions,
              base64: base64String,
            };
            setSelectedImages((prevImages) => [...prevImages, imageInfo]);
            onImageUpload(imageInfo);
          };
        };

        reader.readAsDataURL(file);
      });
    },
    [onImageUpload],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg",
    multiple: true,
  });

  return (
    <div className="border border-gray-400 border-dashed rounded-md p-6 flex flex-col items-center justify-center">
      <div {...getRootProps()} >
        <input {...getInputProps()} />
          <div className="flex flex-col justify-between  items-center text-center space-y-3">
            <IoAddOutline className="size-12 text-primary"/>
            <p className="text-sm">Drag &amp; drop images here, or click to select files</p>
          </div>
      </div>
    </div>
  );
}

export default ImageDropzone;
