export const styles = {
  root: {
    width: "100%",
    height: "100%",
  },
  filter: {
    marginTop: "1rem",
    paddingRight: "2rem",
  },
  desc: {
    fontSize: "0.7rem",
    width: "89%",
  },
  status: {
    color: "#6EBF4A",
    textTransform: "capitalize",
    fontSize: "0.7rem",
  },
  edit: {
    color: "#5E6366",
    fontSize: "0.7rem",
  },
  dot: {
    fontSize: "1rem",
  },
  campaignName: {
    marginBottom: "0.4rem",
  },
  tableRow: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F7F9FA",
    },

    "& .MuiTableCell-root": {
      borderBottom: "none",
      color: "#4B4B4B",
    },
  },
  resume: {
    textTransform: "none",
    color: "#6EBF4A",
  },
  delete: {
    textTransform: "none",
    color: "#ec1906",
    variant: "outlined",
  },
  pause: {
    textTransform: "none",
    color: "#ED6A5E",
  },
  createCampaign: {
    backgroundColor: "#6EBF4A",
    color: "#FFF",
    "&:hover,&:focus": {
      backgroundColor: "#6EBF4A",
    },
    borderRadius: "99px",
    textTransform: "lowercase",
    width: "34%",
    height: "2.5rem",
  },
  addIcon: {
    marginRight: "0.5rem",
  },
  offline: {
    color: "#FDC33F",
    textTransform: "capitalize",
  },
  customize: {
    textTransform: "none",
    fontSize: "0.7rem",
    color: "#979797",
  },
};
