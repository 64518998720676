import React, { createContext, useContext, useState } from "react";

const InvoiceContext = createContext();

export function useInvoiceData() {
    return useContext(InvoiceContext);
}

export function InvoiceProvider({ children }) {
    const [data, setData] = useState([]);

    const addData = (newData) => {
        setData((prevData) => [...prevData, newData]);
    };

    return (
        <InvoiceContext.Provider value={{ data, addData }}>
            {children}
        </InvoiceContext.Provider>
    );
}
