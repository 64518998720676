export const headers = [
  {
    label: "Client",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
    sortKey: "campaign",
  },
  {
    label: "Invoice Title",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
  {
    label: "Invoice Type",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
  {
    label: "Kwanza Campaign",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
  {
    label: "Spent",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
  {
    label: "Status",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },

  {
    label: "Action",
    isSorted: false,
    sort: "asc",
    isTooltip: false,
    tooltip: "Add",
  },
];
