import React, { useState } from "react";

import AgencyPublisherTable from "../agency-module/ssp/websites/websites-table";
import AgencyCampaignsTable from "./agency-campaigns-table";
import Loader from "../../components/loader";

export const ViewAgencyDetails = ({
  data,
  publisherData,
  campaignData,
  campaignLoading,
}) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTable = (items) => {
    // Check if items is an array and not empty
    if (Array.isArray(items) && items.length > 0) {
      return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* Generate table headers based on keys of the first item */}
                {Object.keys(items[0]).map((key) => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  {Object.values(item).map((val, idx) => (
                    <TableCell key={idx}>{val}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      // Render a message if there is no data
      return <p>No data available</p>;
    }
  };
  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Advertisers" value="1" />
          <Tab label="Users" value="2" />
          <Tab label="Websites" value="3" />
          <Tab label="Campaigns" value="4" />
        </Tabs>
      </Box>
      <TabPanel value="1">
        {data.advertisers ? (
          renderTable(data.advertisers)
        ) : (
          <p>No advertisers data</p>
        )}
      </TabPanel>
      <TabPanel value="2">
        {data.users ? renderTable(data.users) : <p>No users data</p>}
      </TabPanel>
      <TabPanel value="3">
        {publisherData ? (
          <AgencyPublisherTable data={publisherData} />
        ) : (
          <p>No websites data</p>
        )}
      </TabPanel>
      <TabPanel value="4">
        {campaignLoading || campaignData === undefined ? (
          <Loader/>
        ) : (
          <AgencyCampaignsTable data={campaignData} />
        )}
      </TabPanel>
    </TabContext>
  );
};
export default ViewAgencyDetails;
