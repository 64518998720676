import Spinner from "./spinner";

const CustomButton = ({
  onClick,
  text,
  className,
  loading,
  background,
  textColor,
}) => {
  return (
    <button
      type="submit"
      className={`${background ?? "bg-primary"} ${textColor ?? "text-white"} flex justify-center rounded-md hover:bg-opacity-90 min-w-32 transition-all duration-200 cursor-pointer py-2 font-semibold ${className}`}
      onClick={onClick}
    >
      {loading ? <Spinner /> : text}
    </button>
  );
};

export default CustomButton;
