import AgencyWrapper from "../../../../../components/wrappers/agency-wrapper";
import { useContext, useEffect, useState } from "react";
import { fetchSspBanner } from "../../../../../api/api";
import { AuthContext } from "../../../../../provider/AuthContext";
import Loader from "../../../../../components/loader";

export default function AgencyEditBanner() {
  const id = window.location.pathname.split("/").pop();
  const [banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);

  useEffect(() => {
    fetchSspBanner(id, token)
      .then((response) => {
        if (response.status === 200) {
          console.log(`Banner: ${JSON.stringify(response.data.data)}`);
          setBanner(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div header="Edit Banner">
      <div container spacing={2} >
        {loading ? (
          <div item xs={12}>
            <Loader/>
          </div>
        ) : (
          <div
            className="bg-white p-10 border border-border"
          >
            <div
            
              justify-content="flex-start"
              align-items="flex-start"
            >
              {banner.type === "image" ? (
                <img
                  src={banner.url}
                  alt={banner.name}
                  width={banner.width}
                  height={banner.height}
                />
              ) : (
                <iframe
                  src={banner.url}
                  title={banner.name}
                  width={banner.width}
                  height={banner.height}
                />
              )}
            </div>
            <div className="grid grid-cols-2 gap-4">
            <div
              item
              container
              justify-content="flex-start"
              align-items="flex-start"
              direction="row"
              spacing={2}
            >
              <label className="text-sm">Name</label>
              <input
                className="form-style"
                id="name"
                label="Name"
                value={banner.name}
              />
            </div>
            <div
              item
              container
              justify-content="flex-start"
              align-items="flex-start"
              direction="row"
              spacing={2}
            >
              <label className="text-sm">Destination Url</label>
              <input
                className="form-style"
                id="destination"
                label="Destination Url"
                value={banner.destination_url}
              />
            </div>
            <div
              item
              container
              justify-content="space-evenly"
              align-items="flex-start"
              direction="row"
              spacing={2}
            >
              <label className="text-sm">Width</label>
              <input
                className="form-style"
                id="width"
                label="Width"
                value={banner.width}
                style={{ marginRight: "10px" }}
              />
             
            </div>
            <div className="space-y-1">
            <label className="text-sm">Height</label>
            <input
                className="form-style"
                id="height"
                label="height"
                value={banner.height}
              />
            </div>
            </div>
            
          </div>
        )}
      </div>
    </div>
  );
}
