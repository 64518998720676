import React, { createContext, useContext, useState } from "react";

const AudienceContext = createContext();

export function useAudienceData() {
    return useContext(AudienceContext);
}

export function AudienceProvider({ children }) {
    const [audienceData, setData] = useState([]);

    const addData = (newData) => {
        setData((prevData) => [...prevData, newData]);
    };

    return (
        <AudienceContext.Provider value={{ audienceData, addData }}>
            {children}
        </AudienceContext.Provider>
    );
}
