
import { useContext, useEffect, useState } from "react";

import {
  addCampaign,
  editCampaign,
  fetchCampaignById,
} from "../../../../../../api/ssp-api";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment";
import Spinner from "../../../../../../components/spinner";
import { StepsContext } from "../create-campaign";
import * as yup from "yup";
import { Formik, formik } from "formik";
import { values } from "lodash";
export default function CampaignSettings({
  campaignDetails,
  credentials,
  setSavedCampaign,
  handleComplete,
}) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState(null);
  const { activeStep, setActiveStep } = useContext(StepsContext);
  const [selectedModal, setSelectedModal] = useState(1);
  const isPremium = JSON.parse(localStorage.getItem("isPremium"));

  const handleSave = (values) => {

    const campaign = {
      startDate: new Date(values.startDate),
      endDate: new Date(values.endDate),
      revenueType: values.revenueType,
      impressions: -1,
      clicks: -1,
      weight: values.weight,
      revenue: values.revenue,
    };
    setLoading(true);
    setDisabled(true);
    console.log("Save campaign", campaign);
    editCampaign(uuid, campaign, credentials).then((data) => {
      toast.success("Changes saved", {
        position: "top-center",
      });
      handleComplete();
    });
  };
  const { uuid } = useParams();
  useEffect(() => {
    fetchCampaignById(uuid, credentials).then((response) => {
      console.log(response.data);
      setData(response.data);
    });
  }, []);
  {isPremium ? (
    selectedModal == 1 ? (
      <label className="text-primary mt-2 font-bold text0sm">
        Minimum value is $2
      </label>
    ) : (
      <label className="text-primary mt-2 font-bold text0sm">
        Minimum Price is $0.5
      </label>
    )
  ) : selectedModal == 1 ? (
    <label className="text-primary mt-2 font-bold text0sm">
      Minimum value is $1.5
    </label>
  ) : (
    <label className="text-primary mt-2 font-bold text0sm">
      Minimum Price is $0.5
    </label>
  )}
  return data&&(
    <Formik
      validationSchema={yup.object({
        startDate: yup.date().required("Start Date is required"),
        //End date must be after start date
        endDate: yup.date().required("End Date is required").min(yup.ref('startDate'),"End date must be after start date"),
        revenueType: yup.number("Select Pricing Model").required("Revenue Type is required"),
        weight: yup.number().required("Weight is required"),
        revenue: yup.number().required("Revenue is required").min(1000,'Minimum value is $1000'),
      })}
      onSubmit={handleSave}
      initialValues={{ startDate:moment(data.startDate).format("yyy-MM-DD"), endDate: moment(data.endDate).format("yyy-MM-DD"), revenueType : data.revenueType, weight : data.weight , revenue:data.revenue }}
    >
      {({values, handleChange, handleSubmit,errors, touched}) =>(
      <form
        onSubmit={handleSubmit}
        container
        spacing={4}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div className="p-6 bg-secondary bg-opacity-10 border border-border text-sm rounded">
          <p variant="body2" color="inherit" align="left">
            <b>CPM:</b> Cost per mille (CPM), also called cost per thousand
            impressions, is a marketing term used to denote the price of 1,000
            advertisement impressions on one webpage.
          </p>
          <p variant="body2" color="inherit" align="left">
            <b>CPC:</b> Cost per click (CPC) is a paid advertising term where an
            advertiser pays a cost to a publisher for every click on an ad.
          </p>
          <p variant="body2" color="inherit" align="left">
            <b>Override:</b> Override campaigns are a special campaign type
            specifically to override (i.e. take priority over) Remnant and
            Contract campaigns.
          </p>

          <p variant="body2" color="inherit" align="left">
            <b>Priority:</b> Priority over other campaigns. The higher the
            number, the higher the priority. (Only used in Contract campaigns)
          </p>
        </div>
        <div item xs={6}>
          <div item>
            <ToastContainer />
          </div>
          <div className="grid grid-cols-2 gap-4 mt-6">
            <div item>
              <label className="text-sm">Start Date*</label>
              <input
                type="date"
                name="startDate"
                className="form-style"
                value={values.startDate}
                onChange={handleChange}
                
              />
              {touched.startDate&&errors.startDate && <label className="text-sm text-red-500">{errors.startDate}</label>}
            </div>
            <div item>
              <label className="text-sm">End Date*</label>
              <input
                type="date"
                name="endDate"
                value={values.endDate}
                onChange={handleChange}
                defaultValue={moment(data.endDate).format("yyy-MM-DD")}
                className="form-style"
                renderInput={(params) => <input {...params} />}
              />

              {touched.endDate&&errors.endDate && <label className="text-sm text-red-500">{errors.endDate}</label>}
            </div>

            <div item xs={6}>
              <label className="text-sm">Pricing Model*</label>
            
                <select
                  name="revenueType"
                  className="form-style"
                  value={values.revenueType}
                  onChange={handleChange}
                >
                  <option>Select pricing Model</option>
                  <option value={1}>CPM</option>
                  <option value={2}>CPC</option>
                </select>
              {touched.revenueType&&errors.revenueType && <label className="text-sm text-red-500">{errors.revenueType}</label>}
            </div>
            <div item xs={5}>
              <label className="text-sm">Budget (in dollars)</label>
              
              <input
                className="form-style"
                name="revenue"
                value={values.revenue}
                onChange={handleChange}
                type="number"
              ></input>
              {touched.revenue&&errors.revenue && <label className="text-sm text-red-500">{errors.revenue}</label>}
              
            </div>

            {/* <div item xs={6}>
            <label className="text-sm">Impressions*</label>
            <input
              fullWidth
              className="form-style"
              name="impressions"
              defaultValue={data.impressions}
              type="number"
              
            ></input>
          </div>
          <div item xs={5}>
            <label className="text-sm">Clicks*</label>
            <input
              fullWidth
              className="form-style"
              name="clicks"
              // label="Clicks"
              defaultValue={data.clicks}
              type="number"
              
            ></input>
          </div> */}

            <div item xs={3}>
              <label className="text-sm">Campaign Weight*</label>
              <input
                fullWidth
                className="form-style"
                name="weight"
                value={values.weight}
                onChange={handleChange}
                type="number"
              ></input>
              {touched.weight&&errors.weight && <label className="text-sm text-red-500">{errors.weight}</label>}
            </div>
          </div>

          <div className="flex justify-between mt-5">
            <div className="flex space-x-3">
              <button
                className="py-2  rounded-md  text-textColor  justify-center flex hover:text-primary transition-all duration-200"
                onClick={() => {
                  setActiveStep(activeStep - 1);
                }}
                type="submit"
              >
                Back
              </button>
              <button
                className="py-2 px-3 rounded-md bg-primary  text-white w-40 justify-center flex focus:scale-105 transition-all duration-150"
                type="submit"
              >
                {loading ? <Spinner /> : "Save Changes"}
              </button>
            </div>

            {/* <button
              className="py-2 px-3 rounded-md  text-textColor  w-24 justify-center flex hover:text-primary transition-all duration-200"
              onClick={() => {
                setActiveStep(activeStep + 1);
              }}
              type="submit"
            >
              Skip
            </button> */}
          </div>
        </div>
      </form>
      ) }
    </Formik>
  );
}
