import * as React from "react";

export default function SitesDataTable(props) {
  console.log("check here", props.data);
  const columns = [
    { field: "app_name", headerName: "App Name", width: 500 },
    { field: "publisher_name", headerName: "Publisher", width: 200 },
    { field: "impressions", headerName: "impressions", width: 150 },
    {
      field: "clicks",
      headerName: "clicks",
      type: "number",
      width: 110,
    },
  ];

  const rows = props.data;

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(row) => row.app_name}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
