import { useState } from "react";

import { ReactComponent as ProfileIcon } from "../../../assets/profile.svg";
import { logoutAPI } from "../../../api/api";




const Profile = ({ history }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onSignout = () => {
    const user = localStorage.getItem("kwanza");
    const { token } = user ? JSON.parse(user) : {};
    logoutAPI(token)
      .then((_) => {
        navigate("/login");
        localStorage.removeItem("kwanza");
      })
      .catch(() => {
        navigate("/login");
        localStorage.removeItem("kwanza");
      });
  };

  return (
    <>
      <div className={classes.profile}>
        <IconButton onClick={handleClick}>
          {" "}
          <ProfileIcon />
        </IconButton>
      </div>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 100,
        }}
        classes={{
          root: classes.popover,
        }}
      >
        <div className={classes.menu}>
          <Button onClick={onSignout} className={classes.menuItem}>
            Logout
          </Button>
        </div>
      </Popover>
    </>
  );
};
export default Profile;
