import React, { useContext, useEffect, useState } from "react";

import { styles } from "./style";
import CheckBoxList from "./list";
import {
  createCampaignApi,
  editCampaigns,
  getCampaignById,
  getTargets,
} from "../../../api/api";
import { Redirect, useNavigate, useParams, withRouter } from "react-router-dom";
import SubmitButtons from "../submit_buttons";

import { useCampaignData } from "../../../provider/DataContext";
import { useAudienceData } from "../../../provider/AudienceContext";
import { AuthContext } from "../../../provider/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/spinner";
import { CampaignContext } from "../campaign-create/create-campaign-tabs";
import Loader from "../../../components/loader";
import { BsPlus } from "react-icons/bs";
import CustomModal from "../../../components/custom-modal";
import NewAdvertiser from "../../agency-module/ssp/advertiser/new-advertiser";
import NewAudience from "../../audience/new-audience";

const CampaignAudience = ({ classes }) => {
  const navigate = useNavigate();
  const { campaignId } = useParams();

  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);
  const [audiences, setAudiences] = useState([]);
  const { data } = useCampaignData();
  const { addData } = useAudienceData();
  const [newData, setNewData] = useState("");
  const { userDetails } = useContext(AuthContext);
  const { token } = JSON.parse(userDetails);
  const [campaign, setCampaign] = useState(null);
  const { uuid } = useParams();
  const { activeStep, setActiveStep } = useContext(CampaignContext);
  const [showAudienceCreationModal, setShowAudienceCreationModal] =
    useState(false);

  useEffect(() => {
    getCampaignById(token, uuid).then((response) => {
      console.log(response.data);
      setCampaign(response.data);
    });
  }, []);

  useEffect(() => {
    if (showAudienceCreationModal == false) {
      getTargets(token)
        .then((response) => {
          setAudiences(response.data || []);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [campaignId, showAudienceCreationModal]);

  const onCheck = (selected) => {
    setSelected(selected);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (selected) {
      setIsLoading(true);
      const audienceData = {
        audienceId: selected,
      };
      addData(audienceData);
      setNewData("");

      const campaignData = {
        advertiserId: parseInt(campaign.advertiser_id),
        campaignDestination: campaign.campaignDestination,
        campaignName: campaign.name,
        startDate: new Date(campaign.activate_time),
        endDate: new Date(campaign.expire_time),
        totalBudget: 100,
        dailyBudget: 10,
        status: "offline",
        id: parseInt(uuid),
        audienceId: parseInt(e.target.audienceId.value),
      };
      try {
        editCampaigns(token, campaignData, uuid).then((response) => {
          console.log(response);
          setActiveStep(activeStep + 1);
          toast.success("Changes Saved");
          setIsLoading(false);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    } else {
      setIsLoading(false);
      setActiveStep(activeStep + 1);
    }
  };

  // if (redirect) {
  //   return <Redirect to={`/audience/new/${campaignId}`} />;
  // }
  return (
    campaign && (
      <div header={"Set up Audience"}>
        {loading || audiences === undefined ? (
          <Loader />
        ) : (
          <div className="p-10 bg-white border border-border rounded">
            <CustomModal
              heading={"Add audience"}
              content={
                <div>
                  <NewAudience setShow={setShowAudienceCreationModal} fromCampaign={true} />
                </div>
              }
              show={showAudienceCreationModal}
              setShow={setShowAudienceCreationModal}
            />
            <ToastContainer />
            <form onSubmit={onSubmit}>
              <h1 className="font-bold text-lg">Choose the audience </h1>
              <div className=" rounded-md my-1 mb-3">
                <h1 className="text-sm text-muted">
                  Select a predefined Audience or{" "}
                  <span className="  cursor-pointer">
                    create a new Target Audience
                  </span>
                </h1>
              </div>
              <div className=" ">
                <select
                  name="audienceId"
                  defaultValue={campaign.target && campaign.target.id}
                  onChange={(e) => {
                    onCheck(e.target.value);
                  }}
                  className="form-style"
                >
                  {audiences.map(({ id, name }) => (
                    <option value={id}>{name}</option>
                  ))}
                </select>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAudienceCreationModal(true);
                  }}
                  className=" py-2 mt-2 cursor-pointer rounded-md border-slate-300  text-primary font-bold flex items-center"
                >
                  <BsPlus className="text-3xl" />
                  Add New Audience
                </div>
              </div>

              <div className="flex justify-between mt-6">
                <div className="w-full flex justify-start space-x-2">
                  <button
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                    }}
                    className="text-textColor py-2  justify-center flex hover:text-primary transition-all duration-200"
                    type="submit"
                    style={{ marginTop: "20px" }}
                  >
                    Back
                  </button>
                  <button
                    className=" bg-primary text-white rounded-md py-2 px-4 w-40 flex justify-center"
                    type="submit"
                    style={{ marginTop: "20px" }}
                  >
                    {isLoading ? <Spinner /> : "Save & Continue"}
                  </button>
                </div>
                <button
                  className="py-2 rounded-md  text-textColor   justify-center flex hover:text-primary transition-all duration-200"
                  onClick={() => {
                    setActiveStep(activeStep + 1);
                  }}
                  type="submit"
                >
                  Skip
                </button>
              </div>

              {/* <SubmitButtons
              next={onSubmit}
              isLoading={isLoading}
              redirectUrl={`/create-campaign/information`}
            /> */}
            </form>
          </div>
        )}
      </div>
    )
  );
};

export default CampaignAudience;
