
import clsx from "clsx";

import { ReactComponent as HelpIcon } from "../../../assets/help.svg";

const BudgetItem = ({
  daily,
  monthly,
  recommended,
  active,
  classes,
  index,
  selectedIndex,
  onHandleClick,
}) => {
  console.log(index === selectedIndex);
  return (
    <Paper
      className={clsx(classes.preview, {
        [classes.activeTab]: index === selectedIndex,
      })}
      container
      onClick={() => onHandleClick(index)}
    >
      {recommended && (
        <Typography className={classes.recommended}>
          Recommended for you{" "}
          <Tooltip title="recommended" arrow>
            <HelpIcon className="icon" />
          </Tooltip>
        </Typography>
      )}

      <div xs={12} container justify="space-between" alignItems="center">
        <div xs={2} className={classes.amount}>
          ${daily}
        </div>
        <div xs={7} container>
          <Typography className={classes.list}>daily average</Typography>
          <Typography className={classes.list}> • </Typography>
          <Typography className={classes.list}>
            ${monthly} monthly max
          </Typography>
        </div>

        <div xs={1} container justify="flex-end">
          <ChevronRight className={classes.iconRight} />
        </div>
      </div>
    </Paper>
  );
};

export default BudgetItem;
