import { ReactComponent as CampaignIcon } from "../../../assets/campaigns.svg";
import { ReactComponent as BannerIcon } from "../../../assets/creatives.svg";

export const navigation = [
  // {
  //   path: "/advertiser/overview",
  //   name: "Overview",
  //   icon: <OverviewIcon className="hoveredIcon" />,
  // },
  // {
  //   path: "/advertiser/campaigns",
  //   name: "DSP Campaigns",
  //   icon: <CampaignIcon className="hoveredIcon" />,
  // },
  {
    path: "/advertiser/ssp",
    name: "SSP Campaigns",
    icon: <CampaignIcon className="hoveredIcon" />,
  },

  {
    path: "/advertiser/banners",
    name: "Banners",
    icon: <BannerIcon className="hoveredIcon" />,
  },

  // {
  //   path: "/advertiser/reports",
  //   name: "Reports",
  //   icon: <PrintIcon className="hoveredIcon" />,
  // },
];
