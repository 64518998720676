
import { Component } from "react";

import NavigationButtons from "../navigation-buttons";
import { createInvoiceTypeApi } from "../../../api/api";

class InvoiceType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      invoiceId: null,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invoiceType !== this.props.invoiceType) {
      const { invoiceType, invoiceId } = this.props.invoiceType;
      this.setState({
        selectedOption: invoiceType,
        invoiceId,
      });
    }
  }

  handleButtonClick = (option) => {
    console.log(option);
    this.setState({
      selectedOption: option,
    });
  };
  onSubmitForm = () => {
    const { selectedOption } = this.state;
    if (selectedOption) {
      this.setState({ isLoading: true });
      createInvoiceTypeApi({ invoiceType: selectedOption })
        .then((res) => {
          const id = res && res.data && res.data.id;
          this.props.navigate(`/generate-invoice/company-details/${id}`);
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    }
  };

  render() {
    const { classes } = this.props;
    const { selectedOption, isLoading } = this.state;

    const options = [
      {
        label: "Kwanza Inclusive",
        value: "kwanza inclusive",
      },
      {
        label: "External Channels Only",
        value: "kwanza exclusive",
      },
    ];

    const renderTooltipContent = () => {
      return (
        <div className={classes.tooltipContainer}>
          <Typography>Lorem ipsum dolor sit amet, consectetur</Typography>
          <Typography>adipiscing elit. Dolor amet dictum ultricies</Typography>
          <Typography>blandit hac ipsum egestas. Lectus diam, sed </Typography>
          <Typography>adipiscing non porttitor consectetur sed.</Typography>
        </div>
      );
    };

    return (
      <div container justifyContent="flex-start">
        <Typography className={classes.label}>
          Select Invoice type{" "}
          {/*<span className={classes.tooltip}>*/}
          {/*  <CustomTooltip*/}
          {/*    title={renderTooltipContent()}*/}
          {/*    placement="bottom-start"*/}
          {/*  />*/}
          {/*</span>{" "}*/}
        </Typography>
        <div xs={12} container className={classes.container}>
          {options.map(({ label, value }, index) => (
            <divButton
              {...{
                title: label,
                index,
                value,
                selected: selectedOption === value,
                handleButtonClick: this.handleButtonClick,
              }}
            />
          ))}
        </div>
        <NavigationButtons
          showNextButton={true}
          showBackButton={false}
          onClickNextButton={this.onSubmitForm}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default InvoiceType;
