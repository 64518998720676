import { Component } from "react";
import { headers } from "./constant";
import FilterMenu from "../../../../components/menu";
import TableLoader from "../../../../components/table-loader";
import TableHeader from "../../../../components/table-header";
import DownloadReport from "../../../../components/download-report";
import BillingEmptyState from "./empty-states";
import BillingActions from "./billing-actions";
import TablePagination from "../../../../components/table-pagination";

class AgencyBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customActive: false,
    };
  }

  componentDidMount() {
    this.props.getInvoices();
  }

  createNewInvoice = () => {
    this.props.navigate("/generate-invoice/invoice-type");
  };

  render() {
    const { classes, isLoading, activeInvoices, page, pageCount } = this.props;

    return (
      <div container justify="flex-start">
        <div header={"Billing and Invoices"}>
          {!activeInvoices.length > 0 && !isLoading ? (
            <BillingEmptyState />
          ) : (
            <>
              <div
                xs={12}
                container
                justify="space-between"
                alignItems="center"
                className={classes.download}
              >
                <div xs={10} container>
                  <div
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div xs={3}>
                      < button
                        className={classes.newInvoice}
                        onClick={this.createNewInvoice}
                      >
                        <AddCircle className={classes.addIcon} />
                        New Invoice
                      </ button>
                    </div>
                    <div xs={6}>
                      <div
                        container
                        xs={12}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <DownloadReport
                          size={1}
                          onDownloadCsv={() => {}}
                          isLoading={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.filter}>
                  <FilterMenu
                    onFilterDateChange={this.onFilterDateChange}
                    onFilterChange={this.onFilterChange}
                    hideCustom={true}
                  />
                </div>
              </div>
              <TableHeader
                fullHeight={false}
                headers={headers}
                maxHeight={true}
              >
                {isLoading ? (
                  <TableLoader columns={8} rows={7} />
                ) : (
                  <TableBody>
                    {activeInvoices.map(
                      ({
                        company,
                        invoiceType,
                        campaignName,
                        spent,
                        paymentStatus,
                        id,
                        title,
                      }) => (
                        <TableRow className={classes.tableRow}>
                          <TableCell>{company || "-"}</TableCell>
                          <TableCell>{title || "-"}</TableCell>
                          <TableCell>{invoiceType}</TableCell>
                          <TableCell>{campaignName || "-"}</TableCell>
                          <TableCell>$ {spent}</TableCell>
                          <TableCell>{paymentStatus}</TableCell>
                          <TableCell>
                            <BillingActions invoiceId={id} />
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                )}
              </TableHeader>
              <TablePagination
                page={page}
                pageCount={pageCount}
                setPageNumber={this.props.setPageNumber}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { activeInvoices, isLoading, page, pageCount } = state.invoices;
  return {
    activeInvoices,
    isLoading,
    page,
    pageCount,
  };
}



export default AgencyBilling;
