export const styles = {
  root: {
    width: "100%",
    height: "100%",
    paddingTop: "2rem",
  },
  tableRow: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F7F9FA",
    },

    "& .MuiTableCell-root": {
      borderBottom: "none",
      color: "#4B4B4B",
    },
  },

  createCampaign: {
    backgroundColor: "#6EBF4A",
    color: "#FFF",
    "&:hover,&:focus": {
      backgroundColor: "#6EBF4A",
    },
    borderRadius: "99px",
    textTransform: "lowercase",
    width: "18%",
    height: "2.5rem",
    marginBottom: "0.8rem",
  },
  addIcon: {
    marginRight: "0.5rem",
  },
};
