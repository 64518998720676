import React, { useContext, useEffect, useState } from "react";

import { styles } from "./styles";
import { loginAPI } from "../../api/api";
import clsx from "clsx";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { EMAIL_REGEX } from "../../config/constant";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthContext";
import { BsEye, BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Spinner from "../../components/spinner";
import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-toastify";

const Home = ({ classes }) => {
  const [emailCorrect, setEmailCorrect] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { setSspUser } = useContext(AuthContext);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailCorrect(EMAIL_REGEX.test(event.target.value));
    setIsError(false);
  };

  const onCheck = () => {
    setShowPassword(!showPassword);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
    setIsError(false);
  };

  const onSubmit = () => {
    if (!emailCorrect || email === "" || password === "") {
      setError("Please fill all credentials");
      setIsError(true);
    } else {
      setIsLoading(true);
      loginAPI(email, password)
        .then((response) => {
          localStorage.setItem("kwanza", JSON.stringify(response.data));

          login(JSON.stringify(response.data));

          let sspCred = {
            username: email,
            password: password,
          };
          localStorage.setItem("ssp", JSON.stringify(sspCred));
          setIsLoading(false);
          if (response.data.role === "publisher") {
            navigate("/publisher/ssp");
          }
          if (response.data.role === "admin") {
            navigate("/overview");
          }
          if (response.data.role === "agency") {
            navigate("/agency/ssp");
          }
          if (response.data.role === "advertiser") {
            navigate("/advertiser/ssp");
          }
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : "";
          setIsLoading(false);
          toast.error(message);
        });
    }
  };
  useEffect(() => {
    setInterval(() => {
      setSelectedIndex((prev) => {
        if (prev == 1) {
          return 0;
        }
        return prev + 1;
      });
    }, 5000);
  }, []);

  return (
    <div className="h-screen overflow-hidden flex flex-col justify-center items-center w-screen bg-white">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.4 } }}
        className=" text-textColor items-center  mx-auto w-11/12 2xl:w-9/12   p-12 rounded-md  grid grid-cols-12 gap-x-24 text-start "
      >
        <div className=" col-span-5 2xl:col-span-4 flex flex-col h-full justify-between">
          <div className="mt-6">
            {" "}
            <img
              className=" cursor-pointer h-10"
              onClick={() => {
                navigate("/");
              }}
              src="/Kwanza 1.svg"
            />
          </div>
          <div className="mt-3">
            <h1 className=" font-bold text-2xl ">Login to your account</h1>
            <h1 className="text-muted text-sm">Enter your credentials below</h1>
            {error && (
              <h1 className="text-sm text-red-400 bg-red-400 bg-opacity-10 py-2 rounded mt-2">
                {error}
              </h1>
            )}
            <div className="w-full ">
              <div className="mt-3 text-start">
                <label className="text-sm text-start">Email address</label>
                <input
                  className="form-style"
                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  onChange={onEmailChange}
                  value={email}
                />
              </div>

              <div className="mt-3 text-start">
                <label className="text-sm text-start">Password </label>
                <div className="flex border border-slate-300 rounded group ">
                  <input
                    type={!showPassword ? "password" : "text"}
                    placeholder="Enter password  "
                    className=" w-full border-0 focus:border-0 group-focus:border-primary group-focus:ring-primary focus:ring-0 
                     bg-opacity-70 bg-background focus:outline-none focus:ring-offset-transparent focus:ring-offset-0 focus:ring-transparent"
                    onChange={onPasswordChange}
                    value={password}
                  />
                  <div className="border-0 border-slate-300 px-3 group-focus:border-primary group-focus:ring-primary flex cursor-pointer bg-background  flex-col items-center justify-center  text-white">
                    {!showPassword ? (
                      <BsFillEyeFill
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        className="size-5 text-muted cursor-pointer"
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        className="size-5 text-textColor cursor-pointer"
                      />
                    )}
                  </div>
                </div>
              </div>

              <button
                className="w-full bg-primary mt-8 hover:scale-105 transition-all duration-400  text-white font-medium py-3 cursor-pointer rounded-md flex justify-center items-center"
                onClick={onSubmit}
              >
                {isLoading ? <Spinner /> : "Login"}
              </button>
            </div>

            <h1 className="space-x-2 text-sm text-muted pt-2">
              Forgot your password?{" "}
              <span
                className="text-primary underline cursor-pointer"
                onClick={() => {
                  navigate("/reset-email");
                }}
              >
                Reset here
              </span>
            </h1>
          </div>
          <div></div>
        </div>
        <div className=" col-span-7 2xl:col-span-8">
          <div className=" bg-[#F2F2F2]  p-8 flex flex-col h-[95vh] justify-center items-center  rounded-2xl px-24 text-start ">
            <div className="flex w-96">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, x: 200 }}
                  animate={{ opacity: 1, x: 0, transition: { duration: 1 } }}
                  exit={{ opacity: 0, x: -200, transition: { duration: 1 } }}
                >
                  <img
                    className="min-h-96 min-w-96 object-cover"
                    src={["/login.png", "login.png"][selectedIndex]}
                  />
                </motion.div>
              </AnimatePresence>
            </div>

            <div>
              <h1 className="font-bold text-2xl text-start">
                A Few clicks away from publishing your ads
              </h1>
              <p className="text-start text-sm text-muted">
                Leading quality traffic to advertisers through local and
                international digital publishers.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
