export const styles = {
  root: {
    width: "100%",
    height: "100%",
  },
  download: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  filterStatus: {
    marginTop: "1rem",
    minHeight: "4rem",
    borderBottom: "2px solid #C4C4C4",
  },
  resume: {
    textTransform: "none",
    color: "#6EBF4A",
  },
  pause: {
    textTransform: "none",
    color: "#ED6A5E",
  },
  createCampaign: {
    backgroundColor: "#6EBF4A",
    color: "#FFF",
    "&:hover,&:focus": {
      backgroundColor: "#6EBF4A",
    },
    borderRadius: "99px",
    textTransform: "lowercase",
    width: "34%",
    height: "2.5rem",
  },
  desc: {
    fontSize: "0.7rem",
  },
  status: {
    color: "#6EBF4A",
    textTransform: "capitalize",
  },
  edit: {
    color: "#5E6366",
  },
  dot: {
    fontSize: "1rem",
    transform: "translate(10%,-10%)",
  },
  campaignName: {
    marginBottom: "0.4rem",
  },
  tableRow: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F7F9FA",
    },

    "& .MuiTableCell-root": {
      borderBottom: "none",
      color: "#4B4B4B",
    },
  },
  statusButton: {
    width: "44%",
    borderRadius: "0px",
    paddingBottom: "0.5rem",
    paddingTop: "1rem",
    fontSize: "0.7rem",
    color: "#4B4B4B",
    transform: "translateY(10%)",
    borderBottom: "0px solid #FFF",
  },
  activeStatusButton: {
    borderBottom: "4px solid #6EBF4A",
    paddingBottom: "1rem",
  },
  input: {
    width: "90%",
    "& input::placeholder": {
      color: "#5E6366",
      opacity: 1,
    },

    "& .MuiInputBase-input": {
      color: "#5E6366", // Text color
      fontSize: "0.9rem",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FFF", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#6EBF4A", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6EBF4A", // Solid underline on focus
    },
  },
  search: {
    marginRight: "1.5rem",
    background: "#F7F9FA",
    width: "100%",
    paddingLeft: "0.8rem",
    height: "2.4rem",
  },
  filter: {
    paddingBottom: 0,
    paddingTop: "0.7rem",
    height: "1rem",
  },
};
