import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import {
  fetchCampaignDailyStatsApi,
  fetchCampaignPublisherStatsApi,
  fetchCampaignZoneStatsApi,
  fetchPublisherBannerStatsApi,
  fetchPublisherWebsites,
} from "../../api/ssp-api";
import Loader from "../../components/loader";
import { useInvoiceData } from "../../provider/InvoiceContext";
import { addSspInvoice } from "../../api/api";
import PublisherStatsTable from "../ssp/campaign-stats/publisher-stats-table";
import ZoneDataTable from "../ssp/campaign-stats/zone-stats-table";
import BannerStatsTable from "../ssp/campaign-stats/banner-stats-table";
import CampaignSummary from "../ssp/campaign-stats/campaign-summary";
import { AuthContext } from "../../provider/AuthContext";
import PublisherWrapper from "../../components/wrappers/publisher-wrapper";

export default function SspCampaignData() {
  const { id, startDate, advertiserId } = useParams();
  const [loading, setLoading] = useState(true);
  const [publisherLoading, setPublisherLoading] = useState(true);
  const [zoneLoading, setZoneLoading] = useState(true);
  const [zoneData, setZoneData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [publisherData, setPublisherData] = useState([]);
  const navigate = useNavigate();
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [role, setRole] = useState("publisher");
  const { addData } = useInvoiceData();
  const [disabled, setDisabled] = useState(true);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [bannerLoading, setBannerLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const [publisherId, setPublisherId] = useState([]);
  const [publisherDetails, setPublisherDetails] = useState({});
  const [campaignName, setCampaignName] = useState("");
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);


  useEffect(() => {
    fetchCampaignDailyStatsApi(id, startDate, base64Credentials)
      .then((response) => {
        setCampaignData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchPublisherWebsites(base64Credentials, agencyId).then((response) => {
      const matchingPublishers = response.data.filter((publisher) =>
        publisher.emailAddress.includes(username),
      );
      let details = {
        publisherName: matchingPublishers[0].publisherName,
        publisherEmail: matchingPublishers[0].emailAddress,
      };
      setPublisherDetails(details);

      const matchingIds = matchingPublishers.map(
        (publisher) => publisher.publisherId,
      );
      setPublisherId(matchingIds);

      fetchCampaignPublisherStatsApi(id, startDate, base64Credentials)
        .then((response) => {
          let newData = response.data.publishers.data.filter((publisher) =>
            matchingIds.includes(publisher.publisherid),
          );
          let summary = calculateSummary(newData);
          setCampaignSummary(summary);
          setSummaryLoading(false);
          setDisabled(false);
          setPublisherData(newData);
          setPublisherLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });

      fetchCampaignZoneStatsApi(id, startDate, base64Credentials)
        .then((response) => {
          let newData = response.data.zones.data.filter((zone) =>
            matchingIds.includes(zone.publisherid),
          );
          setZoneData(newData);
          setZoneLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });

      Promise.all(
        matchingIds.map((id) =>
          fetchPublisherBannerStatsApi(id, startDate, base64Credentials)
            .then((response) => response.data)
            .catch((error) => {
              console.error(`Error fetching stats for id ${id}: `, error);
              return null; // or handle the error as you see fit
            }),
        ),
      )
        .then((allResponses) => {
          const aggregatedData = [];

          allResponses.forEach((response) => {
            if (response) {
              // Assuming each response has a 'data' property that is an array of banner data
              aggregatedData.push(response.banners.data);
            }
          });

          console.log(`campaign id ${id}`);
          const flattenedFilteredData = aggregatedData.flatMap((response) =>
            response.filter((banner) => banner.campaignid === id),
          );
          console.log(`flattenedFilteredData: `, flattenedFilteredData);
          setCampaignName(flattenedFilteredData[0].campaignname);
          setBannerData(flattenedFilteredData);
          setBannerLoading(false);
        })
        .catch((error) => {
          console.error("Error in Promise.all: ", error);
        });
    });
    updateBannerStats(id, base64Credentials);
  }, []);

  async function updateBannerStats(id, base64Credentials) {
    try {
      const response = await fetchPublisherWebsites(base64Credentials);
      let publishers = response.data;

      for (const publisher of publishers) {
        const statsResponse = await fetchPublisherBannerStatsApi(
          publisher.publisherId,
          startDate,
          base64Credentials,
        );
        let bannerStats = statsResponse.data.banners.data.filter(
          (banner) => banner.campaignid === id,
        );
        bannerStats = bannerStats.map((bannerStat) => ({
          ...bannerStat,
          publisherId: publisher.publisherId,
          publisherName: publisher.publisherName, // Assuming 'name' is the property for the publisher's name
        }));

        setBannerData((prevData) => {
          // Create a new set with keys from prevData for quick lookup
          const existingKeys = new Set(prevData.map((item) => item.key));

          // Filter out bannerStats items that have keys already in existingKeys
          const newItems = bannerStats.filter(
            (item) => !existingKeys.has(item.key),
          );

          // Return the new array with appended newItems
          return [...prevData, ...newItems];
        });

        setBannerLoading(false);
        // Update your stats table here
        // For example: updateStatsTable(banner.bannerid, bannerStats);
      }
    } catch (error) {
      console.error("Error fetching or updating banner stats:", error);
    }
  }

  const calculateSummary = (data) => {
    let totalImpressions = 0;
    let totalClicks = 0;
    let totalRevenue = 0;

    for (const entry of data) {
      totalImpressions += parseInt(entry.impressions);
      totalClicks += parseInt(entry.clicks);
      totalRevenue += parseFloat(entry.revenue);
    }

    return [
      {
        impressions: totalImpressions.toString(),
        clicks: totalClicks.toString(),
        revenue: totalRevenue.toFixed(4),
      },
    ];
  };

  const handleInvoiceGeneration = () => {
    const data = {
      campaignId: id,
      startDate: startDate,
      advertiserId: advertiserId,
      revenue: campaignSummary[0].revenue,
      impressions: campaignSummary[0].impressions,
      clicks: campaignSummary[0].clicks,
      publisherIds: publisherId,
      publisherDetails: publisherDetails,
      campaignName: campaignName,
    };

    console.log(`Data for invoice generation: `, JSON.stringify(data));
    addData(data);

    addSspInvoice(data, token)
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });

        // Create a URL for the file
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", "invoice.pdf"); // or any other filename
        document.body.appendChild(link);
        link.click();
        navigate("/publisher/ssp/invoices");
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };
  return (
      <div style={{ marginTop: "24px",  overflowY: "auto" }}>
        <div container direction="row" justifyContent="space-between">
          <div item>
            <Button
              variant="contained"
              onClick={() => navigate("/publisher/ssp")}
              style={{ color: "white", backgroundColor: "#6EBF4A" }}
              startIcon={<ArrowBackIosNewOutlined />}
            >
              Back to Campaigns
            </Button>
          </div>

          <div item style={{ marginRight: "24px" }}>
            <Button
              disabled={disabled}
              variant="contained"
              onClick={() => handleInvoiceGeneration()}
              style={{ color: "white", backgroundColor: "#6EBF4A" }}
            >
              Generate Invoice
            </Button>
          </div>
        </div>

        <div spacing={2}>
          <h4>Campaign Summary</h4>
          {summaryLoading || campaignSummary === undefined ? (
            <Loader />
          ) : (
            <CampaignSummary data={campaignSummary} role={role} />
          )}
        </div>

        <div>
          <h4>Publisher Data</h4>
          {publisherLoading || publisherData === undefined ? (
            <Loader />
          ) : (
            <PublisherStatsTable data={publisherData} role={role} />
          )}
        </div>

        <div>
          <h4>Zone Data</h4>
          {zoneLoading || zoneData === undefined ? (
            <Loader />
          ) : (
            <ZoneDataTable data={zoneData} role={role} />
          )}
        </div>
        <div>
          <h4>Banner Data</h4>
          {bannerLoading || bannerData === undefined ? (
            <Loader />
          ) : (
            <BannerStatsTable data={bannerData} role={role} />
          )}
        </div>
      </div>
  );
}
