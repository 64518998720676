export const styles = {
  container: {
    marginTop: "3rem",
  },
  tooltip: {
    marginLeft: "1.4rem",
  },
  tooltipContainer: {
    "& *": {
      
      color: "#979797",
      fontSize: "0.78rem",
    },
    padding: "1rem",
  },
  label: {
    fontWeight: 700,
    fontSize: "1rem",
    marginBottom: "2rem",
  },
  input: {
    width: "100%",
    height: "2.8rem",
    marginRight: "3.5rem",
    marginTop: 0,
    borderRadius: "9px",
    fontSize: "0.75rem",
    color: "#979797",
    background: "#FFF",
    textAlign: "left",
  },
  helperText: {
    marginBottom: "0.8rem",
    color: "#4B4B4B",
  },
  menuItem: {
    fontSize: "0.7rem",
    color: "#979797",
  },
  marginRight: {
    marginRight: "2rem",
  },
};
