export const styles = {
  container: {
    marginTop: "3rem",
  },
  tooltip: {
    marginLeft: "1.4rem",
  },
  tooltipContainer: {
    "& *": {
      
      color: "#979797",
      fontSize: "0.78rem",
    },
    padding: "1rem",
  },
  label: {
    fontWeight: 700,
    fontSize: "1rem",
    marginBottom: "2rem",
  },
  input: {
    width: "100%",
    height: "2.8rem",
    marginRight: "3.5rem",
    marginTop: 0,
    borderRadius: "9px",
    fontSize: "0.75rem",
    color: "#979797",
    background: "#FFF",
    paddingLeft: "0.67rem",
    textAlign: "left",
  },
  helperText: {
    marginBottom: "0.8rem",
    color: "#4B4B4B",
    fontWeight: 500,
  },
  menuItem: {
    fontSize: "0.7rem",
    color: "#979797",
  },
  marginRight: {
    marginRight: "2rem",
  },
  marginTop: {
    marginTop: "2rem",
  },
  textarea: {
    border: "0.819465px solid #C4C4C4",
    padding: "1.5rem 1.2rem 1.5rem 1.2rem",
    maxWidth: "89%",
    "&:hover": {
      borderColor: "#6EBF4A",
    },
    "&:focus": {
      borderColor: "#6EBF4A",
    },
    outline: "none",
  },
  dateFilter: {
    width: "100%",
    paddingLeft: "0.67rem",
    textAlign: "left",
  },
  dateContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  editIcon: {
    fontSize: "1.2rem",
  },
  channelPerformance: {
    color: "#4B4B4B",
    
    fontWeight: 700,
    fontSize: "0.8rem",
    margin: "1.4rem 0 0.5rem 0",
  },
  socialIcon: {
    width: "13%",
    marginRight: "1rem",
    fontSize: "0.8rem",
  },
  currencyInput: {
    border: "none",
    width: "30%",
    padding: 0,
    height: "1rem",
    background: "none",
    color: "#6EBF4A",
  },
  noBorder: {
    border: "none",
  },
  arrowDown: {
    color: "#6EBF4A",
  },
  currencyName: {
    color: "#6EBF4A",
  },
  addChannel: {
    textTransform: "initial",
    color: "#6EBF4A",
    fontWeight: 700,
    fontSize: "0.95rem",
    marginTop: "1.5rem",
  },
  closeIcon: {
    transform: "translate(-20%,50%)",
  },
  fillRed: {
    color: "#ED6A5E",
  },
};
