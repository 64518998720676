import React, { useEffect, useState } from "react";
import {
  addCampaign,
  editCampaign,
  fetchAdvertiserByAgencyId,
  fetchCampaignById,
} from "../../../api/ssp-api";
import { useNavigate } from "react-router-dom"; // Import the CSS file for styling

export default function EditSspCampaign() {
  const mode = window.location.pathname.split("/")[3];
  const campaignId = window.location.pathname.split("/")[4];
  const [advertisers, setAdvertisers] = useState([]);
  const [advertiserId, setSelectedAdvertiser] = useState(1);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [revenue, setRevenue] = useState(1);
  const [impressions, setImpressions] = useState(-1);
  const [clicks, setClicks] = useState(-1);
  const [weight, setWeight] = useState(0);
  const [priority, setPriority] = useState(0);
  const [isContract, setIsContract] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const [campaign, setCampaign] = useState({
    campaignName: "",
    advertiserId: advertiserId,
    priority: priority,
    startDate: startDate,
    endDate: endDate,
    revenueType: 1,
    revenue: revenue,
    impressions: impressions,
    clicks: clicks,
    weight: weight,
    isContract: false,
    level: 1,
  });

  useEffect(() => {
    async function fetchData() {
      if (mode === "edit") {
        await getCampaignById();
      }
      const response = await fetchAdvertiserByAgencyId(2, base64Credentials);
      setAdvertisers(response.data);
      setLoading(false);
    }
    fetchData();
  }, []);

  async function getCampaignById() {
    try {
      const response = await fetchCampaignById(campaignId, base64Credentials);
      console.log(response.data);
      setCampaign(response.data);
      setStartDate(formatDate(response.data.startDate));
      campaign.startDate = formatDate(response.data.startDate);
      if (response.data.endDate !== null) {
        setEndDate(formatDate(response.data.endDate));
        campaign.endDate = formatDate(response.data.endDate);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const formatDate = (date) => {
    const year = parseInt(date.year);
    const month = parseInt(date.month) - 1;
    const day = parseInt(date.day);

    return new Date(year, month, day);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "priority" && value === 1) {
      setIsContract(true);
    } else {
      setIsContract(false);
    }
    if (name === "level" && value >= 1) {
      setIsContract(true);
    }

    let newValue;

    if (
      name === "advertiserId" ||
      name === "impressions" ||
      name === "clicks" ||
      name === "weight"
    ) {
      newValue = parseInt(value, 10);
    } else if (name === "revenue") {
      newValue = parseFloat(value);
      if (isNaN(newValue)) {
        // Handle invalid number input for 'revenue', if needed
        newValue = 0.0;
      }
    } else {
      newValue = value;
    }

    setCampaign((prevData) => ({ ...prevData, [name]: newValue }));
  };

  const handleSubmit = (event) => {
    setLoading(true);

    if (mode === "edit") {
      campaign.startDate = reformatDate(campaign.startDate);
      campaign.endDate = reformatDate(campaign.endDate);

      editCampaign(campaignId, campaign, base64Credentials)
        .then((response) => {
          setLoading(false);
          navigate("/ssp");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const reformatDate = (dateObj) => {
    const year = dateObj.year;
    const month = String(dateObj.month).padStart(2, "0");
    const day = String(dateObj.day).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    // <div header={"Edit Campaign"}>
    <div style={{ height: "400px", overflowY: "auto" }}>
      {loading || advertisers === undefined ? (
        <Loader />
      ) : (
        <div xs={12} container direction="row" spacing={4}>
          <div item xs={6} container spacing={2} direction="column">
            <div item>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="dropdown-label">
                  Select an advertiser
                </InputLabel>
                <Select
                  labelId="dropdown-label"
                  id="dropdown"
                  value={campaign.advertiserId}
                  name="advertiserId"
                  onChange={handleChange}
                  label="Select an option"
                >
                  {advertisers.map((item) => (
                    <MenuItem value={item.advertiserId}>
                      {item.advertiserName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div item>
              <FormHelperText>Campaign name*</FormHelperText>
              <OutlinedInput
                fullWidth
                value={campaign.campaignName}
                autoComplete="off"
                name="campaignName"
                required
                onChange={handleChange}
              />
            </div>

            <div item>
              <FormHelperText>Campaign Type*</FormHelperText>
              <FormControl fullWidth variant="outlined">
                {/*<InputLabel>Select a Campaign Type</InputLabel>*/}
                <Select
                  name="priority"
                  value={campaign.priority >= 1 ? 1 : campaign.priority}
                  onChange={handleChange}
                  // label="Select a campaign type"
                >
                  <MenuItem value={0}>Remnant</MenuItem>
                  <MenuItem value={1}>Contract</MenuItem>
                  <MenuItem value={-1}>Override</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div item>
              {isContract ? (
                <div>
                  <div item>
                    <FormHelperText>
                      <Typography>Priority over other campaigns</Typography>
                    </FormHelperText>
                  </div>
                  <div item xs={4}>
                    <FormControl fullWidth style={{ marginTop: "8px" }}>
                      <InputLabel>Select priority</InputLabel>
                      <Select
                        value={campaign.level}
                        name="level"
                        onChange={handleChange}
                        label="Select a Number"
                      >
                        {[...Array(10)].map((_, index) => (
                          <MenuItem key={index} value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div item xs={6} container spacing={2} direction="column">
            {/*<div item>*/}
            {/*  <FormHelperText>Campaign Dates*</FormHelperText>*/}
            {/*</div>*/}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div
                item
                direction="row"
                container
                spacing={2}
                style={{ marginTop: "8px" }}
              >
                <div item xs={6}>
                  <DatePicker
                    fullWidth
                    label="Start Date"
                    name="startDate"
                    value={startDate}
                    onChange={(date) =>
                      handleChange({
                        target: { value: date, name: "startDate" },
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
                <div item xs={6}>
                  <DatePicker
                    fullWidth
                    label="End Date"
                    value={endDate}
                    name="endDate"
                    minDate={startDate}
                    onChange={(date) =>
                      handleChange({
                        target: { value: date, name: "endDate" },
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </LocalizationProvider>
            <div
              item
              direction="row"
              container
              spacing={2}
              style={{ paddingTop: "8px" }}
            >
              <div item xs={6}>
                <FormHelperText>Pricing Model*</FormHelperText>
                <FormControl fullWidth variant="outlined">
                  <Select
                    name="revenueType"
                    value={campaign.revenueType}
                    onChange={handleChange}
                    // label="Select an Option"
                  >
                    <MenuItem value={1}>CPM</MenuItem>
                    <MenuItem value={2}>CPC</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div item xs={5}>
                <FormHelperText>Price/Rate*</FormHelperText>
                <TextField
                  variant="outlined"
                  name="revenue"
                  // label="Price/Rate"
                  value={campaign.revenue}
                  type="number"
                  inputProps={{ step: "0.01" }}
                  onChange={handleChange}
                ></TextField>
              </div>
            </div>
            <div
              item
              direction="row"
              container
              spacing={2}
              style={{ paddingTop: "8px" }}
            >
              <div item xs={6}>
                <FormHelperText>Impressions*</FormHelperText>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="impressions"
                  // label="Impressions"
                  type="number"
                  value={campaign.impressions}
                  onChange={handleChange}
                ></TextField>
              </div>
              <div item xs={5}>
                {/*{isVisible ? (*/}
                <FormHelperText>Clicks*</FormHelperText>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="clicks"
                  // label="Clicks"
                  type="number"
                  value={campaign.clicks}
                  onChange={handleChange}
                ></TextField>
                {/*) : (*/}
                {/*  ""*/}
                {/*)}*/}
              </div>
            </div>
            <div item>
              <FormHelperText>
                <Typography>
                  Priority in relation to other campaigns (Active/Paused)
                </Typography>
              </FormHelperText>
            </div>
            <div item direction="row" container spacing={2}>
              <div item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="weight"
                  label="Campaign Weight"
                  type="number"
                  value={campaign.weight}
                  onChange={handleChange}
                ></TextField>
              </div>
            </div>
            <div item direction="row" container spacing={2}>
              <div item xs={6}>
                <Button
                  // disabled={disabled}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  style={{ color: "white" }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    // </div>
  );
}
