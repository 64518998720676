import { Component } from "react";
import { styles } from "./style";
import Navigation from "./navigation";
import Content from "./content";
import { Navigate } from "react-router-dom";

class AdvertiserWrapper extends Component {
  render() {
    let item = localStorage.getItem("kwanza");
    if (!item) {
      return <Navigate to="/" />
    }
    const { classes, header } = this.props;
    return (
      <div className={classes.root}>
        <Navigation />
        <Content header={header}>{this.props.children}</Content>
      </div>
    );
  }
}

export default AdvertiserWrapper;
