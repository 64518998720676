

export default function CampaignComplete({ savedCampaign }) {
  return (
    <div item xs={12}>
      <h1>Campaign Complete</h1>
      <p>Your campaign has been successfully created.</p>
      {/*<p>You can view the campaign details in the campaigns dashboard.</p>*/}
    </div>
  );
}
