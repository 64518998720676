import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import {
  addPublisherWebsite,
  editPublisherWebsite,
  fetchPublisherCategories,
  fetchPublisherWebsite,
} from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import Spinner from "../../components/spinner";

const EditPublisher = () => {
  const { uuid } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { setCurrentPage } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [categories, setCategories] = useState([]);
  const { setActions } = useContext(BreadcrumbContext);
  const [isChecked, setIsChecked] = useState(false);
  const [website, setWebsite] = useState(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setActions(<div></div>);
    setLoading(true)
    setCurrentPage("Edit Website");
    fetchPublisherCategories(token)
      .then((response) => {
        fetchPublisherWebsite(uuid, token).then((response) => {
          if (response.status == 200) {
            setWebsite(response.data.publisher[0]);
            setIsChecked(response.data.publisher[0].type == "premium");
            setLoading(false);
          }
        });
        console.log(response);
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.emailAddress.value);
    setUploading(true);
    const payload = {
      agencyId: 2,
      publisherName: e.target.publisherName.value,
      website: e.target.website.value,
      type: isChecked ? "premium" : "mass",
      contactName: e.target.contactName.value,
      emailAddress: e.target.emailAddress.value,
      categoryId: parseInt(e.target.category.value),
    };
    console.log(payload);

    editPublisherWebsite(uuid, payload, token)
      .then((response) => {
        console.log(response);
        setUploading(false);
        toast.success("Changed Successfully");
        navigate("/websites");
      })
      .catch((error) => {
        console.log(error);
        setUploading(false);
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  

  return loading ?   (
    <Loader />
  ) : website && (
      <div header={"Add Website"}>
        <ToastContainer />
        <div >
          
            <div className="">
              <form
                className="p-10 bg-white border border-border rounded"
                onSubmit={handleSubmit} >
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-1">
                    <label className="text-sm">Publisher Name</label>
                    <input
                      placeholder="Enter Publisher Name"
                      name="publisherName"
                      defaultValue={website.publisher_name}
                      required
                      className="form-style"
                      margin="normal"
                    />
                  </div>
                  <div className="space-y-1">
                    <label className="text-sm">Websites</label>
                    <input
                      placeholder="Enter a Website"
                      name="website"
                      defaultValue={website.website}
                      required
                      className="form-style"
                      margin="normal"
                    />
                  </div>
                  <div className="space-y-1">
                    <h1 className="text-sm">Category*</h1>
                    <select
                      defaultValue={website.category_id}
                      name="category"
                      className="form-style"
                    >
                      <option>Select</option>
                      {categories.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="space-y-1">
                    <label className="text-sm">Contact Name</label>
                    <input
                      placeholder="Enter Contact Name"
                      defaultValue={website.contact_name}
                      name="contactName"
                      required
                      className="form-style"
                      margin="normal"
                    />
                  </div>
                  <div className="space-y-1">
                    <label className="text-sm">Email Address</label>
                    <input
                      placeholder="Enter an Email Address"
                      defaultValue={website.email}
                      name="emailAddress"
                      required
                      className="form-style"
                    />
                  </div>
                  <div className="space-y-1 flex flex-col">
                    <div>
                      <label className="text-sm">
                        Is this website Premium ?
                      </label>
                    </div>
                    <div className="flex space-x-2 items-center group">
                      <input
                        placeholder="Enter an Email Address"
                        type="checkbox"
                        name="email"
                        checked={isChecked}
                        className="size-8 rounded-md border-border checked:bg-primary focus:ring-primary hover:bg-transparent checked:hover:bg-primary checked:focus:bg-primary   focus:border-primary mt-1"
                        onChange={(e) => {
                          setIsChecked(e.target.checked);
                        }}
                        margin="normal"
                      />
                      <p className=" group-aria-checked:hidden font-normal  text-sm">
                        (
                        {!isChecked
                          ? "No, it is not premium"
                          : "Yes, it is premium"}{" "}
                        )
                      </p>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="py-2 px-3 text-bold bg-primary text-white rounded-md mt-8 w-40 flex justify-center items-center"
                >
                  {uploading ? <Spinner /> : "Save Changes"}
                </button>
              </form>
            </div>
          
        </div>
      </div>
  );
};

export default EditPublisher;
