import { TbFileZip } from "react-icons/tb";

const ZippedUploadedItem = ({selectedFile}) => {
    return ( <div className="border border-slate-300 bg-secondary bg-opacity-5 items-center flex space-x-2 p-3 rounded">
        <div>
          <TbFileZip className="size-8" />
        </div>
        <div>
          <h1 className="text-sm font-bold">
            File Name: {selectedFile.name}
          </h1>
          <h1 className="text-sm text-muted">
            File Size: {selectedFile.size} bytes
          </h1>
        </div>
      </div> );
}
 
export default ZippedUploadedItem;