
import { ReactComponent as VolumeOff } from "../../assets/volumeoff.svg";
import React from "react";



const BillingEmptyState = ({ navigate }) => {
  const classes = useStyles();
  const redirectToGenerateInvoice = () => {
    navigate("/generate-invoice/invoice-type");
  };
  return (
    <div className="w-6/12 mx-auto text-center flex flex-col items-center h-[60vh]   justify-center">
       <img className="h-32 mt-12" src="/illustration.png"/>
       <h1 className="font-bold text-xl">No Invoice genarated</h1>
       <p className="text-sm text-gray-400 my-2">Pariatur laboris esse et proident commodo labore. Tempor consequat voluptate dolor et labore. enim nulla pariatur officia. Dolor ut sint eu et ipsum excepteur amet. Enim laborum in labore anim.</p>
      <button
        className="py-3 hover:scale-105 transition-all duration-200 font-semibold px-5 rounded-md bg-primary text-white text-sm mt-4"
        onClick={redirectToGenerateInvoice}
      >
        Generate Invoice
      </button>
    </div>
  );
};

export default BillingEmptyState;
