




const CheckBoxList = ({ name, id, selected, onHandleCheck }) => {
  console.log(id, selected);
  return (
    <div
      xs={12}
      container
      alignItems="center"
      justify="space-between"
    >
      <div xs={8} container justify="flex-start">
        {name}
      </div>
      <div xs={2}>
        <GreenRadio
          checked={parseInt(id) === parseInt(selected)}
          value={id}
          onChange={(event) => onHandleCheck(event.target.value)}
        />
      </div>
    </div>
  );
};

export default CheckBoxList;
