import { useContext, useEffect, useState } from "react";


import CampaignCreateInfo from "./create-campaign-info";

import {
  getAdvertisers,
  getCampaignById,
  getTargets,
  saveCampaigns,
} from "../../../api/api";

import { AuthContext } from "../../../provider/AuthContext";
import { useLocation } from "react-router-dom";
import { CampaignContext } from "./create-campaign-tabs";


const CampaignCreate = (props) => {
  // const classes = useStyles();
  const [campaign, setCampaign] = useState({});
  const { campaignId } = (props.match && props.match.params) || {};
  const {pathname} = useLocation();
  const [audiences, setAudiences] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [campaignInfo, setCampaignInfo] = useState({});
  const [campaignAudience, setCampaignAudience] = useState({});
  const [campaignID, setCampaignID] = useState(null);
  const [files, setFiles] = useState([]);
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);
  const {activeStep,setActiveStep} = useContext(CampaignContext)

  useEffect(() => {
    console.log(`agencyId`, agencyId);
    getAdvertisers(token, agencyId)
      .then((response) => {
        setAdvertisers(response.data ? response.data.advertisers : []);
      })
      .catch(console.log);

    if (campaignId) {
      getCampaignById(token, campaignId)
        .then((response) => {
          const campaign = response.data || {};
          setCampaign(campaign);
        })
        .catch(console.log);
    }
    getTargets(token)
      .then((response) => {
        setAudiences(response.data || []);
      })
      .catch((error) => {
        console.log(error);
      });
    getAdvertisers(token, agencyId)
      .then((response) => {
        setAdvertisers(response.data ? response.data.advertisers : []);
      })
      .catch(console.log);
  }, [campaignId, pathname]);

  

 
  return (
    <div header={"Create campaign"}>
      <div>
        <CampaignCreateInfo advertiserData={advertisers} />
      </div>
    </div>
  );
};

export default CampaignCreate;
