import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export function useCampaignData() {
    return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [data, setData] = useState([]);

    const addData = (newData) => {
        setData((prevData) => [...prevData, newData]);
    };

    return (
        <DataContext.Provider value={{ data, addData }}>
            {children}
        </DataContext.Provider>
    );
}
