
import { useEffect, useState } from "react";
import AdvertiserWrapper from "../../../components/wrappers/advertiser-wrapper";
import { fetchBannerById } from "../../../api/ssp-api";
import Loader from "../../../components/loader";

export default function AdvertiserEditBanner() {
  const id = window.location.pathname.split("/").pop();
  const [banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(true);

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  useEffect(() => {
    fetchBannerById(id, base64Credentials)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.storageType === "html") {
            const htmlTemplate = response.data.htmlTemplate;
            const blob = new Blob([htmlTemplate], { type: "text/html" });
            const blobURL = URL.createObjectURL(blob);
            setBanner({ ...response.data, htmlTemplate: blobURL });
            setLoading(false);
          } else {
            setBanner(response.data);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <AdvertiserWrapper header="Edit Banner">
      <div container spacing={2} >
        {loading ? (
          <Loader/>
        ) : (
          <div
            container
            spacing={4}
            item
            direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            xs={6}
          >
            {/*<h1>{banner.name}</h1>*/}
            <div
              item
              container
              justify-content="flex-start"
              align-items="flex-start"
              spacing={2}
            >
              {banner.storageType === "url" ? (
                <img
                  src={banner.imageURL}
                  alt={banner.bannerName}
                  width={banner.width}
                  height={banner.height}
                />
              ) : (
                <iframe
                  src={banner.htmlTemplate}
                  title={banner.name}
                  width={banner.width}
                  height={banner.height}
                />
              )}
            </div>
            <div
              item
              container
              justify-content="flex-start"
              align-items="flex-start"
              direction="row"
              spacing={2}
            >
              <TextField
                variant="outlined"
                id="name"
                label="Name"
                value={banner.bannerName}
              />
            </div>
            <div
              item
              container
              justify-content="flex-start"
              align-items="flex-start"
              direction="row"
              spacing={2}
            >
              <TextField
                variant="outlined"
                id="destination"
                label="Destination Url"
                value={banner.url}
              />
            </div>
            <div
              item
              container
              justify-content="space-evenly"
              align-items="flex-start"
              direction="row"
              spacing={2}
            >
              <TextField
                variant="outlined"
                id="width"
                label="Width"
                value={banner.width}
                style={{ marginRight: "10px" }}
              />
              <TextField
                variant="outlined"
                id="height"
                label="height"
                value={banner.height}
              />
            </div>
          </div>
        )}
      </div>
    </AdvertiserWrapper>
  );
}
