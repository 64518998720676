import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../provider/AuthContext";
import { getAgencyById } from "../../api/api";
import { useParams } from "react-router-dom";
import ViewAgencyDetails from "./view-agency-details";
import Loader from "../../components/loader";
import { fetchCampaigns, fetchPublisherWebsites } from "../../api/ssp-api";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import AgencyCampaignsTable from "./agency-campaigns-table";
import AgencyPublisherTable from "../agency-module/ssp/websites/websites-table";
import AgencyWebsiteTable from "../agency-module/ssp/websites/websites_table_new";
import NoData from "../../components/noData";

export default function AgencyView() {
  const { id, name } = useParams();
  const { userDetails } = useContext(AuthContext);
  const { token } = JSON.parse(userDetails);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const [loading, setLoading] = useState(true);
  const [agencyData, setAgencyData] = useState({});
  const [publisherData, setPublisherData] = useState([]);
  const [advertiserData, setAdvertiserData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [campaignLoading, setCampaignLoading] = useState(true);
  const { currentPage, setCurrentPage } = useContext(BreadcrumbContext);

  useEffect(() => {
    setCurrentPage(name);
    getAgencyById(id, token)
      .then((res) => {
        fetchCampaignData(res.data.advertisers);
        fetchPublisherWebsites(base64Credentials, id)
          .then((response) => {
            setPublisherData(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
        setAgencyData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const fetchCampaignData = (advertiserData) => {
    console.log("advertiser data", advertiserData);
    advertiserData.map((advertiser) => {
      console.log("advertiser found", advertiser);
      fetchCampaigns(advertiser.id)
        .then((response) => {
          console.log("Campaigns fetch", response);
          if (response.data.length > 0) {
            setCampaignData((prev) => [...prev, response.data]);
            setCampaignLoading(false);
          } else {
            setCampaignLoading(false);
          }
        })
        .catch((error) => {
          console.log("fetch campaigns error", error);
        });
    });
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const [filter, setFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);
  return (
    <div header="View Agency Details">
      <div className="flex space-x-6 mb-4">
        {["Advertisers", "Users", "Websites", "Campaigns"].map(
          (item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setSelectedTab(index);
                }}
                className={`${index == selectedTab && "border-b-4 text-primary"} cursor-pointer border-b-primary pb-2`}
              >
                {item}
              </div>
            );
          }
        )}
      </div>

      {loading || agencyData === undefined || publisherData === undefined ? (
        <Loader />
      ) : (
        <div>
          {selectedTab == 0 &&
            (agencyData.advertisers.length < 1 ? (
              <NoData />
            ) : (
              <div className="bg-white px-5 py-5 w-full rounded-md  border border-border">
                <div className="flex justify-end space-x-2">
                  <input
                    label="Filter by name or campaign name"
                    placeholder="Search here"
                    className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </div>
                <table className="text-sm table w-full mt-4">
                  <thead className="text-muted">
                    <tr className="">
                      <th className="py-3 text-sm text-start">Id</th>
                      <th className="text-sm text-start py-3">Name</th>
                      <th className="text-sm text-start py-3">Company</th>
                      <th className="text-sm text-start py-3">Email</th>
                      <th className="text-sm text-start py-3">Created At</th>
                    </tr>
                  </thead>
                  <tbody className="text-start">
                    {agencyData.advertisers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                          <td className="text-start py-3 px-0 ">{item.id}</td>
                          <td className="text-start py-3 px-0 ">{item.name}</td>
                          <td className="text-start py-3 px-0 ">
                            {item.company}
                          </td>
                          <td className="text-start py-3 px-0 ">
                            {item.email}
                          </td>
                          <td className="text-start py-3 px-0 ">
                            {item.created_at}
                          </td>
                          <td className="text-start px-0 py-3 flex space-x-4"></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
                  <h1 className=" translate-x-5">Rows per page</h1>
                  <select
                    value={rowsPerPage}
                    onChange={(e) => {
                      setRowsPerPage(e.target.value);
                    }}
                    className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
                  >
                    <option className="" value={5}>
                      5
                    </option>
                    <option className="" value={8}>
                      8
                    </option>
                  </select>
                  <div
                    onClick={() => {
                      if (page > 0) {
                        setPage(page - 1);
                      }
                    }}
                    className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
                  >
                    <MdOutlineChevronLeft />
                  </div>
                  <h1>
                    {page + 1} out of{" "}
                    {Math.ceil(agencyData.advertisers.length / rowsPerPage)}
                  </h1>
                  <div
                    onClick={() => {
                      if (
                        page <
                        Math.round(agencyData.advertisers.length / rowsPerPage)
                      ) {
                        setPage(page + 1);
                      }
                    }}
                    className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
                  >
                    <MdOutlineChevronRight />
                  </div>
                </div>
              </div>
            ))}
          {selectedTab == 1 &&
            (agencyData.advertisers.length < 1 ? (
              <NoData />
            ) : (
              <div className="bg-white px-5 py-5 w-full rounded-md  border border-border">
                <div className="flex justify-end space-x-2">
                  <input
                    label="Filter by name or campaign name"
                    placeholder="Search here"
                    className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </div>
                <table className="text-sm table w-full mt-4">
                  <thead className="text-muted">
                    <tr className="">
                      <th className="py-3 text-sm text-start">Id</th>
                      <th className="text-sm text-start py-3">Name</th>
                      <th className="text-sm text-start py-3">Email</th>
                      <th className="text-sm text-start py-3">Created At</th>
                    </tr>
                  </thead>
                  <tbody className="text-start">
                    {agencyData.users
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                          <td className="text-start py-3 px-0 ">{item.id}</td>
                          <td className="text-start py-3 px-0 ">{item.name}</td>
                          <td className="text-start py-3 px-0 ">
                            {item.email}
                          </td>
                          <td className="text-start py-3 px-0 ">
                            {item.created_at}
                          </td>
                          <td className="text-start px-0 py-3 flex space-x-4"></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
                  <h1 className=" translate-x-5">Rows per page</h1>
                  <select
                    value={rowsPerPage}
                    onChange={(e) => {
                      setRowsPerPage(e.target.value);
                    }}
                    className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
                  >
                    <option className="" value={5}>
                      5
                    </option>
                    <option className="" value={8}>
                      8
                    </option>
                  </select>
                  <div
                    onClick={() => {
                      if (page > 0) {
                        setPage(page - 1);
                      }
                    }}
                    className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
                  >
                    <MdOutlineChevronLeft />
                  </div>
                  <h1>
                    {page + 1} out of{" "}
                    {Math.ceil(agencyData.users.length / rowsPerPage)}
                  </h1>
                  <div
                    onClick={() => {
                      if (
                        page < Math.round(agencyData.users.length / rowsPerPage)
                      ) {
                        setPage(page + 1);
                      }
                    }}
                    className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
                  >
                    <MdOutlineChevronRight />
                  </div>
                </div>
              </div>
            ))}
          {selectedTab == 2 &&
            (publisherData.length < 1 ? (
              <NoData />
            ) : (
              <AgencyWebsiteTable data={publisherData} />
            ))}
          {selectedTab == 3 &&
            (campaignData.length < 1 ? (
              <NoData />
            ) : (
              <AgencyCampaignsTable data={campaignData} />
            ))}
        </div>
      )}
    </div>
  );
}
