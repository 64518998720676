
import AgencyWrapper from "../../../../components/wrappers/agency-wrapper";

export default function AgencyZoneIc() {
  return (
    <div header={"Zone Invocation Code"}>
      <div xs={12}  container></div>
    </div>
  );
}
