import { Component } from "react";
import { styles } from "./submit-buttons-styles";


import clsx from "clsx";

class SubmitButtons extends Component {
  onBack = () => {
    const { redirectUrl } = this.props;
    this.props.navigate(redirectUrl);
  };
  render() {
    const { classes, isLoading } = this.props;
    return (
      <div xs={8} container justify="flex-start" sx={styles.container}>
        <Button sx={styles.cancelButton} onClick={this.onBack}>
          Back
        </Button>
        <Button sx={styles.createCampaign} onClick={this.props.next}>
          {" "}
          {isLoading ? (
            <CircularProgress sx={styles.loader} size="1.6rem" />
          ) : (
            "NEXT"
          )}
        </Button>
      </div>
    );
  }
}

export default SubmitButtons;
