const NoData = ({withBorder=true}) => {
    return ( <div className={withBorder&&'bg-white border border-border py-12 rounded'}>
        <div className="flex justify-center items-center flex-col w-full">
                    <img src="/invoice.png" />
                    <h1 className="text-muted text-opacity-50 font-bold text-base mt-1">
                      No data available
                    </h1>
                  </div>
    </div> );
}
 
export default NoData;