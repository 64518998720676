import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchCampaignsByAdvertiser,
  fetchCreativesByCampaignId,
} from "../../api/ssp-api";
import Loader from "../../components/loader";

import { MdOutlineChevronRight, MdOutlineChevronLeft } from "react-icons/md";
import Preview from "../../components/preview";
import ModifyButton from "../../components/modify-button";
import NoData from "../../components/noData";

export default function SspBanners({ advertiserData }) {
  const [campaignData, setCampaignData] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(2);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [hidden, setHidden] = useState(true);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  useEffect(() => {
    setSelectedAdvertiser(advertiserData[0].advertiserId);
    getCampaigns(advertiserData[0].advertiserId, base64Credentials);
  }, []);

  const handleChange = (event) => {
    setSelectedAdvertiser(event.target.value);
    console.log("selected advertiser", event.target.value);
    getCampaigns(event.target.value, base64Credentials);
  };

  const getCampaigns = (selectedAdvertiser, credentials) => {
    fetchCampaignsByAdvertiser(selectedAdvertiser, credentials)
      .then((r) => {
        console.log("campaign data", r.data);
        setCampaignData(r.data);

        if (r.data.length > 0) {
          setSelectedCampaignId(r.data[0].campaignId);
        }
        setCampaignLoading(false);
        setHidden(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (selectedCampaignId != null) {
      getBanners(selectedCampaignId, base64Credentials);
    }
  }, [selectedCampaignId]);

  const getBanners = (selectedCampaign, credentials) => {
    setBannerLoading(true);
    fetchCreativesByCampaignId(selectedCampaign, credentials).then((r) => {
      console.log("banner data", r.data);
      setBannerData(r.data);
      setBannerLoading(false);
    });
  };

  return (
    <div>
      {bannerLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="bg-white px-5 py-5 w-full  rounded-md mt-6">
            <div className="flex justify-end space-x-2">
              <select
                variant="outlined"
                className="py-2 rounded-md border-border text-sm focus:border-primary focus:ring-primary "
                value={selectedAdvertiser}
                onChange={handleChange}
                label="Select advertiser"
              >
                {advertiserData.map((item) => (
                  <option
                    className=""
                    key={item.advertiserId}
                    value={item.advertiserId}
                  >
                    {item.advertiserName}
                  </option>
                ))}
              </select>
              <select
                variant="outlined"
                className="py-2 rounded-md border-border text-sm focus:border-primary focus:ring-primary"
                value={selectedCampaign}
                onChange={(e) => {
                  setSelectedCampaign(e.target.value);
                  setSelectedCampaignId(e.target.value);
                }}
                label="Select campaign"
              >
                {campaignData.map((item) => (
                  <option key={item.campaignId} value={item.campaignId}>
                    {item.campaignName}
                  </option>
                ))}
              </select>
            </div>
            <Preview
              setShow={setShow}
              show={show}
              selectedItem={selectedItem}
              height={selectedItem && selectedItem.height}
              width={selectedItem && selectedItem.width}
              type={
                selectedItem && selectedItem.contenttype == "html"
                  ? "html"
                  : "image"
              }
              url={selectedItem && selectedItem.iurl}
            />
            {bannerData.length < 1 ? (
              <NoData withBorder={false} />
            ) : (
              <div>
                <table className="text-sm table w-full">
                  <thead className="text-muted">
                    <tr className="">
                      <th className="py-3 text-sm text-start">Banner name</th>
                      <th className="text-sm text-start py-3">Dimensions</th>
                      <th className="text-sm text-start py-3"></th>
                    </tr>
                  </thead>
                  <tbody className="text-start">
                    {bannerData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                          <td className="text-start py-3 px-0 ">
                            {item.bannerName}
                          </td>
                          <td className="text-start py-3 px-0 ">
                            {item.height}x{item.width}
                          </td>

                          <td className="text-start px-0 py-3">
                            <button
                              onClick={() => {
                                setSelectedItem(item);
                                setShow(true);
                              }}
                              className="font-bold bg-primary text-white py-1 rounded-md px-2"
                            >
                              Preview
                            </button>
                          </td>
                          <td>
                            <ModifyButton
                              id={item.id}
                              onDelete={() => {
                                deleteDspBanner(item.id).then((response) => {
                                  setRefresh(refresh + 1);
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
                  <h1 className=" translate-x-5">Rows per page</h1>
                  <select
                    value={rowsPerPage}
                    onChange={(e) => {
                      setRowsPerPage(e.target.value);
                    }}
                    className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
                  >
                    <option className="" value={5}>
                      5
                    </option>
                    <option className="" value={8}>
                      8
                    </option>
                  </select>
                  <div
                    onClick={() => {
                      if (page > 0) {
                        setPage(page - 1);
                      }
                    }}
                    className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
                  >
                    <MdOutlineChevronLeft />
                  </div>
                  <h1>
                    {page + 1} out of{" "}
                    {Math.ceil(bannerData.length / rowsPerPage)}
                  </h1>
                  <div
                    onClick={() => {
                      if (page < Math.round(bannerData.length / rowsPerPage)) {
                        setPage(page + 1);
                      }
                    }}
                    className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
                  >
                    <MdOutlineChevronRight />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
