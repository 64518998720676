import { useContext, useState } from "react";
import { BsLightbulb } from "react-icons/bs";
import { Outlet, useNavigate } from "react-router-dom";
import WebsiteNavbar from "../components/website-components/websiteNavbar";
import Navbar from "./navbar";
import Footer from "../components/website-components/footer";

const WebsiteLayout = () => {
  const [theme, setTheme] = useState("dark");
  const navigate = useNavigate();
  return (
    <div className={`${theme} `}>
      <div className=" min-h-screen dark:bg-dark dark:text-white text-base ">
        <WebsiteNavbar />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default WebsiteLayout;
