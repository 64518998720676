
import { ReactComponent as VolumeOff } from "../../../../assets/volumeoff.svg";
import React from "react";



const BillingEmptyState = ({ history }) => {
  const classes = useStyles();
  const redirectToGenerateInvoice = () => {
    navigate("/generate-invoice/invoice-type");
  };
  return (
    <div xs={12} className={classes.root}>
      <div className={classes.iconContainer}>
        <VolumeOff />
      </div>
      <Typography className={classes.noInvoice}>
        No Invoice genarated
      </Typography>
      <Typography className={classes.description}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae ut
      </Typography>
      <Typography className={classes.description}>
        {" "}
        venenatis nisl, elit, ipsum enim pretium. Quam a, feugiat{" "}
      </Typography>
      <Typography className={classes.description}> pharetra tortor</Typography>
      <Button
        className={classes.generateInvoice}
        onClick={redirectToGenerateInvoice}
      >
        Generate Invoice
      </Button>
    </div>
  );
};

export default BillingEmptyState;
