import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { fetchZoneIc, fetchZonesByPublisherId } from "../../../../api/ssp-api";

import { AuthContext } from "../../../../provider/AuthContext";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import Loader from "../../../../components/loader";

const ZoneTable = ({ data, websites }) => {
  const rowsPerPage = 5;
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [zoneId, setZoneId] = React.useState(0);
  const [ic, setIc] = React.useState("");
  const [filter, setFilter] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleOpen = (id) => {
    setOpen(true);
    setLoading(true);
    const zoneIc = {
      code_type: "async",
    };
    fetchZoneIc(id, zoneIc)
      .then((response) => {
        const originalCode = response.data.invocation_code;
        const modifiedCode = originalCode.replace(
          "<!-- Revive Adserver Asynchronous JS Tag - Generated with Revive Adserver v5.4.1 -->",
          ""
        );
        setIc(modifiedCode);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = () => setOpen(false);

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(ic);
    setCopied(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const [zoneData, setZoneData] = useState([]);

  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);
  const filteredData = zoneData.filter(
    (item) =>
      item.width.toLowerCase().includes(filter.toLowerCase()) ||
      item.height.toLowerCase().includes(filter.toLowerCase()) // Filter by campaign name
  );
  const [disabled, setDisabled] = useState(true);
  const [selectedPublisherId, setSelectedPublisherId] = useState(0);
  const [publisherData, setPublisherData] = useState([]);

  const handleSelectChange = (event) => {
    const newPublisherId = event.target.value;
    setSelectedPublisherId(newPublisherId);
    fetchZones(newPublisherId);
  };
  useEffect(() => {
    if (websites.length > 0) {
      fetchZones(websites[0].publisherId);
    }
  }, [websites]);

  const fetchZones = (newPublisherId) => {
    fetchZonesByPublisherId(newPublisherId, base64Credentials)
      .then((response) => {
        setZoneData(response.data);
        console.log(zoneData);
      })
      .catch((error) => {});
  };
  return (
    <div>
      <div className="w-full">
        <div className="bg-white px-5 py-5 w-full rounded-md  border border-border">
          <div className="flex justify-end space-x-3 mb-3">
            <input
              className="py-2 px-3 rounded-md border-border focus:border-primary focus:ring-primary"
              placeholder="Filter by width/height"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <select
              className="py-2 px-3 rounded-md border-border focus:border-primary focus:ring-primary"
              value={selectedPublisherId}
              onChange={handleSelectChange}
            >
              {websites.map((publisher) => (
                <option
                  key={publisher.publisherId}
                  value={publisher.publisherId}
                >
                  {publisher.website}
                </option>
              ))}
            </select>
          </div>
          <table className="text-sm table w-full">
            <thead className="text-muted">
              <tr className="">
                <th className="py-3 text-sm text-start">Zone name</th>
                <th className="text-sm text-start py-3">Width</th>
                <th className="text-sm text-start py-3">Height</th>
                <th className="text-sm text-start py-3"></th>
                <th className="text-sm text-start py-3"></th>
              </tr>
            </thead>
            <tbody className="text-start">
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                    <td className="text-start py-3 px-0 ">{item.zoneName}</td>
                    <td className="text-start py-3 px-0 ">{item.width}</td>
                    <td className="text-start py-3 px-0 ">{item.height}</td>
                    <td className="text-start px-0 py-3">
                      <button
                        className="font-bold py-1 px-2 bg-gray-100"
                        onClick={() => navigate(`/zones/edit/${item.zoneId}`)}
                      >
                        Edit
                      </button>
                    </td>
                    <td className="text-start px-0 py-3">
                      <button
                        className="font-bold text-white bg-primary py-1 rounded-md px-2"
                        onClick={() => handleOpen(item.zoneId)}
                      >
                        Invocation Code
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
            <h1 className=" translate-x-5">Rows per page</h1>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(e.target.value);
              }}
              className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
            >
              <option className="" value={5}>
                5
              </option>
              <option className="" value={8}>
                8
              </option>
            </select>
            <div
              onClick={() => {
                if (page > 0) {
                  setPage(page - 1);
                }
              }}
              className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
            >
              <MdOutlineChevronLeft />
            </div>
            <h1>
              {page + 1} out of {Math.ceil(filteredData.length / rowsPerPage)}
            </h1>
            <div
              onClick={() => {
                if (page < Math.round(filteredData.length / rowsPerPage)) {
                  setPage(page + 1);
                }
              }}
              className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
            >
              <MdOutlineChevronRight />
            </div>
          </div>
        </div>
        <Modal open={open} onClose={handleClose}>
          {loading || ic === undefined ? (
            <Loader />
          ) : (
            <Box sx={style}>
              <TextField
                label="Invocation Code"
                value={ic}
                InputProps={{ readOnly: true }}
                fullWidth
                multiline
                rows={6}
                variant="filled"
              />
              <Stack direction="row" spacing={2} style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCopy}
                >
                  {copied ? "Copied!" : "Copy"}
                </Button>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Close
                </Button>
              </Stack>
            </Box>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default ZoneTable;
