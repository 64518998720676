export const mainCategories = [
  "Automotive",
  "Business Support & Supplies",
  "Computers & Electronics",
  "Construction & Contractors",
  "Education",
  "Entertainment",
  "Food & Dining",
  "Health & Medicine",
  "Home & Garden",
  "Legal & Financial",
  "Manufacturing, Wholesale, Distribution",
  "Merchants (Retail)",
  "Miscellaneous",
  "Personal Care & Services",
  "Real Estate",
  "Travel & Transportation",
];

export const media = [
  "Television",
  "Radio",
  "Billboard",
  "Social",
  "Newspaper",
  "Blog",
  "Display",
  "Influencer",
  "SEO",
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
