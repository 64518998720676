import { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import NoData from "../../components/noData";
import ModifyButton from "../../components/modify-button";
import CustomModal from "../../components/custom-modal";
import { addInfluencer, getInfluencers, registerUser } from "../../api/api";
import CustomButton from "../../components/customButton";
import { useNavigate } from "react-router-dom";

const InfluencersList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const { setActions } = useContext(BreadcrumbContext);
  useEffect(() => {
    console.log("Hello there");
    getInfluencers().then((response) => {
      const data = response.data.data;

      console.log("Influencers", data);
      setData(data);
    });
  }, []);
  useEffect(() => {
    setActions(
      <div>
        <button
          className="font-bold py-2 px-6 bg-primary text-white rounded-lg"
          onClick={() => {
            setShowCreateModal(true);
          }}
        >
          Add influencer
        </button>
      </div>
    );
  }, []);
  return (
    <div>
      <CustomModal
        heading={"Add Influencer"}
        content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const payload = {
                name: e.target.name.value,
                email: e.target.email.value,
                password: e.target.password.value,
                role: 4,
              };
              setUploading(true);
              registerUser(payload).then((data) => {
                console.log(data);

                const influencerPayload = {
                  userId: data.data.id,
                  instagramUrl: e.target.instagram.value,
                  facebookUrl: e.target.facebook.value,
                  twitterUrl: e.target.twitter.value,
                  linkedinUrl: "",
                  facebookLikes: 0,
                  facebookFollowers: parseInt(e.target.fFollowers.value),
                  twitterFollowers: parseInt(e.target.tFollowers.value),
                  instagramFollowers: parseInt(e.target.iFollowers.value),
                  visitors: parseInt(e.target.visitors.value),
                  audience: 0,
                };
                addInfluencer(influencerPayload).then((response) => {
                  console.log(response);
                  setUploading(false);
                });
              });
            }}
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label>Name</label>
                <input
                  required
                  name="name"
                  placeholder="Enter name"
                  className="form-style"
                />
              </div>
              <div>
                <label>Email</label>
                <input
                  required
                  name="email"
                  placeholder="Enter email"
                  className="form-style"
                />
              </div>
              <div>
                <label>Password</label>
                <input
                  required
                  name="password"
                  placeholder="Enter password"
                  className="form-style"
                />
              </div>
              <div>
                <label>Instagram Link</label>
                <input
                  required
                  name="instagram"
                  placeholder="Enter instagram link"
                  className="form-style"
                />
              </div>
              <div>
                <label>Facebook Link</label>
                <input
                  required
                  name="facebook"
                  placeholder="Enter facebook link"
                  className="form-style"
                />
              </div>
              <div>
                <label>Twitter Link</label>
                <input
                  required
                  name="twitter"
                  placeholder="Enter twitter link"
                  className="form-style"
                />
              </div>
              <div>
                <label>Facebook followers</label>
                <input
                  required
                  type="number"
                  name="fFollowers"
                  placeholder="Enter twitter link"
                  className="form-style"
                />
              </div>
              <div>
                <label>Twitter followers</label>
                <input
                  required
                  type="number"
                  name="tFollowers"
                  placeholder="Enter twitter link"
                  className="form-style"
                />
              </div>
              <div>
                <label>Instagram followers</label>
                <input
                  required
                  name="iFollowers"
                  type="number"
                  placeholder="Enter twitter link"
                  className="form-style"
                />
              </div>
              <div>
                <label>Visitors</label>
                <input
                  required
                  name="visitors"
                  type="number"
                  placeholder="Enter twitter link"
                  className="form-style"
                />
              </div>
            </div>
            <div className="mt-5">
              <CustomButton loading={uploading} text={"Add Influencer"} />
            </div>
          </form>
        }
        setShow={setShowCreateModal}
        show={showCreateModal}
      />
      {data.length < 1 ? (
        <NoData />
      ) : (
        <div>
          <div className="bg-white px-5 py-5 w-full rounded-md border border-border ">
            <div className="flex justify-end space-x-2">
              <input
                label="Filter by name or campaign name"
                placeholder="Search here"
                className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <table className="text-sm table w-full mt-4">
              <thead className="text-muted">
                <tr className="">
                  <th className="text-sm text-start py-3">id</th>
                  <th className="text-sm text-start py-3">Name</th>
                  <th className="text-sm text-start py-3">Email</th>
                  <th className="text-sm text-start py-3">Created At</th>
                  <th className="text-sm text-start py-3"></th>
                  <th className="text-sm text-start py-3"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="text-start">
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                      <td className="text-start py-3 px-0 ">{item.id}</td>
                      <td className="text-start py-3 px-0 ">{item.name}</td>
                      <td className="text-start py-3 px-0 ">{item.email}</td>
                      <td className="text-start py-3 px-0 ">
                        {item.created_at}
                      </td>
                      <td className="text-start py-3 px-0 ">
                        <CustomButton
                          onClick={() => {
                            navigate(`/influencers/gigs/${item.id}`);
                          }}
                          text={"View gigs"}
                        />
                      </td>
                      <td className="text-start py-3 px-0 "></td>

                      <td className="text-start px-0 py-3">
                        <ModifyButton
                          id={item.publisher_id}
                          onDelete={() => {
                            //   handleDelete(item.publisher_id);
                          }}
                          onEdit={() => {
                            //   navigate(`/websites/${item.publisher_id}`);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
              <h1 className=" translate-x-5">Rows per page</h1>
              <select
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(e.target.value);
                }}
                className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
              >
                <option className="" value={5}>
                  5
                </option>
                <option className="" value={8}>
                  8
                </option>
              </select>
              <div
                onClick={() => {
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
              >
                <MdOutlineChevronLeft />
              </div>
              <h1>
                {page + 1} out of {Math.ceil(data.length / rowsPerPage)}
              </h1>
              <div
                onClick={() => {
                  if (page < Math.round(data.length / rowsPerPage)) {
                    setPage(page + 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
              >
                <MdOutlineChevronRight />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfluencersList;
