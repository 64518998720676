import React, { useContext, useEffect, useState } from "react";

import { getAdvertiserByIdApi } from "../../../../api/api";
import { AuthContext } from "../../../../provider/AuthContext";
import AgencyWrapper from "../../../../components/wrappers/agency-wrapper";
import Loader from "../../../../components/loader";

const AgencyViewAdvertiser = () => {
  const advertiserId = window.location.pathname.split("/")[4];
  const [advertiser, setAdvertiser] = useState({});
  const [loading, setLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    const { token, role } = JSON.parse(userDetails);
    getAdvertiserByIdApi(advertiserId, token)
      .then((res) => {
        setAdvertiser(res.data.advertiser);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [advertiserId]);

  return (
    <div header={"View Advertiser"}>
      <div container spacing={2} >
        {loading || advertiser === undefined ? (
          <Loader/>
        ) : (
          <div>
            <div className="bg-white p-10 border border-border rounded">
            <div className="space-y-1" xs={6}>
            <Box display="flex" justifyContent="center">
              <Avatar
                src={advertiser.logo}
                alt="Company Logo"
                sx={{ width: 100, height: 100, padding: "16px" }}
              />
            </Box>
            <div className="grid grid-cols-2 gap-4 ">
              <div className="space-y-1"  xs={6}>
              <label className="text-sm">Name</label>

                <input
                  label="Name"
                  value={advertiser.contactName}
                  variant="outlined"
                  className="form-style"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="space-y-1" xs={6}>
              <label className="text-sm">Email</label>

                <input
                  label="Email"
                  value={advertiser.emailAddress}
                  variant="outlined"
                  className="form-style"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="space-y-1" xs={12}>
              <label className="text-sm">Company</label>
                <input
                  label="Company"
                  value={advertiser.company}
                  variant="outlined"
                  className="form-style"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="space-y-1" xs={6}>
              <label className="text-sm">Bid CPM</label>

                <input
                  label="Bid CPM"
                  value={advertiser.bidcpm}
                  variant="outlined"
                  className="form-style"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="space-y-1" xs={6}>
              <label className="text-sm">ECPM</label>

                <input
                  label="ECPM"
                  value={advertiser.ecpm}
                  variant="outlined"
                  className="form-style"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
          </div>
          </div>
          
          </div>
          
        )}
      </div>
    </div>
  );
};

export default AgencyViewAdvertiser;
