export const styles = {
  root: {
    marginTop: "0.6rem",
    "& .MuiTableCell-stickyHeader ": {
      borderBottom: "1px dashed #E3E5E6",
      background: "#fff",
    },
    minHeight: "43vh",
    maxHeight: "43vh",
    borderTop: "1px dashed #E3E5E6",
  },
  fullHeight: {
    maxHeight: "57vh",
    minHeight: "57vh",
  },

  tableHeader: {
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "left",
    height: "2rem",
    maxHeight: "2rem",
  },

  sort: {
    width: "2.4rem",
    height: "2.4rem",
  },
  expandLess: {
    transform: "translate(-50%,30%)",
    color: "#C6CACC",
  },
  expandMore: {
    transform: "translate(50%,-30%)",
    color: "#C6CACC",
  },
  tooltip: {
    transform: "translate(-20%,30%)",
  },
  label: {
    color: "#4B4B4B",
    fontSize: "0.78rem",
  },
  active: {
    color: "#6EBF4A",
  },
  render: {
    background: "green",
    maxHeight: "2rem",
    height: "2rem",
    padding: "0",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  maxHeight: {
    maxHeight: "51vh",
    minHeight: "51vh",
  },
  isAdvertiser: {
    maxHeight: "63vh",
    minHeight: "63vh",
  },
};
