

import { useEffect, useState } from "react";
import { fetchCreativeById } from "../../api/ssp-api";
import Loader from "../../components/loader";

export default function LinkBanners({ data, bannerData, handleLink }) {
  const bannerId = window.location.pathname.split("/")[3];
  const [banner, setBanner] = useState({});
  const [loading, setLoading] = useState(true);
  const [bannerUrl, setBannerUrl] = useState("");

    const { username, password } = JSON.parse(localStorage.getItem("ssp"));
    const credentials = `${username}:${password}`;
    const base64Credentials = btoa(credentials);

  useEffect(() => {
    fetchCreativeById(bannerId, base64Credentials)
      .then((res) => {
        console.log(res.data);
        setBanner(res.data);
        setLoading(false);
        setBannerUrl(
          `https://108.128.136.41:8080/www/images/${res.data.filename}`,
        );
      })
      .catch((err) => {
        console.log(err);
      });


  }, []);
  return (
    <div header={"Linked Zones"}>
      <div container spacing={2} style={{marginTop: '24px'}}>
        {loading || banner === undefined ? (
          <div item xs={12}>
            <Loader/>
          </div>
        ) : (
          <div item xs={9}>
            {banner.storageType === "web" ? (
              <img src={bannerUrl} width={banner.width+"px"} height={banner.height+"px"} />
            ) : (
              <iframe src={bannerUrl} width={banner.width+"px"} height={banner.height+"px"} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
