import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { navigation } from "./constants";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";



const styles = {
  color: "white",
  borderTopLeftRadius: "2rem",
  borderBottomLeftRadius: "2rem",
  fontSize: "0.3rem",
  marginBottom: "0.8rem",
  fontWeight: 300,
  "& span": {
    fontSize: "0.78rem",
  },
  "& .hoveredIcon": {
    color: "#6EBF4A",
    fill: "#6EBF4A",
  },
  "&:hover": {
    backgroundColor: "#4B4B4B",
    color: "#FDC33F",
    "& .hoveredIcon": {
      color: "#FDC33F",
      fill: "#FDC33F",
    },
  },
  "&.Mui-selected": {
    color: "#FDC33F",
    backgroundColor: "white",
    borderTopLeftRadius: "2rem",
    borderBottomLeftRadius: "2rem",
    marginBottom: "1.8rem",
    marginTop: "1.8rem",
    "&:hover": {
      backgroundColor: "white",
    },
    "& .hoveredIcon": {
      color: "#FDC33F",
      fill: "#FDC33F",
    },
  },
};

const Navigation = () => {
  const [nav, setNav] = useState(navigation);

  useEffect(() => {
    const user = localStorage.getItem("kwanza");
    let role;

    if (user) {
      try {
        const userObj = JSON.parse(user);
        role = userObj.role;
        console.log("role", role);
      } catch (error) {
        // Handle the error if the data is not in JSON format
        console.error("Error parsing user data:", error);
      }
    }
    setNav(nav);
  }, []);

  const classes = useStyles();
  const path = window.location.pathname.split("/");
  const pathname = "/" + path[1];

  return (
    <div className={classes.root}>
      <div>
        <Link to="/overview">
          <Logo className={classes.logo} />
        </Link>
      </div>

      <List className={classes.list}>
        {nav.map(({ path, name, icon }, index) => (
          <NavLink to={path} className={classes.navlink} key={index}>
            <ListItemButton selected={pathname === path} sx={styles}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{name}</ListItemText>
            </ListItemButton>
          </NavLink>
        ))}
        {/*<NavLink to="/settings" className={classes.navlink}>*/}
        {/*  <ListItem className={clsx(classes.listItem, classes.noMargin)} button>*/}
        {/*    <ListItemIcon>*/}
        {/*      <SettingIcon className="hoveredIcon" />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText className={classes.listText}>Settings</ListItemText>*/}
        {/*  </ListItem>*/}
        {/*</NavLink>*/}
        {/*/!*<hr className={classes.underline} />*!/*/}
        {/*<NavLink to="/support" className={classes.navlink}>*/}
        {/*  <ListItem className={clsx(classes.listItem, classes.noMargin)} button>*/}
        {/*    <ListItemIcon>*/}
        {/*      <SupportIcon className="hoveredIcon" />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText className={classes.listText}>*/}
        {/*      Customers Support*/}
        {/*    </ListItemText>*/}
        {/*  </ListItem>*/}
        {/*</NavLink>*/}
        <div container>
          <div item xs={5}>
            <Button className={classes.submitButton}>privacy</Button>
          </div>
          <div item xs={5}>
            <Button className={classes.submitButton}>terms</Button>
          </div>
        </div>
      </List>
      {/*<List className={classes.settings}>*/}

      {/*</List>*/}
    </div>
  );
};
export default Navigation;
