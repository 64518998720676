import React, { useContext, useEffect, useState } from "react";
import { MdOutlineChevronRight, MdOutlineChevronLeft } from "react-icons/md";

const CustomTable = ({ headings, data, actions, tableTitle }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const { filter, setFilter } = useState("");

  return (
    <div>
      <div className="bg-white px-5 py-5 w-full rounded-md border border-border">
        <div className="flex justify-between space-x-2">
          <h1 className="text-lg font-bold">{tableTitle}</h1>
          <input
            label="Filter by name or campaign name"
            placeholder="Search here"
            className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <table className="text-sm table w-full">
          <thead className="text-muted">
            <tr className="">
              {headings.map((item) => {
                return <th className="text-sm text-start py-3">{item}</th>;
              })}
            </tr>
          </thead>
          <tbody className="text-start">
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-black w-full  cursor-pointer">
                  {item.values.map((value) => {
                    return <td className="text-start py-3 px-0 ">{value}</td>;
                  })}
                  <td>{actions}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
          <h1 className=" translate-x-5">Rows per page</h1>
          <select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(e.target.value);
            }}
            className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
          >
            <option className="" value={5}>
              5
            </option>
            <option className="" value={8}>
              8
            </option>
          </select>
          <div
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
          >
            <MdOutlineChevronLeft />
          </div>
          <h1>
            {page + 1} out of {Math.ceil(data.length / rowsPerPage)}
          </h1>
          <div
            onClick={() => {
              if (page < Math.round(data.length / rowsPerPage)) {
                setPage(page + 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
          >
            <MdOutlineChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
