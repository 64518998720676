import { useEffect, setState, useContext, useState } from "react";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import GeneralStats from "./components/general-stats";
import MixedGraph from "../overview/mixed-graph";
import CustomTable from "../../components/custom-table";
import PieChart from "../overview/pie-chart";
import GroupedGraph from "../overview/grouped-graph";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import CustomModal from "../../components/custom-modal";
import papa from "papaparse";
import {
  addRecord,
  addTraditionalCampaign,
  deleteCampaignById,
  deleteTraditionalCampaign,
  deleteTraditionalCampaigns,
  getAdvertiserReport,
  getCampaigns,
  getTraditionalCampaigns,
  newAdvertiserFromCSV,
} from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { set } from "lodash";
import { chartColors } from "../audience/constant";
import { media, months } from "../../util/categories";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import Loader from "../../components/loader";
import DeleteButton from "../../components/deleteButton";
import { toast } from "react-toastify";

const GeneralReports = () => {
  const { setActions, setCurrentPage, setShowArrow } =
    useContext(BreadcrumbContext);
  const [show, setShow] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [uploading, setUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [campaigns, setCampaigns] = useState([]);

  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const company = queryParams.get("company");
  const { params } = useParams();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("2000-01-01");
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("yyy-MM-DD")
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show == false && showReportModal == false) {
      setShowArrow(true);
      setLoading(true);
      getAdvertiserReport(id, startDate, endDate).then((response) => {
        const results = response.data;
        console.log("Report", results);
        const graph1 = media.map((item) => {
          return {
            media: item,
            data: {
              impressions: results.records
                .map((e) =>
                  e.campaign_channel.channel.media == item ? e.impressions : 0
                )
                .reduce((acc, cur) => acc + cur, 0),
              spent: results.records
                .map((e) =>
                  e.campaign_channel.channel.media == item ? e.spent : 0
                )
                .reduce((acc, cur) => acc + cur, 0),
            },
          };
        });
        console.log("graph1", graph1);
        const graph2 = months.map((item) => {
          var monthlyData = results.records.filter(
            (e) => moment(e.record_date).format("MMM") === item
          );
          return {
            month: item,
            data:
              monthlyData.length > 0
                ? media.map((item) => {
                    return {
                      media: item,
                      data: {
                        impressions: monthlyData
                          .map((e) =>
                            e.campaign_channel.channel.media == item
                              ? e.impressions
                              : 0
                          )
                          .reduce((acc, cur) => acc + cur, 0),
                        spent: monthlyData
                          .map((e) =>
                            e.campaign_channel.channel.media == item
                              ? e.spent
                              : 0
                          )
                          .reduce((acc, cur) => acc + cur, 0),
                      },
                    };
                  })
                : media.map((item) => {
                    return {
                      media: item,
                      data: {
                        impressions: 0,
                        spent: 0,
                      },
                    };
                  }),
          };
        });
        console.log("graph2", graph2);
        response.data.graph1 = graph1;
        response.data.graph2 = graph2;
        console.log("report", response.data);
        setData(response.data);
        setLoading(false);
      });
    }
  }, [show, showReportModal, startDate, endDate, refresh]);

  useEffect(() => {
    if (show == false) {
      getTraditionalCampaigns(id)
        .then((response) => {
          console.log(response.data.traditionCampaigns);
          const data = response.data.traditionCampaigns;
          setCampaigns(data);
        })
        .catch((error) => {
          console.error("Error fetching campaigns:", error);
          setIsLoading(false);
        });
    }
  }, [show, refresh]);

  useEffect(() => {
    setActions(
      <div className="flex space-x-2 items-center">
        <DateRangePicker
          size="md"
          showOneCalendar
          onto
          onOk={(e) => {
            console.log(e);

            setStartDate(moment(e[0]).format("yyy-MM-DD"));
            setEndDate(moment(e[1]).format("yyy-MM-DD"));
          }}
          shouldDisableDate={DateRangePicker.afterToday()}
          ranges={[]}
          className="focus:border-primary"
        />
        <div
          className="py-2 px-3 text-sm bg-primary rounded-md items-center flex text-white font-semibold cursor-pointer"
          onClick={() => {
            setShow(true);
          }}
        >
          Add Campaign
        </div>
      </div>
    );
    setCurrentPage(company.replace(/-/g, " "));
  }, []);
  return loading ? (
    <Loader />
  ) : (
    data && (
      <div className="space-y-4">
        <GeneralStats data={data} />
        <div className=" grid grid-cols-12 gap-4">
          <div className="col-span-8">
            <div className=" bg-white rounded border border-border p-6">
              <div className="border-b border-border mb-2 py-2">
                <p className="text-muted">Activity</p>
                <h1 className="font-bold text-xl">Impressions</h1>
              </div>
              <MixedGraph
                values={data.graph1.map((item) => item.data.impressions)}
                height={420}
                yValuesName={"Impressions"}
                yValuesName2={"Spent"}
                values2={data.graph1.map((item) => item.data.spent)}
                xlabels={data.graph1.map((item) => item.media)}
              />
            </div>
          </div>
          <div className="col-span-4">
            <div className=" bg-white rounded border border-border  h-full p-5">
              <div className="border-b border-border mb-2 py-2">
                <p className="text-muted">Statistics</p>
                <h1 className="font-bold text-xl">Mentions</h1>
              </div>
              <PieChart
                labels={data.graph1.map((item) => item.media)}
                values={data.graph1.map((item) => item.data.impressions)}
              />
            </div>
          </div>
        </div>

        <div>
          <CustomModal
            setShow={setShow}
            content={
              <div className="w-full  flex justify-center items-center">
                {uploading ? (
                  <div
                    className={`border-4 border-t-transparent border-primary  rounded-full animate-spin size-12`}
                  ></div>
                ) : (
                  <label for="csvPicker" className="text-center cursor-pointer">
                    <lable>
                      <img src="/csv.webp" className="h-24" />{" "}
                    </lable>
                    <h1 className="text-muted">Upload CSV file</h1>
                  </label>
                )}

                <input
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file) {
                      papa.parse(file, {
                        complete: (result) => {
                          const data = result.data;
                          setUploading(true);
                          const futureList = data.map((item) => {
                            const payload = {
                              name: item.name,
                              budget: parseFloat(item.budget),
                              type: item.type,
                              start_date: moment(
                                new Date(item.start_date)
                              ).format("yyy-MM-DD"),
                              end_date: moment(new Date(item.end_date)).format(
                                "yyy-MM-DD"
                              ),
                              channels: `${item.channels}`
                                .split(",")
                                .map((item) => item.trim()),
                              advertiser_id: parseInt(id),
                            };
                            console.log("Campaign payload", payload);
                            return addTraditionalCampaign(payload);
                          });
                          Promise.all(futureList)
                            .then((res) => {
                              console.log("feedback", res);
                              setUploading(false);
                              setShow(false);
                              toast.success("Compaigns added successfully");
                            })
                            .catch((err) => {
                              console.log(err);
                              setUploading(false);
                              setShow(false);
                            });
                        },
                        header: true, // if your CSV has headers
                      });
                    }
                  }}
                  type="file"
                  id="csvPicker"
                  className=" sr-only"
                />
              </div>
            }
            show={show}
            heading={"Upload campaigns"}
          />
          <CustomModal
            setShow={setShowReportModal}
            content={
              <div className="w-full  flex justify-center items-center">
                {uploading ? (
                  <div
                    className={`border-4 border-t-transparent border-primary  rounded-full animate-spin size-12`}
                  ></div>
                ) : (
                  <label for="csvPicker" className="text-center cursor-pointer">
                    <lable>
                      <img src="/csv.webp" className="h-24" />{" "}
                    </lable>
                    <h1 className="text-muted">Upload CSV file</h1>
                  </label>
                )}

                <input
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file) {
                      papa.parse(file, {
                        complete: (result) => {
                          const data = result.data;
                          setUploading(true);
                          const futureList = data.map((item) => {
                            // campaign_name;count;impressions;AVE;mention;record_date
                            const payload = {
                              traditional_campaign_id: parseInt(
                                selectedCampaign.id
                              ),
                              AVE: parseFloat(item.AVE),
                              impressions: parseFloat(item.impressions),
                              count: parseFloat(item.count),
                              spent: parseFloat(item.spent),
                              mention: item.mention,
                              channel_name: item.channel_name,
                              record_date: moment(
                                new Date(item.record_date)
                              ).format("yyy-MM-DD"),
                            };
                            console.log("Payload", payload);
                            return addRecord(payload);
                          });
                          Promise.all(futureList)
                            .then((res) => {
                              console.log(res);
                              setUploading(false);
                              setShowReportModal(false);
                              toast.success("Records added successfully");
                            })
                            .catch((err) => {
                              console.log(err);
                              setShowReportModal(false);
                              setUploading(false);
                            });
                        },
                        header: true, // if your CSV has headers
                      });
                    }
                  }}
                  type="file"
                  id="csvPicker"
                  className=" sr-only"
                />
              </div>
            }
            show={showReportModal}
            heading={"Campaign reports"}
          />
          <div className="bg-white p-6 py-8 w-full rounded-md border border-border">
            <h1 className="font-bold text-xl">Campaigns</h1>

            <div className="flex justify-end space-x-2">
              <input
                label="Filter by name or campaign name"
                placeholder="Search here"
                className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <table className="text-sm table w-full">
              <thead className="text-muted">
                <tr className="">
                  <th className="py-3 text-sm text-start">Id</th>
                  <th className="text-sm text-start py-3">Name</th>
                  <th className="text-sm text-start py-3">Type</th>
                  {/* <th className="text-sm text-start py-3">Medias</th> */}
                  <th className="text-sm text-start py-3">budget</th>
                  <th className="text-sm text-start py-3">Start date</th>
                  <th className="text-sm text-start py-3">End date</th>
                  <th className="text-sm text-start py-3"></th>
                  <th className="text-sm text-start py-3"></th>
                </tr>
              </thead>
              <tbody className="text-start">
                {campaigns
                  .filter((item) =>
                    item.name.toLowerCase().includes(filter.toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-black w-full  cursor-pointer">
                      <td className="text-start py-3 px-0 ">{item.id}</td>
                      <td className="text-start py-3 px-0 ">{item.name}</td>
                      <td className="text-start py-3 px-0">{item.type}</td>
                      {/* <td className="text-start py-3 px-0 flex flex-wrap">
                        {item.campaign_channel
                          .map((item) => item.channel.media)
                          .map((item) => (
                            <div className="bg-gray-200 p-1 rounded-md me-2 mb-2">
                              {item}
                            </div>
                          ))}
                      </td> */}
                      <td className="text-start py-3 px-0">${item.budget}</td>
                      <td className="text-start py-3 px-0">
                        {moment(new Date(item.start_date)).format(
                          "yyy, MMM DD"
                        )}
                      </td>
                      <td className="text-start py-3 px-0">
                        {moment(new Date(item.end_date)).format("yyy, MMM DD")}
                      </td>
                      <td className="text-start px-0 py-3">
                        <button
                          className="font-bold py-1 px-2 bg-primary transition-all hover:bg-opacity-80 text-white rounded"
                          onClick={() => {
                            setSelectedCampaign(item);
                            setShowReportModal(true);
                          }}
                        >
                          Add Report
                        </button>
                      </td>
                      <td className="text-start px-0 py-3">
                        <button
                          className="font-bold py-1 px-2 bg-textColor bg-opacity-10 text-textColor transition-all hover:bg-opacity-20  rounded"
                          onClick={() => {
                            navigate(
                              `/traditional-campaign/reports/${item.id}?campaign=${item.name.replace(/ /g, "-")}`
                            );
                          }}
                        >
                          View Report
                        </button>
                      </td>
                      <td>
                        <DeleteButton
                          onClick={() => {
                            deleteTraditionalCampaign(item.id).then(
                              (response) => {
                                console.log(response);
                                setRefresh(refresh + 1);
                                toast.success("Campaign deleted successully");
                              }
                            );
                          }}
                        />
                      </td>
                      {/* <td className="text-start px-0 py-3"><button className="font-bold text-red-400 bg-red-100 py-1 rounded-md px-2" onClick={()=>handleDelete(item.id)}>Delete</button></td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
              <h1 className=" translate-x-5">Rows per page</h1>
              <select
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(e.target.value);
                }}
                className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
              >
                <option className="" value={5}>
                  5
                </option>
                <option className="" value={8}>
                  8
                </option>
              </select>
              <div
                onClick={() => {
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
              >
                <MdOutlineChevronLeft />
              </div>
              <h1>
                {page + 1} out of {Math.ceil(campaigns.length / rowsPerPage)}
              </h1>
              <div
                onClick={() => {
                  if (page < Math.round(campaigns.length / rowsPerPage)) {
                    setPage(page + 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
              >
                <MdOutlineChevronRight />
              </div>
            </div>
          </div>
        </div>

        <div className=" bg-white rounded border border-border  h-full p-4 py-8">
          <div className=" ">
            <h1 className="font-bold text-xl">Media Exposure</h1>
          </div>
          <GroupedGraph
            series={[
              {
                name: "Television",
                data: data.graph2.map(
                  (item) =>
                    item.data.filter((item) => item.media === "Television")[0]
                      .data.impressions
                ),
              },
              {
                name: "Radio",
                data: data.graph2.map(
                  (item) =>
                    item.data.filter((item) => item.media === "Radio")[0].data
                      .impressions
                ),
              },
              {
                name: "Billboard",
                data: data.graph2.map(
                  (item) =>
                    item.data.filter((item) => item.media === "Billboard")[0]
                      .data.impressions
                ),
              },
              {
                name: "Social",
                data: data.graph2.map(
                  (item) =>
                    item.data.filter((item) => item.media === "Social")[0].data
                      .impressions
                ),
              },
              {
                name: "Newspaper",
                data: data.graph2.map(
                  (item) =>
                    item.data.filter((item) => item.media === "Newspaper")[0]
                      .data.impressions
                ),
              },
              {
                name: "Blog",
                data: data.graph2.map(
                  (item) =>
                    item.data.filter((item) => item.media === "Blog")[0].data
                      .impressions
                ),
              },
            ]}
            xLabels={data.graph2.map((item) => item.month)}
          />

          <div className="flex space-x-3 justify-center items-center ">
            {media.map((item, index) => (
              <div className="flex space-x-1 justify-center items-center">
                <div
                  className="h-3 w-3 rounded-full "
                  style={{ background: chartColors[index] }}
                />
                <p className="text-muted text-sm">{item}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default GeneralReports;
