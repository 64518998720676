import { AiOutlineCalendar, AiOutlineDownload } from "react-icons/ai";
import { MdOutlineAddChart } from "react-icons/md";
import CustomBarGraph from "../../containers/overview/custom-bar-graph";
import CustomLineGraph from "../../containers/overview/custom-line-graph";
import { FaArrowTrendUp } from "react-icons/fa6";
import {
  animate,
  motion,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import { useEffect } from "react";
const RightHeroSection = () => {
  const x = useMotionValue(0);
  const balance = useMotionValue(0);
  const displayNumber = useTransform(x, (latest) => Math.round(latest));
  let controls;

  useEffect(() => {
    controls = animate(x, 140, { duration: 5, ease: "linear" });
    return () => controls.stop();
  });
  useEffect(() => {
    controls = animate(balance, 85211, { duration: 5, ease: "linear" });
    return () => controls.stop();
  });
  return (
    <div>
      <div className="grid grid-cols-12 gap-6">
        <div className=" col-span-5 flex flex-col items-end">
          <div
            onClick={() => {
              controls.play();
            }}
            className=" w-8/12 bg-[#494949] bg-opacity-60 p-5 text-center space-y-4 rounded-lg cursor-pointer "
          >
            <h1 className="text-lg">Engagement</h1>
            <div className="bg-white bg-opacity-10 py-2 px-2 rounded-lg ">
              <h1 className="text-sm">Total Clicks</h1>
              <div className="flex justify-center items-center font-bold text-xl text-primary">
                <motion.div className=" ">{displayNumber}</motion.div>K
              </div>
            </div>
          </div>
          <div className=" w-full bg-[#494949]  bg-opacity-60 p-5 text-start space-y-4 rounded-lg mt-6 ">
            <div>
              <div className=" flex justify-between items-center">
                <div className="text-start">
                  <div className="flex items-center space-x-1 ">
                    <MdOutlineAddChart className="text-xl" />
                    <h1>Impressions</h1>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <div className="size-6 rounded-full bg-white bg-opacity-15 flex justify-center items-center ">
                    <AiOutlineCalendar />
                  </div>
                  <div className="size-6 rounded-full bg-white bg-opacity-15 flex justify-center items-center ">
                    <AiOutlineDownload />
                  </div>
                </div>
              </div>
              <h1 className="font-bold text-2xl">5M</h1>
            </div>
            <CustomBarGraph
              values={[40, 20, 60, 80, 50, 60, 80]}
              xlabels={["J", "F", "M", "A", "M", "J", "J"]}
            />
          </div>
        </div>
        <div className=" col-span-7">
          <div className="w-9/12">
            <div className=" w-full bg-[#494949]  bg-opacity-60 p-5 text-start space-y-4 rounded-lg  ">
              <div className=" flex justify-between items-center">
                <div className="text-start">
                  <div className="flex items-center space-x-1 ">
                    <h1 className="text-lg">Impressions</h1>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <div className="size-6 rounded-full bg-white bg-opacity-15 flex justify-center items-center ">
                    <AiOutlineCalendar />
                  </div>
                </div>
              </div>
              <CustomLineGraph
                values={[70, 90, 70, 40, 50, 40, 90]}
                values2={[70, 30, 50, 90, 40, 20, 90]}
                xlabels={["J", "F", "M", "A", "M", "J", "J"]}
              />
            </div>
          </div>
          <div className=" w-full bg-[#494949]  bg-opacity-60 p-5 text-start space-y-4 rounded-lg mt-6 ">
            <div className=" flex justify-between items-center space-x-3">
              <div className="text-start size-20 flex justify-center items-center rounded-lg bg-white bg-opacity-10 ">
                <h1 className="text-3xl">14k</h1>
              </div>
              <div className="flex flex-col grow">
                <h1 className="text-lg mb-2">Company Growth</h1>
                <motion.div className="w-full h-2 bg-white bg-opacity-15 mb-2 rounded" />
                <motion.div
                  // animate={{
                  //   width: [30, 150],
                  //   transition: {
                  //     duration: 1.5,
                  //     repeat: Infinity,
                  //     repeatType: "reverse",
                  //     damping:300,
                  //     stiffness:100,
                  //     repeatDelay: 0.5,
                  //   },
                  // }}
                  className=" w-8/12 h-2 bg-white bg-opacity-15 rounded"
                />
              </div>
            </div>
          </div>
          <div className=" w-8/12 bg-[#494949]  bg-opacity-60 p-5 text-start space-y-4 rounded-lg mt-6 ">
            <div className=" flex justify-between items-center space-x-3">
              <div className="flex flex-col grow">
                <h1 className="text-base font-bold text-muted mb-2">
                  Balance Statistics
                </h1>
                <div className="flex justify-between">
                  <div>
                    <h1 className="text-lg font-bold mb-0">$85,211.00</h1>
                    <div className="flex space-x-2 items-center">
                      <FaArrowTrendUp className="text-primary" />
                      <h1 className="text-sm font-bold mb-2 text-primary">
                        66.5%
                      </h1>
                    </div>
                  </div>
                  <div>
                    <img src="/info.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightHeroSection;
