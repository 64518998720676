import Loader from "../../components/loader";
import { useContext, useEffect, useState } from "react";
import DspUserTable from "./dsp-users-table";
import { deleteSspUser, fetchSspUsers } from "../../api/ssp-api";
import { deleteDspUserApi, fetchDspUsersApi } from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";

export default function UserTab(sspUsers) {
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [dspUsers, setDspUsers] = useState([]);
  const [dspLoading, setDspLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const { token, accountId, agencyId } = JSON.parse(userDetails);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchUsers();
  }, [refresh]);

  const fetchUsers = () => {
    fetchDspUsersApi(token, agencyId)
      .then((response) => {
        console.log("DSP users", response);
        setDspUsers(response.data);
        setDspLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    fetchSspUsers(accountId)
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDelete = (dspId, sspId) => {
    console.log(dspId);
    console.log(sspId);

    deleteDspUserApi(dspId)
      .then((response) => {
        setRefresh(refresh + 1);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div sx={{ width: "100%", typography: "body1" }}>
      {dspLoading || dspUsers === undefined ? (
        <Loader />
      ) : (
        <DspUserTable data={dspUsers} handleDelete={handleDelete} />
      )}
    </div>
  );
}
