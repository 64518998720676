import AgencyWrapper from "../../../../../components/wrappers/agency-wrapper";

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageDropzone from "../image-dropzone";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../../../provider/AuthContext";
import { uploadSspImageBanner } from "../../../../../api/api";

export default function AgencyCreateBanner() {
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const base64Credentials = btoa(credentials);
  const [type, setType] = useState("");
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    image: null,
    agencyId: agencyId,
    destinationUrl: "",
  });
  const navigate = useNavigate();

  const handleTypeChange = (event) => {
    setType(event.target.value);
    if (event.target.value === 1) {
      setVisible(true);
    } else {
      navigate("/agency/banners/html/create");
    }
  };

  const handleFileSelect = (file) => {
    setSelectedFiles((prev) => [...prev, file]);
    setIsImageSelected(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setDisabled(true);

    if (!selectedFiles || selectedFiles.length === 0) {
      setError(true);
      toast.error("At least one banner is required");
      return;
    }

    if (formData.destinationUrl === "") {
      toast.error("Please fill in all the fields!", {
        position: "top-center",
      });
      return;
    }
    console.log("banner data:", formData);

    const uploadPromises = selectedFiles.map((selectedFile) => {
      formData.image = selectedFile.file;

      return uploadSspImageBanner(formData, token);
    });

    try {
      const responses = await Promise.all(uploadPromises);
      console.log("All banners uploaded:", responses);

      // Check if all responses are successful, then navigate
      if (responses.every((response) => response.status === 200)) {
        window.location.href = "/agency/banners";
      } else {
        console.error("Some uploads failed");
        toast.error("Some uploads failed", {
          position: "top-center",
        });
        setLoading(false);
        setDisabled(false);
      }
    } catch (error) {
      console.error("Error in uploading banners:", error);
      toast.error("Error in uploading banners" + error);
      setLoading(false);
      setDisabled(false);
    }
  };

  return (
    <div header={"Create Banner"}>
      <ToastContainer />
      <div
        container
        spacing={2}
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        
      >
        <div item>
          <InputLabel>Choose Banner Type</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="type"
            onChange={handleTypeChange}
          >
            <MenuItem value={1}>image</MenuItem>
            <MenuItem value={2}>html</MenuItem>
          </Select>
        </div>
        {visible && (
          <div
            item
            spacing={2}
            style={{
              marginTop: "8px",
              height: "400px",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div item style={{ width: "50%", marginBottom: "12px" }}>
              <ImageDropzone onImageUpload={handleFileSelect} />
            </div>
            {error ? (
              <div item xs={12}>
                <p>Creative is required</p>
              </div>
            ) : null}
            <div item style={{ width: "50%" }}>
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                label="Destination URL"
                name="destinationUrl"
                value={formData.destinationUrl}
                onChange={handleChange}
              />
            </div>
            <div
              item
              style={{ width: "50%", marginTop: "12px", marginBottom: "12px" }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={disabled}
                sx={{
                  backgroundColor: "#6EBF4A",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#6EBF4A",
                    color: "#ffffff",
                  },
                }}
                type="submit"
                onClick={handleSubmit}
              >
                {loading ? <CircularProgress size="2.0rem" /> : "Save"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
