import React, { Component } from "react";
import "./style.css";
import Logo from "../../../assets/logo.svg";
import { getIcon } from "../constant";
import { viewInvoiceApi } from "../../../api/api";
import { toDateString } from "../../../util";
import axios from "axios";

class InvoiceTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      tax: 0,
      channels: [],
      subtotal: 0,
      campaignName: "",
      dueDate: new Date(),
      invoiceDate: new Date(),
      advertiser: {},
      invoice: {},
      campaignAmount: 0,
    };
    this.pdfComponent = React.createRef();
  }
  componentDidMount() {
    viewInvoiceApi(this.props.invoiceId)
      .then((res) => {
        const {
          campaignAmount,
          total,
          channels,
          tax,
          subtotal,
          dueDate,
          campaignName,
          invoiceDate,
          advertiser,
          invoice,
        } = res.data || {};
        this.setState({
          campaignAmount,
          total,
          channels: channels || [],
          tax,
          subtotal,
          dueDate,
          campaignName,
          invoiceDate,
          advertiser: advertiser || {},
          invoice: invoice || {},
        });
      })
      .catch(console.log);
  }
  downloadFile = (path) => {
    axios({
      method: "GET",
      url: path,
    })
      .then(console.log)
      .catch(console.log);
  };
  savePdf = () => {
    this.pdfComponent.current.save();
  };
  render() {
    const {
      campaignAmount,
      total,
      channels,
      tax,
      subtotal,
      dueDate,
      campaignName,
      invoiceDate,
      advertiser,
      invoice,
    } = this.state;
    console.log(channels);
    console.log(advertiser.logo);
    if (advertiser.logo) {
      this.downloadFile(
        "https://gn20-images.s3.us-west-2.amazonaws.com/1629800010upload.png",
      );
    }

    return (
      <>
        <div className="root">
          <div className="head">
            <img src={Logo} alt="kwanza logo" className="image-logo" />
            <div className="kwanza-info">
              <div className="info-contents">
                <p className="network">Kwanza Advertising Network</p>
                <p className="contacts">clients@kwanza.co.tz</p>
                <p className="contacts">+255 786 520 788</p>
                <p className="contacts">www.kwanza.co.tz</p>
              </div>
            </div>
          </div>
          <div className="contents">
            <div className="invoice-header">
              <p>{invoice.title}</p>
              <p>INVOICE</p>
            </div>
            <div className="company-info">
              <div>
                <img src={advertiser.logo} alt="company logo" />
              </div>
              <div className="invoice-to">
                <p>Invoice to</p>
                <p>{advertiser.company}</p>
                <p>{advertiser.email}</p>
              </div>
              <div className="invoice-date">
                <p>
                  <span>Invoice</span>: {invoice.invoice_number}
                </p>
                <p>
                  <span>Date</span>: {toDateString(invoiceDate)}
                </p>
                <p>
                  <span>Due date</span> : {toDateString(dueDate)}
                </p>
              </div>
            </div>
            <table className="channels-table">
              <thead className="text-muted">
                <tr className="table-header">
                  <td className="first  table-col">Channel</td>
                  <td className="second  table-col">Description</td>
                  <td className="third table-col">
                    Amount(<span className="color-gr">USD</span>)
                  </td>
                </tr>
              </thead>
              <tbody>
                {channels.map(({ channel, description, amount }) => (
                  <tr>
                    <td className="first">
                      <div className="social-icon">
                        {getIcon(channel)}
                        <span> {channel}</span>
                      </div>
                    </td>
                    <td className="second">{description}</td>
                    <td className="third  bold-700">${amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="invoice-summary">
              <tbody>
                <tr className="invoice-table-header">
                  <td className="tx-5">Payment Method</td>
                  <td className="tx-3">Subtotal</td>
                  <td className="tx-2 bold-700">${subtotal}</td>
                </tr>
                <tr>
                  <td className="tx-5"></td>
                  <td className="tx-3">Tax</td>
                  <td className="tx-2 bold-700">${tax}</td>
                </tr>
                <tr>
                  <td className="tx-5"></td>
                  <td className="tx-3 final">Total Due</td>
                  <td className="tx-2 final bold-700">${total}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default InvoiceTemplate;
