import React, { useContext, useEffect, useState } from "react";

import { editAdvertiserApi, getAdvertiserByIdApi } from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import Loader from "../../components/loader";
import CustomButton from "../../components/customButton";
import { useParams } from "react-router-dom";

const ViewAdvertiser = () => {
  const { id } = useParams();
  const advertiserId = id;
  const [advertiser, setAdvertiser] = useState({});
  const [loading, setLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const [image, setImage] = useState(null);
  const { setCurrentPage, setActions } = useContext(BreadcrumbContext);

  useEffect(() => {
    setActions(<div></div>);
    setCurrentPage("Advertiser details");
    const { token, role } = JSON.parse(userDetails);
    getAdvertiserByIdApi(advertiserId, token)
      .then((res) => {
        setAdvertiser(res.data.advertiser);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [advertiserId]);

  return (
    <div container spacing={2}>
      {loading || advertiser === undefined ? (
        <Loader />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData();
            formData.append("name", e.target.name.value);
            formData.append("email", e.target.email.value);
            formData.append("company", e.target.company.value);
            formData.append("cpm", e.target.cpm.value);
            formData.append("ecpm", e.target.ecpm.value);
            if (image) {
              formData.append("logo", image);
            }
            editAdvertiserApi(formData, advertiserId);
          }}
          className="bg-white p-10 border border-border rounded"
        >
          <div className="space-y-1" xs={6}>
            <div className="flex justify-center">
              <label for="filePicker">
                <img
                  src={
                    image == null ? advertiser.logo : URL.createObjectURL(image)
                  }
                  alt="Company Logo"
                  className="rounded"
                  style={{ width: 100, height: 100, padding: "16px" }}
                />
              </label>
            </div>
            <input
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              type="file"
              id="filePicker"
              className="sr-only"
            />
            <div className="grid grid-cols-2 gap-4 ">
              <div className="space-y-1" xs={6}>
                <label className="text-sm">Name</label>

                <input
                  label="Name"
                  defaultValue={advertiser.contactName}
                  variant="outlined"
                  name="name"
                  className="form-style"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="space-y-1" xs={6}>
                <label className="text-sm">Email</label>

                <input
                  label="Email"
                  defaultValue={advertiser.emailAddress}
                  variant="outlined"
                  name="email"
                  className="form-style"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="space-y-1" xs={12}>
                <label className="text-sm">Company</label>
                <input
                  label="Company"
                  defaultValue={advertiser.company}
                  variant="outlined"
                  name="company"
                  className="form-style"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="space-y-1" xs={6}>
                <label className="text-sm">Bid CPM</label>

                <input
                  label="Bid CPM"
                  defaultValue={advertiser.bidcpm}
                  variant="outlined"
                  name="cpm"
                  className="form-style"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="space-y-1" xs={6}>
                <label className="text-sm">ECPM</label>
                <input
                  label="ECPM"
                  defaultValue={advertiser.ecpm}
                  variant="outlined"
                  className="form-style"
                  name="ecpm"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
          </div>
          <CustomButton className={"mt-3"} text={"Save Changes"} />
        </form>
      )}
    </div>
  );
};

export default ViewAdvertiser;
