import { set } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ImpressionsGraph = ({ campaigns }) => {
  const [year, setYear] = useState(moment(Date.now()).format("yyy")-1);
  const [impressionsByMonth, setImpressionsByMonth] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [clicksByMonth, setClicksByMonth] = useState([
    0, 15000, 5000, 0, 20000, 30458, 0, 0, 50000, 0, 1000, 0,
  ]);
  useEffect(() => {
    setImpressionsByMonth(getImpressionsByMonth);
    // setClicksByMonth(getClicksByMonth)
  }, [year,campaigns]);
  var getImpressionsByMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
    (month) => {
      return campaigns.reduce((acc, campaign) => {
        if (
          parseInt(moment(campaign.startDate).month()) === month &&
          parseInt(moment(campaign.startDate).year()) === parseInt(year)
        ) {
          return acc + campaign.totalImpressions;
        }
        return acc;
      }, 0);
    }
    
  );
  var getClicksByMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
    (month) => {
      return campaigns.reduce((acc, campaign) => {
        if (
          parseInt(moment(campaign.startDate).month()) === month &&
          parseInt(moment(campaign.startDate).year()) === parseInt(year)
        ) {
          return acc + campaign.totalClicks;
        }
        return acc;
      }, 0);
    }
    
  );
  console.log(impressionsByMonth);
  const chartData = {
    series: [
      {
        name: "Impressions",
        data: impressionsByMonth,
      },
      {
        name: "Clicks",
        data: clicksByMonth,
      },
    ],
    options: {
      animations: {
        enabled: true,
      },
      chart: {
        height: 450,
        type: "area",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: campaigns.map((campaign) =>campaign.campaign),
      },

      colors: ["#6DBF48","#FAB005"], // Adding the color green
    },
  };

  return (
    <div className="p-5 bg-white border border-border rounded-md">
      <div className="flex justify-between">
        <h1 className="font-bold text-xl">Impressions and Clicks</h1>
        <select
          defaultValue={year}
          onChange={(e) => {
            setYear(e.target.value);
          }}
          className="text-sm border border-border font-medium focus:border-primary focus:ring-primary rounded-md bg-white"
        >
          {[
            moment(Date.now()).format("yyy"),
            moment(Date.now()).format("yyy") - 1,
            moment(Date.now()).format("yyy") - 2,
            moment(Date.now()).format("yyy") - 3,
            moment(Date.now()).format("yyy") - 4,
          ].map((item) => {
            return <option>{item}</option>;
          })}
        </select>
      </div>

      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
      />
    </div>
  );
};

export default ImpressionsGraph;
