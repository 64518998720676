import { DateTime } from "luxon";

export function toDate(date) {
  console.log(new Date(date).toISOString());
  return DateTime.fromISO(new Date(date).toISOString()).toFormat(
    "LLL, dd yyyy",
  );
}

export function toDateString(date) {
  return DateTime.fromISO(date).toFormat("yyyy-LL-dd");
}

export function toDateFormat(date) {
  return DateTime.fromISO(date).toFormat("dd-LL-yyyy");
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function filterLifetime(campaigns) {
  let start = 0;
  let end = 5;
  let activeCampaigns = campaigns.slice(start, end);
  return { campaigns, activeCampaigns };
}

export function toDays(expireTime, activateTime) {
  console.log("expireTime:", expireTime);
  console.log("activateTime:", activateTime);

  const differenceInMilliseconds =
    new Date(expireTime).getTime() - new Date(activateTime).getTime();
  console.log("differenceInMilliseconds:", differenceInMilliseconds);

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  console.log("differenceInDays:", differenceInDays);

  return differenceInDays;
}

// export function toDays(expireTime, activateTime) {
//   return (
//       (new Date(expireTime).getTime() - new Date(activateTime).getTime()) / (1000 * 60 * 60 * 24)
//     // (DateTime.fromISO(new Date(expireTime).toISOString()).toMillis() -
//     //   DateTime.fromISO(new Date(activateTime).toISOString()).toMillis()) /
//     // (1000 * 60 * 60 * 24)
//   );
// }

export function filterPastMonth(campaigns) {
  let start = 0;
  let end = 5;
  let startMillis = DateTime.local().minus({ days: 28 }).toMillis();
  let filteredCampaigns = campaigns.filter(({ startDate }) => {
    let millis = DateTime.fromISO(new Date(startDate).toISOString()).toMillis();
    return startMillis <= millis;
  });
  let activeCampaigns = filteredCampaigns.slice(start, end);
  return { campaigns: filteredCampaigns, activeCampaigns };
}

export function filterRange(campaigns, startDate, endDate) {
  let start = 0;
  let end = 5;
  let startMillis = DateTime.fromISO(
    new Date(startDate).toISOString(),
  ).toMillis();
  let endMillis = DateTime.fromISO(new Date(endDate).toISOString()).toMillis();
  let filteredCampaigns = campaigns.filter(({ startDate }) => {
    let millis = DateTime.fromISO(new Date(startDate).toISOString()).toMillis();
    return startMillis <= millis && millis < endMillis;
  });
  let activeCampaigns = filteredCampaigns.slice(start, end);
  return { campaigns: filteredCampaigns, activeCampaigns };
}

export function summary(campaigns) {
  let totalImpressions = 0,
    totalCtr = 0,
    totalClicks = 0,
    totalSpent = 0;
  let count = campaigns.length;
  campaigns.forEach(({ ctr, clicks, impressions, cost }) => {
    totalCtr += parseFloat(ctr) / count;
    totalClicks += parseInt(clicks);
    totalImpressions += parseInt(impressions);
    totalSpent += parseFloat(cost);
  });
  return {
    totalCtr: (totalImpressions && (totalClicks / totalImpressions) * 100) || 0,
    totalClicks,
    totalImpressions,
    totalSpent,
  };
}

export function getPrefixBeforeDomain(email) {
  const parts = email.split(".");
  if (parts.length >= 2) {
    return parts[0];
  } else {
    return email;
  }
}

export function findAdvertiserId(username, advertisers) {
  for (const advertiser of advertisers) {
    const regex = new RegExp(advertiser.advertiserName);
    if (regex.test(username)) {
      return advertiser.advertiserId;
    }
    // if (advertiser.emailAddress.includes(username)) {
    //   return advertiser.advertiserId;
    // }
  }
  return null; // Return null if no match is found
}

export function formatDate(date) {
  const dateObject = new Date(date);
  return dateObject.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}
