import { useState } from "react";

import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../../../assets/close.svg";
import clsx from "clsx";


const ClearInvoices = ({ open, onClose }) => {
  const [invoiceCleared, setInvoiceCleared] = useState(false);
  const classes = useStyles();
  const onClearInvoice = () => {
    setInvoiceCleared(true);
  };
  return (
    <Modal open={open} className={classes.root}>
      <Paper className={classes.paper}>
        <div xs={12} className={classes.content}>
          <div xs={12} container justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className={classes.description}>
            {!invoiceCleared ? (
              <>
                <Typography className={classes.header}>
                  Clear Invoices
                </Typography>
                <Typography className={classes.warning}>
                  Are you sure you want to clear this invoice, this
                </Typography>
                <Typography className={classes.warning}>
                  action is irreversable
                </Typography>
                <div
                  container
                  justifyContent="space-between"
                  className={classes.actionsContainer}
                >
                  <Button className={classes.actionButtons} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className={clsx(
                      classes.actionButtons,
                      classes.submitButton,
                    )}
                    onClick={onClearInvoice}
                  >
                    Clear Invoice
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography className={classes.header}>
                  Invoice Cleared
                </Typography>
                <Typography className={classes.warning}>
                  1 Invoice Cleared
                </Typography>
              </>
            )}
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

ClearInvoices.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ClearInvoices;
