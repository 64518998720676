import { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import CustomButton from "../../components/customButton";
import { addGig, addInfluencer, getGigs, getInfluencerId } from "../../api/api";
import CustomModal from "../../components/custom-modal";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import NoData from "../../components/noData";
import ModifyButton from "../../components/modify-button";

const InfluencerGigs = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);
  const { setActions, setCurrentPage } = useContext(BreadcrumbContext);
  const [uploading, setUploading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams();

  useEffect(() => {
    getInfluencerId(userId).then((response) => {
      console.log(response);
      setData(response.data.data.gig);
    });
  }, []);

  useEffect(() => {
    setCurrentPage("Influencer Gigs");
    setActions(
      <CustomButton
        onClick={() => {
          setShowCreateModal(true);
        }}
        text={"Add Gig"}
      />
    );
  }, []);
  return (
    <div>
      <CustomModal
        heading={"New Gig"}
        content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const payload = {
                userId,
                name: e.target.name.value,
                description: e.target.description.value,
                price: e.target.price.value,
              };
              setUploading(true);
              addGig(payload).then((data) => {
                console.log(data);
                setUploading(false);
              });
            }}
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label>Gig title</label>
                <input
                  required
                  name="name"
                  placeholder="Enter gig title"
                  className="form-style"
                />
              </div>
              <div>
                <label>Price</label>
                <input
                  required
                  name="price"
                  placeholder="Enter price"
                  className="form-style"
                />
              </div>
              <div className="col-span-2">
                <label>Description</label>
                <textarea
                  required
                  name="description"
                  placeholder="Enter description"
                  className="form-style"
                />
              </div>
            </div>
            <div className="mt-5">
              <CustomButton loading={uploading} text={"Add Gig"} />
            </div>
          </form>
        }
        setShow={setShowCreateModal}
        show={showCreateModal}
      />
      {data.length < 1 ? (
        <NoData />
      ) : (
        <div>
          <div className="bg-white px-5 py-5 w-full rounded-md border border-border ">
            <div className="flex justify-end space-x-2">
              <input
                label="Filter by name or campaign name"
                placeholder="Search here"
                className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <table className="text-sm table w-full mt-4">
              <thead className="text-muted">
                <tr className="">
                  <th className="text-sm text-start py-3">id</th>
                  <th className="text-sm text-start py-3">Gig title</th>
                  <th className="text-sm text-start py-3">Gig description</th>
                  <th className="text-sm text-start py-3">Price</th>
                  <th className="text-sm text-start py-3">Created At</th>
                  <th className="text-sm text-start py-3"></th>
                  <th className="text-sm text-start py-3"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="text-start">
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                      <td className="text-start py-3 px-0 ">{item.id}</td>
                      <td className="text-start py-3 px-0 ">{item.name}</td>
                      <td className="text-start py-3 px-0 ">
                        {item.description}
                      </td>
                      <td className="text-start py-3 px-0 ">{item.price}</td>
                      <td className="text-start py-3 px-0 ">
                        {item.created_at}
                      </td>
                      <td className="text-start py-3 px-0 ">
                        <CustomButton
                          onClick={() => {
                            navigate(`/gig/posts/${item.id}`);
                          }}
                          text={"Gig Posts"}
                        />
                      </td>
                      <td className="text-start py-3 px-0 "></td>

                      <td className="text-start px-0 py-3">
                        <ModifyButton
                          id={item.publisher_id}
                          onDelete={() => {
                            //   handleDelete(item.publisher_id);
                          }}
                          onEdit={() => {
                            //   navigate(`/websites/${item.publisher_id}`);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
              <h1 className=" translate-x-5">Rows per page</h1>
              <select
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(e.target.value);
                }}
                className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
              >
                <option className="" value={5}>
                  5
                </option>
                <option className="" value={8}>
                  8
                </option>
              </select>
              <div
                onClick={() => {
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
              >
                <MdOutlineChevronLeft />
              </div>
              <h1>
                {page + 1} out of {Math.ceil(data.length / rowsPerPage)}
              </h1>
              <div
                onClick={() => {
                  if (page < Math.round(data.length / rowsPerPage)) {
                    setPage(page + 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
              >
                <MdOutlineChevronRight />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfluencerGigs;
