import { useState } from "react";
import { IoClose } from "react-icons/io5";

const Iframe = ({ item,setShow }) => {
  return (
    <div onClick={()=>{
        setShow(true)
    }}>
     
     
    </div>
  );
};

export default Iframe;
