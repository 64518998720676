import { ReactComponent as AdvertIcon } from "../../assets/advert.svg";
import { ReactComponent as ChartIcon } from "../../assets/chart.svg";
import { ReactComponent as OverviewIcon } from "../../assets/overview.svg";
import { ReactComponent as PrintIcon } from "../../assets/print.svg";
import { ReactComponent as CampaignIcon } from "../../assets/campaigns.svg";
import { ReactComponent as UserIcon } from "../../assets/users.svg";
import { ReactComponent as BannerIcon } from "../../assets/creatives.svg";
import { ReactComponent as PaymentIcon } from "../../assets/payment.svg";
import { ReactComponent as BrowserIcon } from "../../assets/browser.svg";
import { ReactComponent as ZoneIcon } from "../../assets/zone.svg";
import { ReactComponent as AgencyIcon } from "../../assets/advertising.svg";
import { FaChartArea } from "react-icons/fa";
import { PiDotsNineBold, PiSpeakerSimpleHigh } from "react-icons/pi";
import { GiChart } from "react-icons/gi";
import { MdOutlineSsidChart } from "react-icons/md";
import { SlPicture } from "react-icons/sl";
import { FiUsers } from "react-icons/fi";
import { CiMoneyCheck1 } from "react-icons/ci";
import { TbMoneybag } from "react-icons/tb";
import {
  LiaFileInvoiceDollarSolid,
  LiaPersonBoothSolid,
} from "react-icons/lia";
import { RiFolderChartLine, RiInstagramLine } from "react-icons/ri";
import { SiAmazonec2 } from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { GoPeople } from "react-icons/go";
import { BsPersonRolodex } from "react-icons/bs";
import {
  LuAreaChart,
  LuFrame,
  LuLayoutDashboard,
  LuLayoutTemplate,
  LuRocket,
  LuSendToBack,
  LuShapes,
} from "react-icons/lu";

export const navigation = [
  {
    path: "/overview",
    roles: ["admin", "agency"],
    routes: ["/", "overview"],
    name: "Overview",
    icon: <LuLayoutDashboard />,
    children: [],
  },
  {
    path: "/campaigns",
    roles: ["admin"],
    routes: ["campaigns", "report", "dsp"],
    name: "DSP Campaigns",
    icon: <LuAreaChart />,
    children: [],
  },
  {
    path: "/ssp",
    roles: ["admin", "agency", "advertiser", "publisher"],
    routes: ["ssp"],
    name: "SSP Campaigns",
    icon: <MdOutlineSsidChart />,
    children: [],
  },

  {
    path: "/banners",
    roles: ["admin", "agency", "advertiser"],
    name: "Banners",
    routes: ["banners"],
    icon: <LuLayoutTemplate />,
    children: [],
  },
  {
    path: "/agencies",
    roles: ["admin"],
    routes: ["agencies"],
    name: "Agencies",
    icon: <LuShapes />,
    children: [],
  },
  {
    path: "/advertiser",
    roles: ["admin", "agency"],
    routes: ["advertiser"],
    name: "Advertisers",
    icon: <LuRocket />,
    children: [],
  },
  {
    path: "/audience",
    roles: ["admin"],
    routes: ["audience"],
    name: "Audiences",
    icon: <LuSendToBack />,
    children: [],
  },
  {
    path: "/websites",
    roles: ["admin", "agency"],
    routes: ["websites"],
    name: "Websites",
    icon: <CgWebsite />,
    children: [],
  },

  {
    path: "/zones",
    roles: ["admin", "agency"],
    routes: ["zones"],
    name: "Zones",
    icon: <LuFrame />,
    children: [],
  },

  {
    path: "/reports",
    roles: ["admin"],
    routes: ["reports"],
    name: "Reports",
    icon: <RiFolderChartLine />,
    children: [
      {
        name: "Companies",
        routes: ["companies"],
        path: "/reports/companies",
      },
      {
        name: "Channels",
        routes: ["channels"],
        path: "/reports/channels",
      },
    ],
  },
  {
    path: "/influencers",
    roles: ["admin"],
    routes: ["influencers"],
    name: "Influencers",
    icon: <RiInstagramLine />,
    children: [],
  },
  {
    path: "/invoices",
    roles: ["admin", "agency", "publisher"],
    routes: ["invoices"],
    name: "Invoices",
    icon: <LiaFileInvoiceDollarSolid />,
    children: [],
  },
  // {
  //   path: "/billing",
  //   roles: ["admin"],
  //   routes: ["billing"],
  //   name: "Billing",
  //   icon: <TbMoneybag />,
  // children:[]},
  {
    path: "/users",
    roles: ["admin", "agency"],
    routes: ["users"],
    name: "Users",
    icon: <FiUsers />,
    children: [],
  },
];
