import { useContext, useEffect, useState } from "react";
import CustomTable from "../../components/custom-table";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import CustomModal from "../../components/custom-modal";
import { FaFileCsv, FaFileImport } from "react-icons/fa";
import papa from "papaparse";
import { getAdvertisers, newAdvertiserFromCSV } from "../../api/api";
import Spinner from "../../components/spinner";
import { AuthContext } from "../../provider/AuthContext";
import Loader from "../../components/loader";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CompaniesList = () => {
  const { setActions, setCurrentPage, setOverrideHeading, setShowArrow } =
    useContext(BreadcrumbContext);
  const [show, setShow] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const [advertisers, setAdvertisers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setShowArrow(false);
    if (show == false) {
      getAdvertisers(token, agencyId)
        .then((response) => {
          // const data = new Map(Object.entries(response.data.advertisers));
          const advertisers = response.data.advertisers;
          console.log(advertisers);
          setAdvertisers(advertisers);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching advertisers:", error);
          setIsLoading(false);
        });
    }
  }, [show]);

  useEffect(() => {
    setCurrentPage("Companies");
    setActions(
      <div className="flex space-x-2">
        <div
          className="py-2 px-3 text-sm bg-primary rounded-md items-center flex text-white font-semibold cursor-pointer"
          onClick={() => {
            setShow(true);
          }}
        >
          Add Companies
        </div>
      </div>
    );
  }, []);
  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <CustomModal
        setShow={setShow}
        content={
          <div className="w-full  flex justify-center items-center">
            {uploading ? (
              <div
                className={`border-4 border-t-transparent border-primary  rounded-full animate-spin size-12`}
              ></div>
            ) : (
              <label for="csvPicker" className="text-center cursor-pointer">
                <lable>
                  <img src="/csv.webp" className="h-24" />{" "}
                </lable>
                <h1 className="text-muted">Upload CSV file</h1>
              </label>
            )}

            <input
              onChange={async (e) => {
                const file = e.target.files[0];
                if (file) {
                  papa.parse(file, {
                    complete: (result) => {
                      const data = result.data;
                      setUploading(true);
                      const futureList = data.map((item) => {
                        const payload = {
                          contactName: item.name,
                          emailAddress: item.email,
                          company: item.company,
                          agencyId,
                        };
                        return newAdvertiserFromCSV(payload);
                      });
                      Promise.all(futureList)
                        .then((res) => {
                          console.log(res);
                          setUploading(false);
                          setShow(false);
                          toast.success("Companies added successfully");
                        })
                        .catch((err) => {
                          console.log(err);
                          setUploading(false);
                        });
                    },
                    header: true, // if your CSV has headers
                  });
                }
              }}
              type="file"
              id="csvPicker"
              className=" sr-only"
            />
          </div>
        }
        show={show}
        heading={"Upload companies"}
      />
      <div className="bg-white p-6 w-full rounded-md border border-border">
        <div className="flex justify-end space-x-2">
          <input
            label="Filter by name or campaign name"
            placeholder="Search here"
            className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <table className="text-sm table w-full">
          <thead className="text-muted">
            <tr className="">
              <th className="py-3 text-sm text-start">Id</th>
              <th className="text-sm text-start py-3">Name</th>
              <th className="text-sm text-start py-3">Company</th>
              <th className="text-sm text-start py-3">Email</th>
              <th className="text-sm text-start py-3"></th>
              <th className="text-sm text-start py-3"></th>
            </tr>
          </thead>
          <tbody className="text-start">
            {advertisers
              .filter((item) =>
                item.advertiser.toLowerCase().includes(filter.toLowerCase())
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-black w-full  cursor-pointer">
                  <td className="text-start py-3 px-0 ">{item.id}</td>
                  <td className="text-start py-3 px-0 ">{item.advertiser}</td>
                  <td className="text-start py-3 px-0 ">{item.company}</td>
                  <td className="text-start py-3 px-0">{item.email}</td>
                  <td className="text-start px-0 py-3">
                    <button
                      className="font-bold py-1 px-2 bg-primary hover:bg-opacity-80 text-white rounded"
                      onClick={() => {
                        navigate(
                          `/general/reports/${item.id}?company=${item.company.replace(/ /g, "-")}`
                        );
                      }}
                    >
                      View Report
                    </button>
                  </td>
                  {/* <td className="text-start px-0 py-3"><button className="font-bold text-red-400 bg-red-100 py-1 rounded-md px-2" onClick={()=>handleDelete(item.id)}>Delete</button></td> */}
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
          <h1 className=" translate-x-5">Rows per page</h1>
          <select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(e.target.value);
            }}
            className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
          >
            <option className="" value={5}>
              5
            </option>
            <option className="" value={8}>
              8
            </option>
          </select>
          <div
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
          >
            <MdOutlineChevronLeft />
          </div>
          <h1>
            {page + 1} out of {Math.ceil(advertisers.length / rowsPerPage)}
          </h1>
          <div
            onClick={() => {
              if (page < Math.round(advertisers.length / rowsPerPage)) {
                setPage(page + 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
          >
            <MdOutlineChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesList;
