import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState,useContext } from "react";
import {
  fetchAdvertiserCampaignStatsApi,
  fetchAdvertiserPublisherStatsApi,
  fetchCampaignDailyStatsApi,
  fetchPublisherBannerStatsApi,
  fetchPublisherWebsites,
  fetchZoneStatsApi,
  getRoles,
} from "../../../../../api/ssp-api";
import PublisherStatsTable from "./publisher-stats-table";
import ZoneDataTable from "./zone-stats-table";
import CampaignSummary from "./campaign-summary";
import { useInvoiceData } from "../../../../../provider/InvoiceContext";
import BannerStatsTable from "./banner-stats-table";
import { addSspInvoice } from "../../../../../api/api";
import { BreadcrumbContext } from "../../../../../layouts/dashboardLayout";
import Loader from "../../../../../components/loader";

export default function AgencySspCampaignData() {
  const { id, startDate, advertiserId } = useParams();
  const [loading, setLoading] = useState(true);
  const [publisherLoading, setPublisherLoading] = useState(true);
  const [zoneLoading, setZoneLoading] = useState(true);
  const [zoneData, setZoneData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [publisherData, setPublisherData] = useState([]);
  const navigate = useNavigate();
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [role, setRole] = useState("");
  const { addData } = useInvoiceData();
  const [disabled, setDisabled] = useState(true);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [bannerLoading, setBannerLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const user = localStorage.getItem("kwanza");
  const { token, agencyId } = user ? JSON.parse(user) : {};
  const {setCurrentPage } = useContext(BreadcrumbContext)

  useEffect(() => {
    setCurrentPage("Campaign details")
    setRole(getRoles());
    let userRole = getRoles();

    fetchCampaignDailyStatsApi(id, startDate, base64Credentials)
      .then((response) => {
        setCampaignData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchAdvertiserCampaignStatsApi(advertiserId, base64Credentials)
      .then((response) => {
        setCampaignSummary(response.data.campaigns.data);
        setSummaryLoading(false);
        setDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchAdvertiserPublisherStatsApi(advertiserId, startDate, base64Credentials)
      .then((response) => {
        setPublisherData(response.data.publishers.data);
        setPublisherLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchZoneStatsApi(advertiserId, startDate, base64Credentials)
      .then((response) => {
        setZoneData(response.data.zones.data);
        setZoneLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    updateBannerStats(id, base64Credentials);
  }, []);

  async function updateBannerStats(id, base64Credentials) {
    try {
      const response = await fetchPublisherWebsites(
        base64Credentials,
        agencyId,
      );
      let publishers = response.data;

      for (const publisher of publishers) {
        const statsResponse = await fetchPublisherBannerStatsApi(
          publisher.publisherId,
          startDate,
          base64Credentials,
        );
        let bannerStats = statsResponse.data.banners.data.filter(
          (banner) => banner.campaignid === id,
        );
        bannerStats = bannerStats.map((bannerStat) => ({
          ...bannerStat,
          publisherId: publisher.publisherId,
          publisherName: publisher.publisherName, // Assuming 'name' is the property for the publisher's name
        }));

        setBannerData((prevData) => [...prevData, ...bannerStats]);
        setBannerLoading(false);
        // Update your stats table here
        // For example: updateStatsTable(banner.bannerid, bannerStats);
      }
    } catch (error) {
      console.error("Error fetching or updating banner stats:", error);
    }
  }

  const calculateSummary = (data) => {
    let totalImpressions = 0;
    let totalClicks = 0;
    let totalRevenue = 0;

    for (const entry of data) {
      totalImpressions += parseInt(entry.impressions);
      totalClicks += parseInt(entry.clicks);
      totalRevenue += parseFloat(entry.revenue);
    }

    return [
      {
        impressions: totalImpressions.toString(),
        clicks: totalClicks.toString(),
        revenue: totalRevenue.toFixed(4),
      },
    ];
  };

  const handleInvoiceGeneration = () => {
    const data = {
      campaignId: id,
      startDate: startDate,
      advertiserId: advertiserId,
      campaignName: campaignSummary.campaigns.data[0].campaignname,
      campaignTotal: campaignSummary.campaigns.data[0].revenue,
      campaignImpressions: campaignSummary.campaigns.data[0].impressions,
      campaignClicks: campaignSummary.campaigns.data[0].clicks,
    };

    addData(data);

    addSspInvoice(data, base64Credentials)
      .then((response) => {
        console.log(response);
        navigate("/ssp/invoice");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div header={"Campaign Data"}>
      <div className="space-y-4" style={{ marginTop: "24px",  overflowY: "auto" }}>
        
        <div className="space-y-1">
          <h4>Campaign Summary</h4>
          {summaryLoading || campaignSummary === undefined ? (
            <Loader/>
          ) : (
            <CampaignSummary data={campaignSummary} role={role} />
          )}
        </div>
        <div className="space-y-1">
          <h4 >Publisher Data</h4>
          {publisherLoading || publisherData === undefined ? (
            <Loader />
          ) : (
            <PublisherStatsTable data={publisherData} role={role} />
          )}
        </div>

        <div className="space-y-1">
          <h4>Zone Data</h4>
          {zoneLoading || zoneData === undefined ? (
            <Loader />
          ) : (
            <ZoneDataTable data={zoneData} role={role} />
          )}
        </div>
        <div className="space-y-1">
          <h4>Banner Data</h4>
          {bannerLoading || bannerData === undefined ? (
            <Loader />
          ) : (
            <BannerStatsTable data={bannerData} role={role} />
          )}
        </div>
      </div>
    </div>
  );
}
