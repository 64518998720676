import React, { useState, useEffect, useContext } from "react";
import { getKwanzaStatsAPI } from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import { useNavigate } from "react-router-dom";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";

function Reports({ classes }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  const [selectedTab,setSelectedTab] = useState(0)
  const {setActions} = useContext(BreadcrumbContext)


  useEffect(() => {
    setActions(<div></div>)
    const { token } = JSON.parse(userDetails);
    setIsLoading(true);
    getKwanzaStatsAPI(token)
      .then((res) => {
        setActiveCampaigns(res.data.campaigns);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



  const viewCampaign = (id) => {
    navigate(`/report/${id}`);
  };

  
  return (
    <div>
      <div className="flex justify-between">
      <div className="flex space-x-4 ">
          {["All","Completed"].map((item,index)=>{
            return <div className="cursor-pointer space-y-1">
              <div onClick={()=>{
              setSelectedTab(index)
            }} className={`${index==selectedTab?"text-primary":""}`}>{item}</div>
            {index==selectedTab&&<div className="w-full h-1 bg-primary"/>}
            </div>
          })}
        </div>
        <select className="border border-border rounded-md py-2 text-sm focus:border-primary focus:ring-primary">
          <option value="Lifetime">Lifetime</option>
          <option value="Lifetime">Past Month</option>
          <option value="Lifetime">Custom</option>
        </select>
      </div>
     <div className="bg-white px-5 py-5 w-full rounded-md mt-4">
      <div className="flex justify-end items-center space-x-2 text-white text-sm">
        <div>
        <button className="bg-primary py-1 px-3 rounded">CVS (0)</button>
        </div>
        <div>
        <button className="bg-primary py-1 px-3 rounded">PDF (0)</button>
        </div>
        <input className="border border-border rounded-md py-2 text-sm focus:border-primary focus:ring-primary" placeholder="Search here"/>
      </div>
<div className="mt-4">
<table className="text-sm table w-full">
            <thead className="text-muted">
              <tr className="">
                <th className="py-3 text-sm text-start">Campaign</th>
                <th className="py-3 text-sm text-start">Status</th>
                <th className="text-sm text-start py-3">Impressions</th>
                <th className="text-sm text-start py-3">Expected Impressions</th>
                <th className="text-sm text-start py-3">Clicks</th>
                <th className="text-sm text-start py-3">CRT</th>
                <th className="text-sm text-start py-3">Start Date</th>
                <th className="text-sm text-start py-3">End Date</th>

              </tr>
            </thead>
            <tbody className="text-start">
            {activeCampaigns
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                  <td className="text-start py-3 px-0 ">{item.campaign}</td>
                  <td className="text-start py-3 px-0 ">{item.status}</td>
                  <td className="text-start py-3 px-0 ">{item.totalImpressions}</td>
                  <td className="text-start py-3 px-0">{item.expectedImpressions}</td>
                  <td className="text-start py-3 px-0">{item.totalClicks}</td>
                  <td className="text-start py-3 px-0">{item.totalCTR}</td>
                  <td className="text-start py-3 px-0">{item.startDate}</td>
                  <td className="text-start py-3 px-0">{item.endDate}</td>
                  <td className="text-start px-0 py-3"><button className="font-bold text-white bg-primary py-1 rounded-md px-2" onClick={() => viewCampaign(id)}>View</button></td>
                </tr>
                ))}
            </tbody>
          </table>
          <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
            <h1 className=" translate-x-5">Rows per page</h1>
            <select value={rowsPerPage} onChange={(e)=>{
              setRowsPerPage(e.target.value)
            }} className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm">
              <option className="" value={5}>5</option>
             <option className="" value={8}>8</option>
            </select>
            <div onClick={()=>{
              if(page>0){
                setPage(page-1)
              }
            }} className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer">
            <MdOutlineChevronLeft />
            </div>
            <h1>{page+1} out of {Math.ceil(activeCampaigns.length/rowsPerPage)}</h1>
            <div onClick={()=>{
              if( page< Math.round(activeCampaigns.length/rowsPerPage)){
                setPage(page+1)
              }
               
            }} className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center">
            <MdOutlineChevronRight />
            </div>
          </div>
</div>
     </div>
   
     
    </div>
   
  );
}

export default Reports;
