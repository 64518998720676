import { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import { useLocation, useParams } from "react-router-dom";
import { getCampaignReport } from "../../api/api";
import GeneralStats from "./components/general-stats";
import MixedGraph from "../overview/mixed-graph";
import { media, months } from "../../util/categories";
import PieChart from "../overview/pie-chart";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import Loader from "../../components/loader";

const CampaignReport = () => {
  const { setCurrentPage, setActions } = useContext(BreadcrumbContext);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const campaign = search.get("campaign");
  const {id} = useParams()
  const [data,setData]= useState()
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [campaigns, setCampaigns] = useState([]);
  const [channelMedia,setChannelMedia] = useState(null);
  const [startDate, setStartDate] = useState("2000-01-01");
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("yyy-MM-DD"))
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    setCurrentPage(campaign.replace(/-/g, " "));
    setActions(<div> </div>);
  }, []);
 useEffect(() => {
  setLoading(true)
    getCampaignReport(id,startDate,endDate,channelMedia).then((response)=>{
        console.log(response.data)
      const results = response.data;
        // setData(response.data)
        const graph1 = media.map((item) => {
          return {
            media: item,
            data: {
              impressions: results.records
                .map((e) =>
                  e.campaign_channel.channel.media == item ? e.impressions : 0
                )
                .reduce((acc, cur) => acc + cur, 0),
              spent: results.records
                .map((e) =>
                  e.campaign_channel.channel.media == item ? e.spent : 0
                )
                .reduce((acc, cur) => acc + cur, 0),
            },
          };
        });
    
        const graph2 = months.map((item) => {
          var monthlyData = results.records.filter(
            (e) => moment(e.record_date).format("MMM") === item
          );
          return {
            month: item,
            data:
              monthlyData.length > 0
                ? media.map((item) => {
                    return {
                      media: item,
                      data: {
                        impressions: monthlyData
                          .map((e) =>
                            e.campaign_channel.channel.media == item
                              ? e.impressions
                              : 0
                          )
                          .reduce((acc, cur) => acc + cur, 0),
                        spent: monthlyData
                          .map((e) =>
                            e.campaign_channel.channel.media == item
                              ? e.spent
                              : 0
                          )
                          .reduce((acc, cur) => acc + cur, 0),
                      },
                    };
                  })
                : media.map((item) => {
                    return {
                      media: item,
                      data: {
                        impressions: 0,
                        spent: 0,
                      },
                    };
                  }),
          };
        });
          response.data.graph1 = graph1;
          response.data.graph2 = graph2;
          console.log("report", response.data);
          setData(response.data);
          setLoading(false)
    })
 },[startDate,endDate,channelMedia])
  return loading?<Loader/>: data&&(
    <div>
      <div className="flex justify-between mb-4">
        <div className="w-1/6">
          <select onChange={(e)=>{
            if(e.target.value==="General Stats"){
              setChannelMedia(null)
              return
            }
            else{
              setChannelMedia(e.target.value)
            }
          }} className="form-style-with-white-background ">
            {["General Stats",...media].map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>

        <div className="flex space-x-4">
        <DateRangePicker
          size="lg"
          showOneCalendar
          onto
          onOk={(e) => {
            console.log(e);

            setStartDate(moment(e[0]).format("yyy-MM-DD"));
            setEndDate(moment(e[1]).format("yyy-MM-DD"));
          }}
          shouldDisableDate={DateRangePicker.afterToday()}
          ranges={[]}
          className="focus:border-primary"
        />
        </div>
      </div>
      <GeneralStats data={data} />
      <div className=" grid grid-cols-12 gap-4 mt-4">
          <div className="col-span-8">
            <div className=" bg-white rounded border border-border p-6">
              <div className="border-b border-border mb-2 py-2">
                <p className="text-muted">Activity</p>
                <h1 className="font-bold text-xl">Impressions</h1>
              </div>
              <MixedGraph
                values={data.graph1.map((item) => item.data.impressions)}
                height={420}
                yValuesName={"Impressions"}
                yValuesName2={"Spent"}
                values2={data.graph1.map((item) => item.data.spent)}
                xlabels={data.graph1.map((item) => item.media)}
              />
            </div>
          </div>
          <div className="col-span-4">
            <div className=" bg-white rounded border border-border  h-full p-5">
              <div className="border-b border-border mb-2 py-2">
                <p className="text-muted">Statistics</p>
                <h1 className="font-bold text-xl">Mentions</h1>
              </div>
              <PieChart
                labels={data.graph1.map((item) => item.media)}
                values={data.graph1.map((item) => item.data.impressions)}
              />
            </div>
          </div>
        </div>
        <div className="bg-white p-6 w-full rounded-md border border-border mt-4 space-y-2">
            <div className="font-bold text-xl">Mentions</div>
           {data.mentions.map((item)=>{
            return <div className="bg-background p-4 rounded-md space-y-1 ">
                <div className="font-bold">{item.mention}</div>
                <div className="flex space-x-2 items-center ">
                    <div className="">{item.campaign_channel.channel.name}</div>
                    <div className="h-2 w-2 rounded-full bg-primary"/>
                    {moment(new Date(item.record_date)).format("yyy, MMM DD")}
                </div>
            
            </div>
           })}
           
           
          </div>

    </div>
  );
};

export default CampaignReport;
