import React, { createContext, useContext, useState } from "react";

const BudgetContext = createContext();

export function useBudgetData() {
    return useContext(BudgetContext);
}

export function BudgetProvider({ children }) {
    const [budgetData, setData] = useState([]);

    const addData = (newData) => {
        setData((prevData) => [...prevData, newData]);
    };

    return (
        <BudgetContext.Provider value={{ budgetData, addData }}>
            {children}
        </BudgetContext.Provider>
    );
}
