import React, { createContext, useContext, useState } from "react";

const BannerContext = createContext();

export function useBannerData() {
    return useContext(BannerContext);
}

export function BannerProvider({ children }) {
    const [bannerData, setData] = useState([]);

    const addData = (newData) => {
        setData((prevData) => [...prevData, newData]);
    };

    return (
        <BannerContext.Provider value={{ bannerData, addData }}>
            {children}
        </BannerContext.Provider>
    );
}
