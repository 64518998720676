

import { useContext, useEffect, useState } from "react";

import EditZone from "./edit/edit-zone";
import {
  editZone,
  fetchAdvertiserByAgencyId,
  fetchPublisherWebsites,
  fetchZoneById,
  linkZoneToBanner,
} from "../../../../api/ssp-api";
import { useNavigate } from "react-router-dom";
import { editSspZone } from "../../../../api/api";
import { AuthContext } from "../../../../provider/AuthContext";
import ZoneBanners from "./edit/zone-banners";
import Loader from "../../../../components/loader";

export default function AgencyZoneInfo() {
  const zoneId = window.location.pathname.split("/")[4];
  const [value, setValue] = useState("1");
  const [zoneData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [publisherData, setPublisherData] = useState([]);
  const [advertiserData, setAdvertiserData] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { token, role, agencyId } = JSON.parse(userDetails);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      fetchZoneById(zoneId, base64Credentials),
      fetchPublisherWebsites(base64Credentials, agencyId),
      fetchAdvertiserByAgencyId(agencyId, base64Credentials),
    ])
      .then(([zoneResponse, websitesResponse, advertiserResponse]) => {
        console.log(zoneResponse.data);
        setFormData(zoneResponse.data);
        setPublisherData(websitesResponse.data);
        setAdvertiserData(advertiserResponse.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (formData) => {
    const { token } = JSON.parse(userDetails);
    setLoading(true);
    editZone(formData, base64Credentials)
      .then((response) => {
        console.log(response);
        const data = {
          zoneId: response.data.zoneId,
          zoneName: response.data.zoneName,
          width: response.data.width,
          height: response.data.height,
          type: response.data.type,
        };
        editSspZone(data, token)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log();
          });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        navigate("/agency/zones");
      });
  };

  const handleLink = (zoneId, bannerId) => {
    console.log("link stuff", zoneId, bannerId);
    linkZoneToBanner(zoneId, bannerId, base64Credentials)
      .then((res) => {
        console.log(res);
        navigate("/agency/zones");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div header={"Edit Zone"}>
      <div xs={12} spacing={4} >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Edit Zone" value="1" />
              <Tab label="Linked Banners " value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {loading ||
            zoneData === undefined ||
            publisherData === undefined ? (
              <Loader/>
            ) : (
              <EditZone
                data={zoneData}
                publisherData={publisherData}
                handleSubmit={handleSubmit}
              />
            )}
          </TabPanel>
          <TabPanel value="2">
            {loading || advertiserData === undefined ? (
              <Loader/>
            ) : (
              <ZoneBanners zoneId={zoneId} />
            )}
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
}
