
import { useContext, useEffect, useState } from "react";
import { getAgencies } from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../components/loader";
import AgencyTable from "./agency-table";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";

export default function AgencyData() {
  const [agencies, setAgencies] = useState([]);
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);

  const [loading, setLoading ] = useState(true);
  const { setActions } = useContext(BreadcrumbContext);

  useEffect(() => {
    setActions(<div></div>);
    setLoading(true)
    getAgencies(token)
      .then((res) => {
        setAgencies(res.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error, {
          position: "top-right",
        });
      });
  }, []);

  return (
    <div header="Agency Overview">
      <div>
        {loading ? <Loader /> : <AgencyTable data={agencies} />}
      </div>
    </div>
  );
}
