
import { useContext, useEffect, useState } from "react";
import { fetchBannersByZoneId } from "../../../../../api/ssp-api";
import { unlinkBannerFromZone } from "../../../../../api/api";
import { AuthContext } from "../../../../../provider/AuthContext";

export default function ZoneBanners({ zoneId }) {
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  useEffect(() => {
    fetchBannersByZoneId(base64Credentials, zoneId)
      .then((response) => {
        setBannerData(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUnlink = (bannerId) => {
    unlinkBannerFromZone(token, zoneId, bannerId)
      .then((response) => {
        console.log(response);
        setBannerData(bannerData.filter((item) => item.bannerId !== bannerId));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Width</TableCell>
              <TableCell>Height</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bannerData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Apply pagination
              .map((item) => (
                <TableRow key={item.bannerId}>
                  <TableCell>{item.bannerId}</TableCell>
                  <TableCell>{item.bannerName}</TableCell>
                  <TableCell>{item.width}</TableCell>
                  <TableCell>{item.height}</TableCell>
                  <TableCell>
                    {/*<Stack direction="row" spacing={2}>*/}
                    {/*  <Button*/}
                    {/*    variant="contained"*/}
                    {/*    color="primary"*/}
                    {/*    style={{ color: "white", backgroundColor: "#6EBF4A" }}*/}
                    {/*    onClick={() =>*/}
                    {/*      navigate(`/agency/banners/edit/${item.id}`)*/}
                    {/*    }*/}
                    {/*  >*/}
                    {/*    View*/}
                    {/*  </Button>*/}
                    <Button
                      variant="contained"
                      color="error"
                      // style={{ color: "white", backgroundColor: "#F44336" }}
                      onClick={() => handleUnlink(item.bannerId)}
                    >
                      Unlink
                    </Button>
                    {/*</Stack>*/}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]} // Define options for rows per page
          component="div"
          count={bannerData.length} // Total number of rows
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}
