import React from "react";
import { figureSeparator } from "../../../../util/figureSeparator";

const BannerStatsTable = ({ data }) => {
  return (
    <table className="text-sm table w-full">
      <thead className="text-muted">
        <tr>
          <th className="py-3 text-sm text-start px-2">Creative ID</th>
          <th className="text-sm text-start py-3">Creative</th>
          <th className="text-sm text-start py-3">Impressions </th>
          <th className="text-sm text-start py-3">Clicks</th>
        </tr>
      </thead>
      <tbody className="text-start">
        {data.map((stat) => (
          <tr
            key={stat.creative_id}
            className="text-start even:bg-background text-textColor even:border-y-[1px] hover:text-textColor w-full  cursor-pointer"
          >
            <td className="text-start py-3 px-2">{stat.creative_id}</td>
            <td className="text-start py-3 px-0">{stat.name}</td>
            <td className="text-start py-3 px-0">
              {figureSeparator(stat.impressions)}
            </td>
            <td className="text-start py-3 px-0">
              {figureSeparator(stat.clicks)}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className=" rounded-md border-y-2 border-border bg-white  bg-opacity-15 ">
        <tr>
          <th className="text-sm text-start text-muted transition-all py-3 px-2">
            Total
          </th>
          <th className="text-sm text-start hover:text-primary cursor-pointer transition-all py-3">
            {data.length}
          </th>
          <th className="text-sm text-start hover:text-primary cursor-pointer transition-all py-3">
            {figureSeparator(
              data.reduce((acc, current) => acc + current.impressions, 0)
            )}
          </th>
          <th className="text-sm text-start hover:text-primary cursor-pointer transition-all py-3">
            {figureSeparator(
              data.reduce((acc, current) => acc + current.clicks, 0)
            )}
          </th>
        </tr>
      </tfoot>
    </table>
  );
};

export default BannerStatsTable;
