import React, { useContext, useEffect, useState } from "react";

import { styles } from "./style";
import AdvertiserTabLayout from "./advertiser-tab";
import { getAdvertisers } from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import { useNavigate } from "react-router-dom";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";

function Advertiser({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const navigate = useNavigate();
  const { setActions } = useContext(BreadcrumbContext);
  const [activeAdvertisers, setActiveAdvertisers] = useState([]);

  useEffect(() => {
    getAdvertisers(token, agencyId)
      .then((data) => {
        setActiveAdvertisers(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching advertisers:", error);
        setIsLoading(false);
      });
    setActions(
      <div>
        <div
          className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer"
          onClick={() => navigate("/advertiser/new")}
        >
          Create advertiser
        </div>
      </div>
    );
  }, []);

  return (
    <div>
      <div className="flex justify-end"></div>

      <AdvertiserTabLayout
        activeAdvertisers={activeAdvertisers}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Advertiser;
