
import EditCampaignButton from "../edit-campaign-button";
import { toDate } from "../../../util";
import CampaignSkeleton from "../campaign-skeleton";
import { getCampaignById } from "../../../api/api";
import { AuthContext } from "../../../provider/AuthContext";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { CampaignContext } from "../campaign-create/create-campaign-tabs";


const CampaignInformation = () => {
 
    const { userDetails } = useContext(AuthContext);
    const { token } = JSON.parse(userDetails);
    const [campaign, setCampaign] = useState(null);
    const {uuid} = useParams();
    const {activeStep,setActiveStep} = useContext(CampaignContext);
  
    useEffect(() => {
      getCampaignById(token, uuid).then((response) => {
        console.log(response.data)
        setCampaign(response.data);
      });
    }, []);
  return campaign&&(
    <div className="bg-white p-10 border border-border rounded-md w-full text-base" header={"Campaign Information"}>
        <>
          <div>
            <div className="grid grid-cols-12">
              <p className=" col-span-3">
                Campaign name
              </p>
              <p className="col-span-9">{campaign.name}</p>
            </div>
            <div className="grid grid-cols-12">
              <p className="col-span-3">Advertiser</p>
              <p className="col-span-9">{campaign.advertiser&&campaign.advertiser.company}</p>
            </div>
          </div>
          <div >
            <div className="grid grid-cols-12">
              <p className="col-span-3">Start Date</p>
              <p className="col-span-9">
                {moment(campaign.activateTime).format("MMM, DD yyy")}
              </p>
            </div>
            <div className="grid grid-cols-12">
              <p className="col-span-3">End Date</p>
              <p className="col-span-9">
              {moment(campaign.expireTime).format("MMM, DD yyy")}

              </p>
            </div>
          </div>
          <div >
            <div className="grid grid-cols-12" >
              <p className="col-span-3">
                Campaign Destination
              </p>
              <p className="col-span-9">
                {campaign.campaignDestination}
              </p>
            </div>
          </div>
          <button
                className=" border border-primary text-primary  rounded-md py-1 px-4 w-40 flex justify-center"
                onClick={()=>{
                  setActiveStep(0)
                }}
                style={{ marginTop: "20px" }}
              >
                Edit Details
              </button>
        </>
      
    </div>
  );
};

export default CampaignInformation;
