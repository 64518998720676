import Chart from "react-apexcharts";
import { chartColors } from "../audience/constant";
import { figureSeparator } from "../../util/figureSeparator";


const PieChart = ({ labels, values,colors = chartColors,measurement="%" }) => {

  console.log("Values",values.map((item) => parseInt(item),labels))
  const chartData = {
    series: values.map((item) => parseInt(item)),
    options: {
      chart: {
        type: "donut",
      },
      colors: colors,
      labels: labels,
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            labels: {
              show: true,
              value:{
                show:true,
                fontSize:"1.5vw",
                fontWeight:600
              },
              total:{
                show:true,
                color:"#828682",
                showAlways:true,
                formatter:function(val){
                  console.log("Val",)
                  const values = val.config.series
                  const sum = values.reduce((a,b)=>a+b,0)
                  return figureSeparator(sum) 
                }
                
                
              }
            },
            size: "65%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  };

  return (
    <div className="p-5 bg-white rounded-md ">
      <div className="">
        <div className="w-full">
          <Chart
            series={chartData.series}
            options={chartData.options}
            type="donut"
          />
        </div>

        <div className="flex flex-wrap space-x-3 justify-center items-center mt-3">
          {labels.map((item,index) => {
            return (
              <div class Name="flex justify-between bg-black w-full">
                <div className="flex space-x-2 items-center">
                  <div
                    style={{ backgroundColor: colors[index] }}
                    className=" size-3 rounded-full"
                  />
                  <p className="text-muted capitalize">{item}</p> 

                </div>
               
              </div>
            );
          })}
        </div>
      </div>

    </div>
  );
};

export default PieChart;
