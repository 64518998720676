import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyTokenAPI } from "../api/api";

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const user = localStorage.getItem("kwanza");
      const token = user ? JSON.parse(user).token : null;
      const role = user ? JSON.parse(user).role : null;

      // Function to verify your token (this is just a placeholder)
      const isTokenValid = () => {
        verifyTokenAPI(token)
          .then((response) => {
            return response.status === 200;
          })
          .catch((error) => {
            console.log(error);
            navigate("/login");
            return false;
          });
      };

      if (!isTokenValid() === false) {
        navigate("/login");
      } else {
        if (role === "publisher") {
          navigate("/publisher/ssp");
        }
        if (role === "agency") {
          navigate("/agency/ssp");
        }
        if (role === "advertiser") {
          navigate("/advertiser/ssp");
        }
      }
    }, [history]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
