import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../provider/AuthContext";
import {
  fetchBannerByCampaignId,
  fetchCampaignListByAdvertiserId,
} from "../../../api/ssp-api";
import AdvertiserWrapper from "../../../components/wrappers/advertiser-wrapper";

import AdvertiserBannerTable from "./banner-table";
import Loader from "../../../components/loader";

export default function AdvertiserBanners() {
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId, advertiserId } = JSON.parse(userDetails);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const [campaigns, setCampaigns] = useState([]);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCampaignListByAdvertiserId(advertiserId, base64Credentials)
      .then((response) => {
        setCampaigns(response.data);
        fetchBanners(response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const fetchBanners = (campaigns) => {
    campaigns.forEach((campaign) => {
      fetchBannerByCampaignId(campaign.campaignId, base64Credentials)
        .then((response) => {
          setBanners((prevState) => [...prevState, response.data]);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    });
  };

  return (
    <AdvertiserWrapper header={"Banners"}>
      <div >
        {loading || banners === undefined ? (
          <Loader/>
        ) : (
          <div item >
            <AdvertiserBannerTable filteredData={banners[0]} />
          </div>
        )}
      </div>
    </AdvertiserWrapper>
  );
}
