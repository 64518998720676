import * as React from "react";

export default function ToolbarGrid(props) {
  const columns = [
    { field: "day", headerName: "Day", width: 300 },
    { field: "impressions", headerName: "Impressions", width: 150 },
    { field: "clicks", headerName: "Clicks", width: 150 },
    {
      field: "ctr",
      headerName: "CTR",
      type: "number",
      width: 110,
    },
  ];

  const rows = props.data;

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(row) => row.day}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
