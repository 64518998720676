
import { ReactComponent as SupportIcon } from "../../../assets/help.svg";
import Profile from "./profile";



const Content = ({ children, header }) => {
  const classes = useStyles();
  const user = localStorage.getItem("kwanza");
  const { name } = user ? JSON.parse(user) : {};
  return (
    <div className={classes.root}>
      <div container className={classes.header}>
        <div item xs={3} container>
          <Typography className={classes.tab}>
            {header || "Overview"}
          </Typography>
        </div>
        <div
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <div item xs={3} className={classes.helpContainer}>
            <Button className={classes.helpButton}>
              <span>Help</span> <SupportIcon className={classes.help} />
            </Button>
          </div>
          <div item xs={5}>
            <Typography className={classes.userName}>{name}</Typography>
          </div>
          <Profile />
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Content;
