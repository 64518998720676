
import { Component } from "react";
import { styles } from "./style";

import NavigationButtons from "../navigation-buttons";
import {
  createCompanyInvoiceDetailsApi,
  getCampaignsWithAdvertisers,
} from "../../../api/api";

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: null,
      campaignId: null,
      advertisers: [],
      data: [],
      campaigns: [],
      isLoading: false,
      isExclusive: false,
    };
  }
  onHandleChange = (event) => {
    let campaigns = this.state.campaigns;
    if (event.target.name === "clientId") {
      campaigns = this.state.data.filter(
        ({ advertiserId }) => advertiserId === event.target.value,
      );
    }
    this.setState({
      [event.target.name]: event.target.value,
      campaigns,
    });
  };
  onSubmitForm = () => {
    const { campaignId, clientId, isExclusive } = this.state;
    const { invoiceId } = (this.props.match && this.props.match.params) || {};
    if (
      (clientId && isExclusive && invoiceId) ||
      (clientId && !isExclusive && invoiceId && campaignId)
    ) {
      this.setState({ isLoading: true });
      const data = {
        advertiserId: clientId,
      };
      if (!isExclusive) {
        data.campaignId = campaignId;
      }
      createCompanyInvoiceDetailsApi(data, invoiceId)
        .then((res) => {
          const id = res && res.data && res.data.id;
          this.props.navigate(`/generate-invoice/invoice-details/${id}`);
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    }
  };
  onBackButtonClick = () => {
    const { invoiceId } = (this.props.match && this.props.match.params) || {};
    this.props.navigate(`/generate-invoice/invoice-type/${invoiceId}`);
  };

  componentDidMount() {
    getCampaignsWithAdvertisers()
      .then((res) => {
        const data = res.data || {};
        const advertisers = data.advertisers || [];
        const campaigns = data.campaigns || [];
        this.setState({
          data: campaigns,
          advertisers,
        });
      })
      .catch(console.log);
  }
  componentDidUpdate(prevProps) {
    const { advertiserId, campaignId, isExclusive } =
      this.props.invoiceCompanyDetails;
    if (prevProps.invoiceCompanyDetails !== this.props.invoiceCompanyDetails) {
      const campaigns = this.state.data.filter(
        (campaign) => advertiserId === campaign.advertiserId,
      );
      this.setState({
        clientId: advertiserId,
        campaignId,
        isExclusive,
        campaigns,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      clientId,
      campaignId,
      advertisers,
      campaigns,
      isLoading,
      isExclusive,
    } = this.state;

    return (
      <div container justifyContent="flex-start">
        <Typography className={classes.label}>
          Select Kwanza Clients and Campaign
        </Typography>
        <div xs={12} container>
          <div xs={12} container>
            <div xs={5} className={classes.marginRight}>
              <FormHelperText className={classes.helperText}>
                Select client
              </FormHelperText>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                input={<OutlinedInput className={classes.input} />}
                name="clientId"
                value={clientId}
                onChange={this.onHandleChange}
              >
                {advertisers.map(({ advertiserId, company }, index) => (
                  <MenuItem
                    className={classes.menuItem}
                    value={advertiserId}
                    key={index}
                  >
                    {company}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {!isExclusive && (
              <div xs={5}>
                <FormHelperText className={classes.helperText}>
                  Select campaign
                </FormHelperText>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  input={<OutlinedInput className={classes.input} />}
                  name="campaignId"
                  value={campaignId}
                  onChange={this.onHandleChange}
                >
                  {campaigns.map(({ campaignId, campaign }, index) => (
                    <MenuItem
                      className={classes.menuItem}
                      value={campaignId}
                      key={index}
                    >
                      {campaign}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </div>
        </div>

        <NavigationButtons
          showNextButton={true}
          showBackButton={true}
          onClickNextButton={this.onSubmitForm}
          onClickBackButton={this.onBackButtonClick}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default CompanyDetails;
