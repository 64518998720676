import React from 'react';


const CustomAlert = ({ open, handleClose, severity, message }) => {
    return (
        <Snackbar open={open} autoHideDuration={9000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomAlert;
