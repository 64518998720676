// PublisherForm.js
import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AgencyWrapper from "../../../../components/wrappers/agency-wrapper";
import { AuthContext } from "../../../../provider/AuthContext";
import {
  addPublisherWebsite,
  fetchPublisherCategories,
} from "../../../../api/api";
import {Formik} from "formik"
import * as Yup from "yup"
import { BreadcrumbContext } from "../../../../layouts/dashboardLayout";
import Loader from "../../../../components/loader";
export default function AgencyPublisherForm() {
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);

  const [formData, setFormData] = useState({
    agencyId: agencyId,
    publisherName: "",
    website: "",
    contactName: "",
    emailAddress: "",
    categoryId: 8,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [categories, setCategories] = useState([]);
  const {setCurrentPage} = useContext(BreadcrumbContext)

  useEffect(() => {
    setCurrentPage("New Website")
    fetchPublisherCategories(token)
      .then((response) => {
        console.log(response);
        setCategories(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (values) => {
    setLoading(true);
    event.preventDefault();
    formData.agencyId = agencyId;
    formData.publisherName = values.publisherName;
    formData.categoryId = values.category;
    formData.emailAddress = values.email;
    formData.website = values.website;
    formData.contactName = values.contactName;
    console.log(formData)
    addPublisherWebsite(formData, token)
      .then((response) => {
        navigate("/agency/websites");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

 

  const theme = createTheme({
    palette: {
      primary: {
        main: "#6EBF4A",
        contrastText: "#FFFFFF",
      },
    },
  });
const validationSchema = Yup.object({
  publisherName:Yup.string().required("Enter publisher name"),
  website:Yup.string().required("Enter website link"),
  category:Yup.string().required("Select category"),
  contactName:Yup.string().required("Enter contact name"),
  email:Yup.string().required("Enter email address")
})
  return (
      <div header={"Add Website"}>
        <ToastContainer />
        <div >
          {loading || categories === undefined ? (
            <Loader/>
          ) : (
            <div >
          <Formik validationSchema={validationSchema} initialValues={{
            publisherName:"",
            website:"",
            category:"",
            contactName:"",
            email:""
          }} onSubmit={onSubmit} >
            {({errors,values,isSubmitting,handleChange,handleSubmit,touched})=>(<div>
              <form className="p-10 bg-white border border-border w-full" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 w-full">
            <div className="space-y-1">
          <h1 className="text-sm">Publisher Name</h1>
          <input type="text" onChange={handleChange} value={values.publisherName} name="publisherName"  className="form-style"/>
          <div>{touched.publisherName && errors.publisherName && <div className="text-red-500 text-sm mt-1">{errors.publisherName}</div>}</div>
         </div>
         <div className="space-y-1">
          <h1 className="text-sm">Website</h1>
          <input type="text" onChange={handleChange} value={values.website} name="website"  className="form-style"/>
          <div>{touched.website && errors.website && <div className="text-red-500 text-sm mt-1">{errors.website}</div>}</div>
         </div>
         <div className="space-y-1">
          <h1 className="text-sm">Category*</h1>
          <select onChange={handleChange} value={values.category} name="category"  className="form-style">
            <option>Select</option>
            {categories.map((item) => (
                 <option key={item.id} value={item.id}>
                   {item.name}
                 </option>
               ))}
          </select>
          <div>{touched.category && errors.category && <div className="text-red-500 text-sm mt-1">{errors.category}</div>}</div>
         </div>
         <div className="space-y-1">
          <h1 className="text-sm">Contact Name</h1>
          <input type="text" onChange={handleChange} value={values.contactName} name="contactName"  className="form-style"/>
          <div>{touched.contactName && errors.contactName && <div className="text-red-500 text-sm mt-1">{errors.contactName}</div>}</div>
         </div>
         <div className="space-y-1">
          <h1 className="text-sm">Email Address</h1>
          <input type="text" onChange={handleChange} value={values.email} name="email"  className="form-style"/>
          <div>{touched.email && errors.email && <div className="text-red-500 text-sm mt-1">{errors.email}</div>}</div>
         </div>
            </div>
            
                
         <button
                  className="mt-6 bg-primary rounded-md py-2 px-3 text-white"
                  type="submit">
                  {isSubmitting ? <CircularProgress style={{color:"white"}} size="1.4rem" /> : "Add Website"}
                </button>
              </form>
            </div>)}
          </Formik>
             
            </div>
          )}
        </div>
      </div>
  );
}
