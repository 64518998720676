import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IoClose } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

function ImageDropzone({ onImageUpload }) {
  // const [selectedImage, setSelectedImage] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {}, [onImageUpload]);

  const removeSelectedImage = () => {
    setSelectedImage(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg",
    multiple: false,
  });
  const [hasEntered, setHasEntered] = useState(false);

  return (
    <div className="w-full ">
      <input
        multiple={true}
        onChange={(e) => {
          var files = e.target.files;
          for (let i = 0; i < files.length; i++) {
            onImageUpload(files[i]);
          }
        }}
        id="image-form"
        type="file"
        className="sr-only"
      />
      <label
        onDragEnter={(e) => {
          setHasEntered(true);
        }}
        onDragExit={(e) => {
          setHasEntered(false);
        }}
        onDrop={(e) => {
          var files = e.dataTransfer.files;
          for (let i = 0; i < files.length; i++) {
            onImageUpload(files[i]);
          }
        }}
        for="image-form"
        className="grid grid-cols-1 gap-5"
      >
        {
          <div
            className={`border ${hasEntered ? "border-primary" : "border-transparent"} rounded-md p-10 flex justify-center border-dashed  items-center flex-col text-center`}
          >
            <p className="text-sm text-muted">
              Drag &amp; drop image here, or click to select files
            </p>
            <div
              className="border-2 border-primary rounded-md px-3 py-1 mt-2 text-primary text-sm cursor-pointer"
              component="span"
            >
              Browse
            </div>
          </div>
        }
      </label>
    </div>
  );
}

export default ImageDropzone;
