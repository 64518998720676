import React, { useContext, useEffect, useState } from "react";
import { styles } from "./style";

import { numberWithCommas } from "../../util";
import { headers } from "./constant";
import {
  createCampaignApi,
  deleteCampaignById,
  getKwanzaStatsAPI,
} from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import { useNavigate } from "react-router-dom";
import { MdOutlineChevronRight, MdOutlineChevronLeft } from "react-icons/md";
import moment from "moment";
import Spinner from "../../components/spinner";
import { toast } from "react-toastify";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import Loader from "../../components/loader";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineMenu } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import CustomModal from "../../components/custom-modal";
import { set } from "lodash";
import { GrTrash } from "react-icons/gr";
import ModifyButton from "../../components/modify-button";

function Campaigns(props) {
  const { userDetails } = useContext(AuthContext);
  const { token, role } = userDetails ? JSON.parse(userDetails) : {};
  const [activeCampaigns, setActiveCampaigns] = useState([]); // Local state for activeCampaigns
  const [isLoading, setIsLoading] = useState(true); // Local state for loading
  const [page, setPage] = useState(0); // Local state for page
  const [pageCount, setPageCount] = useState(1); // Local state for pageCount
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selected, setSelected] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCampaigns(token, role);
  }, [refresh]);

  const getCampaigns = (token, role) => {
    getKwanzaStatsAPI(token, role)
      .then((res) => {
        console.log(res.data);
        setActiveCampaigns(res.data.campaigns);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const redirectToCreateCampaign = () => {
    navigate("/campaign-create/information");
  };

  const viewCampaign = (id) => {
    navigate(`/report/${id}`);
  };

  const handleDelete = (id) => {
    console.log(token);
    setDeleting(true);

    // Simulated API call to delete a campaign
    deleteCampaignById(token, id)
      .then((response) => {
        // Handle success
        console.log(response);
        setDeleting(false);
        toast.success("Deleted successfully");
        setRefresh(refresh + 1);
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        setIsLoading(false);
      });
  };

  const paginationProps = {
    setPageNumber: setPage, // Set the current page
    page: page, // Current page
    pageCount: pageCount, // Total number of pages
  };
  const [filter, setFilter] = useState("");
  const filteredData = activeCampaigns.filter(
    (item) => item.campaign.toLowerCase().includes(filter.toLowerCase()) // Filter by campaign name
  );

  const { setActions } = useContext(BreadcrumbContext);
  useEffect(() => {
    setActions(
      <div
        className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer w-40 flex justify-center items-center"
        onClick={() => {
          const data = {
            startDate: moment(Date.now()).format("yyy-MM-DD"),
            endDate: moment(Date.now()).format("yyy-MM-DD"),
            campaignName: "Campaign 1",
            campaignDestination: "kwanza.co.tz",
            status: "draft",
            advertiserId: 1,
            audienceId: 1,
          };
          setCreating(true);
          createCampaignApi(data).then((response) => {
            console.log(response);
            navigate(`/create-campaign/dsp/${response.data.campaignId}`);
            setCreating(false);
          });
        }}
      >
        {creating ? <Spinner /> : "Create Campaign"}
      </div>
    );
  }, []);
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {/* <div container justify="flex-end" sx={styles.filter}>
        <CampaignFilter  hideCreate={false} />
      </div>
      <SearchCampaign /> */}

      <div className="bg-white px-5 py-5 w-full rounded-md  border border-border">
        <CustomModal
          show={showConfirmDeletion}
          heading={"Confirm Deletion"}
          content={
            <div className="flex flex-col justify-center items-center">
              <GrTrash className="text-4xl text-red-400" />
              <h1 className="text-xl mt-4">
                Are you sure you want to delete ?
              </h1>
              <div className="flex space-x-2 mt-4">
                <button
                  className="font-bold py-2 px-4  bg-muted bg-opacity-60 hover:scale-105 transition-all duration-200  text-white rounded"
                  onClick={() => {
                    setShowConfirmDeletion(false);
                  }}
                >
                  No
                </button>
                <button
                  className="font-bold py-2 px-4  bg-red-400 hover:scale-105 transition-all duration-200  text-white rounded"
                  onClick={() => {
                    handleDelete(selected);
                    setShowConfirmDeletion(false);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          }
          setShow={setShowConfirmDeletion}
        />
        <div className="flex justify-end space-x-2">
          <input
            label="Filter by name or campaign name"
            placeholder="Search here"
            className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <table className="text-sm table w-full mt-4">
          <thead className="text-muted">
            <tr className="">
              <th className="py-3 text-sm text-start">Campaign</th>
              <th className="text-sm text-start py-3">Impressions</th>
              <th className="text-sm text-start py-3">Expected Impressions</th>
              <th className="text-sm text-start py-3">Clicks</th>
              <th className="text-sm text-start py-3">CRT</th>
              <th className="text-sm text-start py-3">Start Date</th>
              <th className="text-sm text-start py-3">End Date</th>
              <th className="text-sm text-start py-3">Status</th>
              <th className="text-sm text-start py-3"></th>
              <th className="text-sm text-start py-3"></th>
              <th className="text-sm text-start py-3"></th>
            </tr>
          </thead>
          <tbody className="text-start">
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  transition-all duration-200 cursor-pointer">
                  <td className="text-start py-3 px-0 ">{item.campaign}</td>
                  <td className="text-start py-3 px-0 ">
                    {" "}
                    {numberWithCommas(item.totalImpressions || 0)}
                  </td>
                  <td className="text-start py-3 px-0 ">
                    {numberWithCommas(item.expectedImpressions || 0)}
                  </td>
                  <td className="text-start py-3 px-0 ">{item.totalClicks}</td>
                  <td className="text-start py-3 px-0 ">
                    {parseFloat(item.totalCTR ?? 0).toFixed(2)}
                  </td>
                  <td className="text-start py-3 px-0 ">
                    {moment(item.startDate).format("MMM DD, YYYY")}
                  </td>
                  <td className="text-start py-3 px-0 ">
                    {moment(item.endDate).format("MMM DD, YYYY")}
                  </td>
                  <td
                    className={`text-start py-3 px-0 ${
                      item.status == "runnable"
                        ? "text-green-500"
                        : item.status == "draft"
                          ? "text-gray-500"
                          : "text-secondary"
                    } font-semibold`}
                  >
                    {item.status}
                  </td>

                  {item.status == "draft" ? (
                    <td className="text-start px-0 py-3">
                      <button
                        className="font-bold py-1 px-2 bg-muted bg-opacity-80 text-white rounded"
                        onClick={() => {
                          navigate(`/create-campaign/dsp/${item.id}`);
                        }}
                      >
                        Continue
                      </button>
                    </td>
                  ) : (
                    <td className="text-start px-0 py-3">
                      <button
                        className="font-bold py-1 px-2 bg-primary text-white rounded"
                        onClick={() => viewCampaign(item.id)}
                      >
                        View Stats
                      </button>
                    </td>
                  )}
                  <td className="text-start">
                    <ModifyButton
                      id={item.id}
                      onEdit={() => {
                        navigate(`/campaigns/${item.id}`);
                      }}
                      onDelete={() => {
                        handleDelete(item.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
          <h1 className=" translate-x-5">Rows per page</h1>
          <select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(e.target.value);
            }}
            className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
          >
            <option className="" value={5}>
              5
            </option>
            <option className="" value={8}>
              8
            </option>
          </select>
          <div
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
          >
            <MdOutlineChevronLeft />
          </div>
          <h1>
            {page + 1} out of {Math.ceil(activeCampaigns.length / rowsPerPage)}
          </h1>
          <div
            onClick={() => {
              if (page < Math.round(activeCampaigns.length / rowsPerPage)) {
                setPage(page + 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
          >
            <MdOutlineChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
