export const styles = {
  root: {
    marginTop: "3rem",
    textAlign: "left",
    "& span.MuiSlider-thumb span span span": {
      color: "white",
    },
  },
  left: {
    paddingLeft: "2rem",
  },
  text1: {
    fontSize: "0.9rem",
    color: "#4B4B4B",
    fontWeight: 900,
    marginBottom: "0.5rem",
  },
  text2: {
    fontWeight: 300,
    marginTop: "0.3rem",
    fontSize: "0.8rem",
  },
  paper: {
    minHeight: "30vh",
    minWidth: "40%",
    maxWidth: "40%",
    marginTop: "1.8rem",
    boxShadow: "none",
    border: "0.819465px solid #C4C4C4",
    fontSize: "1rem",
    fontWeight: 200,
    borderRadius: "9px",
    padding: "2rem 2rem",
  },
  // createCampaign: {
  //   backgroundColor: "#6EBF4A",
  //   color: "#FFF",
  //   "&:hover,&:focus": {
  //     backgroundColor: "#6EBF4A",
  //   },
  //   borderRadius: "9px",
  //   textTransform: "lowercase",
  //   width: "100%",
  //   height: "2.6rem",
  //   marginTop: "2rem",
  // },
  amount: {
    color: "#6EBF4A",
    paddingLeft: "2rem",
    fontSize: "1.8rem",
  },

  input: {
    width: "100%",
    height: "2.8rem",
    // marginRight: "3.5rem",
    marginTop: 0,
    borderRadius: "9px",
    marginBottom: "-0.3rem",
    marginRight: "0.4rem",
    "& .MuiInputBase-input": {
      color: "#6EBF4A", // Text color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: " #979797;", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: " #979797", // Solid underline on focus
    },
  },

  row: {
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  first: {
    marginRight: "3.6rem",
  },
  helperText: {
    marginBottom: "0.3rem",
    color: "#4B4B4B",
    fontWeight: 500,
  },
  createCampaign: {
    backgroundColor: "#6EBF4A",
    color: "#FFF",
    "&:hover,&:focus": {
      backgroundColor: "#6EBF4A",
    },
    borderRadius: "9px",
    textTransform: "none",
    width: "40%",
    height: "2.5rem",
    marginBottom: "4rem",
  },
  cancelButton: {
    background: "white",
    color: "#979797",
    border: "1px solid #979797",
    marginRight: "1rem",
    "&:hover,&:focus": {
      background: "#FFF",
    },
  },
  dropzone: {
    outline: "none",
  },
  dropContent: {
    width: "87.4%",
    outline: "none",
    color: "#4B4B4B",
    border: "0.819465px dashed #C4C4C4",
    borderRadius: "9px",
    textAlign: "center",
  },
  drag: {
    color: "#808080",
    fontSize: "0.7rem",
    marginBottom: "0.4rem",
  },
  browse: {
    background: "#E3E3E3",
    borderRadius: "4px",
    color: "#808080",
    textTransform: "none",
    fontSize: "0.7rem",
    width: "52%",
    marginBottom: "2rem",
  },
  previewContainer: {
    marginTop: "2rem",
  },
  preview: {
    background: "#FFF",

    border: "0.819465px solid #C4C4C4",
    width: "58.4%",
    maxWidth: "58.4%",
    minHeight: "7vh",
    fontSize: "0.6rem",
    color: "#000",
    fontWeight: 200,
    paddingTop: "0.8rem",
    paddingBottom: "0.4rem",
    marginBottom: "1rem",
  },
  list: {
    fontSize: "0.6rem",
    color: " #979797",
    marginRight: "0.6rem",
    transform: "translateY(50%)",
  },
  desc: {
    height: "100%",
    transform: "translateY(50%)",
  },
  iconRight: {
    marginRight: "2rem",
    color: "#C6CACC",
  },
  recommended: {
    fontSize: "0.7rem",
    textTransform: "uppercase",
    marginLeft: "2.2rem",
    color: "#4B4B4B",
    fontWeight: 700,
    marginBottom: "0.4rem",
    "& .icon": {
      width: "0.9rem",
      transform: "translateY(25%)",
    },
  },
  newBudget: {
    color: "#6EBF4A",
    width: "58.4%",
    maxWidth: "58.4%",
    minHeight: "7vh",
    border: "0.819465px solid #C4C4C4",
  },
  budgetModal: {
    background: "white",
    outline: "none",
    position: "absolute",
    left: "35%",
    top: "20%",
    minWidth: "30%",
    minHeight: "25%",
    padding: "2rem 3rem",
    borderRadius: "9px",
  },
  andornment: {
    color: "#6EBF4A",
  },
  descText: {
    fontSize: "0.8rem",
    color: " #979797",
  },
  monthText: {
    marginLeft: "0rem",
    marginTop: "1rem",
  },
  slider: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  activeTab: {
    background: "rgba(110, 191, 74, 0.1)",
    border: "0.819465px solid #6EBF4A",
  },
};
