import { useContext, useEffect, useState } from "react";
import {
  fetchLinkedZonesByCampaignId,
  linkZoneToCampaign,
  unlinkZoneFromCampaign,
} from "../../../../../api/ssp-api";

import {
  fetchSspZones,
  linkSspZone,
  unlinkSspZone,
} from "../../../../../api/api";
import { AuthContext } from "../../../../../provider/AuthContext";
import AgencyWrapper from "../../../../../components/wrappers/agency-wrapper";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import Loader from "../../../../../components/loader";
import NoData from "../../../../../components/noData";

export default function AgencyLinkCampaign({ linkZones, edit }) {
  const editLink = edit;
  const linking = linkZones;
  const campaignId = window.location.pathname.split("/")[4];
  const [publishers, setPublishers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const [linkedZones, setLinkedZones] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  useEffect(() => {
    setLoading(true);
    if (linkedZones.length === 0) {
      fetchLinkedZonesByCampaignId(campaignId, base64Credentials)
        .then((res) => {
          setLinkedZones(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLinkedZones(linkZones);
      setLoading(false);
    }

    const { token, agencyId } = JSON.parse(userDetails);
    fetchSspZones(token, agencyId)
      .then((response) => {
        console.log(response.data);
        setZones(response.data.sspZones);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setZoneLoading(false);
      });
  }, []); // Removed zones from dependencies

  const handleUnlinkCampaign = (zoneId) => {
    setLoading(true);

    const { username, password } = JSON.parse(localStorage.getItem("ssp"));

    let removedLinkedZone = null;
    let updatedLinkedZones = linkedZones.filter((linkedZone) => {
      if (linkedZone.zoneId === zoneId) {
        removedLinkedZone = linkedZone;
        return false; // Remove from linkedZones
      }
      return true; // Keep in linkedZones
    });

    if (removedLinkedZone) {
      const newZone = {
        zone_id: removedLinkedZone.zoneId,
        zone_name: removedLinkedZone.zoneName,
        type: removedLinkedZone.type,
        width: removedLinkedZone.width,
        height: removedLinkedZone.height,
      };

      // Add the transformed zone back to zones
      setZones([...zones, newZone]);
    } else {
      console.log(`Linked zone with ID ${zoneId} not found in linkedZones.`);
    }

    setLinkedZones(updatedLinkedZones);

    unlinkZoneFromCampaign(zoneId, campaignId, username, password)
      .then((response) => {
        console.log(response.data);
        const data = {
          zoneId: zoneId,
        };
        const { token } = JSON.parse(userDetails);

        unlinkSspZone(data, token)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLinkCampaign = (zoneId) => {
    setZoneLoading(true);

    const { username, password } = JSON.parse(localStorage.getItem("ssp"));

    let removedZone = null;
    let updatedZones = zones.filter((zone) => {
      if (zone.zone_id === zoneId) {
        removedZone = zone;
        return false; // Remove from zones
      }
      return true; // Keep in zones
    });

    if (removedZone) {
      const newLinkedZone = {
        zoneId: removedZone.zone_id,
        zoneName: removedZone.zone_name,
        type: removedZone.type,
        width: removedZone.width,
        height: removedZone.height,
      };

      setLinkedZones([...linkedZones, newLinkedZone]);
    } else {
      console.log(`Zone with ID ${zoneId} not found in zones.`);
    }

    // Update the zones without the removed zone
    setZones(updatedZones);
    console.log(zoneId);
    linkZoneToCampaign(zoneId, campaignId, username, password)
      .then((response) => {
        console.log(response.data);

        const data = {
          zoneId: zoneId,
        };
        const { token } = JSON.parse(userDetails);

        linkSspZone(data, token)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setZoneLoading(false);
      });
  };

  if (editLink !== "edit") {
    return loading ? (
      <Loader />
    ) : zones.length < 1 ? (
      <NoData />
    ) : (
      <div header={"Link Campaign"}>
        <div container spacing={2} style={{ overflowY: "auto" }}>
          <div item spacing={2} xs={12}>
            {
              <div item xs={12}>
                <p>Linked zones</p>
                <div className="bg-white px-5 py-5 w-full rounded-md border border-border ">
                  <div className="flex justify-end space-x-2">
                    <input
                      label="Filter by name or campaign name"
                      placeholder="Search here"
                      className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>
                  <table className="text-sm table w-full">
                    <thead className="text-muted">
                      <tr className="">
                        <th className="py-3 text-sm text-start">Zone ID</th>
                        <th className="text-sm text-start py-3">Zone Name</th>
                        <th className="text-sm text-start py-3">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="text-start">
                      {zones
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                            <td className="text-start py-3 px-0 ">
                              {item.zone_id}
                            </td>
                            <td className="text-start py-3 px-0 ">
                              {item.zone_name}
                            </td>
                            <td className="text-start py-3 px-0 ">
                              {" "}
                              <button
                                className="py-2 px-3 bg-primary rounded-md text-sm"
                                onClick={() => handleLinkCampaign(item.zone_id)}
                              >
                                Link
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
                    <h1 className=" translate-x-5">Rows per page</h1>
                    <select
                      value={rowsPerPage}
                      onChange={(e) => {
                        setRowsPerPage(e.target.value);
                      }}
                      className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
                    >
                      <option className="" value={5}>
                        5
                      </option>
                      <option className="" value={8}>
                        8
                      </option>
                    </select>
                    <div
                      onClick={() => {
                        if (page > 0) {
                          setPage(page - 1);
                        }
                      }}
                      className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
                    >
                      <MdOutlineChevronLeft />
                    </div>
                    <h1>
                      {page + 1} out of {Math.ceil(zones.length / rowsPerPage)}
                    </h1>
                    <div
                      onClick={() => {
                        if (page < Math.round(zones.length / rowsPerPage)) {
                          setPage(page + 1);
                        }
                      }}
                      className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
                    >
                      <MdOutlineChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  } else {
    return loading ? (
      <Loader />
    ) : (
      <div container spacing={2} style={{ overflowY: "auto" }}>
        <div item spacing={2} xs={12}>
          {linkedZones.length < 1 ? (
            <NoData />
          ) : (
            <div item xs={12}>
              <p>Linked zones</p>
              <div className="bg-white px-5 py-5 w-full rounded-md border border-border mt-1">
                <div className="flex justify-end space-x-2">
                  <input
                    label="Filter by name or campaign name"
                    placeholder="Search here"
                    className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </div>
                <table className="text-sm table w-full">
                  <thead className="text-muted">
                    <tr className="">
                      <th className="py-3 text-sm text-start">Zone ID</th>
                      <th className="text-sm text-start py-3">Zone Name</th>
                      <th className="text-sm text-start py-3">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-start">
                    {linkedZones
                      .filter((item) =>
                        item.zoneName
                          .toLowerCase()
                          .includes(filter.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                          <td className="text-start py-3 px-0 ">
                            {item.zoneId}
                          </td>
                          <td className="text-start py-3 px-0 ">
                            {item.zoneName}
                          </td>
                          <td className="text-start py-3 px-0 ">
                            {" "}
                            <button
                              className="py-1 px-3 bg-red-400 text-white rounded-md text-sm"
                              onClick={() => handleUnlinkCampaign(item.zoneId)}
                            >
                              Unlink
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
                  <h1 className=" translate-x-5">Rows per page</h1>
                  <select
                    value={rowsPerPage}
                    onChange={(e) => {
                      setRowsPerPage(e.target.value);
                    }}
                    className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
                  >
                    <option className="" value={5}>
                      5
                    </option>
                    <option className="" value={8}>
                      8
                    </option>
                  </select>
                  <div
                    onClick={() => {
                      if (page > 0) {
                        setPage(page - 1);
                      }
                    }}
                    className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
                  >
                    <MdOutlineChevronLeft />
                  </div>
                  <h1>
                    {page + 1} out of {Math.ceil(zones.length / rowsPerPage)}
                  </h1>
                  <div
                    onClick={() => {
                      if (page < Math.round(zones.length / rowsPerPage)) {
                        setPage(page + 1);
                      }
                    }}
                    className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
                  >
                    <MdOutlineChevronRight />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <br />
        {zoneLoading ? (
          <div item spacing={2} xs={12}>
            <Loader />
          </div>
        ) : zones.length < 1 ? (
          <NoData />
        ) : (
          <div item xs={12} spacing={2}>
            <p>Available zones</p>
            <div className="bg-white px-5 py-5 w-full rounded-md border border-border mt-1 ">
              <div className="flex justify-end space-x-2">
                <input
                  label="Filter by name or campaign name"
                  placeholder="Search here"
                  className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>
              <table className="text-sm table w-full">
                <thead className="text-muted">
                  <tr className="">
                    <th className="py-3 text-sm text-start">Zone ID</th>
                    <th className="text-sm text-start py-3">Zone Name</th>
                    <th className="text-sm text-start py-3">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-start">
                  {zones
                    .filter((item) =>
                      item.zone_name
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                        <td className="text-start py-3 px-0 ">
                          {item.zone_id}
                        </td>
                        <td className="text-start py-3 px-0 ">
                          {item.zone_name}
                        </td>
                        <td className="text-start py-3 px-0 ">
                          {" "}
                          <button
                            className="py-1 px-3 bg-primary rounded-md text-sm text-white"
                            onClick={() => handleLinkCampaign(item.zone_id)}
                          >
                            Link
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
                <h1 className=" translate-x-5">Rows per page</h1>
                <select
                  value={rowsPerPage}
                  onChange={(e) => {
                    setRowsPerPage(e.target.value);
                  }}
                  className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
                >
                  <option className="" value={5}>
                    5
                  </option>
                  <option className="" value={8}>
                    8
                  </option>
                </select>
                <div
                  onClick={() => {
                    if (page > 0) {
                      setPage(page - 1);
                    }
                  }}
                  className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
                >
                  <MdOutlineChevronLeft />
                </div>
                <h1>
                  {page + 1} out of {Math.ceil(zones.length / rowsPerPage)}
                </h1>
                <div
                  onClick={() => {
                    if (page < Math.round(zones.length / rowsPerPage)) {
                      setPage(page + 1);
                    }
                  }}
                  className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
                >
                  <MdOutlineChevronRight />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
