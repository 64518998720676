import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../../provider/AuthContext";
import { addDspUserApi } from "../../../../api/api";
import { Formik } from "formik";
import * as Yup from "yup";
import Spinner from "../../../../components/spinner";

const AddUserModal = ({ isOpen, onClose, data }) => {
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId, accountId } = JSON.parse(userDetails);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "0", // Default role admin
    active: 0, // Default status
    sspUserId: 0, // Default sspId
    advertiser: 0, // Default advertiserId
  });

  const [loading, setLoading] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = async (values) => {
    try {
      let user = {
        contactName: values.name,
        emailAddress: values.email,
        username: values.email,
        password: `${getPrefix(values.email)}@kwanza`,
        defaultAccountId: accountId,
        role: values.role,
        active: values.status,
        advertiser: values.advertiser,
        agencyId: agencyId,
      };
      console.log("payload", user);
       await addDspUserApi(user, token);
      window.location.reload();
    } catch (error) {
      setLoading(false);

      // Extract the error message
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;

      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    setLoading(false);

    onClose();
  };

  function getPrefix(email) {
    const parts = email.split(".");
    if (parts.length >= 2) {
      return parts[0];
    } else {
      return email;
    }
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter user name"),
    email: Yup.string().email().required("Enter user email"),
    role: Yup.string().required("Enter user role"),
    advertiser: Yup.string().required("Please select advertiser"),
    status: Yup.string().required("Enter user status"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          role: "",
          advertiser: "",
          status: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
        }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <div className="mt-2">
                <h1 className="text-xs text-gray-500 mb-1">Name*</h1>
                <input
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  className="form-style"
                />
                <div>
                  {touched.name && errors.name && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.name}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <h1 className="text-xs text-gray-500 mb-1">Email*</h1>
                <input
                  type="text"
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  className="form-style"
                />
                <div>
                  {touched.email && errors.email && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <h1 className="text-xs text-gray-500 mb-1">Select Role</h1>
                <select
                  type="text"
                  onChange={handleChange}
                  value={values.role}
                  name="role"
                  className="form-style"
                >
                  <option>Select role</option>
                  <option value="3">Admin</option>
                  <option value="1">Advertiser</option>
                  <option value="2">Publisher</option>
                </select>
                <div>
                  {touched.role && errors.role && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.role}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-2">
                <h1 className="text-xs text-gray-500 mb-1">
                  Select Advertiser
                </h1>
                <select
                  type="text"
                  onChange={handleChange}
                  value={values.advertiser}
                  name="advertiser"
                  className="form-style"
                >
                  <option>Select advertiser</option>
                  {data.map((item) => {
                    return (
                      <option value={item.advertiserId}>
                        {item.advertiserName}
                      </option>
                    );
                  })}
                </select>
                <div>
                  {touched.advertiser && errors.advertiser && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.advertiser}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <h1 className="text-xs text-gray-500 mb-1">Status</h1>
                <select
                  type="text"
                  onChange={handleChange}
                  value={values.status}
                  name="status"
                  className="form-style"
                >
                  <option>Select status</option>
                  <option value="0">Active</option>
                  <option value="1">Inactive</option>
                </select>
                <div>
                  {touched.status && errors.status && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.status}
                    </div>
                  )}
                </div>
              </div>

              <button
                className="mt-6 bg-primary rounded-md py-2 px-3 text-white"
                type="submit"
                disabled={isSubmitting || loading}
              >
                {isSubmitting || loading ? <Spinner /> : "Save"}
              </button>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddUserModal;
