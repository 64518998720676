import React, { useContext, useState } from "react";
import { styles } from "./styles";
import { resetPassword, verifyEmail } from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Spinner from "../../components/spinner";
import * as yup from "yup";
import { useFormik, Formik } from "formik";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { email, token } = useParams();

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Password is required")
      .min(11, "Password must be at least 11 characters"),
    repeatPassword: yup
      .string()
      .required("Password is required")
      .oneOf([yup.ref("password"), null], "Password must match")
      .min(11, "Password must be at least 11 characters"),
  });

  const onSubmit = (values) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = { password: values.password, email, token };
    console.log(payload);
    console.log(payload);
    resetPassword(payload)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        toast.success("Password reset successful");
        // navigate("/password-reset");
      })
      .catch((error) => {
        console.log(error);
        const message = error.response ? error.response.data.message : "";
        toast.error(message);
        setIsLoading(false);
      });
  };
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Formik
      initialValues={{ password: "", repeatPassword: "" }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, errors, handleSubmit, handleChange, touched }) => (
        <div className="h-screen overflow-hidden flex flex-col justify-center items-center">
          <div className="grid grid-cols-12 text-textColor items-center w-10/12 2xl:w-8/12 mx-auto ">
            <form
              onSubmit={handleSubmit}
              className=" col-span-5 2xl:col-span-4 flex flex-col justify-between h-full"
            >
              <div className="mt-8">
                {" "}
                <img className="h-10" src="/Kwanza 1.svg" />
              </div>
              <div className="mt-3">
                <h1 className=" font-bold text-2xl ">Create new password</h1>
                <h1 className="text-muted text-sm">Fill the form below</h1>
                <div className="w-10/12">
                  <div className="mt-3">
                    <label className="text-sm">Enter new password </label>
                    <div className="flex border border-slate-300 rounded">
                      <input
                        type={!showPassword ? "password" : "text"}
                        value={values.password}
                        onChange={handleChange}
                        className=" w-full bg-slate-50 border-0 focus:border-0 focus:ring-0  ffocus:ring-offset-transparent focus:outline-none focus:ring-offset-transparent focus:ring-offset-0 focus:ring-transparent"
                        name="password"
                      />
                      <div className="border-l border-slate-300 px-3 flex cursor-pointer flex-col items-center justify-center  text-white">
                        {!showPassword ? (
                          <BsFillEyeFill
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            className="size-5 text-muted cursor-pointer"
                          />
                        ) : (
                          <BsFillEyeSlashFill
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            className="size-5 text-textColor cursor-pointer"
                          />
                        )}
                      </div>
                    </div>
                    {touched.password && errors.password && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div className="mt-3">
                    <label className="text-sm">Repeat password </label>
                    <div className="flex border border-slate-300 rounded">
                      <input
                        type={!showPassword ? "password" : "text"}
                        onChange={handleChange}
                        value={values.repeatPassword}
                        className=" w-full bg-slate-50 border-0 focus:border-0 focus:ring-0  ffocus:ring-offset-transparent focus:outline-none focus:ring-offset-transparent focus:ring-offset-0 focus:ring-transparent"
                        name="repeatPassword"
                      />
                      <div className="border-l border-slate-300 px-3 flex cursor-pointer flex-col items-center justify-center  text-white">
                        {!showPassword ? (
                          <BsFillEyeFill
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            className="size-5 text-muted cursor-pointer"
                          />
                        ) : (
                          <BsFillEyeSlashFill
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            className="size-5 text-textColor cursor-pointer"
                          />
                        )}
                      </div>
                    </div>
                    {touched.repeatPassword && errors.repeatPassword && (
                      <label className="text-red-500 text-sm mt-1">
                        {errors.repeatPassword}
                      </label>
                    )}
                  </div>

                  <button
                    className="w-full bg-primary mt-8 text-white font-medium py-3 cursor-pointer rounded-md flex justify-center items-center"
                    type="submit"
                  >
                    {isLoading ? <Spinner /> : "Verify Email"}
                  </button>
                </div>

                <h1 className="space-x-2 text-sm text-muted pt-2">
                  Return to{" "}
                  <span
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="text-primary underline cursor-pointer"
                  >
                    Login
                  </span>
                </h1>
              </div>
              <div></div>
            </form>
            <div className=" col-span-7 2xl:col-span-8">
              <div className=" bg-[#F2F2F2]  p-8 flex flex-col h-[95vh] justify-center items-center  rounded-2xl px-24 text-start ">
                <div className="flex w-96">
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, x: 200 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 1 },
                      }}
                      exit={{
                        opacity: 0,
                        x: -200,
                        transition: { duration: 1 },
                      }}
                    >
                      <img
                        className="min-h-96 min-w-96 object-cover"
                        src={["/login.png", "login.png"][selectedIndex]}
                      />
                    </motion.div>
                  </AnimatePresence>
                </div>

                <div>
                  <h1 className="font-bold text-2xl text-start">
                    A Few clicks away from publishing your ads
                  </h1>
                  <p className="text-start text-sm text-muted">
                    Consectetur irure officia magna sit occaecat occaecat fugiat
                    do ipsum. Sunt duis laborum laboris dolor. Irure do nulla
                    amet eu.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ResetPassword;
