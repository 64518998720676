import React, { useContext, useEffect, useState } from "react";
import { addCampaign, fetchAdvertiserByAgencyId } from "../../../api/ssp-api";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../../components/alerts/custom-alert";
import { toast, ToastContainer } from "react-toastify";
import { BreadcrumbContext } from "../../../layouts/dashboardLayout";

export default function CreateSspCampaign() {
  const [advertisers, setAdvertisers] = useState([]);
  const [advertiserId, setSelectedAdvertiser] = useState(1);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [revenue, setRevenue] = useState(1);
  const [impressions, setImpressions] = useState(-1);
  const [clicks, setClicks] = useState(-1);
  const [weight, setWeight] = useState(0);
  const [priority, setPriority] = useState(0);
  const [isContract, setIsContract] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const { currentPage, setCurrentPage } = useContext(BreadcrumbContext);
  const [message, setMessage] = useState("");

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const [campaign, setCampaign] = useState({
    campaignName: "Default Campaign",
    advertiserId: advertiserId,
    priority: priority,
    startDate: startDate,
    endDate: endDate,
    revenueType: 1,
    revenue: revenue,
    impressions: impressions,
    clicks: clicks,
    weight: weight,
    isContract: false,
    level: 1,
  });

  useEffect(() => {
    setCurrentPage("Create Campaign");
    fetchAdvertiserByAgencyId(2, base64Credentials).then((response) => {
      setAdvertisers(response.data);
      setLoading(false);
    });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "priority") {
      setCampaign({ ...campaign, priority: parseInt(value) });
    }
    if (name === "priority" && value === 1) {
      setIsContract(true);
      setDisabled(false);
    } else if (name === "priority" && (value === 0 || value === -1)) {
      setIsContract(false);
      setDisabled(true);
    }
    if (name === "level" && value >= 1) {
      setIsContract(true);
    }

    let newValue;

    if (
      name === "advertiserId" ||
      name === "impressions" ||
      name === "clicks" ||
      name === "weight"
    ) {
      newValue = parseInt(value, 10);
    } else if (name === "revenue") {
      newValue = parseFloat(value);
      if (isNaN(newValue)) {
        newValue = 0.0;
      }
    } else {
      newValue = value;
    }

    setCampaign((prevData) => ({ ...prevData, [name]: newValue }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (campaign.priority === 1) {
      campaign.priority = campaign.level;
    }
    addCampaign(campaign, base64Credentials)
      .then((response) => {
        localStorage.setItem("campaign", JSON.stringify(response.data));
        setLoading(false);
        navigate("/ssp/banner/create");
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          setMessage(error.response.data.error.message);
          toast.error(error.response.data.error.message, {
            autoClose: 7000,
            pauseOnHover: true,
          });
        } else if (error.request) {
          setMessage("No response from server");
        }
        toast.error("Error creating campaign");
      });
  };

  const handleAlertClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ToastContainer />
      <div className="mt-6 overflow-y-auto">
        {loading || advertisers === undefined ? (
          <div className="animate-pulse h-6 bg-gray-200"></div>
        ) : (
          <div className="bg-white border border-border rounded-md p-10">
            <div className="grid grid-cols-3 gap-4">
              <div className="space-y-1">
                <label className="block text-sm">Select an advertiser</label>
                <select
                  id="dropdown"
                  value={campaign.advertiserId}
                  name="advertiserId"
                  onChange={handleChange}
                  className="form-style"
                >
                  {advertisers.map((item) => (
                    <option key={item.advertiserId} value={item.advertiserId}>
                      {item.advertiserName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label className="block text-sm">Campaign name*</label>
                <input
                  type="text"
                  value={campaign.campaignName}
                  name="campaignName"
                  required
                  onChange={handleChange}
                  className="form-style"
                />
              </div>
              <div className="space-y-1">
                <label className="block text-sm">Campaign Type*</label>
                <select
                  name="priority"
                  value={campaign.priority >= 1 ? 1 : campaign.priority}
                  onChange={handleChange}
                  className="form-style"
                >
                  <option value={0}>Remnant</option>
                  <option value={1}>Contract</option>
                  <option value={-1}>Override</option>
                </select>
              </div>
              {isContract && (
                <div className="space-y-1">
                  <label className="block text-sm">
                    Priority over other campaigns
                  </label>
                  <select
                    value={campaign.level}
                    name="level"
                    onChange={handleChange}
                    className="form-style mt-2"
                  >
                    {[...Array(10)].map((_, index) => (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="flex-1 space-y-1">
                <label className="block text-sm">Start Date</label>
                <input
                  type="date"
                  className="form-style"
                  name="startDate"
                  value={startDate.toISOString().split("T")[0]}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        value: new Date(e.target.value),
                        name: "startDate",
                      },
                    })
                  }
                />
              </div>
              <div className="flex-1 space-y-1">
                <label className="block text-sm">End Date</label>
                <input
                  type="date"
                  className="form-style"
                  name="endDate"
                  value={endDate.toISOString().split("T")[0]}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        value: new Date(e.target.value),
                        name: "endDate",
                      },
                    })
                  }
                />
              </div>
              <div className="flex-1 space-y-1">
                <label className="block text-sm">Pricing Model*</label>
                <select
                  name="revenueType"
                  value={campaign.revenueType}
                  onChange={handleChange}
                  className="form-style"
                >
                  <option value={1}>CPM</option>
                  <option value={2}>CPC</option>
                </select>
              </div>
              <div className="flex-1 space-y-1">
                <label className="block text-sm">Price/Rate*</label>
                <input
                  type="number"
                  name="revenue"
                  value={campaign.revenue}
                  step="0.01"
                  onChange={handleChange}
                  className="form-style"
                />
              </div>
              <div className="flex-1 space-y-1">
                <label className="block text-sm">Impressions*</label>
                <input
                  type="number"
                  name="impressions"
                  value={campaign.impressions}
                  onChange={handleChange}
                  className="form-style"
                />
              </div>
              <div className="flex-1 space-y-1">
                <label className="block text-sm">Clicks*</label>
                <input
                  type="number"
                  name="clicks"
                  value={campaign.clicks}
                  onChange={handleChange}
                  className="form-style"
                />
              </div>

              <div className="space-y-1">
                <label className="block text-sm">
                  Priority in relation to other campaigns (Active/Paused)
                </label>
                <input
                  type="number"
                  name="weight"
                  value={campaign.weight}
                  onChange={handleChange}
                  className="form-style"
                />
                <div>
                  <button
                    className="bg-primary mt-4 text-white py-2 px-6 rounded"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
