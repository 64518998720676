import React, { useContext, useEffect, useState } from "react";

import AddUserModal from "./add-user";
import {
  deleteSspUser,
  fetchAdvertiserByAgencyId,
} from "../../../../api/ssp-api";
import AgencyWrapper from "../../../../components/wrappers/agency-wrapper";
import { AuthContext } from "../../../../provider/AuthContext";
import { deleteDspUserApi, fetchDspUsersApi } from "../../../../api/api";
import DspUserTable from "./dsp-users-table";
import Loader from "../../../../components/loader";
import { BreadcrumbContext } from "../../../../layouts/dashboardLayout";

export default function AgencyUsers() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [advertisers, setAdvertisers] = useState([]);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);

  const [dspUsers, setDspUsers] = useState([]);
  const [dspLoading, setDspLoading] = useState(true);
  const {setActions} = useContext(BreadcrumbContext)

  useEffect(() => {
    setActions(<div> <div
      className="py-2 px-3 text-sm bg-primary rounded-md hover:scale-105 transition-all duration-200 text-white font-semibold cursor-pointer"
      onClick={handleOpenModal}
     >
       Add User
     </div></div>)
    fetchDspUsersApi(token, agencyId)
      .then((response) => {
        setDspUsers(response.data);
        console.log("response users", response.data);
        setDspLoading(false);
        fetchAdvertisers();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchAdvertisers = () => {
    fetchAdvertiserByAgencyId(agencyId, base64Credentials)
      .then((response) => {
        console.log("advertisers", response.data);
        setAdvertisers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleDelete = (dspId, sspId) => {
    deleteSspUser(sspId)
      .then((response) => {
        if (response.status === 200) {
          deleteDspUserApi(dspId)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#6EBF4A",
      },
    },
  });

  return (
      <div header={"Users"}>
       
        <div className="flex justify-end">
       
        </div>
        {loading || advertisers === undefined ? (
          <Loader/>
        ) : (
          <div style={{  overflow: "auto" }} spacing={2}>
            <div container>
              <AddUserModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                data={advertisers}
              />
            </div>
            <div xs={12}>
              {dspLoading || dspUsers === undefined ? (
                <Loader/>
              ) : (
                <DspUserTable data={dspUsers} handleDelete={handleDelete} />
              )}
            </div>
          </div>
        )}
      </div>
  );
}
