import moment from "moment";
import { useState } from "react";
import Chart from "react-apexcharts";
import { chartColors } from "../audience/constant";

const MixedGraph = ({
  xlabels,
  values,
  values2,
  ylabels,
  yValuesName,
  yValuesName2,
  height,
}) => {
  const [year, setYear] = useState(moment(Date.now()).format("yyyy") - 1);

  const chartData = {
    series: [
      {
        name: yValuesName,
        type: "column",
        data: values,
      },
      {
        name: yValuesName2,
        type: "line",
        data: values2,
      },
    ],
    options: {
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
        stacked: false,
      },
      stroke: {
        width: [0, 4],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "30%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: [
        {
          title: {
            text: yValuesName,
          },
          categories: ylabels,
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          opposite: true,
          title: {
            text: `${yValuesName2} `,
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(1);
            },
          },
        },
      ],
      xaxis: {
        categories: xlabels.map((item) => item),
      },
      colors: chartColors,
      legend: {
        show: true,
      },
    },
  };

  return (
    <div className="rounded-md">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={height}
      />
    </div>
  );
};

export default MixedGraph;
