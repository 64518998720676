export const styles = {
  root: {
    position: "absolute",
    left: "40%",
    top: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "20%",
    minHeight: "20vh",
    outline: "none",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "6rem",
    height: "6rem",
    background: "white",
    borderRadius: "50%",
    marginBottom: "1.0rem",
  },
  message: {
    color: "white",
    marginTop: "0.4rem",
    fontSize: "1.1rem",
  },
  modal: {
    background: "rgba(0,0,0,0.6)",
  },
};
