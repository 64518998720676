import { useContext, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../../../provider/AuthContext";
import { editCampaign, fetchCampaignById } from "../../../../../../api/ssp-api";
import Spinner from "../../../../../../components/spinner";
import * as Yup from "yup";

export default function CampaignDetails({
  advertisers,
  setCampaignDetails,
  handleComplete,
}) {
  const [disabled, setDisabled] = useState(true);
  const [campaign, setCampaign] = useState({
    priority: 0,
    campaignName: "",
    advertiserId: 1,
  });
  const [isContract, setIsContract] = useState(false);
  const { userDetails } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const { uuid } = useParams();
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    var isPremiumObject = localStorage.getItem("isPremium");
    console.log(isPremiumObject);
    var isPremium = JSON.parse(isPremiumObject);
    setIsChecked(isPremium);
    console.log("Fetching campaign");
    fetchCampaignById(uuid, base64Credentials).then((response) => {
      console.log(response.data);
      setData(response.data);
    });
  }, []);

  const validationSchema = Yup.object({
    campaignName: Yup.string().required("Campaign name is required"),
    advertiserId: Yup.string().required("Please select advertiser"),
    campaignType: Yup.string().required("Select compaign Type"),
    level: Yup.string(),
  });
  return (
    data && (
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <div item>
            <div className="p-6 border border-border text-sm bg-secondary bg-opacity-10 rounded">
              <p>
                <b>Remnant:</b> Default Type. Use Remnant campaigns to display
                house banners, ad-network banners, or even direct advertising
                that has been sold.
              </p>
              <p>
                <b>Contract:</b> Contract campaigns are for smoothly delivering
                the impressions required to achieve a specified time-critical
                performance requirement.
              </p>
              <p>
                <b>Override:</b> Override campaigns are a special campaign type
                specifically to override (i.e. take priority over) Remnant and
                Contract campaigns.
              </p>

              <p>
                <b>Priority:</b> Priority over other campaigns. The higher the
                number, the higher the priority. (Only used in Contract
                campaigns)
              </p>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            campaignName: data.campaignName,
            advertiserId: data.advertiserId,
            campaignType: data.priority,
            level: data.level,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setDisabled(true);
            setLoading(true);
            const campaign = {
              priority: parseInt(values.campaignType),
              campaignName: values.campaignName,
              level: parseInt(values.level),
              advertiserId: values.advertiserId,
            };
            console.log(campaign);
            editCampaign(uuid, campaign, base64Credentials).then((data) => {
              setLoading(false);
              toast.success("Campaign details saved", {
                position: "top-center",
              });
              handleComplete();
            });
          }}
        >
          {({
            errors,
            values,
            handleChange,
            handleSubmit,
            touched,
            isSubmitting,
          }) => (
            <form className="mt-6 col-span-12 " onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-1">
                  <h1 className="text-sm">Compaign Type*</h1>
                  <select
                    onChange={handleChange}
                    defaultValue={data.priority}
                    name="campaignType"
                    className="form-style"
                  >
                    <option value={0}>Remnant</option>
                    <option value={1}>Contract</option>
                    {/* <option value={-1}>Override</option> */}
                  </select>
                </div>
                <div className=" space-y-1">
                  <h1 className="text-sm">Campaign name*</h1>
                  <input
                    type="text"
                    onChange={handleChange}
                    defaultValue={data.campaignName}
                    name="campaignName"
                    className="form-style"
                  />
                  <div>
                    {touched.campaignName && errors.campaignName && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.campaignName}
                      </div>
                    )}
                  </div>
                </div>

                <div className=" space-y-1">
                  <h1 className="text-sm">Select advertiser*</h1>
                  <select
                    onChange={handleChange}
                    defaultValue={data.advertiserId}
                    name="advertiserId"
                    className="form-style"
                  >
                    <option>Select</option>
                    {advertisers.map((item) => (
                      <option key={item.advertiserId} value={item.advertiserId}>
                        {item.advertiserName}
                      </option>
                    ))}
                  </select>
                  <div>
                    {touched.advertiserId && errors.advertiserId && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.advertiserId}
                      </div>
                    )}
                  </div>
                </div>

                {values.campaignType == 1 ? (
                  <div className=" space-y-1">
                    <h1 className="text-sm">Priority over other campaigns</h1>
                    <select
                      onChange={handleChange}
                      value={values.level}
                      name="level"
                      className="form-style"
                    >
                      {[...Array(10)].map((_, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                    <div>
                      {touched.level && errors.level && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.level}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="space-y-1 flex flex-col">
                  <div>
                    <label className="text-sm">
                      Do you target premium sites?
                    </label>
                  </div>
                  {isChecked}
                  <div className="flex space-x-2 items-center group">
                    <input
                      placeholder="Enter an Email Address"
                      type="checkbox"
                      name="targetType"
                      checked={isChecked}
                      className="size-8 rounded-md border-border checked:bg-primary focus:ring-primary hover:bg-transparent checked:hover:bg-primary checked:focus:bg-primary   focus:border-primary mt-1"
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                        localStorage.setItem("isPremium", e.target.checked);
                      }}
                      margin="normal"
                    />
                    <p className=" group-aria-checked:hidden font-normal  text-sm">
                      (
                      {!isChecked
                        ? "No, send my Advert to any sites"
                        : "Yes, send my Advert to premium sites"}{" "}
                      )
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="py-2 px-4 bg-primary text-white font-medium rounded-md mt-6 w-40  flex justify-center"
                type="submit"
              >
                {loading ? <Spinner /> : "Save Changes"}
              </button>
            </form>
          )}
        </Formik>
      </div>
    )
  );
}
