export const countries = [
  {
    label: "Afghanistan",
    code: "AF",
    iso3: "AFG",
    numeric: "004",
  },
  {
    label: "South Africa",
    code: "ZA",
    iso3: "ZAF",
    numeric: "710",
  },
  {
    label: "Åland Islands",
    code: "AX",
    iso3: "ALA",
    numeric: "248",
  },
  {
    label: "Albania",
    code: "AL",
    iso3: "ALB",
    numeric: "008",
  },
  {
    label: "Algeria",
    code: "DZ",
    iso3: "DZA",
    numeric: "012",
  },
  {
    label: "Germany",
    code: "DE",
    iso3: "DEU",
    numeric: "276",
  },
  {
    label: "Andorra",
    code: "AD",
    iso3: "AND",
    numeric: "020",
  },
  {
    label: "Angola",
    code: "AO",
    iso3: "AGO",
    numeric: "024",
  },
  {
    label: "Anguilla",
    code: "AI",
    iso3: "AIA",
    numeric: "660",
  },
  {
    label: "Antarctica",
    code: "AQ",
    iso3: "ATA",
    numeric: "010",
  },
  {
    label: "Antigua and Barbuda",
    code: "AG",
    iso3: "ATG",
    numeric: "028",
  },
  {
    label: "Saudi Arabia",
    code: "SA",
    iso3: "SAU",
    numeric: "682",
  },
  {
    label: "Argentina",
    code: "AR",
    iso3: "ARG",
    numeric: "032",
  },
  {
    label: "Armenia",
    code: "AM",
    iso3: "ARM",
    numeric: "051",
  },
  {
    label: "Aruba",
    code: "AW",
    iso3: "ABW",
    numeric: "533",
  },
  {
    label: "Australia",
    code: "AU",
    iso3: "AUS",
    numeric: "036",
  },
  {
    label: "Austria",
    code: "AT",
    iso3: "AUT",
    numeric: "040",
  },
  {
    label: "Azerbaijan",
    code: "AZ",
    iso3: "AZE",
    numeric: "031",
  },
  {
    label: "Bahamas (the)",
    code: "BS",
    iso3: "BHS",
    numeric: "044",
  },
  {
    label: "Bahrain",
    code: "BH",
    iso3: "BHR",
    numeric: "048",
  },
  {
    label: "Bangladesh",
    code: "BD",
    iso3: "BGD",
    numeric: "050",
  },
  {
    label: "Barbados",
    code: "BB",
    iso3: "BRB",
    numeric: "052",
  },
  {
    label: "Belarus",
    code: "BY",
    iso3: "BLR",
    numeric: "112",
  },
  {
    label: "Belgium",
    code: "BE",
    iso3: "BEL",
    numeric: "056",
  },
  {
    label: "Belize",
    code: "BZ",
    iso3: "BLZ",
    numeric: "084",
  },
  {
    label: "Benin",
    code: "BJ",
    iso3: "BEN",
    numeric: "204",
  },
  {
    label: "Bermuda",
    code: "BM",
    iso3: "BMU",
    numeric: "060",
  },
  {
    label: "Bhutan",
    code: "BT",
    iso3: "BTN",
    numeric: "064",
  },
  {
    label: "Bolivia (Plurinational State of)",
    code: "BO",
    iso3: "BOL",
    numeric: "068",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
    iso3: "BES",
    numeric: "535",
  },
  {
    label: "Bosnia and Herzegovina",
    code: "BA",
    iso3: "BIH",
    numeric: "070",
  },
  {
    label: "Botswana",
    code: "BW",
    iso3: "BWA",
    numeric: "072",
  },
  {
    label: "Bouvet Island",
    code: "BV",
    iso3: "BVT",
    numeric: "074",
  },
  {
    label: "Brazil",
    code: "BR",
    iso3: "BRA",
    numeric: "076",
  },
  {
    label: "Brunei Darussalam",
    code: "BN",
    iso3: "BRN",
    numeric: "096",
  },
  {
    label: "Bulgaria",
    code: "BG",
    iso3: "BGR",
    numeric: "100",
  },
  {
    label: "Burkina Faso",
    code: "BF",
    iso3: "BFA",
    numeric: "854",
  },
  {
    label: "Burundi",
    code: "BI",
    iso3: "BDI",
    numeric: "108",
  },
  {
    label: "Cabo Verde",
    code: "CV",
    iso3: "CPV",
    numeric: "132",
  },
  {
    label: "Cayman Islands (the)",
    code: "KY",
    iso3: "CYM",
    numeric: "136",
  },
  {
    label: "Cambodia",
    code: "KH",
    iso3: "KHM",
    numeric: "116",
  },
  {
    label: "Cameroon",
    code: "CM",
    iso3: "CMR",
    numeric: "120",
  },
  {
    label: "Canada",
    code: "CA",
    iso3: "CAN",
    numeric: "124",
  },
  {
    label: "Chile",
    code: "CL",
    iso3: "CHL",
    numeric: "152",
  },
  {
    label: "China",
    code: "CN",
    iso3: "CHN",
    numeric: "156",
  },
  {
    label: "Christmas Island",
    code: "CX",
    iso3: "CXR",
    numeric: "162",
  },
  {
    label: "Cyprus",
    code: "CY",
    iso3: "CYP",
    numeric: "196",
  },
  {
    label: "Cocos (Keeling) Islands (the)",
    code: "CC",
    iso3: "CCK",
    numeric: "166",
  },
  {
    label: "Colombia",
    code: "CO",
    iso3: "COL",
    numeric: "170",
  },
  {
    label: "Comoros (the)",
    code: "KM",
    iso3: "COM",
    numeric: "174",
  },
  {
    label: "Congo (the Democratic Republic of the)",
    code: "CD",
    iso3: "COD",
    numeric: "180",
  },
  {
    label: "Congo (the)",
    code: "CG",
    iso3: "COG",
    numeric: "178",
  },
  {
    label: "Cook Islands (the)",
    code: "CK",
    iso3: "COK",
    numeric: "184",
  },
  {
    label: "Korea (the Republic of)",
    code: "KR",
    iso3: "KOR",
    numeric: "410",
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    code: "KP",
    iso3: "PRK",
    numeric: "408",
  },
  {
    label: "Costa Rica",
    code: "CR",
    iso3: "CRI",
    numeric: "188",
  },
  {
    label: "Côte d'Ivoire",
    code: "CI",
    iso3: "CIV",
    numeric: "384",
  },
  {
    label: "Croatia",
    code: "HR",
    iso3: "HRV",
    numeric: "191",
  },
  {
    label: "Cuba",
    code: "CU",
    iso3: "CUB",
    numeric: "192",
  },
  {
    label: "Curaçao",
    code: "CW",
    iso3: "CUW",
    numeric: "531",
  },
  {
    label: "Denmark",
    code: "DK",
    iso3: "DNK",
    numeric: "208",
  },
  {
    label: "Djibouti",
    code: "DJ",
    iso3: "DJI",
    numeric: "262",
  },
  {
    label: "Dominican Republic (the)",
    code: "DO",
    iso3: "DOM",
    numeric: "214",
  },
  {
    label: "Dominica",
    code: "DM",
    iso3: "DMA",
    numeric: "212",
  },
  {
    label: "Egypt",
    code: "EG",
    iso3: "EGY",
    numeric: "818",
  },
  {
    label: "El Salvador",
    code: "SV",
    iso3: "SLV",
    numeric: "222",
  },
  {
    label: "United Arab Emirates (the)",
    code: "AE",
    iso3: "ARE",
    numeric: "784",
  },
  {
    label: "Ecuador",
    code: "EC",
    iso3: "ECU",
    numeric: "218",
  },
  {
    label: "Eritrea",
    code: "ER",
    iso3: "ERI",
    numeric: "232",
  },
  {
    label: "Spain",
    code: "ES",
    iso3: "ESP",
    numeric: "724",
  },
  {
    label: "Estonia",
    code: "EE",
    iso3: "EST",
    numeric: "233",
  },
  {
    label: "United States of America (the)",
    code: "US",
    iso3: "USA",
    numeric: "840",
  },
  {
    label: "Ethiopia",
    code: "ET",
    iso3: "ETH",
    numeric: "231",
  },
  {
    label: "Falkland Islands (the) [Malvinas]",
    code: "FK",
    iso3: "FLK",
    numeric: "238",
  },
  {
    label: "Faroe Islands (the)",
    code: "FO",
    iso3: "FRO",
    numeric: "234",
  },
  {
    label: "Fiji",
    code: "FJ",
    iso3: "FJI",
    numeric: "242",
  },
  {
    label: "Finland",
    code: "FI",
    iso3: "FIN",
    numeric: "246",
  },
  {
    label: "France",
    code: "FR",
    iso3: "FRA",
    numeric: "250",
  },
  {
    label: "Gabon",
    code: "GA",
    iso3: "GAB",
    numeric: "266",
  },
  {
    label: "Gambia (the)",
    code: "GM",
    iso3: "GMB",
    numeric: "270",
  },
  {
    label: "Georgia",
    code: "GE",
    iso3: "GEO",
    numeric: "268",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    code: "GS",
    iso3: "SGS",
    numeric: "239",
  },
  {
    label: "Ghana",
    code: "GH",
    iso3: "GHA",
    numeric: "288",
  },
  {
    label: "Gibraltar",
    code: "GI",
    iso3: "GIB",
    numeric: "292",
  },
  {
    label: "Greece",
    code: "GR",
    iso3: "GRC",
    numeric: "300",
  },
  {
    label: "Grenada",
    code: "GD",
    iso3: "GRD",
    numeric: "308",
  },
  {
    label: "Greenland",
    code: "GL",
    iso3: "GRL",
    numeric: "304",
  },
  {
    label: "Guadeloupe",
    code: "GP",
    iso3: "GLP",
    numeric: "312",
  },
  {
    label: "Guam",
    code: "GU",
    iso3: "GUM",
    numeric: "316",
  },
  {
    label: "Guatemala",
    code: "GT",
    iso3: "GTM",
    numeric: "320",
  },
  {
    label: "Guernsey",
    code: "GG",
    iso3: "GGY",
    numeric: "831",
  },
  {
    label: "Guinea",
    code: "GN",
    iso3: "GIN",
    numeric: "324",
  },
  {
    label: "Equatorial Guinea",
    code: "GQ",
    iso3: "GNQ",
    numeric: "226",
  },
  {
    label: "Guinea-Bissau",
    code: "GW",
    iso3: "GNB",
    numeric: "624",
  },
  {
    label: "Guyana",
    code: "GY",
    iso3: "GUY",
    numeric: "328",
  },
  {
    label: "French Guiana",
    code: "GF",
    iso3: "GUF",
    numeric: "254",
  },
  {
    label: "Haiti",
    code: "HT",
    iso3: "HTI",
    numeric: "332",
  },
  {
    label: "Heard Island and McDonald Islands",
    code: "HM",
    iso3: "HMD",
    numeric: "334",
  },
  {
    label: "Honduras",
    code: "HN",
    iso3: "HND",
    numeric: "340",
  },
  {
    label: "Hong Kong",
    code: "HK",
    iso3: "HKG",
    numeric: "344",
  },
  {
    label: "Hungary",
    code: "HU",
    iso3: "HUN",
    numeric: "348",
  },
  {
    label: "Isle of Man",
    code: "IM",
    iso3: "IMN",
    numeric: "833",
  },
  {
    label: "United States Minor Outlying Islands (the)",
    code: "UM",
    iso3: "UMI",
    numeric: "581",
  },
  {
    label: "India",
    code: "IN",
    iso3: "IND",
    numeric: "356",
  },
  {
    label: "British Indian Ocean Territory (the)",
    code: "IO",
    iso3: "IOT",
    numeric: "086",
  },
  {
    label: "Indonesia",
    code: "ID",
    iso3: "IDN",
    numeric: "360",
  },
  {
    label: "Iran (Islamic Republic of)",
    code: "IR",
    iso3: "IRN",
    numeric: "364",
  },
  {
    label: "Iraq",
    code: "IQ",
    iso3: "IRQ",
    numeric: "368",
  },
  {
    label: "Ireland",
    code: "IE",
    iso3: "IRL",
    numeric: "372",
  },
  {
    label: "Iceland",
    code: "IS",
    iso3: "ISL",
    numeric: "352",
  },
  {
    label: "Israel",
    code: "IL",
    iso3: "ISR",
    numeric: "376",
  },
  {
    label: "Italy",
    code: "IT",
    iso3: "ITA",
    numeric: "380",
  },
  {
    label: "Jamaica",
    code: "JM",
    iso3: "JAM",
    numeric: "388",
  },
  {
    label: "Japan",
    code: "JP",
    iso3: "JPN",
    numeric: "392",
  },
  {
    label: "Jersey",
    code: "JE",
    iso3: "JEY",
    numeric: "832",
  },
  {
    label: "Jordan",
    code: "JO",
    iso3: "JOR",
    numeric: "400",
  },
  {
    label: "Kazakhstan",
    code: "KZ",
    iso3: "KAZ",
    numeric: "398",
  },
  {
    label: "Kenya",
    code: "KE",
    iso3: "KEN",
    numeric: "404",
  },
  {
    label: "Kyrgyzstan",
    code: "KG",
    iso3: "KGZ",
    numeric: "417",
  },
  {
    label: "Kiribati",
    code: "KI",
    iso3: "KIR",
    numeric: "296",
  },
  {
    label: "Kuwait",
    code: "KW",
    iso3: "KWT",
    numeric: "414",
  },
  {
    label: "Lao People's Democratic Republic (the)",
    code: "LA",
    iso3: "LAO",
    numeric: "418",
  },
  {
    label: "Lesotho",
    code: "LS",
    iso3: "LSO",
    numeric: "426",
  },
  {
    label: "Latvia",
    code: "LV",
    iso3: "LVA",
    numeric: "428",
  },
  {
    label: "Lebanon",
    code: "LB",
    iso3: "LBN",
    numeric: "422",
  },
  {
    label: "Liberia",
    code: "LR",
    iso3: "LBR",
    numeric: "430",
  },
  {
    label: "Libya",
    code: "LY",
    iso3: "LBY",
    numeric: "434",
  },
  {
    label: "Liechtenstein",
    code: "LI",
    iso3: "LIE",
    numeric: "438",
  },
  {
    label: "Lithuania",
    code: "LT",
    iso3: "LTU",
    numeric: "440",
  },
  {
    label: "Luxembourg",
    code: "LU",
    iso3: "LUX",
    numeric: "442",
  },
  {
    label: "Macao",
    code: "MO",
    iso3: "MAC",
    numeric: "446",
  },
  {
    label: "Macedonia (the former Yugoslav Republic of)",
    code: "MK",
    iso3: "MKD",
    numeric: "807",
  },
  {
    label: "Madagascar",
    code: "MG",
    iso3: "MDG",
    numeric: "450",
  },
  {
    label: "Malaysia",
    code: "MY",
    iso3: "MYS",
    numeric: "458",
  },
  {
    label: "Malawi",
    code: "MW",
    iso3: "MWI",
    numeric: "454",
  },
  {
    label: "Maldives",
    code: "MV",
    iso3: "MDV",
    numeric: "462",
  },
  {
    label: "Mali",
    code: "ML",
    iso3: "MLI",
    numeric: "466",
  },
  {
    label: "Malta",
    code: "MT",
    iso3: "MLT",
    numeric: "470",
  },
  {
    label: "Northern Mariana Islands (the)",
    code: "MP",
    iso3: "MNP",
    numeric: "580",
  },
  {
    label: "Morocco",
    code: "MA",
    iso3: "MAR",
    numeric: "504",
  },
  {
    label: "Marshall Islands (the)",
    code: "MH",
    iso3: "MHL",
    numeric: "584",
  },
  {
    label: "Martinique",
    code: "MQ",
    iso3: "MTQ",
    numeric: "474",
  },
  {
    label: "Mauritius",
    code: "MU",
    iso3: "MUS",
    numeric: "480",
  },
  {
    label: "Mauritania",
    code: "MR",
    iso3: "MRT",
    numeric: "478",
  },
  {
    label: "Mayotte",
    code: "YT",
    iso3: "MYT",
    numeric: "175",
  },
  {
    label: "Mexico",
    code: "MX",
    iso3: "MEX",
    numeric: "484",
  },
  {
    label: "Micronesia (Federated States of)",
    code: "FM",
    iso3: "FSM",
    numeric: "583",
  },
  {
    label: "Moldova (the Republic of)",
    code: "MD",
    iso3: "MDA",
    numeric: "498",
  },
  {
    label: "Monaco",
    code: "MC",
    iso3: "MCO",
    numeric: "492",
  },
  {
    label: "Mongolia",
    code: "MN",
    iso3: "MNG",
    numeric: "496",
  },
  {
    label: "Montenegro",
    code: "ME",
    iso3: "MNE",
    numeric: "499",
  },
  {
    label: "Montserrat",
    code: "MS",
    iso3: "MSR",
    numeric: "500",
  },
  {
    label: "Mozambique",
    code: "MZ",
    iso3: "MOZ",
    numeric: "508",
  },
  {
    label: "Myanmar",
    code: "MM",
    iso3: "MMR",
    numeric: "104",
  },
  {
    label: "Namibia",
    code: "NA",
    iso3: "NAM",
    numeric: "516",
  },
  {
    label: "Nauru",
    code: "NR",
    iso3: "NRU",
    numeric: "520",
  },
  {
    label: "Nepal",
    code: "NP",
    iso3: "NPL",
    numeric: "524",
  },
  {
    label: "Nicaragua",
    code: "NI",
    iso3: "NIC",
    numeric: "558",
  },
  {
    label: "Niger (the)",
    code: "NE",
    iso3: "NER",
    numeric: "562",
  },
  {
    label: "Nigeria",
    code: "NG",
    iso3: "NGA",
    numeric: "566",
  },
  {
    label: "Niue",
    code: "NU",
    iso3: "NIU",
    numeric: "570",
  },
  {
    label: "Norfolk Island",
    code: "NF",
    iso3: "NFK",
    numeric: "574",
  },
  {
    label: "Norway",
    code: "NO",
    iso3: "NOR",
    numeric: "578",
  },
  {
    label: "New Caledonia",
    code: "NC",
    iso3: "NCL",
    numeric: "540",
  },
  {
    label: "New Zealand",
    code: "NZ",
    iso3: "NZL",
    numeric: "554",
  },
  {
    label: "Oman",
    code: "OM",
    iso3: "OMN",
    numeric: "512",
  },
  {
    label: "Uganda",
    code: "UG",
    iso3: "UGA",
    numeric: "800",
  },
  {
    label: "Uzbekistan",
    code: "UZ",
    iso3: "UZB",
    numeric: "860",
  },
  {
    label: "Pakistan",
    code: "PK",
    iso3: "PAK",
    numeric: "586",
  },
  {
    label: "Palau",
    code: "PW",
    iso3: "PLW",
    numeric: "585",
  },
  {
    label: "Palestine, State of",
    code: "PS",
    iso3: "PSE",
    numeric: "275",
  },
  {
    label: "Panama",
    code: "PA",
    iso3: "PAN",
    numeric: "591",
  },
  {
    label: "Papua New Guinea",
    code: "PG",
    iso3: "PNG",
    numeric: "598",
  },
  {
    label: "Paraguay",
    code: "PY",
    iso3: "PRY",
    numeric: "600",
  },
  {
    label: "Netherlands (the)",
    code: "NL",
    iso3: "NLD",
    numeric: "528",
  },
  {
    label: "Peru",
    code: "PE",
    iso3: "PER",
    numeric: "604",
  },
  {
    label: "Philippines (the)",
    code: "PH",
    iso3: "PHL",
    numeric: "608",
  },
  {
    label: "Pitcairn",
    code: "PN",
    iso3: "PCN",
    numeric: "612",
  },
  {
    label: "Poland",
    code: "PL",
    iso3: "POL",
    numeric: "616",
  },
  {
    label: "French Polynesia",
    code: "PF",
    iso3: "PYF",
    numeric: "258",
  },
  {
    label: "Puerto Rico",
    code: "PR",
    iso3: "PRI",
    numeric: "630",
  },
  {
    label: "Portugal",
    code: "PT",
    iso3: "PRT",
    numeric: "620",
  },
  {
    label: "Qatar",
    code: "QA",
    iso3: "QAT",
    numeric: "634",
  },
  {
    label: "Syrian Arab Republic",
    code: "SY",
    iso3: "SYR",
    numeric: "760",
  },
  {
    label: "Central African Republic (the)",
    code: "CF",
    iso3: "CAF",
    numeric: "140",
  },
  {
    label: "Réunion",
    code: "RE",
    iso3: "REU",
    numeric: "638",
  },
  {
    label: "Romania",
    code: "RO",
    iso3: "ROU",
    numeric: "642",
  },
  {
    label: "United Kingdom of Great Britain and Northern Ireland (the)",
    code: "GB",
    iso3: "GBR",
    numeric: "826",
  },
  {
    label: "Russian Federation (the)",
    code: "RU",
    iso3: "RUS",
    numeric: "643",
  },
  {
    label: "Rwanda",
    code: "RW",
    iso3: "RWA",
    numeric: "646",
  },
  {
    label: "Western Sahara*",
    code: "EH",
    iso3: "ESH",
    numeric: "732",
  },
  {
    label: "Saint Barthélemy",
    code: "BL",
    iso3: "BLM",
    numeric: "652",
  },
  {
    label: "Saint Kitts and Nevis",
    code: "KN",
    iso3: "KNA",
    numeric: "659",
  },
  {
    label: "San Marino",
    code: "SM",
    iso3: "SMR",
    numeric: "674",
  },
  {
    label: "Saint Martin (French part)",
    code: "MF",
    iso3: "MAF",
    numeric: "663",
  },
  {
    label: "Sint Maarten (Dutch part)",
    code: "SX",
    iso3: "SXM",
    numeric: "534",
  },
  {
    label: "Saint Pierre and Miquelon",
    code: "PM",
    iso3: "SPM",
    numeric: "666",
  },
  {
    label: "Holy See (the)",
    code: "VA",
    iso3: "VAT",
    numeric: "336",
  },
  {
    label: "Saint Vincent and the Grenadines",
    code: "VC",
    iso3: "VCT",
    numeric: "670",
  },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    iso3: "SHN",
    numeric: "654",
  },
  {
    label: "Saint Lucia",
    code: "LC",
    iso3: "LCA",
    numeric: "662",
  },
  {
    label: "Solomon Islands",
    code: "SB",
    iso3: "SLB",
    numeric: "090",
  },
  {
    label: "Samoa",
    code: "WS",
    iso3: "WSM",
    numeric: "882",
  },
  {
    label: "American Samoa",
    code: "AS",
    iso3: "ASM",
    numeric: "016",
  },
  {
    label: "Sao Tome and Principe",
    code: "ST",
    iso3: "STP",
    numeric: "678",
  },
  {
    label: "Senegal",
    code: "SN",
    iso3: "SEN",
    numeric: "686",
  },
  {
    label: "Serbia",
    code: "RS",
    iso3: "SRB",
    numeric: "688",
  },
  {
    label: "Seychelles",
    code: "SC",
    iso3: "SYC",
    numeric: "690",
  },
  {
    label: "Sierra Leone",
    code: "SL",
    iso3: "SLE",
    numeric: "694",
  },
  {
    label: "Singapore",
    code: "SG",
    iso3: "SGP",
    numeric: "702",
  },
  {
    label: "Slovakia",
    code: "SK",
    iso3: "SVK",
    numeric: "703",
  },
  {
    label: "Slovenia",
    code: "SI",
    iso3: "SVN",
    numeric: "705",
  },
  {
    label: "Somalia",
    code: "SO",
    iso3: "SOM",
    numeric: "706",
  },
  {
    label: "Sudan (the)",
    code: "SD",
    iso3: "SDN",
    numeric: "729",
  },
  {
    label: "South Sudan",
    code: "SS",
    iso3: "SSD",
    numeric: "728",
  },
  {
    label: "Sri Lanka",
    code: "LK",
    iso3: "LKA",
    numeric: "144",
  },
  {
    label: "Sweden",
    code: "SE",
    iso3: "SWE",
    numeric: "752",
  },
  {
    label: "Switzerland",
    code: "CH",
    iso3: "CHE",
    numeric: "756",
  },
  {
    label: "Surilabel",
    code: "SR",
    iso3: "SUR",
    numeric: "740",
  },
  {
    label: "Svalbard and Jan Mayen",
    code: "SJ",
    iso3: "SJM",
    numeric: "744",
  },
  {
    label: "Swaziland",
    code: "SZ",
    iso3: "SWZ",
    numeric: "748",
  },
  {
    label: "Tajikistan",
    code: "TJ",
    iso3: "TJK",
    numeric: "762",
  },
  {
    label: "Taiwan (Province of China)",
    code: "TW",
    iso3: "TWN",
    numeric: "158",
  },
  {
    label: "Tanzania, United Republic of",
    code: "TZ",
    iso3: "TZS",
    numeric: "834",
  },
  {
    label: "Chad",
    code: "TD",
    iso3: "TCD",
    numeric: "148",
  },
  {
    label: "Czechia",
    code: "CZ",
    iso3: "CZE",
    numeric: "203",
  },
  {
    label: "French Southern Territories (the)",
    code: "TF",
    iso3: "ATF",
    numeric: "260",
  },
  {
    label: "Thailand",
    code: "TH",
    iso3: "THA",
    numeric: "764",
  },
  {
    label: "Timor-Leste",
    code: "TL",
    iso3: "TLS",
    numeric: "626",
  },
  {
    label: "Togo",
    code: "TG",
    iso3: "TGO",
    numeric: "768",
  },
  {
    label: "Tokelau",
    code: "TK",
    iso3: "TKL",
    numeric: "772",
  },
  {
    label: "Tonga",
    code: "TO",
    iso3: "TON",
    numeric: "776",
  },
  {
    label: "Trinidad and Tobago",
    code: "TT",
    iso3: "TTO",
    numeric: "780",
  },
  {
    label: "Tunisia",
    code: "TN",
    iso3: "TUN",
    numeric: "788",
  },
  {
    label: "Turkmenistan",
    code: "TM",
    iso3: "TKM",
    numeric: "795",
  },
  {
    label: "Turks and Caicos Islands (the)",
    code: "TC",
    iso3: "TCA",
    numeric: "796",
  },
  {
    label: "Turkey",
    code: "TR",
    iso3: "TUR",
    numeric: "792",
  },
  {
    label: "Tuvalu",
    code: "TV",
    iso3: "TUV",
    numeric: "798",
  },
  {
    label: "Ukraine",
    code: "UA",
    iso3: "UKR",
    numeric: "804",
  },
  {
    label: "Uruguay",
    code: "UY",
    iso3: "URY",
    numeric: "858",
  },
  {
    label: "Vanuatu",
    code: "VU",
    iso3: "VUT",
    numeric: "548",
  },
  {
    label: "Venezuela (Bolivarian Republic of)",
    code: "VE",
    iso3: "VEN",
    numeric: "862",
  },
  {
    label: "Virgin Islands (British)",
    code: "VG",
    iso3: "VGB",
    numeric: "092",
  },
  {
    label: "Virgin Islands (U.S.)",
    code: "VI",
    iso3: "VIR",
    numeric: "850",
  },
  {
    label: "Viet Nam",
    code: "VN",
    iso3: "VNM",
    numeric: "704",
  },
  {
    label: "Wallis and Futuna",
    code: "WF",
    iso3: "WLF",
    numeric: "876",
  },
  {
    label: "Yemen",
    code: "YE",
    iso3: "YEM",
    numeric: "887",
  },
  {
    label: "Zambia",
    code: "ZM",
    iso3: "ZMB",
    numeric: "894",
  },
  {
    label: "Zimbabwe",
    code: "ZW",
    iso3: "ZWE",
    numeric: "716",
  },
];
