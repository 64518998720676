

import { useNavigate } from "react-router-dom";
import {
  fetchPublisherWebsites,
  fetchZonesByPublisherId,
} from "../../api/ssp-api";
import { useContext, useEffect, useState } from "react";
import Loader from "../../components/loader";
import ZoneTable from "./zone-table";
import { AuthContext } from "../../provider/AuthContext";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";

export default function Zones() {
  const navigate = useNavigate();
  const [publisherData, setPublisherData] = useState([]);

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);

  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: "#6EBF4A",
  //       contrastText: "#FFFFFF",
  //     },
  //   },
  // });
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [zoneData, setZoneData] = useState([]);
  const [selectedPublisherId, setSelectedPublisherId] = useState(0);
  const { setActions } = useContext(BreadcrumbContext);

  useEffect(() => {
    setActions(
      <div>
        <div
          className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer"
          onClick={() => navigate("/zones/new")}
        >
          Add New Zone
        </div>
      </div>
    );
    fetchPublisherWebsites(base64Credentials, agencyId)
      .then((response) => {
        setPublisherData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSelectChange = (event) => {
    const newPublisherId = event.target.value;
    setSelectedPublisherId(newPublisherId);
    fetchZones(newPublisherId);
  };

  const fetchZones = (newPublisherId) => {
    fetchZonesByPublisherId(newPublisherId, base64Credentials)
      .then((response) => {
        setZoneData(response.data);
        setDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return loading?<Loader/>: (
    <div header={"Zones"}>
      <div className="flex justify-end"></div>
      <ZoneTable data={zoneData} websites={publisherData} />
    </div>
  );
}
