import { Component } from "react";
import { styles } from "./styles";



class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div container justify="flex-start">
        <div header={"Settings"}></div>
      </div>
    );
  }
}



export default Settings;
