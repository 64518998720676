export const styles = {
  root: {
    marginTop: "3rem",
    textAlign: "left",
  },
  left: {
    paddingLeft: "2rem",
  },
  text1: {
    fontSize: "0.9rem",
    color: "#4B4B4B",
    fontWeight: 900,
  },
  text2: {
    fontWeight: 300,
    marginTop: "0.3rem",
    fontSize: "0.8rem",
  },
  paper: {
    minHeight: "30vh",
    minWidth: "40%",
    maxWidth: "40%",
    marginTop: "1.8rem",
    boxShadow: "none",
    border: "0.819465px solid #C4C4C4",
    fontSize: "1rem",
    fontWeight: 200,
    borderRadius: "9px",
    padding: "2rem 2rem",
  },
  createCampaign: {
    backgroundColor: "#6EBF4A",
    color: "#FFF",
    "&:hover,&:focus": {
      backgroundColor: "#6EBF4A",
    },
    borderRadius: "9px",
    textTransform: "lowercase",
    width: "100%",
    height: "2.6rem",
    marginTop: "2rem",
  },

  input: {
    width: "100%",
    height: "2.8rem",
    marginRight: "3.5rem",
    marginTop: 0,
    borderRadius: "9px",
    color: "#979797",
    fontSize: "0.8rem",
  },

  row: {
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  first: {
    marginRight: "3.6rem",
  },
  helperText: {
    marginBottom: "0.3rem",
    color: "#4B4B4B",
    fontWeight: 500,
  },
  // createCampaign: {
  //   backgroundColor: "#6EBF4A",
  //   color: "#FFF",
  //   "&:hover,&:focus": {
  //     backgroundColor: "#6EBF4A",
  //   },
  //   borderRadius: "9px",
  //   textTransform: "lowercase",
  //   width: "80%",
  //   height: "2.8rem",
  //   marginBottom: "4rem",
  // },
  dropzone: {
    outline: "none",
  },
  dropContent: {
    width: "87.4%",
    outline: "none",
    color: "#4B4B4B",
    border: "0.819465px dashed #C4C4C4",
    borderRadius: "9px",
    // outline: "none",
    // textAlign: "center",
  },
  drag: {
    color: "#808080",
    fontSize: "0.7rem",
    marginBottom: "0.4rem",
  },
  browse: {
    background: "#E3E3E3",
    borderRadius: "4px",
    color: "#808080",
    textTransform: "none",
    fontSize: "0.7rem",
    width: "52%",
    marginBottom: "2rem",
  },
  preview: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "rgba(110, 191, 74, 0.1)",
    border: "0.819465px dashed #6EBF4A",
    width: "87.4%",
    maxWidth: "87.4%",
    minHeight: "13vh",
    fontSize: "0.6rem",
    color: "#000",
    fontWeight: 200,
  },
  actionButtons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8rem",
  },
  action: {
    fontSize: "0.7rem",
    marginBottom: "0.3rem",
  },
  innerContent: {
    paddingLeft: "3rem",
    paddingRight: "2rem",
  },
  delete: {
    transform: "translateY(-7%)",
    "& .icon": {
      width: "4rem",
      transform: "translateY(12%)",
      height: "2rem",
    },
  },
  isUploading: {
    width: "100%",
    height: "6rem",
  },
};
