import { useEffect, useState } from "react";

import clsx from "clsx";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import InvoiceType from "./invoice-type";
import CompanyDetails from "./company-details";
import InvoiceDetails from "./invoice-details";
import InvoicePreview from "./invoice-preview";
import { formatInvoice, getCompletedSteps } from "./util";
import { getInvoiceSummaryApi } from "../../api/api";


const InvoiceGenerator = (props) => {
  const classes = useStyles();
  const [invoiceType, setInvoiceType] = useState({});
  const [invoiceCompanyDetails, setInvoiceCompanyDetails] = useState({});
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [advertiser, setAdvertiser] = useState({});
  const [pricing, setPricing] = useState({});
  const { invoiceId } = (props.match && props.match.params) || {};
  const {pathname} =  useLocation();
  useEffect(() => {
    if (invoiceId) {
      getInvoiceSummaryApi(invoiceId)
        .then((response) => {
          const invoice = response.data || {};
          console.log(invoice);
          const { invoiceType, invoiceDetails, companyDetails } =
            formatInvoice(invoice);
          console.log(invoiceType, invoiceDetails, companyDetails);
          setInvoiceType(invoiceType);
          setInvoiceCompanyDetails(companyDetails);
          setInvoiceDetails(invoiceDetails);
          setAdvertiser(invoice.advertiser);
          setPricing(invoice.pricingSummary);
        })
        .catch(console.log);
    }
  }, [invoiceId, pathname]);
  const completedSteps = getCompletedSteps(
    invoiceId,
    invoiceCompanyDetails,
    invoiceDetails,
    invoiceType,
  );
  const urlParam = invoiceId ? `/${invoiceId}` : "";
  const tabs = [
    {
      index: 0,
      tabName: "Invoice type",
      url: `/generate-invoice/invoice-type${urlParam}`,
    },
    {
      index: 1,
      tabName: "",
      url: ``,
    },
    {
      index: 2,
      tabName: "Company Details",
      url: `/generate-invoice/company-details${urlParam}`,
    },
    {
      index: 3,
      tabName: "",
      url: ``,
    },
    {
      index: 4,
      tabName: "Invoice Details",
      url: `/generate-invoice/invoice-details${urlParam}`,
    },
    {
      index: 5,
      tabName: "",
      url: ``,
    },
    {
      index: 6,
      tabName: "Preview and Download",
      url: `/generate-invoice/invoice-preview${urlParam}`,
    },
  ];
  const matchingTab = tabs.find(({ url }) => url === pathname);
  const currentTabIndex = matchingTab ? matchingTab.index : null;
  const currentTab = currentTabIndex;
  return (
    <div header={"Invoice generator"}>
      <div className={classes.container}>
        <Paper className={classes.tabs}>
          <Tabs
            textColor="primary"
            variant="fullWidth"
            indicatorColor="primary"
            value={currentTab}
          >
            {tabs.map(({ tabName, url, index }) => {
              if (index % 2 === 0) {
                return (
                  <Tab
                    className={classes.tab}
                    disabled={index > completedSteps && index !== 0}
                    icon={
                      <CheckCircle
                        className={clsx(classes.icon, {
                          [classes.active]: index < completedSteps,
                        })}
                      />
                    }
                    label={tabName}
                    component={NavLink}
                    to={url}
                    value={index}
                    key={index}
                  />
                );
              }
              return (
                <Tab
                  style={{ maxWidth: 10, padding: 0, minWidth: 10 }}
                  value={index}
                  label={
                    <Divider
                      orientation="vertical"
                      className={classes.divider}
                      disabled={true}
                    />
                  }
                />
              );
            })}
          </Tabs>
        </Paper>
        <div xs={12} className={classes.content}>
          {currentTab === 0 ? <InvoiceType invoiceType={invoiceType} /> : null}
          {currentTab === 2 ? (
            <h1>
              <CompanyDetails invoiceCompanyDetails={invoiceCompanyDetails} />
            </h1>
          ) : null}
          {currentTab === 4 ? (
            <h1>
              <InvoiceDetails invoiceDetails={invoiceDetails} />
            </h1>
          ) : null}
          {currentTab === 6 ? (
            <h1>
              <InvoicePreview
                invoiceDetails={invoiceDetails}
                advertiser={advertiser}
                pricing={pricing}
              />
            </h1>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default InvoiceGenerator;
