import { Component } from "react";
import { fetchSitesApi } from "../../../../api/api";
import FilterMenu from "../../../../components/menu";
import CampaignDownload from "../../../campaigns/campaign-download";
import { styles } from "../../../campaigns/campaign-edit/view-campaign-styles";
import SitesDataTable from "./sites-data-table";

class SitesData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFilter: false,
      reportFilterInactive: true,
      campaign: {},
      banners: [],
      audience: {},
      isLoading: false,
    };
  }
  componentDidMount() {
    let campaignId = window.location.pathname.split("/")[2];
    console.log("this id, ", campaignId);
    this.setState({ isLoading: true });
    fetchSitesApi(campaignId)
      .then((response) => {
        console.log("sites new data ", response);
        const { campaign, sitesData, target, banners } = response.data;
        this.setState({
          campaign,
          sitesData,
          name: campaign.name,
          advertiser: campaign.advertiserName,
          banners,
          audience: target,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onFilterChange = (name) => {
    this.setState({
      reportFilter: false,
      reportFilterInactive: true,
    });
    this.props.filterCampaign(name);
  };
  onFilterDateChange = (customDate) => {
    this.setState({
      reportFilter: true,
      reportFilterInactive: false,
    });
    const { startDate, endDate } = customDate[0];
    this.props.filterByRange(startDate, endDate);
  };
  render() {
    const { classes } = this.props;
    const {
      reportFilter,
      reportFilterInactive,
      campaign,
      audience,
      banners,
      isLoading,
    } = this.state;
    console.log("loading?", isLoading);
    return (
      <div header="Campaign">
        <div className={classes.root}>
          <div xs={12} container justify="space-between" alignItems="center">
            <div xs={4} container justify="flex-start" alignItems="center">
              <div xs={12} container justify="flex-start">
                <Typography className={classes.campaignName}>
                  {this.state.name || "Company"}
                </Typography>
              </div>
              <div xs={12} container justify="flex-start">
                <Typography className={classes.company}>
                  {this.state.advertiserName}
                </Typography>
              </div>
            </div>
            <div xs={7} className={classes.leftMenu}>
              <div xs={12} container alignItems="center" justify="flex-end">
                <div className={classes.filter}>
                  <FilterMenu
                    reportFilter={reportFilter}
                    onFilterChange={this.onFilterChange}
                    onFilterDateChange={this.onFilterDateChange}
                    reportFilterInactive={reportFilterInactive}
                  />
                </div>

                <CampaignDownload
                  size={100}
                  isLoading={false}
                  onDownloadCsv={() => {}}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.campaignDetails}>
          {isLoading || this.state.sitesData === undefined ? (
            <Loader/>
          ) : (
            <SitesDataTable
              data={this.state.sitesData}
              isLoading={this.state.isLoading}
            />
          )}
        </div>
      </div>
    );
  }
}


export default SitesData;
