export const styles = {
  createCampaign: {
    backgroundColor: "#6EBF4A",
    color: "#FFF",
    "&:hover,&:focus": {
      backgroundColor: "#6EBF4A",
    },
    borderRadius: "9px",
    textTransform: "none",
    width: "40%",
    height: "2.5rem",
    marginBottom: "4rem",
  },
  cancelButton: {
    background: "white",
    color: "#979797",
    border: "1px solid #979797",
    width: "40%",
    height: "2.5rem",
    marginBottom: "4rem",
    marginRight: "1rem",
    "&:hover,&:focus": {
      background: "#FFF",
    },
  },

  container: {
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  loader: {
    color: "white",
  },
};
