import * as React from "react";

import EditSspCampaign from "./edit-campaign";
import LinkCampaignToZones from "../create-campaign/link-campaign";
import { fetchLinkedZonesByCampaignId } from "../../../api/ssp-api";
import { useContext, useEffect, useState } from "react";
import Loader from "../../../components/loader";

export default function EditSspCampaignTabs() {
  const [value, setValue] = React.useState("1");
  const campaignId = window.location.pathname.split("/")[4];
  const [linkZones, setLinkZones] = useState([]);
  const [loading, setLoading] = useState(true);

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  useEffect(() => {
    setLoading(true);
    fetchLinkedZonesByCampaignId(campaignId, base64Credentials)
      .then((res) => {
        setLinkZones(res.data);
        console.log("linkedZones", JSON.stringify(linkZones));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div header={"Edit Campaign"}>
      <div spacing={2}>
        {loading ? (
          <Loader />
        ) : (
          <div item xs={12}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Edit Campaign" value="1" />
                  <Tab label="Link Campaign" value="2" />
                  {/*<Tab label="Item Three" value="3" />*/}
                </TabList>
              </Box>
              <TabPanel value="1">
                <EditSspCampaign />
              </TabPanel>
              <TabPanel value="2">
                <LinkCampaignToZones linkZones={linkZones} edit={"edit"} />
              </TabPanel>
              {/*<TabPanel value="3">Item Three</TabPanel>*/}
            </TabContext>
          </div>
        )}
      </div>
    </div>
  );
}
