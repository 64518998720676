import React, { useContext, useEffect, useState } from "react";

import { styles } from "./style";
import AdvertiserTabLayout from "./advertiser-tab";
import { getAdvertisers } from "../../../../api/api";
import { AuthContext } from "../../../../provider/AuthContext";
import AgencyWrapper from "../../../../components/wrappers/agency-wrapper";
import { useNavigate } from "react-router-dom";
import { BreadcrumbContext } from "../../../../layouts/dashboardLayout";
import Loader from "../../../../components/loader";

function AgencyAdvertiser({ history }) {
  const [activeAdvertisers, setActiveAdvertisers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
 const navigate = useNavigate()
 const {setActions} = useContext(BreadcrumbContext)
  useEffect(() => {
    setActions( <button
      className="bg-primary text-white py-2 px-3 flex rounded-md items-center"
       onClick={() => navigate("/agency/advertiser/new")}
     >
       Create advertiser
     </button>)
    getAdvertisers(token, agencyId)
      .then((data) => {
        setActiveAdvertisers(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching advertisers:", error);
        setIsLoading(false);
      });
  }, []);

  return isLoading?<Loader/>: (
  
     <div>
 <div className="flex justify-end">
       
        </div>
          
        
        <AdvertiserTabLayout
          activeAdvertisers={activeAdvertisers}
          isLoading={isLoading}
        />
     </div>
       
      
    
  );
}

export default AgencyAdvertiser;
