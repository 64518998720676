
import { Component } from "react";
import { styles } from "./styles";
import { ReactComponent as SupportIcon } from "../../assets/help.svg";
import clsx from "clsx";
import PropTypes from "prop-types";

const RenderHeader = ({
  isSorted,
  isTooltip,
  tooltip,
  label,
  index,
  classes,
  sortKey,
  sort,
  onSortChange,
}) => {
  return (
    <TableCell key={index} className={classes.render}>
      {isSorted && (
        <IconButton
          className={classes.sort}
          onClick={() => onSortChange(sortKey)}
        >
          <ExpandLess
            className={clsx(classes.expandMore, {
              [classes.active]: sort === "desc",
            })}
          />{" "}
          <ExpandMore
            className={clsx(classes.expandLess, {
              [classes.active]: sort === "asc",
            })}
          />
        </IconButton>
      )}
      {isTooltip && (
        <Tooltip title={tooltip} arrow className={classes.tooltip}>
          <SupportIcon />
        </Tooltip>
      )}
      <span className={classes.label}>{label}</span>
    </TableCell>
  );
};
class TableHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [],
    };
  }

  onSortChange = (sortItem) => {
    let key = "";
    let order = "";
    let headers = this.state.headers.map((item) => {
      if (item.sortKey !== sortItem) {
        return item;
      }
      key = item.sortKey;
      order = item.sort;
      return {
        ...item,
        sort: item.sort === "asc" ? "desc" : "asc",
      };
    });
    this.setState({
      headers,
    });

    this.props.handleSort(key, order === "asc" ? "desc" : "asc");
  };
  componentDidMount() {
    this.setState({
      headers: this.props.headers,
    });
  }

  render() {
    const { classes, fullHeight, maxHeight, isAdvertiser } = this.props;
    const { headers } = this.state;
    return (
      <TableContainer
        className={clsx(
          classes.root,
          { [classes.fullHeight]: fullHeight, [classes.maxHeight]: maxHeight },
          { [classes.isAdvertiser]: isAdvertiser },
        )}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {headers.map(
                (
                  { isSorted, isTooltip, tooltip, label, sortKey, sort },
                  index,
                ) => {
                  return (
                    <RenderHeader
                      classes={classes}
                      isSorted={isSorted}
                      isTooltip={isTooltip}
                      tooltip={tooltip}
                      label={label}
                      sortKey={sortKey}
                      sort={sort}
                      index={index}
                      onSortChange={this.onSortChange}
                    />
                  );
                },
              )}
            </TableRow>
          </TableHead>
          {this.props.children}
        </Table>
      </TableContainer>
    );
  }
}

TableHeader.propTypes = {
  headers: PropTypes.array.isRequired,
  handleSort: PropTypes.func,
  fullHeight: PropTypes.bool,
  maxHeight: PropTypes.bool,
  isAdvertiser: PropTypes.bool,
};

export default TableHeader;
