import React, {useState, useEffect, useContext} from 'react';
// import { styles } from '../../campaigns/campaign-edit/view-campaign-styles';

import { fetchCampaignSummaryApi } from '../../../api/api';
import ReportOverview from './report-overview/report-overview';

import {AuthContext} from "../../../provider/AuthContext";
import { BreadcrumbContext } from '../../../layouts/dashboardLayout';
import Loader from '../../../components/loader';

const ReportView = ({ classes }) => {
  const [reportState, setReportState] = useState({
    reportFilter: false,
    reportFilterInactive: true,
    campaign: {},
    banners: [],
    audience: {},
    isLoading: false,
    id: null,
  });

  const {userDetails} = useContext(AuthContext)
  // const dispatch = useDispatch();
  const {currentPage,setCurrentPage} = useContext(BreadcrumbContext)

  useEffect(() => {
    setCurrentPage("Campaign Report")
    const campaignId = window.location.pathname.split('/')[2];
    const {token, role} = JSON.parse(userDetails);
    console.log("token", token)
    setReportState(prevState => ({ ...prevState, isLoading: true }));

    fetchCampaignSummaryApi(campaignId, token)
        .then(response => {
          const { campaign, target, banners } = response.data;
          setReportState({
            campaign,
            banners,
            audience: target,
            isLoading: false,
            id: campaignId,
          });
        })
        .catch(error => {
          console.log(error);
        });
  }, []);

  const onFilterChange = name => {
    setReportState(prevState => ({
      ...prevState,
      reportFilter: false,
      reportFilterInactive: true,
    }));
    dispatch(filterCampaign(name));
  };

  const onFilterDateChange = customDate => {
    setReportState(prevState => ({
      ...prevState,
      reportFilter: true,
      reportFilterInactive: false,
    }));
    const { startDate, endDate } = customDate[0];
    dispatch(filterByRange(startDate, endDate));
  };

  const {
    reportFilter,
    reportFilterInactive,
    campaign,
    audience,
    banners,
    isLoading,
    id,
  } = reportState;

  return (
      <div >
        <div >
          {isLoading || id === undefined ? (
              <Loader/>
          ) : (
              <ReportOverview data={id} />
          )}
        </div>
      </div>
  );
};

export default ReportView;
