
import { createContext, useContext, useEffect, useState } from "react";

import CampaignDetails from "./steps/campaign-details";
import { fetchAdvertiserByAgencyId } from "../../../../../api/ssp-api";
import { AuthContext } from "../../../../../provider/AuthContext";

import CampaignSettings from "./steps/campaign-settings";
import CampaignZones from "./steps/campaign-zones";
import { toast, ToastContainer } from "react-toastify";
import CampaignComplete from "./steps/campaign-complete";
import { useNavigate } from "react-router-dom";
import { BreadcrumbContext } from "../../../../../layouts/dashboardLayout";
import Loader from "../../../../../components/loader";
import CampaignBudget from "./steps/campaign-budget";
import CampaignAdverts from "./steps/campaign-adverts";



export const StepsContext = createContext()

export default function AgencyCreateCampaign() {
  const [advertisers, setAdvertisers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId } = JSON.parse(userDetails);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const navigate = useNavigate();
  const {setCurrentPage,setActions} = useContext(BreadcrumbContext)
  const isPremium = JSON.parse(localStorage.getItem("isPremium"));

  const [savedCampaign, setSavedCampaign] = useState({});

  const [campaignDetails, setCampaignDetails] = useState({
    priority: 0,
    campaignName: "",
    advertiserId: 1,
  });

  const [campaignSettings, setCampaignSettings] = useState({
    startDate: new Date(),
    endDate: new Date(),
    revenueType: 1,
    impressions: -1,
    clicks: -1,
    weight: -1,
  });

  useEffect(() => {
    setActions(<div></div>)
    setCurrentPage("Create Campaign")
    fetchAdvertiserByAgencyId(agencyId, base64Credentials)
      .then((response) => {
        setAdvertisers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const steps = [
    "Create campaign",
    "Campaign settings",
    "Campaign Budget",
    "Campaign creatives",
    "Campaign Zones"
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    console.log(`total steps: ${steps.length}`);
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if (step === 0 || completed[step - 1]) {
      // Check if it's the first step or the previous step is completed
      setActiveStep(step);
    } else {
      toast.error("Please complete the previous step first!", {
        position: "top-center",
      });
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    localStorage.setItem("campaignId", "");
    navigate("/agency/ssp");
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CampaignDetails
            advertisers={advertisers}
            setCampaignDetails={setCampaignDetails}
            handleComplete={handleComplete}
          />
        );
      case 1:
        return (
          <CampaignSettings
            campaignDetails={campaignDetails}
            credentials={base64Credentials}
            setSavedCampaign={setSavedCampaign}
            handleComplete={handleComplete}
          />
        );
      case 2:
        return (
          <CampaignBudget
            savedCampaign={savedCampaign}
            credentials={base64Credentials}
            handleComplete={handleComplete}
          />
        );
      case 3:
        return (
          <CampaignAdverts
            savedCampaign={savedCampaign}
            token={token}
            agencyId={agencyId}
            handleComplete={handleComplete}
          />
        );
      case 4:
        return (
          <CampaignZones
            savedCampaign={savedCampaign}
            token={token}
            credentials={base64Credentials}
            agencyId={agencyId}
          />
        );
    }
  };
  if (loading || advertisers === undefined) {
    return (
      <div header={"Create Campaign"}>
        <div >
          <Loader/>
        </div>
      </div>
    );
  }

  return (
    <div header={"Create Campaign"}>
      <ToastContainer position="top-right" />
      <div style={{ marginTop: "16px" }}>
        {/* <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper> */}
        <div className="grid grid-cols-5 ">
          {steps.map((item,index)=>{
            return <div key={index} onClick={()=>{
              setActiveStep(index)
            }} className="flex items-center cursor-pointer ">
              <div className={`${activeStep >= index?"bg-primary text-white font-bold":"bg-border"} text-sm size-6 flex justify-center items-center rounded-full`}>{index+1}</div>
              <div className={` ${activeStep >= index?"bg-primary":"bg-border"} h-[3px] flex-grow`}/>
              <h1 className={`${activeStep >= index?"text-primary font-semibold":""}`}>{item}</h1>
              <div className={` ${activeStep >= index?"bg-primary":"bg-border"} h-[3px] flex-grow`}/>
              </div>
          })}
        </div>
        <div item>
          {allStepsCompleted() ? (
            <div item>
              <h1>Campaign Setup Completed</h1>
              <p>Your campaign has been successfully created.</p>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button variant="contained" onClick={handleReset}>
                  Back to Campaigns
                </Button>
              </Box>
            </div>
          ) : (
            <div item>
              {/* Display the current step */}
               <div className="bg-white p-10 rounded-md border border-border mt-6">
               <div
              >
                <StepsContext.Provider value={{setActiveStep,activeStep}}>
                {getStepContent(activeStep)}
                </StepsContext.Provider>
              </div>
               </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
