import Loader from "../../components/loader";
import { BsGraphUpArrow } from "react-icons/bs";
import { LuMousePointerClick } from "react-icons/lu";
import { MdOutlineAdsClick } from "react-icons/md";
import { TbBrandCashapp } from "react-icons/tb";
import { motion } from "framer-motion";
import { figureSeparator } from "../../util/figureSeparator";

const styles = {
  root: {},
  paper: {
    background: "#F7F9FA",
    borderRadius: "16px",
    height: "5rem",
    padding: "14% 0 0rem 16%",
    textAlign: "left",
  },
  label: {
    color: "#6EBF4A",
    marginBottom: "0.4rem",
    fontSize: "0.9rem",
  },
  figure: {
    color: "#4B4B4B",
    fontWeight: 700,
    fontSize: "1.4rem",
  },
  card: {
    minWidth: "17%",
    marginRight: "2rem",
  },
};
const CampaignSummary = ({
  impressions,
  clicks,
  ctr,
  totalSpent,
  isLoading,
}) => {
  const campaignsSummary = [
    {
      label: "Impressions",
      value: impressions,
      icon: "/analytics.svg",
      color: "bg-red-500",
    },
    {
      label: "Clicks",
      value: clicks,
      icon: "/cursor.svg",
      color: "bg-blue-500",
    },
    {
      label: "CTR-Clicks",
      value: `${figureSeparator(parseFloat(ctr || 0).toFixed(2))}%`,
      icon: "/ctr.svg",
      color: "bg-orange-500",
    },
    {
      label: "Spent",
      value: `$${figureSeparator(parseFloat(totalSpent || 0).toFixed(2))}`,
      icon: "/bitcoin.svg",
      color: "bg-green-500",
    },
  ];
  const parentVariants = {
    hidden: {
      opacity: 0,
      x: 500,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const childVariants = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  return (
    <div>
      <motion.div
        variants={parentVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-4 gap-4"
      >
        {campaignsSummary.map((item, index) => {
          return (
            <motion.div
              variants={childVariants}
              className=" bg-white border border-border group rounded-md py-3 px-4 flex items-center space-x-2 hover:scale-[1.05] cursor-pointer transition-all duration-200"
            >
              <div
                className={`size-14 rounded-full  bg-opacity-10  flex items-center justify-center`}
              >
                <img src={item.icon} />
              </div>
              <div>
                <p className="text-2xl font-bold text-textColor">
                  {item.value}
                </p>
                <h1 className="text-sm text-muted">{item.label}</h1>
              </div>
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default CampaignSummary;
