import React, { useContext, useEffect, useState } from "react";

import { styles } from "./new-advertiser-style";
import Dropzone from "react-dropzone";
import { ReactComponent as DownloadIcon } from "../../../../assets/download.svg";
import { ReactComponent as PicIcon } from "../../../../assets/pic.svg";
import { ReactComponent as ReloadIcon } from "../.././../../assets/reload.svg";
import { newAdvertiserApi } from "../../../../api/api";
import clsx from "clsx";
import { addAdvertiserApi } from "../../../../api/ssp-api";
import { AuthContext } from "../../../../provider/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import AgencyWrapper from "../../../../components/wrappers/agency-wrapper";
import { Formik } from "formik";
import * as Yup from "yup"
import { MdOutlineCloudUpload } from "react-icons/md";
import { BreadcrumbContext } from "../../../../layouts/dashboardLayout";

const AgencyNewAdvertiser = ({ classes, history }) => {
  const [name, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [advertiserType, setAdvertiserType] = useState(1);
  const [files, setFiles] = useState([]);
  const [ecpm, setEcpm] = useState("");
  const [bidCpm, setBidCpm] = useState("");
  const [hourlyCpm, setHourlyCpm] = useState("0");
  const [isUploaded, setIsUploaded] = useState(true);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sspAdvertiserId, setSspAdvertiserId] = useState({});

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const { userDetails } = useContext(AuthContext);
  const { token, agencyId, accountId } = JSON.parse(userDetails);
  const {setCurrentPage} = useContext(BreadcrumbContext)

  useEffect(() => {
    setCurrentPage("Add advertiser")

  }, [])

  const onDrop = (acceptedFiles) => {
    const { name } = acceptedFiles[0];
    setFiles(acceptedFiles);
    setIsUploaded(false);
    setFileName(name);
  };

  const reupload = () => {
    setIsUploaded(true);
    setFiles([]);
  };


  let advertiser = {
    contactName: name,
    emailAddress: email,
    advertiserName: company,
  };

  const onSubmit = async (values) => {
  
    const formData = new FormData();
    formData.append("contactName", values.name);
    formData.append("emailAddress", values.email);
    formData.append("company", values.company);
    formData.append("ecpm", values.ecpm);
    formData.append("bidCpm", values.bidCpm);
    formData.append("hourlyCpm", values.hourlyCpm);
    formData.append("logo", files[0]);
    formData.append("agencyId", agencyId);
    
    advertiser.defaultAccountId = accountId;
    advertiser.contactName = values.name,
    advertiser.emailAddress = values.email,
    advertiser.active = 1;
    advertiser.advertiserName = values.company;
    console.log(advertiser)
    try {
      const advertiserResponse = await addAdvertiserApi(
        advertiser,
        base64Credentials,
      );
      if (advertiserResponse.status === 200) {
        console.log("Ads data", advertiserResponse.data)
        formData.append("sspId", advertiserResponse.data.advertiserId);
         console.log("Advitiser data",formData)
        const newAdvertiserResponse = await newAdvertiserApi(formData, token);
        console.log("New advertiser added", newAdvertiserResponse.data);
        toast.success("Advertiser added successfully");
      } else {
        console.log("failed to add advertiser");
        toast.error("Failed to add advertiser");
      }
      setIsLoading(false);
      navigate("/agency/advertiser");
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object({
    name:Yup.string().required("Enter contact name"),
    company:Yup.string().required("Enter website"),
    email:Yup.string().email().required("Enter email"),
    ecpm:Yup.string().required("Enter eCPM "),
    bidCpm:Yup.string().required("Enter bid CPM"),
    hourlyCpm:Yup.string().required("Enter hourly CPM")
  })

  return (
  <div>
      <ToastContainer />
      <Formik initialValues={{
        name:"",company:"",email:"",ecpm:"",bidCpm:"",hourlyCpm:""
      }} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({errors,values,handleChange,handleSubmit,touched,isSubmitting})=>(
          <form className="p-10 bg-white border border-border" onSubmit={handleSubmit}>


        <div className=" w-full flex flex-col justify-center items-center border border-border rounded-md py-5" >
          {isUploaded ? (
            <Dropzone
              onDrop={onDrop}
              sx={styles.dropzone}
              accept={["image/png", "image/jpg", "image/svg+xml"]}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="w-full flex flex-col text-center items-center justify-center" >
                  <MdOutlineCloudUpload className="size-16 text-primary" />
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="text-sm mb-3" >
                      Drag and drop a file here{" "}
                    </div >
                    <div className=" py-1 px-4 border border-primary rounded-md text-primary cursor-pointer" >Browse from your files</div>
                  </div>
                </section>
              )}
            </Dropzone>
          ) : (
            <Paper sx={styles.preview}>
              <div
                xs={12}
                container
                justify="space-between"
                sx={styles.innerContent}
              >
                <div
                  xs={3}
                  container
                  alignItems="center"
                  justify="space-between"
                >
                  <div xs={2}>
                    <PicIcon />
                  </div>
                  <div xs={10}>{fileName}</div>
                </div>
                <div
                  xs={4}
                  container
                  alignItems="center"
                  justify="space-between"
                >
                  <div xs={6} className={clsx(classes.actionButtons)}>
                    <Typography sx={styles.action}>Upload file</Typography>
                    <IconButton onClick={reupload}>
                      <ReloadIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Paper>
          )}
        </div>
      <br/>

      <div className="grid grid-cols-3 gap-5">
        <div className="space-y-1">
                <h1 className="text-xs text-gray-500 mb-1">Contact name*</h1>
                <input
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  className="form-style"
                />
                <div>
                  {touched.name && errors.name && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.name}
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-1">
                <h1 className="text-xs text-gray-500 mb-1">Email*</h1>
                <input
                  type="text"
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  className="form-style"
                />
                <div>
                  {touched.email && errors.email && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-1">
                <h1 className="text-xs text-gray-500 mb-1">Company*</h1>
                <input
                  type="text"
                  onChange={handleChange}
                  value={values.company}
                  name="company"
                  className="form-style"
                />
                <div>
                  {touched.company && errors.company && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.company}
                    </div>
                  )}
                </div>
              </div>
      </div>
      <br/>
  
        <div className="grid grid-cols-3 gap-5">
      <div className="space-y-1">
                <h1 className="text-xs text-gray-500 mb-1">eCPM*</h1>
                <input
                  type="number"
                  onChange={handleChange}
                  value={values.ecpm}
                  name="ecpm"
                  className="form-style"
                />
                <div>
                  {touched.ecpm && errors.ecpm && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.ecpm}
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-1">
                <h1 className="text-xs text-gray-500 mb-1">Bid CPM*</h1>
                <input
                  type="number"
                  onChange={handleChange}
                  value={values.bidCpm}
                  name="bidCpm"
                  className="form-style"
                />
                <div>
                  {touched.bidCpm && errors.bidCpm && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.bidCpm}
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-1">
                <h1 className="text-xs text-gray-500 mb-1">Houry CPM*</h1>
                <input
                  type="number"
                  onChange={handleChange}
                  value={values.hourlyCpm}
                  name="hourlyCpm"
                  className="form-style"
                />
                <div>
                  {touched.hourlyCpm && errors.hourlyCpm && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.hourlyCpm}
                    </div>
                  )}
                </div>
              </div>
      </div>
       <button
                  className="mt-6 bg-primary rounded-md py-2 px-3 text-white w-40 flex justify-center items-center"
                  type="submit">
                  {isSubmitting ? <CircularProgress style={{color:"white"}} size="1.4rem" /> : "Add Advertiser"}
                </button>
          </form>
        )}
        
      </Formik>
    
</div>
  );
};

export default AgencyNewAdvertiser;