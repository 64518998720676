import { useContext, useEffect, useState } from "react";
import { editZone, fetchAdvertiserByAgencyId, fetchZoneById } from "../../api/ssp-api";
import { editSspZone, fetchPublisherWebsites, fetchSspZoneById } from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import Loader from "../../components/loader";

export default function EditZone({ }) {
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const navigate = useNavigate()
  const {setCurrentPage,setActions} = useContext(BreadcrumbContext)
  const [isChecked, setIsChecked] = useState(false);
  const {zoneId} = useParams()
  const { token, role, agencyId } = JSON.parse(userDetails);


  useEffect(() => {
  setCurrentPage("Edit Zone")
  setActions(<div> </div>)
    setLoading(true);
    Promise.all([
      fetchSspZoneById(zoneId, token)
    ])
      .then(([zoneResponse]) => {
        console.log(zoneResponse.data);
        const data = zoneResponse.data.sspZone;
        setFormData(data);
        console.log(data.tier)
        const type = data.tier == "premium" ? true : false;
        setIsChecked(type);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [])
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue =
      name === "width" || name === "height" || name === "publisherId"
        ? parseInt(value, 10)
        : value;
    setFormData((prevData) => ({ ...prevData, [name]: newValue }));
  };

  const onSubmit = (e) => {
    e.preventDefault()
    console.log("Edit Zone")
    setLoading(true);
    const formData = {
      width: parseInt(e.target.width.value),
      height: parseInt(e.target.height.value),
      zoneName: e.target.zoneName.value,
      tier: isChecked?'premium':'mass',
      zoneId: parseInt(zoneId)
    }
    console.log(formData)
    editSspZone(formData, token)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        navigate("/zones");
      });
  };
  return loading?<Loader/>: (
    <form onSubmit={onSubmit} className="bg-white border border-border rounded-md p-10">
      <div className="grid grid-cols-2 gap-4" >
        {/*<div item xs={12}>*/}
        {/*  <FormControl className="form-style">*/}
        {/*    <InputLabel>Publisher</InputLabel>*/}
        {/*    <Select*/}
        {/*      name="publisherId"*/}
        {/*      value={formData.publisherId}*/}
        {/*      onChange={handleChange}*/}
        {/*      required*/}
        {/*    >*/}
        {/*      {publisherData.map((publisher) => (*/}
        {/*        <MenuItem*/}
        {/*          key={publisher.publisherId}*/}
        {/*          value={publisher.publisherId}*/}
        {/*        >*/}
        {/*          {publisher.website}*/}
        {/*        </MenuItem>*/}
        {/*      ))}*/}
        {/*    </Select>*/}
        {/*  </FormControl>*/}
        {/*</div>*/}
        <div className="space-y-1">
          
          <label className="text-sm">Zone Name</label>
          <input
            label="Zone Name"
            name="zoneName"
            defaultValue={formData.zone_name}
            className="form-style"
            required
          />
        </div>
        <div className="space-y-1">
          <label className="text-sm">Width</label>
          <input
            label="Width"
            name="width"
            defaultValue={formData.width}
            className="form-style"
            required
            type="number"
          />
        </div>
        <div className="space-y-1">
          <label className="text-sm">Height</label>
          <input
            label="Height"
            name="height"
          defaultValue={formData.height}
            className="form-style"
            required
            type="number"
          />
        </div>
        <div className="space-y-1 flex flex-col">
                    <div>
                      <label className="text-sm">
                        Is this zone Premium ?
                      </label>
                    </div>
                    <div className="flex space-x-2 items-center group">
                      <input
                        placeholder="Enter an Email Address"
                        type="checkbox"
                        name="email"
                        checked={isChecked}
                        className="size-8 rounded-md border-border checked:bg-primary focus:ring-primary hover:bg-transparent checked:hover:bg-primary checked:focus:bg-primary   focus:border-primary mt-1"
                        onChange={(e) => {
                          setIsChecked(e.target.checked);
                        }}
                        margin="normal"
                      />
                      <p className=" group-aria-checked:hidden font-normal  text-sm">
                        (
                        {!isChecked
                          ? "No, it is not premium"
                          : "Yes, it is premium"}{" "}
                        )
                      </p>
                    </div>
                  </div>
          
        
      </div>
      <button
            className="py-2 px-3 bg-primary text-white font-medium rounded-md  mt-6 w-40 flex justify-center items-center"
            style={{ color: "white" }}
          >
            {loading?<div className="size-5 border-2 border-white border-t-transparent rounded-full animate-spin"/>:"Save Changes"}
            
          </button>
    </form>
  );
}
