import CampaignSummary from "./campaign-summary";
import { useContext, useEffect, useState } from "react";
import {
  fetchAllOsData,
  getKwanzaStatsAPI,
  getStatsForOverview,
} from "../../api/api";
import Loader from "../../components/loader";
import CampaignTable from "./dsp-campaign-table";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthContext";
import withAuth from "../../auth/auth";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import ImressionsGraph from "./impression-graph";
import OsGraph from "./os_graph";
import moment from "moment";
import { BsArrowRight } from "react-icons/bs";
import { figureSeparator } from "../../util/figureSeparator";
import MixedGraph from "./mixed-graph";
import { DateRangePicker } from "rsuite";
import "rsuite/styles/index.less";
import {
  fetchAdvertiserByAgencyId,
  fetchAdvertiserCampaignStatsApi,
  fetchAgencyCampaignStatsApi,
} from "../../api/ssp-api";
import PieChart from "./pie-chart";

function CampaignOverview() {
  const [campaignData, setCampaignData] = useState([]);
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  const { setActions } = useContext(BreadcrumbContext);
  const [data, setData] = useState([]);
  const [dashboardType, setDashboardType] = useState("DSP Campaigns");
  const [startDate, setStartDate] = useState(
    moment(new Date().setFullYear(new Date().getFullYear() - 2)).format(
      "yyy-MM-DD"
    )
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("yyy-MM-DD")
  );
  const { token, role, agencyId } = JSON.parse(userDetails);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const [SSPCampaignSummary, setSSPCampaignSummary] = useState(null);
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  useEffect(() => {
    navigate("/overview");
    setActions(
      <div className="flex">
        <div className="space-x-3 flex items-center">
          <DateRangePicker
            size="xl"
            onto
            onOk={(e) => {
              console.log(e);

              setStartDate(moment(e[0]).format("yyy-MM-DD"));
              setEndDate(moment(e[1]).format("yyy-MM-DD"));
            }}
            shouldDisableDate={DateRangePicker.afterToday()}
            ranges={[]}
            className="focus:border-primary right-0"
          />

          <select
            onChange={(e) => {
              setDashboardType(e.target.value);
            }}
            className="text-sm border border-border font-medium focus:border-primary focus:ring-primary rounded-md bg-white"
          >
            {["DSP Campaigns", "SSP Campaigns"].map((item) => {
              return <option>{item}</option>;
            })}
          </select>
        </div>
      </div>
    );
    getCampaigns(token, role);
  }, []);

  useEffect(() => {
    if (userDetails === "") {
      navigate("/login");
    } else {
      const user = JSON.parse(userDetails);
      if (user.role === "publisher") {
        navigate("/publisher/ssp");
      }
      if (user.role === "agency") {
        navigate("/agency/ssp");
      }
      if (user.role === "advertiser") {
        navigate("/advertiser");
      } else {
        getCampaigns(user.token, user.role);
      }
    }
  }, [startDate, endDate]);

  const getCampaigns = (token, role) => {
    console.log("Stats for overview");
    getStatsForOverview(token, startDate, endDate).then((res) => {
      console.log("Data", res.data.campaigns);
      setCampaignData(res.data.campaigns);
      console.log("summary", res.data.summary);
      setCampaignSummary(res.data.summary);
      setLoading(false);
    });

    fetchAgencyCampaignStatsApi(
      agencyId,
      base64Credentials,
      startDate,
      endDate
    ).then((res) => {
      console.log("SSP Data", res.data);
      //calculate totalCTR
      const sspData = res.data.campaigns.data;
      const totalImpressions = sspData.reduce((acc, item) => {
        return acc + parseFloat(item.impressions);
      }, 0);
      const totalCTR = sspData.reduce((acc, item) => {
        return (
          acc + (parseFloat(item.clicks) * 100) / parseFloat(item.impressions)
        );
      }, 0);
      const totalClicks = sspData.reduce((acc, item) => {
        return acc + parseFloat(item.clicks);
      }, 0);
      const totalSpent = sspData.reduce((acc, item) => {
        return acc + parseFloat(item.revenue);
      }, 0);
      console.log("Total Spent", totalSpent);
      setSSPCampaignSummary({
        totalCTR,
        totalClicks,
        totalImpressions,
        totalSpent,
      });
      setData(sspData);
    });
  };

  return (
    <div header={"Overview"}>
      <div>
        {loading ||
        campaignSummary === undefined ||
        campaignData == undefined ? (
          <Loader />
        ) : (
          <div className="w-full">
            <CampaignSummary
              clicks={
                dashboardType === "DSP Campaigns"
                  ? campaignSummary.totalClicks
                  : SSPCampaignSummary.totalClicks
              }
              ctr={
                dashboardType === "DSP Campaigns"
                  ? campaignSummary.totalCtr
                  : SSPCampaignSummary.totalCTR
              }
              impressions={figureSeparator(
                dashboardType === "DSP Campaigns"
                  ? campaignSummary.totalImpressions ?? 0
                  : SSPCampaignSummary.totalImpressions ?? 0
              )}
              totalSpent={
                dashboardType === "DSP Campaigns"
                  ? figureSeparator(campaignSummary.totalSpent ?? 0)
                  : figureSeparator(SSPCampaignSummary.totalSpent ?? 0)
              }
              isLoading={loading}
            />

            <div className="grid grid-cols-12 mt-4 gap-4 content-stretch">
              <div className="col-span-8">
                <div className="bg-white p-8 rounded-md border border-border">
                  <p className="text-lg mb-2 font-bold ">Impression</p>
                  <MixedGraph
                    yValuesName="Impressions"
                    yValuesName2="CTR"
                    values={
                      dashboardType === "DSP Campaigns"
                        ? campaignData.map((item) => item.totalImpressions)
                        : data.map((item) => parseFloat(item.impressions))
                    }
                    values2={
                      dashboardType === "DSP Campaigns"
                        ? campaignData.map((item) =>
                            item.totalCTR == null ? 0 : item.totalCTR * 100
                          )
                        : data.map(
                            (item) =>
                              (parseFloat(item.clicks) * 100) /
                                parseFloat(item.impressions) ?? 0
                          )
                    }
                    xlabels={
                      dashboardType === "DSP Campaigns"
                        ? campaignData.map((item) => item.campaign)
                        : data.map((item) => item.campaignname)
                    }
                  />
                  {/* {campaignData.map((item) => item.campaign)} */}
                </div>
              </div>
              <div className="col-span-4 w-full">
                {dashboardType === "DSP Campaigns" ? (
                  <OsGraph campaigns={campaignData} />
                ) : (
                  <div className="border border-border p-8 h-full bg-white">
                    <h1 className="font-bold text-lg">Clicks</h1>
                    <PieChart
                      labels={data.map((item) => item.campaignname)}
                      values={data.map((item) => parseFloat(item.clicks))}
                    />
                  </div>
                )}
              </div>
              {/* <CampaignTable campaigns={campaignData} handleView={handleView} /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withAuth(CampaignOverview);
