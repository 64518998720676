
import AdvertiserWrapper from "../../components/wrappers/advertiser-wrapper";
import { useContext, useEffect, useState } from "react";
import { fetchCampaignListByAdvertiserId } from "../../api/ssp-api";
import { AuthContext } from "../../provider/AuthContext";
import Loader from "../../components/loader";
import AdvertiserCampaignTable from "./campaigns/campaign-table";

export default function AdvertiserOverview() {
  const { userDetails } = useContext(AuthContext);
  const { token, agencyId, advertiserId } = JSON.parse(userDetails);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCampaignListByAdvertiserId(advertiserId, base64Credentials)
      .then((response) => {
        setCampaignData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div >
        {loading || campaignData === undefined ? (
          <div item container spacing={2}>
            <Loader/>
          </div>
        ) : (
          <div item >
            <AdvertiserCampaignTable data={campaignData} />
          </div>
        )}
      </div>
    </div>
  );
}
