import { useEffect, useState } from "react";
import { fetchSspBanners } from "../../../../../api/ssp-api";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import Loader from "../../../../../components/loader";
import NoData from "../../../../../components/noData";

export default function LinkedBanners({ campaignId }) {
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    fetchSspBanners(base64Credentials, campaignId).then((response) => {
      console.log(`response: ${JSON.stringify(response.data)}`);
      setBannerData(response.data);
      setLoading(false);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      {loading || bannerData === undefined ? (
        <Loader />
      ) : bannerData.length < 1 ? (
        <NoData />
      ) : (
        <div>
          <div className="bg-white px-5 py-5 w-full rounded-md border border-border ">
            <div className="flex justify-end space-x-2">
              <input
                label="Filter by name or campaign name"
                placeholder="Search here"
                className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <table className="text-sm table w-full">
              <thead className="text-muted">
                <tr className="">
                  <th className="py-3 text-sm text-start">Banner Id</th>
                  <th className="text-sm text-start py-3">Banner Name</th>
                  <th className="text-sm text-start py-3">Width</th>
                  <th className="text-sm text-start py-3">Height</th>
                </tr>
              </thead>
              <tbody className="text-start">
                {bannerData
                  .filter((item) =>
                    item.bannerName.toLowerCase().includes(filter.toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                      <td className="text-start py-3 px-0 ">{item.bannerId}</td>
                      <td className="text-start py-3 px-0 ">
                        {item.bannerName}
                      </td>
                      <td className="text-start py-3 px-0 ">{item.width}</td>
                      <td className="text-start py-3 px-0">{item.height}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
              <h1 className=" translate-x-5">Rows per page</h1>
              <select
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(e.target.value);
                }}
                className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
              >
                <option className="" value={5}>
                  5
                </option>
                <option className="" value={8}>
                  8
                </option>
              </select>
              <div
                onClick={() => {
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
              >
                <MdOutlineChevronLeft />
              </div>
              <h1>
                {page + 1} out of {Math.ceil(bannerData.length / rowsPerPage)}
              </h1>
              <div
                onClick={() => {
                  if (page < Math.round(bannerData.length / rowsPerPage)) {
                    setPage(page + 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
              >
                <MdOutlineChevronRight />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
