import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { addCreative, fetchCampaignsByAdvertiser } from "../../../api/ssp-api";

import ImageDropzone from "../../banners/image-dropzone";
import {toast, ToastContainer} from "react-toastify";

export default function CreateSSPBanner() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [binaryData, setBinaryData] = useState("");
  const [formData, setFormData] = useState({
    campaignId: 0,
    bannerName: "banner",
    storageType: "web",
    imageURL: "",
    url: "",
    width: 0,
    height: 0,
    aImage: {
      filename: "",
      content: "",
    },
  });
  const [advertiserData, setAdvertiserData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [advertiserLoading, setAdvertiserLoading] = useState(true);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState("");
  const [campaignLoading, setCampaignLoading] = useState(true);
  const [hidden, setHidden] = useState(true);
  const [advertiserId, setAdvertiserId] = useState("");
  const [campaignId, setCampaignId] = useState("");

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("campaign"));
    setAdvertiserId(savedData.advertiserId);
    setCampaignId(savedData.campaignId);
    console.log("saved data:", savedData);
  }, []);

  const handleAdvertiserChange = (event) => {
    setSelectedAdvertiser(event.target.value);
    getCampaigns(event.target.value);
  };
  const getCampaigns = (selectedAdvertiser) => {
    fetchCampaignsByAdvertiser(selectedAdvertiser, base64Credentials)
      .then((r) => {
        if (r.data.length === 0) {
          setHidden(true);
          alert("No campaigns found for the selected advertiser");
          return;
        }
        setCampaignData(r.data);
        setCampaignLoading(false);
        setHidden(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleFileSelect = (file) => {
    setSelectedFiles((prev) => [...prev, file]);
    setIsImageSelected(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "storageType") {
      setIsVisible(value === "html");
    }
    const newValue =
      name === "width" || name === "height" || name === "campaignId"
        ? parseInt(value)
        : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFiles || selectedFiles.length === 0) {
      setError(true);
      toast.error("At least one banner is required");
      return;
    }

    const uploadPromises = selectedFiles.map((selectedFile) => {
      const extension = selectedFile.file.name.split(".").pop();
      let newFileName = Date.now() + "." + extension;
      // let formData = { ...yourFormDataStructure }; // Assuming yourFormDataStructure is an object template for formData
      formData.imageURL = `${process.env.REACT_APP_SSP_URL}/www/images/${newFileName}`;
      formData.bannerName = selectedFile.file.name;
      formData.fileName = `${newFileName}`;
      formData.aImage.filename = `${newFileName}`;
      formData.aImage.content = selectedFile.base64.split(",")[1];
      formData.campaignId = campaignId;

      console.log("banner data:", formData);

      return addCreative(formData, base64Credentials);
    });

    try {
      const responses = await Promise.all(uploadPromises);
      console.log("All banners uploaded:", responses);

      // Check if all responses are successful, then navigate
      if (responses.every(response => response.status === 200)) {
        navigate(`/ssp/campaign/link/${campaignId}`);
      } else {
        console.error("Some uploads failed");
        toast.error("Some uploads failed")
        // Handle the error scenario, maybe set an error state
      }
    } catch (error) {
      console.error("Error in uploading banners:", error);
        toast.error("Error in uploading banners" + error)
      // Handle the error scenario, maybe set an error state
    }
  };

  return (
    <div header={"Create Banner"}>
      <ToastContainer/>
      <div
        className="bg-white border border-border "
      >
        <div className="grid grid-cols-3">
          <div item  style={{ marginTop: "24px"}}>
            <input
              variant="outlined"
              fullWidth
              type="text"
              label="Destination URL"
              name="url"
              value={formData.url}
              onChange={handleChange}
            />
          </div>
          {isVisible ? (
            <div item>
              <input
                variant="outlined"
                fullWidth
                type="text"
                label="Folder name"
                name="folderName"
                value={formData.folderName}
                onChange={handleChange}
              />
            </div>
          ) : null}
          <div item xs={6} spacing={2}>
            <ImageDropzone onImageUpload={handleFileSelect} />
          </div>
          {error ? (
            <div item xs={12}>
              <p>Creative is required</p>
            </div>
          ) : null}
          {/*<div item xs={6}>*/}
          {/*  <input*/}
          {/*    fullWidth*/}
          {/*    type="number"*/}
          {/*    label="Width"*/}
          {/*    name="width"*/}
          {/*    value={formData.width}*/}
          {/*    onChange={handleChange}*/}
          {/*    required*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div item xs={6}>*/}
          {/*  <input*/}
          {/*    fullWidth*/}
          {/*    type="number"*/}
          {/*    label="Height"*/}
          {/*    name="height"*/}
          {/*    value={formData.height}*/}
          {/*    onChange={handleChange}*/}
          {/*    required*/}
          {/*  />*/}
          {/*</div>*/}

          <div item xs={4}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#6EBF4A",
                color: "#ffffff",
                '&:hover': {
                    backgroundColor: '#6EBF4A',
                    color: '#ffffff'
                    }
            }}
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
