import { useState } from "react";
import ImageCreatives from "./image-creatives";
import HtmlCreatives from "./html-creatives";

const CampaignAdverts = ({ savedCampaign,
    token,
    agencyId,
    handleComplete}) => {
     const [selectedTab,setSelectedTab] =   useState(0)
    return ( <div>
        <div className="flex space-x-6 mb-4">
         {["Image Creatives","HTML Creatives"].map((item,index)=>{
            return <div onClick={()=>{
                setSelectedTab(index)
            }} className={` border-b-4 ${selectedTab == index ?"border-primary":"border-transparent"} py-2 cursor-pointer`}>{item}</div>
         })}
        </div> 
        {selectedTab  == 0 && <ImageCreatives savedCampaign={savedCampaign} agencyId={agencyId}  token={token} handleComplete={handleComplete}/>}
        {selectedTab  == 1 && <HtmlCreatives savedCampaign={savedCampaign} agencyId={agencyId} token={token} handleComplete={handleComplete}/>}
        
    </div> );
}
 
export default CampaignAdverts;