import React, { useContext, useEffect, useState } from "react";

import { getAdvertiserCreativesApi, getCreatives } from "../../api/api";
import Loader from "../../components/loader";
import DspBanners from "./dsp-banners";

import { useNavigate } from "react-router-dom";

import SspBanners from "./ssp-banners";
import {
  fetchAdvertiserByAgencyId,
  fetchAdvertisersByAgencyId,
  sspURL,
} from "../../api/ssp-api";
import { AuthContext } from "../../provider/AuthContext";
import axios from "axios";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";

export default function Banners() {
  const [bannerData, setBannerData] = useState([]);
  const [advertiserData, setAdvertiserData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [hidden, setHidden] = useState(true);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [advertiserLoading, setAdvertiserLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const { userDetails } = useContext(AuthContext);

  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { setActions } = useContext(BreadcrumbContext);
  const [refresh, setRefresh] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setActions(<div></div>);
    const { token, role } = JSON.parse(userDetails);
    getCreatives(token)
      .then((res) => {
        setBannerData(res.data);
        console.log(res.data);
        setLoading(false);
        setHidden(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    fetchData();
    // console.log(response)
    // setAdvertiserData(response.data);
    // setAdvertiserLoading(false);
  }, [refresh]);

  async function fetchData() {
    const config = {
      method: "get",
      url: `${sspURL}/adv/agc/2`,
      headers: {
        Authorization: `Basic ${base64Credentials}`,
      },
      timeout: 10000, // 10 seconds timeout
    };

    try {
      const response = await axios(config);
      console.log(response.data);
      setAdvertiserData(response.data);
      setAdvertiserLoading(false);
      // Additional processing can be done here
    } catch (error) {
      console.error("Error fetching data:", error);
      // Error handling logic here
    }
  }

  const getAdvertiserCreatives = (token, id) => {
    getAdvertiserCreativesApi(token, id)
      .then((res) => {
        console.log(res.data);
        setBannerData(res.data);
        setLoading(false);
        setHidden(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return loading ? (
    <Loader />
  ) : (
    <div header="Banners">
      <div className="flex justify-end">
        {/* <div
           className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer"
           onClick={() => navigate("/banners/create")}
          >
           Create Banner
          </div> */}
      </div>
      <div>
        <div className="flex space-x-4 ">
          {["DSP Creatives", "SSP Creatives"].map((item, index) => {
            return (
              <div className="cursor-pointer space-y-1">
                <div
                  onClick={() => {
                    setSelectedTab(index);
                  }}
                  className={`${index == selectedTab ? "text-primary" : ""}`}
                >
                  {item}
                </div>
                {index == selectedTab && (
                  <div className="w-full h-1 bg-primary" />
                )}
              </div>
            );
          })}
        </div>

        {selectedTab == 0 && (
          <DspBanners
            refresh={refresh}
            setRefresh={setRefresh}
            bannerData={bannerData}
          />
        )}
        {selectedTab == 1 && <SspBanners advertiserData={advertiserData} />}
      </div>
    </div>
  );
}
