import { GrTrash } from "react-icons/gr";
import CustomModal from "./custom-modal";
import { useContext, useState } from "react";
import { DeletionContext } from "../layouts/dashboardLayout";

const DeleteButton = ({ onClick, key }) => {
  const { setOnDelete, showConfirmDeletion, setShowConfirmDeletion } =
    useContext(DeletionContext);

  return (
    <div key={key}>
      <button
        className="font-bold text-red-400 bg-red-100 py-1 rounded-md px-2"
        onClick={() => {
          setOnDelete(() => onClick);
          setShowConfirmDeletion(true);
        }}
      >
        Delete
      </button>
    </div>
  );
};

export default DeleteButton;
