import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PublisherTable from "./websites-table";
import { AuthContext } from "../../../../provider/AuthContext";
import { fetchPublisherWebsites } from "../../../../api/api";
import Loader from "../../../../components/loader";
import { BreadcrumbContext } from "../../../../layouts/dashboardLayout";
import Spinner from "../../../../components/spinner";

export default function AgencyPublisherWebsites() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);
  const { setActions } = useContext(BreadcrumbContext);

  useEffect(() => {
    setActions(
      <div>
        <div
          className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer"
          onClick={() => navigate("/agency/websites/new")}
        >
          Add New Website
        </div>
      </div>
    );
    fetchPublisherWebsites(token, agencyId)
      .then((response) => {
        console.log(response.data.publishers);
        setData(response.data.publishers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#6EBF4A",
        contrastText: "#FFFFFF",
      },
    },
  });

  if (data.length === 0) {
    return loading?<Loader/>: (
      <div header={"Publisher Websites"}>
        <div>
          <button
            variant="contained"
            color="primary"
            onClick={() => navigate("/agency/websites/new")}
          >
            Add New Website
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
<div className="flex justify-end"></div>
      <div item xs={12} style={{ overflowY: "auto" }}>
        {loading || data === undefined ? (
          <Loader />
        ) : (
          <PublisherTable data={data} />
        )}
      </div>
    </div>
      
  );
}
